import React from "react";
import LayoutWithSidebar from "../components/LayoutWithSidebar";
import MainSideMenu from "../components/MainSideMenu";
import MainHeaderContainer from "../containers/MainHeaderContainer";
import GangMembersListContainer from "../containers/GangMembersListContainer";

interface Props {}

const GangMemberPage = (props: Props) => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <MainSideMenu></MainSideMenu>;
  };

  const contentPanelContent = () => {
    return <GangMembersListContainer></GangMembersListContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default GangMemberPage;
