import RequestPermit from "../components/RequestPermit";
import { useRequestPermit } from "../operations/mutations/requestPermit";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useLazyGetPermitToDig } from "../operations/queries/getPermitToDig";
import { triggerRequestPermitted } from "../models/Permit";
import { useRequestRejectedPermit } from "../operations/mutations/requestRejectedPermit";
import { GET_PERMIT_TO_DIGS_BY_PROJECT_ID } from "../operations/queries/getPermitToDigsByProjectId";
import { PermitDataInput } from "../models/PermitDataInput";
import { useLazyGetAffectedServicesOptions } from "../operations/queries/getAffectedServicesOptions";
import { useLazyGetNextPermitNumber } from "../operations/queries/getNextPermitNumber";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";
import { EsriIntegrationTypes } from "../models/EsriIntegrationType";

const RequestPermitContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const { currentProject } = useCurrentProject();
  const { permitId } = useParams<{ permitId: string }>();

  const { mutate: requestRejectedPermitMutate, status: requestRejectedPermitStatus } = useRequestRejectedPermit();
  const { getPermitToDig: getPermit, data: permit } = useLazyGetPermitToDig();
  const { getAffectedServicesOptions, data: affectedServicesOptions } = useLazyGetAffectedServicesOptions();
  const { mutate: requestPermitMutate, status: requestPermitStatus } = useRequestPermit();
  const { getNextPermitNumber, data: getNextPermitNumberData } = useLazyGetNextPermitNumber();

  useEffect(() => {
    getAffectedServicesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permitId) {
      getPermit({ variables: { id: permitId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitId]);

  useEffect(() => {
    if (currentProject && !permitId) {
      getNextPermitNumber({ variables: { projectId: currentProject.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, permitId]);

  const handleRequestPermit = (
    permitDataInput: PermitDataInput,
    number: number,
    documentIds: string[],
    boundaries: GeoJSONPolygonType
  ) => {
    if (currentProject) {
      requestPermitMutate({
        variables: {
          permitDataInput,
          number,
          projectId: currentProject.id,
          documentIds,
          boundaries,
        },
      });
    }
  };

  const handleRequestRejectedPermit = (
    id: string,
    permitDataInput: PermitDataInput,
    documentIds: string[],
    boundaries: GeoJSONPolygonType
  ) => {
    if (permit && currentProject) {
      requestRejectedPermitMutate({
        refetchQueries: [
          {
            query: GET_PERMIT_TO_DIGS_BY_PROJECT_ID,
            variables: {
              projectId: currentProject.id,
            },
          },
        ],
        variables: {
          id,
          permitDataInput,
          documentIds,
          boundaries,
        },
      });
    }
  };

  return (
    <RequestPermit
      showPermitBoundariesOnMap={
        currentOrganization?.esriConfig?.integrationTypeId != null &&
        currentOrganization.esriConfig.integrationTypeId !== EsriIntegrationTypes.NONE.id
      }
      requestButtonVisible={!permit}
      requestRejectedPermitButtonsVisible={permit && triggerRequestPermitted(permit)}
      permit={permit}
      currentProject={currentProject}
      affectedServicesOptions={affectedServicesOptions && affectedServicesOptions.affectedServicesOptions}
      onRequestPermit={handleRequestPermit}
      requestPermitStatus={requestPermitStatus}
      onRequestRejectedPermit={handleRequestRejectedPermit}
      requestRejectedPermitStatus={requestRejectedPermitStatus}
      nextPermitNumber={getNextPermitNumberData?.nextPermitNumber}
    ></RequestPermit>
  );
};

export default RequestPermitContainer;
