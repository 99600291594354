import { View } from "@react-pdf/renderer";

type Props = { height?: number };

const Break = ({ height }: Props) => {
  return (
    <View
      style={{
        marginVertical: height ? height : 10,
      }}
    />
  );
};

export default Break;
