import { Modal } from "semantic-ui-react";
import { Document_Data } from "../models/Document_Data";
import DocumentViewContainer from "../containers/DocumentViewContainer";
import { Document_ListItem } from "../models/Document_ListItem";

interface Props {
  open: boolean;
  onClose: () => void;
  document?: Document_Data;
  onChangeVersion?: (doc: Document_ListItem) => void;
}

const ViewDocumentModal = ({ open, onClose, document, onChangeVersion }: Props) => {
  return (
    <Modal open={open}>
      <Modal.Content>
        <Modal.Description>
          <DocumentViewContainer
            isPopup={true}
            onClose={onClose}
            document={document}
            onChangeVersion={onChangeVersion}
          ></DocumentViewContainer>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ViewDocumentModal;
