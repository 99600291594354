import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { UpdateOrganization, UpdateOrganizationVariables } from "./__generated__/UpdateOrganization";

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($id: String!, $name: String, $logoMetaData: FileMetaDataInput) {
    updateOrganization(input: { id: $id, name: $name, logoMetaData: $logoMetaData }) {
      organization {
        id
        name
      }
    }
  }
`;

export function useUpdateOrganization() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateOrganization,
    UpdateOrganizationVariables
  >(UPDATE_ORGANIZATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
