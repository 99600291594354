import { gql } from "@apollo/client";

export const ADMIN_USER_FIELDS = gql`
  fragment AdminUserFields on ApplicationUser {
    id
    firstName
    lastName
    email
  }
`;
