import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { getErrors } from "../../utils/apolloError";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { DeleteProjectAssignment, DeleteProjectAssignmentVariables } from "./__generated__/DeleteProjectAssignment";

const DELETE_PROJECT_ASSIGNMENT = gql`
  mutation DeleteProjectAssignment($projectId: String!, $projectAssignmentId: String!) {
    deleteProjectAssignment(input: { projectId: $projectId, projectAssignmentId: $projectAssignmentId }) {
      deletedProjectAssignmentId
    }
  }
`;

export function useDeleteProjectAssignment() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteProjectAssignment,
    DeleteProjectAssignmentVariables
  >(DELETE_PROJECT_ASSIGNMENT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
