import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Segment, Statistic } from "semantic-ui-react";
import { StateKey } from "../models/globalTypes";
import { Organization } from "../models/Organization";
import { Project } from "../models/Project";

interface Props {
  data: { stateKey?: string; count: number }[];
  currentProject?: Project;
  currentOrganization?: Organization;
}

const ProjectPermitsCounts = ({ data, currentOrganization, currentProject }: Props) => {
  var history = useHistory();

  const requestedPermits = data.filter((s) => s.stateKey === StateKey.REQUESTED)[0]?.count ?? 0;
  const livePermits = data.filter((s) => s.stateKey === StateKey.ISSUED)[0]?.count ?? 0;
  const expiredPermits = data.filter((s) => s.stateKey === StateKey.EXPIRED)[0]?.count ?? 0;
  const inprogressPermits = data.filter((s) => s.stateKey === StateKey.IN_PROGRESS)[0]?.count ?? 0;
  const completedPermits = data.filter((s) => s.stateKey === StateKey.COMPLETED)[0]?.count ?? 0;
  const readyToIssuePermits = data.filter((s) => s.stateKey === StateKey.READY_TO_ISSUE)[0]?.count ?? 0;
  //const totalPermits = data.reduce((sum, item) => sum + item.count, 0);

  const handleClick = (state: StateKey | null) => {
    const queryString = new URLSearchParams();
    if (state) {
      queryString.set("state", state.toString());
    }

    history.push({
      pathname: `/${currentOrganization!.name}/projects/${currentProject!.name}/permits`,
      search: queryString.toString(),
    });
  };

  const renderStatistic = (value: number, label: string, onClick: () => void) => {
    return (
      <Card as="a" onClick={onClick}>
        <Segment basic textAlign="center">
          <Statistic>
            <Statistic.Label>{label}</Statistic.Label>
            <Statistic.Value>{value}</Statistic.Value>
          </Statistic>
        </Segment>
      </Card>
    );
  };

  return (
    <>
      <Segment basic>
        <Card.Group stackable doubling itemsPerRow={3}>
          {renderStatistic(requestedPermits, "Requested/Pending Approval", () => {
            handleClick(StateKey.REQUESTED);
          })}
          {renderStatistic(readyToIssuePermits, "Ready to Issue Permits", () => {
            handleClick(StateKey.READY_TO_ISSUE);
          })}
          {renderStatistic(livePermits, "Live/Issued Permits", () => {
            handleClick(StateKey.ISSUED);
          })}
          {renderStatistic(expiredPermits, "Expired Permits", () => {
            handleClick(StateKey.EXPIRED);
          })}
          {renderStatistic(inprogressPermits, "In progress Permits", () => {
            handleClick(StateKey.IN_PROGRESS);
          })}
          {renderStatistic(completedPermits, "Completed Permits", () => {
            handleClick(StateKey.COMPLETED);
          })}
        </Card.Group>
      </Segment>
    </>
  );
};

export default ProjectPermitsCounts;
