import moment from "moment";
import { Form, Select } from "semantic-ui-react";

interface Props {
  controlName: string;
  hoursLabel: string;
  minutesLabel: string;
  showMinutes?: boolean;
  timeSpan?: string;
  error?: string;
  onChange: (e: any, { name, value }: any) => void;
}

const TimePicker = ({ controlName, hoursLabel, minutesLabel, showMinutes, timeSpan, error, onChange }: Props) => {
  const hours = timeSpan ? moment.duration(timeSpan).hours() : undefined;
  const minutes = timeSpan ? moment.duration(timeSpan).minutes() : undefined;

  const handleChange = (e: any, { name, value }: any) => {
    let val: string = "";

    if (name === "hours") {
      val = moment.duration({ hours: value, minutes }).toISOString();
    }

    if (name === "minutes") {
      val = moment.duration({ hours, minutes: value }).toISOString();
    }

    onChange(e, { name: controlName, value: val });
  };

  const hoursOptions = [
    { key: "0", text: "00", value: 0 },
    { key: "1", text: "01", value: 1 },
    { key: "2", text: "02", value: 2 },
    { key: "3", text: "03", value: 3 },
    { key: "4", text: "04", value: 4 },
    { key: "5", text: "05", value: 5 },
    { key: "6", text: "06", value: 6 },
    { key: "7", text: "07", value: 7 },
    { key: "8", text: "08", value: 8 },
    { key: "9", text: "09", value: 9 },
    { key: "10", text: "10", value: 10 },
    { key: "11", text: "11", value: 11 },
    { key: "12", text: "12", value: 12 },
    { key: "13", text: "13", value: 13 },
    { key: "14", text: "14", value: 14 },
    { key: "15", text: "15", value: 15 },
    { key: "16", text: "16", value: 16 },
    { key: "17", text: "17", value: 17 },
    { key: "18", text: "18", value: 18 },
    { key: "19", text: "19", value: 19 },
    { key: "20", text: "20", value: 20 },
    { key: "21", text: "21", value: 21 },
    { key: "22", text: "22", value: 22 },
    { key: "23", text: "23", value: 23 },
  ];

  const minutesOptions = [
    { key: "1", text: "00", value: 0 },
    { key: "2", text: "05", value: 5 },
    { key: "3", text: "10", value: 10 },
    { key: "4", text: "15", value: 15 },
    { key: "5", text: "20", value: 20 },
    { key: "6", text: "25", value: 25 },
    { key: "7", text: "30", value: 30 },
    { key: "8", text: "35", value: 35 },
    { key: "9", text: "40", value: 40 },
    { key: "10", text: "45", value: 45 },
    { key: "11", text: "50", value: 50 },
    { key: "12", text: "55", value: 55 },
  ];

  return (
    <>
      <Form.Field
        required
        error={error}
        control={Select}
        name="hours"
        value={hours}
        fluid
        label={hoursLabel}
        options={
          showMinutes
            ? hoursOptions
            : hoursOptions.map((op) => {
                return { ...op, text: op.text + ":00" };
              })
        }
        onChange={handleChange}
      />
      {showMinutes && (
        <Form.Field
          required
          error={error}
          control={Select}
          name="minutes"
          value={minutes}
          fluid
          label={minutesLabel}
          options={minutesOptions}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default TimePicker;
