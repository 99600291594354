import { Input, InputOnChangeData, Menu } from "semantic-ui-react";
import { DataGrid } from "./common/DataGrid";
import { Column } from "react-table";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { Document_ListItem } from "../models/Document_ListItem";

interface Props {
  documents?: Document_ListItem[] | null;
  pageCount?: number;
  loading?: boolean;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchDocuments: (serachStrign: string) => void;
  onSelectDocument: (doc: Document_ListItem) => void;
}

const SelectDocument = ({
  documents,
  pageCount,
  loading,
  onPageInfoChange,
  onSearchDocuments,
  onSelectDocument,
}: Props) => {
  const columns: Column<Document_ListItem>[] = [
    {
      Header: "Title",
      id: "Title",
      accessor: (doc) => doc.title,
    },
    {
      Header: "Type",
      id: "Type",
      accessor: (doc) => doc.type.name,
    },
    {
      Header: "Version",
      id: "Version",
      accessor: (doc) => doc.versionNo,
    },
    {
      Header: "Date",
      id: "Date",
      accessor: (doc) => doc.dateCreatedUtc,
      Cell: ({ value }: any) => (
        <Popup
          trigger={<span>{moment(value).local().fromNow()}</span>}
          content={moment.utc(value).local().format("llll")}
        ></Popup>
      ),
    },
    {
      Header: "Created by",
      id: "Created by",
      accessor: (doc) => doc.createdBy.user.displayName,
    },
  ];

  const handleSelectDocument = (doc: Document_ListItem, i: number) => {
    onSelectDocument(doc);
  };

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchDocuments(value);
  };

  return (
    <>
      <Menu pointing secondary color="green">
        <Menu.Item>
          <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} />
        </Menu.Item>
      </Menu>
      <DataGrid
        columns={columns}
        data={documents ? documents : []}
        onRowClick={handleSelectDocument}
        onPageInfoChange={onPageInfoChange}
        loading={loading ? loading : false}
        pageCount={pageCount}
      />
    </>
  );
};

export default SelectDocument;
