import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddProject, AddProjectVariables } from "./__generated__/AddProject";
import { CORE_PROJECT_FIELDS } from "../fragments/project";

const ADD_PROJECT = gql`
  ${CORE_PROJECT_FIELDS}
  mutation AddProject(
    $name: String!
    $number: String
    $location: String!
    $boundaries: GeoJSONPolygonInput
    $emergencyContact: EmergencyContactInput
    $organizationId: String!
  ) {
    addProject(
      input: {
        name: $name
        number: $number
        location: $location
        boundaries: $boundaries
        emergencyContact: $emergencyContact
        organizationId: $organizationId
      }
    ) {
      project {
        ...CoreProjectFields
      }
    }
  }
`;

export function useAddProject() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<AddProject, AddProjectVariables>(
    ADD_PROJECT,
    {
      errorPolicy: "all",
    }
  );
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
