import { useEffect, useState } from "react";
import { Button, Checkbox, Divider, Grid, Header, Icon, Item, Menu, Segment } from "semantic-ui-react";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { OrgRole } from "../models/OrgRole";
import { OrgUser } from "../models/OrgUser";
import { Project } from "../models/Project";
import { ProjectAssignment } from "../models/ProjectAssignment";
import { toastSuccess } from "../utils";
import AddProjectAssignmentModal from "./AddProjectAssignmentModal";
import MapWithBoundaries from "./MapWithBoundaries";
import ProjectAssignmentsGrid from "./ProjectAssignmentsGrid";
import ViewProjectModal from "./ViewProjectModal";

interface Props {
  showProjectBoundariesOnMap?: boolean;
  isPopup?: boolean;
  project?: Project;
  projectAssignments?: ProjectAssignment[];
  orgUsers?: OrgUser[];
  orgRoles?: OrgRole[];
  onClose: () => void;
  onOrgUsersRequest: () => void;
  onAddProjectAssignments: (orgUserIds: string[], projectId: string, orgRoleId: string) => void;
  addProjectAssignmentsStatus: AsyncActionStatus;
  onDeleteProjectAssignment: (id: string) => void;
  deleteProjectAssignmentStatus: AsyncActionStatus;
}

const ProjectView = ({
  showProjectBoundariesOnMap,
  isPopup,
  project,
  projectAssignments,
  orgUsers,
  orgRoles,
  onClose,
  onOrgUsersRequest,
  onAddProjectAssignments,
  addProjectAssignmentsStatus,
  onDeleteProjectAssignment,
  deleteProjectAssignmentStatus,
}: Props) => {
  const [openViewProjectModal, setOpenViewProjectModal] = useState(false);
  const [openProjectAssignmentModal, setOpenProjectAssignmentModal] = useState(false);
  const [showMapLegendSelected, setShowMapLegendSelected] = useState(false);
  const [showMapLayersListSelected, setShowMapLayersListSelected] = useState(false);

  useEffect(() => {
    if (deleteProjectAssignmentStatus.successful) {
      toastSuccess("Assignment deleted successfully!");
    }
  }, [deleteProjectAssignmentStatus.successful]);

  const handleModalClose = () => {
    setOpenViewProjectModal(false);
    setOpenProjectAssignmentModal(false);

    addProjectAssignmentsStatus.reset();
    deleteProjectAssignmentStatus.reset();
  };

  const handleProjectAssignmentModalSubmit = (orgUserIds: string[], orgRoleId: string) => {
    if (project) {
      onAddProjectAssignments(orgUserIds, project.id, orgRoleId);
    }
  };

  useEffect(() => {
    if (addProjectAssignmentsStatus.successful) {
      toastSuccess("Assignments added successfully!");
      setOpenProjectAssignmentModal(false);
    }
  }, [addProjectAssignmentsStatus.successful]);

  const handleViewProjectPopup = () => {
    setOpenViewProjectModal(true);
  };

  const handelAddProjectAssignment = () => {
    onOrgUsersRequest();
    setOpenProjectAssignmentModal(true);
  };

  const ProjectViewItem = (header: string, value?: string | null) => {
    return (
      <Item.Group>
        <Item>
          <Item.Content>
            <Item.Meta>{header}</Item.Meta>
            <Item.Description>{value ? value : ""}</Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    );
  };

  return (
    <>
      {project && (
        <>
          <Segment attached="top" basic secondary compact>
            <Button
              circular
              icon="close"
              floated="right"
              style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
              onClick={onClose}
            ></Button>
            <>
              {!isPopup && (
                <>
                  <Button
                    circular
                    icon="arrow up"
                    floated="right"
                    style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
                    onClick={handleViewProjectPopup}
                  ></Button>
                </>
              )}
            </>
            <Header as="h4" style={{ display: "inline" }}>
              Project details
            </Header>
          </Segment>

          <Segment attached color="green" style={{ borderTop: "3px !important" }}>
            <Grid>
              <Grid.Column width={showProjectBoundariesOnMap && isPopup ? "8" : "16"}>
                <Grid columns="2">
                  <Grid.Row>
                    <Grid.Column>{ProjectViewItem("Name", project.name)}</Grid.Column>
                    <Grid.Column>{ProjectViewItem("Number", project.number || "-")}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>{ProjectViewItem("Location", project.location)}</Grid.Column>
                  </Grid.Row>
                  <Divider horizontal>Emergency Contact</Divider>
                  <Grid.Row>
                    <Grid.Column>{ProjectViewItem("Name", project.emergencyContact?.name || "-")}</Grid.Column>
                    <Grid.Column>
                      {ProjectViewItem("Phone Number", project.emergencyContact?.phoneNumber || "-")}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Divider />

                <Button
                  //compact
                  circular
                  floated="right"
                  style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
                  onClick={handelAddProjectAssignment}
                >
                  <Icon size="small" name="plus" />
                  Add member
                </Button>
                <Header as="h4" style={{ display: "inline" }}>
                  Project team
                </Header>

                <Divider />

                <ProjectAssignmentsGrid
                  projectAssignments={projectAssignments}
                  onDeleteProjectAssignment={onDeleteProjectAssignment}
                  deleteProjectAssignmentInProgress={deleteProjectAssignmentStatus.loading}
                ></ProjectAssignmentsGrid>
              </Grid.Column>
              {isPopup && showProjectBoundariesOnMap && project && (
                <Grid.Column width="8" style={{ padding: "0px", backgroundColor: "#f1f1f1" }}>
                  <Menu
                    borderless
                    size="small"
                    style={{ marginBottom: "0px", border: "0", backgroundColor: "#f1f1f1" }}
                  >
                    <Menu.Item header>Map options:</Menu.Item>
                    <Menu.Item position="right">
                      <Checkbox
                        slider
                        label="Layers list"
                        checked={showMapLayersListSelected}
                        onChange={(e, data) => {
                          setShowMapLayersListSelected(data.checked ?? false);
                          if (data.checked) {
                            setShowMapLegendSelected(!data.checked);
                          }
                        }}
                      ></Checkbox>
                    </Menu.Item>
                    <Menu.Item>
                      <Checkbox
                        slider
                        label="Map legend"
                        checked={showMapLegendSelected}
                        onChange={(e, data) => {
                          setShowMapLegendSelected(data.checked ?? false);
                          if (data.checked) {
                            setShowMapLayersListSelected(!data.checked);
                          }
                        }}
                      ></Checkbox>
                    </Menu.Item>
                  </Menu>

                  <MapWithBoundaries
                    items={[]}
                    showLegend={showMapLegendSelected}
                    showLayersList={showMapLayersListSelected}
                    boundaries={project.boundaries}
                    height="600px"
                  />
                </Grid.Column>
              )}
            </Grid>
          </Segment>

          <ViewProjectModal
            showProjectBoundariesOnMap={showProjectBoundariesOnMap}
            open={openViewProjectModal}
            onClose={handleModalClose}
            project={project}
          ></ViewProjectModal>

          <AddProjectAssignmentModal
            open={openProjectAssignmentModal}
            onClose={handleModalClose}
            onSubmit={handleProjectAssignmentModalSubmit}
            submitInProgress={addProjectAssignmentsStatus.loading}
            availableOrgUsers={orgUsers?.filter((u) => !projectAssignments?.map((a) => a.orgUser.id).includes(u.id))}
            availableOrgRoles={orgRoles}
          ></AddProjectAssignmentModal>
        </>
      )}{" "}
    </>
  );
};

export default ProjectView;
