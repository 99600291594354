import { gql, useLazyQuery } from "@apollo/client";
import { getErrors } from "../../utils";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import {
  GetPermitRequestApprovalStats,
  GetPermitRequestApprovalStatsVariables,
} from "./__generated__/GetPermitRequestApprovalStats";

export const GET_PERMIT_REQUEST_APPROVAL_STATS = gql`
  query GetPermitRequestApprovalStats($projectId: String!) {
    permitRequestApprovalStats(projectId: $projectId) {
      approved
      rejected
    }
  }
`;

export function useGetPermitRequestApprovalStats() {
  const [getPermitRequestApprovalStats, { loading, error, data }] = useLazyQuery<
    GetPermitRequestApprovalStats,
    GetPermitRequestApprovalStatsVariables
  >(GET_PERMIT_REQUEST_APPROVAL_STATS, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return {
    getPermitRequestApprovalStats,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
