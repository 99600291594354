import { gql, useLazyQuery } from "@apollo/client";
import { getErrors } from "../../utils";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import {
  GetPermitAverageStateTransitionTime,
  GetPermitAverageStateTransitionTimeVariables,
} from "./__generated__/GetPermitAverageStateTransitionTime";

export const GET_PERMIT_AVERAGE_STATE_TRANSITIOM_TIME = gql`
  query GetPermitAverageStateTransitionTime(
    $projectId: String!
    $stateKeyFrom: StateKey!
    $stateKeyTo: StateKey!
    $dateFrom: DateTime!
    $dateTo: DateTime!
  ) {
    permitAverageStateTransitionTime(
      projectId: $projectId
      stateKeyFrom: $stateKeyFrom
      stateKeyTo: $stateKeyTo
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`;

export function useGetPermitAverageStateTransitionTime() {
  const [getPermitAverageStateTransitionTime, { loading, error, data }] = useLazyQuery<
    GetPermitAverageStateTransitionTime,
    GetPermitAverageStateTransitionTimeVariables
  >(GET_PERMIT_AVERAGE_STATE_TRANSITIOM_TIME, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return {
    getPermitAverageStateTransitionTime,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
