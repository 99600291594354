import { useEffect, useState } from "react";
import { Segment, Menu, Header } from "semantic-ui-react";
import AdminOrganizationsGrid from "./AdminOrganizationsGrid";
import { Organization } from "../models/Organization";
import AdminEditOrganizationModal from "./AdminEditOrganizationModal";
import AdminEditEsriConfigModal from "./AdminEditEsriConfigModal";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { toastError, toastSuccess } from "../utils";
import ConfirmModal from "./common/ConfirmModal";
import { UserFile } from "../models/UserFile";
import { FileMetaData } from "../models/FileMetaData";

interface Props {
  isOrganizationsLoading: boolean;
  organizations?: Organization[];
  onAddOrganization: (name: string, addDefaultRoles: boolean, logoMetaData: FileMetaData | null) => void;
  addOrganizationStatus: AsyncActionStatus;
  onEditOrganization: (id: string, name: string, logoMetaData: FileMetaData | null) => void;
  editOrganizationStatus: AsyncActionStatus;
  onEditEsriConfig: (id: string, integrationTypeId: string, webMapItemId: string) => void;
  editEsriConfigStatus: AsyncActionStatus;
  onDeleteOrganization: (id: string) => void;
  deleteOrganizationStatus: AsyncActionStatus;
  logo?: UserFile;
  onUploadLogo: (logo: UserFile) => void;
  uploading: boolean;
}

const AdminOrganizationsList = ({
  isOrganizationsLoading,
  organizations,
  onAddOrganization,
  addOrganizationStatus,
  onEditOrganization,
  editOrganizationStatus,
  onEditEsriConfig,
  editEsriConfigStatus,
  onDeleteOrganization,
  deleteOrganizationStatus,
  logo,
  onUploadLogo,
  uploading,
}: Props) => {
  const [openEditOrganizationModal, setOpenEditOrganizationModal] = useState(false);
  const [openEditEsriConfigModal, setOpenEditEsriConfigModal] = useState(false);
  const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false);
  const [selectedOrganizationToEdit, setSelectedOrganizationToEdit] = useState<Organization | undefined>(undefined);
  const [selectedOrganizationToDelete, setSelectedOrganizationToDelete] = useState<Organization | undefined>(undefined);

  const handleEsriConfigClicked = (organizationId: string) => {
    const org = organizations?.find((o) => o.id === organizationId);
    if (org) {
      setSelectedOrganizationToEdit({ ...org } as Organization);
      setOpenEditEsriConfigModal(true);
    }
  };

  const handleEditOrganization = (organizationId: string) => {
    const org = organizations?.find((o) => o.id === organizationId);
    if (org) {
      setSelectedOrganizationToEdit({ ...org } as Organization);
      setOpenEditOrganizationModal(true);
    }
  };

  const handleDeleteOrganization = (organizationId: string) => {
    const org = organizations?.find((o) => o.id === organizationId);
    if (org) {
      setSelectedOrganizationToDelete({ ...org } as Organization);
      setOpenConfirmRemoveModal(true);
    }
  };

  useEffect(() => {
    if (deleteOrganizationStatus.successful) {
      toastSuccess("Organization deleted successfully!");
      handleModalClose();
    } else if (deleteOrganizationStatus.errors) {
      toastError("Unable to delete this organization");
      handleModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrganizationStatus]);

  const handleModalClose = () => {
    setOpenEditOrganizationModal(false);
    setOpenEditEsriConfigModal(false);
    setOpenConfirmRemoveModal(false);
    setSelectedOrganizationToEdit(undefined);
    setSelectedOrganizationToDelete(undefined);

    editEsriConfigStatus.reset();
    addOrganizationStatus.reset();
    editOrganizationStatus.reset();
    deleteOrganizationStatus.reset();
  };

  const handleEditOrganizationModalSubmit = (
    name: string,
    addDefaultRoles: boolean,
    logoMetaData: FileMetaData | null,
    id?: string
  ) => {
    if (id) {
      onEditOrganization(id, name, logoMetaData);
    } else {
      onAddOrganization(name, addDefaultRoles, logoMetaData);
    }
  };

  const handleEditEsriConfigModalSubmit = (integrationTypeId: string, webMapItemId: string, id: string) => {
    if (id) {
      onEditEsriConfig(id, integrationTypeId, webMapItemId);
    }
  };

  const handleConfirmRemoveModalSubmit = () => {
    selectedOrganizationToDelete && onDeleteOrganization(selectedOrganizationToDelete.id);
  };

  useEffect(() => {
    if (addOrganizationStatus.successful) {
      toastSuccess("Organization added successfully!");
      setOpenEditOrganizationModal(false);
      setSelectedOrganizationToEdit(undefined);
    }
  }, [addOrganizationStatus.successful]);

  useEffect(() => {
    if (editOrganizationStatus.successful) {
      toastSuccess("Organization updated successfully!");
      setOpenEditOrganizationModal(false);
      setSelectedOrganizationToEdit(undefined);
    }
  }, [editOrganizationStatus.successful]);

  useEffect(() => {
    if (editEsriConfigStatus.successful) {
      toastSuccess("Esri configuration updated successfully!");
      setOpenEditEsriConfigModal(false);
      setSelectedOrganizationToEdit(undefined);
    }
  }, [editEsriConfigStatus.successful]);

  return (
    <>
      <Segment basic>
        <Header as="h2">Organization</Header>
      </Segment>

      <Menu pointing secondary color="green">
        <Menu.Item icon="plus" name="New organization" onClick={(e) => setOpenEditOrganizationModal(true)} />
      </Menu>

      <Segment basic loading={isOrganizationsLoading}>
        <AdminOrganizationsGrid
          organizations={organizations}
          onEditOrganization={handleEditOrganization}
          onDeleteOrganization={handleDeleteOrganization}
          onEsriConfigClicked={handleEsriConfigClicked}
        />
      </Segment>

      <AdminEditOrganizationModal
        open={openEditOrganizationModal}
        onClose={handleModalClose}
        onSubmit={handleEditOrganizationModalSubmit}
        submitInProgress={addOrganizationStatus.loading || editOrganizationStatus.loading}
        organization={selectedOrganizationToEdit}
        submitErrors={
          addOrganizationStatus.errors
            ? addOrganizationStatus.errors
            : editOrganizationStatus.errors
            ? editOrganizationStatus.errors
            : undefined
        }
        logo={logo}
        onUploadLogo={onUploadLogo}
        uploading={uploading}
      ></AdminEditOrganizationModal>

      <AdminEditEsriConfigModal
        open={openEditEsriConfigModal}
        onClose={handleModalClose}
        onSubmit={handleEditEsriConfigModalSubmit}
        submitInProgress={editOrganizationStatus.loading}
        organization={selectedOrganizationToEdit}
        submitErrors={editEsriConfigStatus.errors ? editEsriConfigStatus.errors : undefined}
      ></AdminEditEsriConfigModal>

      <ConfirmModal
        title="Remove organization"
        open={openConfirmRemoveModal}
        onClose={handleModalClose}
        onSubmit={handleConfirmRemoveModalSubmit}
      ></ConfirmModal>
    </>
  );
};

export default AdminOrganizationsList;
