import { useCallback, useEffect, useState } from "react";
import { matchPath, useHistory, useParams, useLocation } from "react-router-dom";
import { Breadcrumb, Dropdown, Menu, MenuItem } from "semantic-ui-react";
import { Organization } from "../models/Organization";
import { LoginMenu } from "./api-authorization/LoginMenu";
import ContactSupportModalContainer from "../containers/ContactSupportModalContainer";

interface Props {
  organizations?: Organization[];
}

const MainHeader = ({ organizations }: Props) => {
  const { orgName } = useParams<{ orgName: string }>();
  const { projectName } = useParams<{ projectName: string }>();

  const [activeItem, setActiveItem] = useState<string>(orgName ? orgName : "");
  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const isSettingPathActive = !!matchPath(location.pathname, "/settings");

  const handleItemClick = useCallback(
    (e: any, name: string): void => {
      setActiveItem(name);
      history.push(`/${name}`);
    },
    [history]
  );

  useEffect(() => {
    if (activeItem === "" && organizations && organizations.length > 0 && !isSettingPathActive) {
      handleItemClick(null, organizations[0].name);
    }
  }, [activeItem, organizations, isSettingPathActive, handleItemClick]);

  const renderBreadcrumb = () => {
    if (orgName) {
      return (
        <Menu.Item>
          <Breadcrumb>
            {!projectName && (
              <Breadcrumb.Section style={{ padding: "0 10px 0 10px" }}>
                <Dropdown
                  trigger={<>{orgName}</>}
                  style={{
                    padding: "0px",
                  }}
                >
                  <Dropdown.Menu>
                    {organizations &&
                      organizations.map((org) => (
                        <Dropdown.Item key={org.id} onClick={(e) => handleItemClick(e, org.name)}>
                          {org.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Breadcrumb.Section>
            )}
            {projectName && (
              <Breadcrumb.Section
                color="red"
                active
                style={{ padding: "0 10px 0 10px" }}
                onClick={(e) => handleItemClick(e, orgName)}
              >
                {orgName}
              </Breadcrumb.Section>
            )}

            {projectName && (
              <>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active style={{ padding: "0 10px 0 10px" }}>
                  {projectName}
                </Breadcrumb.Section>
              </>
            )}
          </Breadcrumb>
        </Menu.Item>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Menu color="green" inverted fixed="top" borderless size="massive" style={{ boxShadow: "none" }}>
        <Menu.Item header>VISLOCK</Menu.Item>
        {renderBreadcrumb()}
        <Menu.Menu position="right">
          <MenuItem
            name="Help desk"
            onClick={() => {
              setOpenContactSupportModal(true);
            }}
          ></MenuItem>
          <LoginMenu />
        </Menu.Menu>
      </Menu>

      <ContactSupportModalContainer
        open={openContactSupportModal}
        onClose={() => {
          setOpenContactSupportModal(false);
        }}
      />
    </>
  );
};

export default MainHeader;
