import { gql, useQuery } from "@apollo/client";
import { GetMyOrganizations } from "./__generated__/GetMyOrganizations";
import { CORE_ORGANIZATION_FIELDS } from "../fragments/organization";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_LICENCE_FIELDS } from "../fragments/Licence";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";

export const GET_MY_ORGANIZATIONS = gql`
  ${CORE_ORGANIZATION_FIELDS}
  ${CORE_LICENCE_FIELDS}
  ${ORG_ROLE_FIELDS}
  query GetMyOrganizations {
    myOrganizations {
      ...CoreOrganizationFields
      activeLicence {
        ...CoreLicenceFields
      }
      orgRoles {
        ...OrgRoleFields
      }
    }
  }
`;

export function useGetMyOrganizations() {
  const { loading, error, data } = useQuery<GetMyOrganizations>(GET_MY_ORGANIZATIONS, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
