import React, { useEffect } from "react";
import ProjectPermitsCounts from "../components/ProjectPermitsCounts";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useGetPermitsCountByStates } from "../operations/queries/getPermitsCountByStates";

interface Props {}

const ProjectPermitsCountsContainer = (props: Props) => {
  const { currentProject } = useCurrentProject();
  const { currentOrganization } = useCurrentOrganization();

  const { getPermitsCountByStates, data: permitsCountByStates } = useGetPermitsCountByStates();

  useEffect(() => {
    if (currentProject) {
      getPermitsCountByStates({
        variables: {
          projectId: currentProject.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  if (!permitsCountByStates) return null;

  return (
    <ProjectPermitsCounts
      currentOrganization={currentOrganization}
      currentProject={currentProject}
      data={permitsCountByStates.permitsCountByStates.map(function (d) {
        return { stateKey: d.state?.key, count: d.count };
      })}
    />
  );
};

export default ProjectPermitsCountsContainer;
