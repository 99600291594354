import { Container, Segment } from "semantic-ui-react";
import styles from "./LayoutWithSidebar.module.css";

interface Props {
  headerContent: any;
  sidebarContent: any;
  mainPanelContent: any;
}

const LayoutWithSidebar = ({ headerContent, sidebarContent, mainPanelContent }: Props) => {
  return (
    <>
      {headerContent}
      <div className={styles.fpWrapper}>
        <div className={styles.sidebar}>
          <div style={{ marginTop: "20px" }}>{sidebarContent}</div>
        </div>
        <div className={styles.fpPanelMain}>
          <Container fluid>
            <Segment basic>{mainPanelContent}</Segment>
          </Container>
        </div>
      </div>
    </>
  );
};

export default LayoutWithSidebar;
