import { Image, Text, View } from "@react-pdf/renderer";
import styles from "./components/Styles";
import { Permit } from "../../models/Permit";
import Row from "./components/Row";
import Item from "./components/Item";
import moment from "moment";
import { PermitToDigSignatureType } from "../../models/globalTypes";
import Break from "./components/Break";
import HeaderText from "./components/HeaderText";

interface Props {
  permit: Permit;
  orgLogoUrl: string | null;
  token: string;
}

const AuthoriztionsPage = ({ permit, orgLogoUrl, token }: Props) => {
  if (!permit) return null;

  return (
    // <Page style={styles.body} wrap>
    <>
      {/* <Header logoUrl={orgLogoUrl} token={token} title="Authorizations" permitNumber={permit.number} /> */}

      <HeaderText title="AUTHORIZATION" />
      {permit.signatures
        .filter((s) => s.type === PermitToDigSignatureType.PERMIT_AUTHORIZATION)
        .map((signature) => (
          <View wrap={false}>
            <HeaderText title={signature.signatory?.roleName?.toUpperCase() ?? ""} backgroundColor="#888" />
            <View style={{ backgroundColor: "whitesmoke", border: "1px solid #666", borderTop: 0 }}>
              {signature.declaration.split(/\r?\n/).map((d) => (
                <Text style={{ ...styles.text, margin: "3px 5px", color: "#444", fontSize: 10 }}>{d}</Text>
              ))}
            </View>
            <Row border>
              <Item width="33%" title="Name" text={signature.signatory?.name}></Item>
              <Item
                width="33%"
                title="Signature"
                text={signature?.dateSignedUtc ? moment(signature?.dateSignedUtc)?.format("lll") : "-"}
              ></Item>
              {signature.signatureUrl && (
                <Image
                  style={{ ...styles.image, maxWidth: "30mm" }}
                  src={{
                    uri: signature.signatureUrl,
                    method: "GET",
                    headers: { authorization: `Bearer ${token}` },
                    body: "",
                  }}
                />
              )}
            </Row>
            <Break height={5} />
          </View>
        ))}

      {permit.permitAssignments
        .filter((a) => a.orgRole.permitRoleSettings.shouldAuthorizePermit)
        .filter((a) => !permit.signatures.find((s) => s.orgRole?.id === a.orgRole.id))
        .map((a) => (
          <View wrap={false}>
            <HeaderText title={a.orgRole.displayName.toUpperCase() ?? ""} backgroundColor="#888" />
            <View style={{ backgroundColor: "whitesmoke", border: "1px solid #666", borderTop: 0 }}>
              {a.orgRole.permitRoleSettings.authorizationDeclaration.split(/\r?\n/).map((d) => (
                <Text style={{ ...styles.text, margin: "3px 5px ", color: "#444", fontSize: 10 }}>{d}</Text>
              ))}
            </View>
            <Row border>
              <Item width="33%" title="Name" text="not assigned"></Item>
              <Item width="33%" title="Signature" text="-"></Item>
            </Row>
            <Break height={5} />
          </View>
        ))}

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </>
  );
};

export default AuthoriztionsPage;
