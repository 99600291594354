import { useLazyGetReferenceProceduresByOrganizationId } from "../operations/queries/getReferenceProceduresByOrganizationId";
import { useLazyGetProjectsByOrganizationId } from "../operations/queries/getProjectsByOrganizationId";
import ReferenceProceduresList from "../components/ReferenceProceduresList";
import { useDeleteReferenceProcedure } from "../operations/mutations/deleteReferenceProcedure";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

const ReferenceProceduresListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 0, pageIndex: 0 });
  const [searchString, setSearchString] = useState("");
  const [projectFilter, setProjectFilter] = useState<string>();

  const {
    getReferenceProceduresByOrganizationId: getReferenceProcedures,
    data: referenceProcedures,
    status: getReferenceProceduresStatus,
    refetch: refetchReferenceProcedures,
  } = useLazyGetReferenceProceduresByOrganizationId();
  const { mutate: deleteReferenceProcedureMutate, status: deleteReferenceProcedureStatus } =
    useDeleteReferenceProcedure();
  const { getProjectsByOrganizationId: getProjects, data: projects } = useLazyGetProjectsByOrganizationId();

  const handlePageInfoChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      if (pageSize !== paginationInfo.pageSize || pageIndex !== paginationInfo.pageIndex) {
        setPaginationInfo({ pageSize, pageIndex });
      }
    },
    [paginationInfo.pageIndex, paginationInfo.pageSize]
  );

  const handleSearchReferenceProcedures = debounce((searchString: string) => {
    if (searchString !== undefined) {
      setSearchString(searchString);
    }
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  }, 500);

  const handleProjectFilter = (projectId: string) => {
    if (projectId !== undefined) setProjectFilter(projectId);
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  };

  useEffect(() => {
    if (currentOrganization && paginationInfo.pageSize > 0)
      getReferenceProcedures({
        variables: {
          organizationId: currentOrganization.id,
          skip: paginationInfo.pageIndex * paginationInfo.pageSize,
          take: paginationInfo.pageSize,
          searchString,
          pId: projectFilter,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.pageSize, paginationInfo.pageIndex, currentOrganization, searchString, projectFilter]);

  useEffect(() => {
    if (currentOrganization) {
      getProjects({
        variables: { organizationId: currentOrganization.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const handleDeleteReferenceProcedure = (id: string) => {
    if (currentOrganization) {
      deleteReferenceProcedureMutate({
        variables: {
          id,
        },
      }).then(() => {
        refetchReferenceProcedures && refetchReferenceProcedures();
      });
    }
  };

  const handleReferenceProcedureAdded = () => {
    refetchReferenceProcedures && refetchReferenceProcedures();
  };

  return (
    <ReferenceProceduresList
      projects={projects && projects.projectsByOrganizationId?.items}
      referenceProcedures={referenceProcedures && referenceProcedures.referenceProceduresByOrganizationId?.items}
      pageCount={
        referenceProcedures &&
        Math.ceil((referenceProcedures?.referenceProceduresByOrganizationId?.totalCount ?? 0) / paginationInfo.pageSize)
      }
      onPageInfoChange={handlePageInfoChange}
      onSearchReferenceProcedures={handleSearchReferenceProcedures}
      onProjectFilter={handleProjectFilter}
      getReferenceProceduresStatus={getReferenceProceduresStatus}
      onDeleteReferenceProcedure={handleDeleteReferenceProcedure}
      deleteReferenceProcedureStatus={deleteReferenceProcedureStatus}
      onReferenceProcedureAdded={handleReferenceProcedureAdded}
    ></ReferenceProceduresList>
  );
};

export default ReferenceProceduresListContainer;
