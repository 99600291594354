import { gql } from "@apollo/client";

export const USER_FIELDS = gql`
  fragment UserFields on ApplicationUser {
    id
    firstName
    lastName
    email
    phoneNumber
    displayName
  }
`;
