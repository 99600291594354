import moment from "moment";
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Container, Header, Segment } from "semantic-ui-react";

interface Props {
  data: { year: number; month: number; count: number }[];
}

const ProjectPermitIssueTrend = ({ data }: Props) => {
  const graphData = data.map(function (item) {
    return { month: moment(`${item.year}-${item.month}-01`).format("MMM YY"), count: item.count };
  });

  return (
    <Container fluid>
      <Segment basic>
        <Segment>
          <Header textAlign="center">Issued permits</Header>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={graphData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                name="Number of permits issued"
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Segment>
      </Segment>
    </Container>
  );
};

export default ProjectPermitIssueTrend;
