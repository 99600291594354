import { Segment, Menu, Grid } from "semantic-ui-react";
import OrgRolesGrid from "./OrgRolesGrid";
import { OrgRole } from "../models/OrgRole";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastSuccess } from "../utils";
import EditOrgRoleModal from "./EditOrgRoleModal";
import { ApplicationPermission } from "../models/ApplicationPermission";
import { PermitRoleSettingsInput } from "../models/PermitRoleSettingsInput";

interface Props {
  isOrgRolesLoading: boolean;
  orgRoles?: OrgRole[];
  applicationPermissions?: ApplicationPermission[];
  onAddOrgRole: (name: string, permissionNames: string[], permitRoleSettingsInput: PermitRoleSettingsInput) => void;
  addOrgRoleStatus: AsyncActionStatus;
  onEditOrgRole: (
    roleId: string,
    name: string,
    permissionNames: string[],
    permitRoleSettingsInput: PermitRoleSettingsInput
  ) => void;
  editOrgRoleStatus: AsyncActionStatus;
}

const OrgRolesList = ({
  isOrgRolesLoading,
  orgRoles,
  applicationPermissions,
  onAddOrgRole,
  addOrgRoleStatus,
  onEditOrgRole,
  editOrgRoleStatus,
}: Props) => {
  const [openEditOrgRoleModal, setOpenEditOrgRoleModal] = useState(false);
  const [selectedOrgRoleToEdit, setSelectedOrgRoleToEdit] = useState<OrgRole | undefined>(undefined);

  const handleEditOrgRole = (orgRoleId: string) => {
    const orgRole = orgRoles?.find((o) => o.id === orgRoleId);
    if (orgRole) {
      setSelectedOrgRoleToEdit({ ...orgRole } as OrgRole);
      setOpenEditOrgRoleModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenEditOrgRoleModal(false);
    setSelectedOrgRoleToEdit(undefined);

    addOrgRoleStatus.reset();
    editOrgRoleStatus.reset();
  };

  const handleEditOrgRoleModalSubmit = (
    name: string,
    permissionNames: string[],
    permitRoleSettingsInput: PermitRoleSettingsInput,
    roleId?: string
  ) => {
    if (roleId) {
      onEditOrgRole(roleId, name, permissionNames, permitRoleSettingsInput);
    } else {
      onAddOrgRole(name, permissionNames, permitRoleSettingsInput);
    }
  };

  useEffect(() => {
    if (addOrgRoleStatus.successful) {
      toastSuccess("New role added successfully!");
      setOpenEditOrgRoleModal(false);
      setSelectedOrgRoleToEdit(undefined);
    }
  }, [addOrgRoleStatus.successful]);

  useEffect(() => {
    if (editOrgRoleStatus.successful) {
      toastSuccess("Role updated successfully!");
      setOpenEditOrgRoleModal(false);
      setSelectedOrgRoleToEdit(undefined);
    }
  }, [editOrgRoleStatus.successful]);

  return (
    <>
      <Menu secondary>
        <Menu.Item icon="plus" name="New role" onClick={(e) => setOpenEditOrgRoleModal(true)} />
      </Menu>

      <Segment basic loading={isOrgRolesLoading}>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column width="16">
              <OrgRolesGrid orgRoles={orgRoles} onEditOrgRole={handleEditOrgRole} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <EditOrgRoleModal
        open={openEditOrgRoleModal}
        onClose={handleModalClose}
        onSubmit={handleEditOrgRoleModalSubmit}
        submitInProgress={addOrgRoleStatus.loading || editOrgRoleStatus.loading}
        orgRole={selectedOrgRoleToEdit}
        applicationPermissions={applicationPermissions}
        submitErrors={
          addOrgRoleStatus.errors
            ? addOrgRoleStatus.errors
            : editOrgRoleStatus.errors
            ? editOrgRoleStatus.errors
            : undefined
        }
      ></EditOrgRoleModal>
    </>
  );
};

export default OrgRolesList;
