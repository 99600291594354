import moment from "moment";
import React, { useEffect } from "react";
import DashboardGauge from "../components/DashboardGauge";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { StateKey } from "../models/globalTypes";
import { useGetPermitAverageStateTransitionTime } from "../operations/queries/getPermitAverageStateTransitionTime";

interface Props {}

const PermitInProgressAvgDurationContainer = (props: Props) => {
  const { currentProject } = useCurrentProject();

  const { getPermitAverageStateTransitionTime, data } = useGetPermitAverageStateTransitionTime();

  useEffect(() => {
    if (currentProject) {
      getPermitAverageStateTransitionTime({
        variables: {
          projectId: currentProject.id,
          dateFrom: moment().add(-7, "days").toDate(),
          dateTo: moment().toDate(),
          stateKeyFrom: StateKey.IN_PROGRESS,
          stateKeyTo: StateKey.READY_TO_ISSUE,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  if (!data) return null;

  return (
    <DashboardGauge
      header="Permit Prepration Efficiency"
      description="Last week average time in hours to submit a permit for issue on site"
      value={data.permitAverageStateTransitionTime}
      maxValue={72}
    ></DashboardGauge>
  );
};

export default PermitInProgressAvgDurationContainer;
