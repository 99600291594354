import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { UploadFile, UploadFileVariables } from "./__generated__/UploadFile";

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!, $fileId: String) {
    uploadFile(input: { file: $file, fileId: $fileId }) {
      fileId
    }
  }
`;

export function useUploadFile() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<UploadFile, UploadFileVariables>(
    UPLOAD_FILE,
    {
      errorPolicy: "all",
    }
  );
  return { mutate, loading, data, error, reset, called };
}
