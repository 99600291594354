import { Image, Text, View } from "@react-pdf/renderer";
import styles from "./components/Styles";
import { Permit } from "../../models/Permit";
import Header from "./components/Header";

interface Props {
  permit: Permit;
  orgLogoUrl: string | null;
  token: string;
}

const PhotosPage = ({ permit, orgLogoUrl, token }: Props) => {
  if (!permit) return null;

  const photos = [...permit.sitePhotos];
  return (
    <>
      <Header logoUrl={orgLogoUrl} token={token} title="Photos" permitNumber={permit.number} />

      <View
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          padding: "0 4px",
        }}
      >
        <View style={{ width: "50%" }}>
          {photos.map((photo, index) => (
            <>
              {index % 2 === 0 && (
                <Image
                  style={{ ...styles.image }}
                  src={{
                    uri: photo.fileUrl,
                    method: "GET",
                    headers: { authorization: `Bearer ${token}` },
                    body: "",
                  }}
                />
              )}
            </>
          ))}
        </View>
        <View style={{ width: "50%" }}>
          {photos.map((photo, index) => (
            <>
              {index % 2 === 1 && (
                <Image
                  style={{ ...styles.image }}
                  src={{
                    uri: photo.fileUrl,
                    method: "GET",
                    headers: { authorization: `Bearer ${token}` },
                    body: "",
                  }}
                />
              )}
            </>
          ))}
        </View>
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </>
  );
};

export default PhotosPage;
