import { Modal } from "semantic-ui-react";
import { ReferenceProcedure_Data } from "../models/ReferenceProcedure_Data";
import ReferenceProcedureView from "./ReferenceProcedureView";

interface Props {
  open: boolean;
  onClose: () => void;
  referenceProcedure?: ReferenceProcedure_Data;
}

const ViewReferenceProcedureModal = ({ open, onClose, referenceProcedure }: Props) => {
  return (
    <Modal open={open}>
      <Modal.Content>
        <Modal.Description>
          <ReferenceProcedureView
            containerId="refProcViewModal"
            isPopup={true}
            onClose={onClose}
            referenceProcedure={referenceProcedure}
          ></ReferenceProcedureView>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ViewReferenceProcedureModal;
