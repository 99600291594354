import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";
import { GetNextPermitNumber, GetNextPermitNumberVariables } from "./__generated__/GetNextPermitNumber";

export const GET_NEXT_PERMIT_NUMBER = gql`
  query GetNextPermitNumber($projectId: String!) {
    nextPermitNumber(projectId: $projectId)
  }
`;

export function useLazyGetNextPermitNumber() {
  const [getNextPermitNumber, { loading, error, data }] = useLazyQuery<
    GetNextPermitNumber,
    GetNextPermitNumberVariables
  >(GET_NEXT_PERMIT_NUMBER, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  return {
    getNextPermitNumber,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
