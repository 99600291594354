import {
  GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID,
  useLazyGetDocumentTypesByOrganizationId,
} from "../operations/queries/getDocumentTypesByOrganizationId";
import DocumentTypesList from "../components/DocumentTypesList";
import { useAddDocumentType } from "../operations/mutations/addDocumentType";
import { useUpdateDocumentType } from "../operations/mutations/updateDocumentType";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect, useState } from "react";
import { useDeleteDocumentType } from "../operations/mutations/deleteDocumentType";
import { useToggleArchivedStatusDocumentType } from "../operations/mutations/toggleArchivedStatusDocumentType";

const DocumentTypesListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const [isArchivedFilterValue, setIsArchivedFilterValue] = useState<boolean | null>(false);

  const handleIsArchivedFilterValueChange = (isArchived: boolean | null) => {
    setIsArchivedFilterValue(isArchived);
  };

  const {
    getDocumentTypesByOrganizationId: getDocumentTypes,
    data: documentTypes,
    status: getDocumentTypesStatus,
  } = useLazyGetDocumentTypesByOrganizationId();
  const { mutate: addDocumentTypeMutate, status: addDocumentTypeStatus } = useAddDocumentType();
  const { mutate: updateDocumentTypeMutate, status: updateDocumentTypeStatus } = useUpdateDocumentType();
  const { mutate: deleteDocumentTypeMutate, status: deleteDocumentTypeStatus } = useDeleteDocumentType();
  const { mutate: toggleArchivedStatusDocumentTypeMutate, status: toggleArchivedStatusDocumentTypeStatus } =
    useToggleArchivedStatusDocumentType();

  useEffect(() => {
    if (currentOrganization) {
      getDocumentTypes({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getDocumentTypes]);

  const handleAddDocumentType = (name: string, declaration: string, isMandatory: boolean) => {
    if (currentOrganization) {
      addDocumentTypeMutate({
        variables: {
          name,
          key: name,
          organizationId: currentOrganization.id,
          declaration,
          isMandatory,
        },
      });
    }
  };

  const handleEditDocumentType = (id: string, name: string, declaration: string, isMandatory: boolean) => {
    if (currentOrganization) {
      updateDocumentTypeMutate({
        variables: {
          organizationId: currentOrganization.id,
          id,
          name,
          declaration,
          isMandatory,
        },
      });
    }
  };

  const handleDeleteDocumentType = (id: string) => {
    if (currentOrganization) {
      deleteDocumentTypeMutate({
        refetchQueries: [
          { query: GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID, variables: { organizationId: currentOrganization.id } },
        ],
        variables: {
          organizationId: currentOrganization.id,
          id,
        },
      });
    }
  };

  const handleToggleArchivedStatusDocumentType = (id: string) => {
    if (currentOrganization) {
      toggleArchivedStatusDocumentTypeMutate({
        variables: {
          organizationId: currentOrganization.id,
          id,
        },
      });
    }
  };

  return (
    <DocumentTypesList
      isDocumentTypesLoading={getDocumentTypesStatus.loading}
      documentTypes={
        documentTypes &&
        (isArchivedFilterValue === false
          ? documentTypes.documentTypesByOrganizationId.filter((t) => t.isArchived === false)
          : isArchivedFilterValue === true
          ? documentTypes.documentTypesByOrganizationId.filter((t) => t.isArchived === true)
          : documentTypes.documentTypesByOrganizationId)
      }
      onAddDocumentType={handleAddDocumentType}
      addDocumentTypeStatus={addDocumentTypeStatus}
      onEditDocumentType={handleEditDocumentType}
      editDocumentTypeStatus={updateDocumentTypeStatus}
      onDeleteDocumentType={handleDeleteDocumentType}
      deleteDocumentTypeStatus={deleteDocumentTypeStatus}
      onToggleArchivedStatusDocumentType={handleToggleArchivedStatusDocumentType}
      toggleArchivedStatusDocumentTypeStatus={toggleArchivedStatusDocumentTypeStatus}
      isArchivedFilterValue={isArchivedFilterValue}
      onIsArchivedFilterValueChange={handleIsArchivedFilterValueChange}
    ></DocumentTypesList>
  );
};

export default DocumentTypesListContainer;
