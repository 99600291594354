import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID } from "../queries/getDocumentTypesByOrganizationId";
import { GetDocumentTypesByOrganizationId } from "../queries/__generated__/GetDocumentTypesByOrganizationId";
import { useResettableMutation } from "../useResettableMutation";
import { AddDocumentType, AddDocumentTypeVariables } from "./__generated__/AddDocumentType";

const ADD_DOCUMENT_TYPE = gql`
  mutation AddDocumentType(
    $name: String!
    $key: String!
    $organizationId: String!
    $declaration: String!
    $isMandatory: Boolean!
  ) {
    addDocumentType(
      input: {
        name: $name
        key: $key
        organizationId: $organizationId
        declaration: $declaration
        isMandatory: $isMandatory
      }
    ) {
      documentType {
        id
        name
        key
        organization {
          id
        }
        declaration
        isMandatory
      }
    }
  }
`;

export function useAddDocumentType() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddDocumentType,
    AddDocumentTypeVariables
  >(ADD_DOCUMENT_TYPE, {
    update(cache, { data }) {
      const newDocumentTypeFromResponse = data?.addDocumentType?.documentType;
      if (newDocumentTypeFromResponse) {
        const existingDocumentTypes = cache.readQuery<GetDocumentTypesByOrganizationId>({
          query: GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID,
          variables: {
            organizationId: newDocumentTypeFromResponse.organization.id,
          },
        });

        if (existingDocumentTypes) {
          cache.writeQuery({
            query: GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID,
            variables: {
              organizationId: newDocumentTypeFromResponse.organization.id,
            },
            data: {
              documentTypesByOrganizationId: [
                ...existingDocumentTypes.documentTypesByOrganizationId,
                newDocumentTypeFromResponse,
              ],
            },
          });
        }
      }
    },
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
