import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { BuriedServicesInduction } from "../models/BuriedServicesInduction";
import { CompanyInduction } from "../models/CompanyInduction";
import { FormError } from "../models/FormError";
import { GangMember } from "../models/GangMember";
import { trancateTime } from "../utils";
import EditGangMemberForm, { FormData } from "./EditGangMemberForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    firstName: string,
    lastName: string,
    jobTitle: string,
    employerName: string,
    companyInduction: CompanyInduction,
    buriedServicesInduction: BuriedServicesInduction,
    id?: string
  ) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  gangMember?: GangMember;
}

const EditGangMemberModal = ({ open, onClose, onSubmit, submitInProgress, submitErrors, gangMember }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData({
        ...gangMember,
        companyInduction_date: gangMember?.companyInduction?.date
          ? new Date(gangMember?.companyInduction?.date)
          : undefined,
        companyInduction_number: gangMember?.companyInduction?.number,
        buriedServicesInduction_date: gangMember?.buriedServicesInduction?.date
          ? new Date(gangMember?.buriedServicesInduction?.date)
          : undefined,
        buriedServicesInduction_number: gangMember?.buriedServicesInduction?.number,
        buriedServicesInduction_expiresOn: gangMember?.buriedServicesInduction?.expiresOn
          ? new Date(gangMember?.buriedServicesInduction?.expiresOn)
          : undefined,
      } as FormData);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, gangMember]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.firstName) {
      formError.firstName = "Please enter gang member first name";
    }

    if (!formData.lastName) {
      formError.lastName = "Please enter gang member last name";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const {
        firstName,
        lastName,
        jobTitle,
        employerName,
        companyInduction_date,
        companyInduction_number,
        buriedServicesInduction_date,
        buriedServicesInduction_number,
        buriedServicesInduction_expiresOn,
        id,
      } = formData;
      onSubmit(
        firstName,
        lastName,
        jobTitle,
        employerName,
        {
          date: companyInduction_date ? trancateTime(companyInduction_date) : null,
          number: companyInduction_number,
        } as CompanyInduction,
        {
          date: buriedServicesInduction_date ? trancateTime(buriedServicesInduction_date) : null,
          number: buriedServicesInduction_number,
          expiresOn: buriedServicesInduction_expiresOn ? trancateTime(buriedServicesInduction_expiresOn) : null,
        } as BuriedServicesInduction,
        id
      );
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Gang member</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <EditGangMemberForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
          ></EditGangMemberForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditGangMemberModal;
