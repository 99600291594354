import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { ResetPasswordAdminUser, ResetPasswordAdminUserVariables } from "./__generated__/ResetPasswordAdminUser";

const RESET_ADMIN_USER_PASSWORD = gql`
  mutation ResetPasswordAdminUser($id: String, $newPassword: String) {
    resetPasswordAdminUser(input: { id: $id, newPassword: $newPassword }) {
      succeeded
    }
  }
`;

export function useResetAdminUserPassword() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ResetPasswordAdminUser,
    ResetPasswordAdminUserVariables
  >(RESET_ADMIN_USER_PASSWORD, {
    errorPolicy: "all",
  });

  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
