import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import AddProjectAssignmentForm, { FormData } from "./AddProjectAssignmentForm";
import { FormError } from "../models/FormError";
import { OrgUser } from "../models/OrgUser";
import { OrgRole } from "../models/OrgRole";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (orgUserIds: string[], orgRoleId: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  availableOrgUsers?: OrgUser[];
  availableOrgRoles?: OrgRole[];
}

const AddProjectAssignmentModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  submitErrors,
  availableOrgUsers,
  availableOrgRoles,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    setFormData({} as FormData);
    setFormErrors({} as FormError);
    setHasSubmitted(false);
  }, [open]);

  const validateFormData = (formData: FormData): FormError => {
    const formError: FormError = {};

    if (!formData.orgUserIds) {
      formError.orgUserIds = "Please select user";
    } else if (formData.orgUserIds.length < 1) {
      formError.orgUserIds = "Please select user";
    }

    if (!formData.orgRoleId) {
      formError.orgRoleId = "Please select user role";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { orgUserIds, orgRoleId } = formData;
      onSubmit(orgUserIds, orgRoleId);
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open} size="small">
      <Modal.Header>Add user to project</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AddProjectAssignmentForm
            users={availableOrgUsers}
            roles={availableOrgRoles}
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
          ></AddProjectAssignmentForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddProjectAssignmentModal;
