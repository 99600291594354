import { Form, InputOnChangeData } from "semantic-ui-react";
import ErrorsView from "./ErrorsView";
import { FormError } from "../models/FormError";

export interface FormData {
  newPassword: string;
  repeatNewPassword: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
}

const ResetPasswordForm = ({ formData, onChange, formErrors, errors }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>New Password</label>
            <Form.Input
              error={formErrors.newPassword}
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field required>
            <label>Repeat New Password</label>
            <Form.Input
              error={formErrors.repeatNewPassword}
              type="password"
              name="repeatNewPassword"
              value={formData.repeatNewPassword}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default ResetPasswordForm;
