import { Image, Text, View } from "@react-pdf/renderer";
import styles from "./components/Styles";
import { Permit } from "../../models/Permit";
import Header from "./components/Header";
import Row from "./components/Row";
import Item from "./components/Item";
import { GangMember } from "../../models/GangMember";
import moment, { Moment } from "moment";
import { PermitToDigSignatureType } from "../../models/globalTypes";
import HeaderText from "./components/HeaderText";
import { groupBy } from "lodash";

interface Props {
  permit: Permit;
  orgLogoUrl: string | null;
  token: string;
}

const EightPointChecklistPage = ({ permit, orgLogoUrl, token }: Props) => {
  const numberOfSignInRow = 7;

  const permitDurationInDays =
    moment(permit.validityDuration.to)
      .startOf("day")
      .diff(moment(permit.validityDuration.from).startOf("day"), "days") + 1;

  const getSignature = (gangMemberId: string, date: Moment) => {
    return permit.signatures.filter(
      (s) =>
        s.type === PermitToDigSignatureType.GANG_MEMBER &&
        s.gangMember?.id === gangMemberId &&
        moment(s.date).isSame(date, "date")
    )[0];
  };

  const checkListItem = (itemNumber: number, itemText: string) => {
    return (
      <Row border first={itemNumber === 1}>
        <Text
          style={{
            padding: "5px",
            borderRight: "1px solid #666",
            width: "5%",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          {itemNumber}
        </Text>
        <Text style={{ fontSize: 12, padding: "5px", width: "95%" }}> {itemText}</Text>
      </Row>
    );
  };

  const renderGangMember = (gangMember: GangMember, index: number) => {
    return (
      <View wrap={false}>
        <Row border bgColor="whitesmoke" first={index === 0}>
          <Item text={gangMember.displayName} width="100%"></Item>
        </Row>
        {Object.values(
          groupBy(
            [...Array(permitDurationInDays)].map((e, i) => i),
            (i) => Math.floor(i / numberOfSignInRow)
          )
        ).map((days) => (
          <Row border>
            {days.map((dayIndex) => {
              const signature = getSignature(gangMember.id, moment(permit.validityDuration.from).add(dayIndex, "days"));
              return (
                <View style={{ width: "14.28%" }}>
                  <Text style={styles.subtitle}>
                    {moment(permit.validityDuration.from).add(dayIndex, "days").format("DD/MM")}
                  </Text>
                  <Text style={{ ...styles.subtitle, marginTop: 0 }}>
                    {signature?.dateSignedUtc ? moment(signature.dateSignedUtc).format("LT") : ""}
                  </Text>
                  {signature?.isAbsent ? (
                    <Text style={styles.subtitle}>Absent</Text>
                  ) : (
                    signature?.signatureUrl && (
                      <Image
                        style={{ ...styles.image, margin: 0 }}
                        src={{
                          uri: signature.signatureUrl,
                          method: "GET",
                          headers: { authorization: `Bearer ${token}` },
                          body: "",
                        }}
                      />
                    )
                  )}
                </View>
              );
            })}
          </Row>
        ))}
      </View>
    );
  };

  if (!permit.eightPointCheckList) return null;

  const declarationLines = permit.eightPointCheckList.declaration?.split(/\r?\n/) ?? [];

  return (
    <>
      <Header logoUrl={orgLogoUrl} token={token} title={"Permit to dig"} permitNumber={permit.number} />
      {/* <Header logoUrl={orgLogoUrl} token={token} title="8 Point Checklist" /> */}

      <HeaderText title="Checklist" />
      {permit.eightPointCheckList.items?.map((i, index) => checkListItem(index + 1, i ? i : ""))}

      <View style={{ margin: "5mm 0" }}>
        {declarationLines.map((l) => (
          <View>
            <Text style={{ padding: "2px 0px", fontSize: 12 }}>{l}</Text>
          </View>
        ))}
      </View>

      {permit.gangMembers.map(renderGangMember)}

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </>
  );
};

export default EightPointChecklistPage;
