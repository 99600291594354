import { uniq } from "lodash";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Card,
  Checkbox,
  CheckboxProps,
  Form,
  Header,
  Icon,
} from "semantic-ui-react";
import { ApplicationPermission } from "../models/ApplicationPermission";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";
import { useState } from "react";

export interface FormData {
  displayName: string;
  permissionNames: string[];
  isPermitAssignee: boolean;
  mandatoryOnSubmission: boolean;
  shouldAuthorizePermit: boolean;
  authorizationDeclaration: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  applicationPermissions?: ApplicationPermission[];
}

const EditOrgRoleForm = ({ formData, formErrors, errors, onChange, applicationPermissions }: Props) => {
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);

  const handleChange = (e: any, { name, value }: any) => {
    onChange({ ...formData, [name]: value });
  };

  const handlePermissionChange = (e: any, { checked, value: permissionName }: CheckboxProps) => {
    if (!permissionName) {
      return;
    }

    let selectedPermissions = [...(formData.permissionNames ? formData.permissionNames : [])];

    if (checked) {
      selectedPermissions = [...selectedPermissions, permissionName.toString()];
    } else {
      selectedPermissions = selectedPermissions.filter((obj) => obj !== permissionName.toString());
    }

    onChange({ ...formData, permissionNames: selectedPermissions });
  };

  const hanldeSettingsChange = (e: any, { name, checked }: any) => {
    onChange({ ...formData, [name]: checked });
  };

  const permissionCategories = uniq(applicationPermissions?.map((p) => p.category))
    .sort()
    .reverse();

  const permissionCategoryRender = (category: string | null, index: number) => {
    const lenght = applicationPermissions?.filter((p) => p.category === category).length;
    const selected = applicationPermissions?.filter(
      (p) => p.category === category && formData.permissionNames?.includes(p.name)
    ).length;
    return (
      <>
        <AccordionTitle
          active={accordionActiveIndex === index}
          index={index}
          onClick={() => setAccordionActiveIndex(index)}
          key={category}
        >
          <h5>
            <Icon name="dropdown" />
            {`${category} (${selected}/${lenght})`}
          </h5>
        </AccordionTitle>
        <AccordionContent active={accordionActiveIndex === index}>
          <Card.Group itemsPerRow={2} style={{ "margin-left": "15px" }}>
            {applicationPermissions?.filter((p) => p.category === category).map((p) => permissionRender(p))}
          </Card.Group>
        </AccordionContent>
      </>
    );
  };

  const permissionRender = (permission: ApplicationPermission) => {
    return (
      <Card style={{ "box-shadow": "0px 0px", margin: "7px" }}>
        <Form.Field>
          <Checkbox
            label={permission.displayName}
            name={permission.name}
            value={permission.name}
            checked={formData.permissionNames?.includes(permission.name)}
            onChange={handlePermissionChange}
          />
        </Form.Field>
      </Card>
    );
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Name</label>
            <Form.Input
              error={formErrors.displayName}
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Header as="h5" dividing>
          Permit workflow settings
        </Header>
        <Form.Group>
          <Form.Field width={5}>
            <Checkbox
              name="isPermitAssignee"
              checked={formData.isPermitAssignee}
              label="A permit team member"
              onChange={hanldeSettingsChange}
            />
          </Form.Field>
          <Form.Field width={5}>
            <Checkbox
              name="mandatoryOnSubmission"
              checked={formData.mandatoryOnSubmission}
              label="Mandatory (on submission)"
              disabled={!formData.isPermitAssignee}
              onChange={hanldeSettingsChange}
            />
          </Form.Field>
          <Form.Field width={6}>
            <Checkbox
              name="shouldAuthorizePermit"
              checked={formData.shouldAuthorizePermit}
              label="Signature required for permit authorization"
              disabled={!formData.isPermitAssignee}
              onChange={hanldeSettingsChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            name="authorizationDeclaration"
            value={formData.authorizationDeclaration}
            placeholder="Authorization declaration"
            disabled={!formData.shouldAuthorizePermit}
            onChange={handleChange}
            error={formErrors.authorizationDeclaration}
          ></Form.TextArea>
        </Form.Group>

        <Header as="h5" dividing>
          Permissions
        </Header>
        <Accordion fluid style={{ minHeight: "270px" }}>
          {permissionCategories?.map((c, index) => permissionCategoryRender(c, index))}
        </Accordion>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default EditOrgRoleForm;
