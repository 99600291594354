import { Button, Card, Checkbox, Form, InputOnChangeData } from "semantic-ui-react";
import { FileMetaData } from "../models/FileMetaData";
import { FormError } from "../models/FormError";
import { UserFile } from "../models/UserFile";
import FileDropZone from "./common/FileDropZone";
import UserFiles from "./common/UserFiles";
import ErrorsView from "./ErrorsView";

export interface FormData {
  id: string;
  name: string;
  addDefaultRoles: boolean;
  logoMetaData?: FileMetaData;
  logo?: UserFile;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  showAddDefaultRoleOption: boolean;
  onChange: (formData: FormData) => void;
  onUploadLogo: (logo: UserFile) => void;
  onRemoveLogo: () => void;
}

const AdminEditOrganizationForm = ({
  formData,
  formErrors,
  errors,
  showAddDefaultRoleOption,
  onChange,
  onUploadLogo,
  onRemoveLogo,
}: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  const handleLogoSelected = (logos: UserFile[]) => {
    onUploadLogo(logos[0]);
  };

  const handleAddDefaultRolesChange = (e: any, { name, checked }: any) => {
    onChange({ ...formData, [name]: checked });
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Name</label>
            <Form.Input error={formErrors.name} name="name" value={formData.name} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
        {showAddDefaultRoleOption && (
          <Form.Group widths="equal">
            <Form.Field required>
              <Checkbox
                name="addDefaultRoles"
                checked={formData.addDefaultRoles}
                label="Add default roles?"
                toggle
                onChange={handleAddDefaultRolesChange}
              />
            </Form.Field>
          </Form.Group>
        )}
        <Form.Group widths="equal">
          <Form.Field>
            <label>Logo</label>
            {!formData.logo && formData.logoMetaData && (
              <Card content style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }} onClick={(e) => {}}>
                <Card.Content
                  style={{
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  <Button
                    circular
                    icon="close"
                    size="mini"
                    floated="right"
                    style={{ padding: "2px", "box-shadow": "0px 0px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveLogo();
                    }}
                  ></Button>
                  {formData.logoMetaData?.fileName}
                </Card.Content>
              </Card>
            )}
            {formErrors.logo && (
              <span className="field error">
                <label>{formErrors.logo}</label>
              </span>
            )}
            <FileDropZone multiple={false} accept={[".jpg"]} onFilesSelected={handleLogoSelected} />
          </Form.Field>
        </Form.Group>

        {formData.logo && (
          <Form.Group widths="equal">
            <Form.Field>
              <UserFiles userFilesMap={new Map([[formData.logo.id, formData.logo]])} />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default AdminEditOrganizationForm;
