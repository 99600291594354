import { Modal } from "semantic-ui-react";
import { Project } from "../models/Project";
import ProjectViewContainer from "../containers/ProjectViewContainer";

interface Props {
  showProjectBoundariesOnMap?: boolean;
  open: boolean;
  project?: Project;
  onClose: () => void;
}

const ViewProjectModal = ({ showProjectBoundariesOnMap, open, project, onClose }: Props) => {
  return (
    <Modal open={open} size={showProjectBoundariesOnMap ? "large" : "small"}>
      <Modal.Content>
        <Modal.Description>
          <ProjectViewContainer
            showProjectBoundariesOnMap={showProjectBoundariesOnMap}
            isPopup={true}
            project={project}
            onClose={onClose}
          ></ProjectViewContainer>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ViewProjectModal;
