import { useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { PDFObject } from "react-pdfobject";

interface Props {
  containerId: string;
  url: string;
  width?: number;
  height?: number;
}

function PdfViewer({ url, width, height, containerId }: Props) {
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getToken() {
      const val = await authService.getAccessToken();

      if (val) {
        setToken(val);
      }
    }

    getToken();
  }, []);

  //const contentWidth = width ? width - 20 : undefined;
  const zoomPluginInstance = zoomPlugin({ enableShortcuts: true });
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const nativePdfSupported = PDFObject.supportsPDFs();

  console.log(height);
  return (
    <>
      {nativePdfSupported ? (
        <div style={{ paddingTop: 5 }}>
          <PDFObject
            containerId={containerId}
            url={`${url}&access_token=${token}`}
            height={`${height}px`}
            pdfOpenParams={{ pagemode: "none", view: "FitH" }}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#fff",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "center",
              padding: "4px",
            }}
          >
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <div style={{ height: height, overflow: "auto" }}>
            <Viewer
              defaultScale={SpecialZoomLevel.PageFit}
              fileUrl={url}
              httpHeaders={{ authorization: token ? `Bearer ${token}` : "" }}
              plugins={[zoomPluginInstance]}
            />
          </div>
        </>
      )}
    </>
  );
}

export default PdfViewer;
