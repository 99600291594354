import React from "react";
import { Segment } from "semantic-ui-react";
import ServerImage from "./ServerImage";

interface Props {
  url: string;
}

const photoViewer = ({ url }: Props) => {
  return (
    <Segment basic>
      <ServerImage rounded src={url} centered style={{ maxHeight: "700px" }} />
    </Segment>
  );
};

export default photoViewer;
