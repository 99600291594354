import { gql, useLazyQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { GetUserByEmail, GetUserByEmailVariables } from "./__generated__/GetUserByEmail";
import { USER_FIELDS } from "../fragments/User";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";

export const GET_USER_BY_EMAIL = gql`
  ${USER_FIELDS}
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      ...UserFields
    }
  }
`;

export function useLazyGetUserByEmail(fetchPolicy?: WatchQueryFetchPolicy) {
  const [getUserByEmail, { loading, error, data }] = useLazyQuery<GetUserByEmail, GetUserByEmailVariables>(
    GET_USER_BY_EMAIL,
    {
      errorPolicy: "all",
      fetchPolicy: fetchPolicy || "cache-and-network",
    }
  );

  return {
    getUserByEmail,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
