import gql from "graphql-tag";
import { ORG_USER_FIELDS } from "./OrgUser";
import { CORE_PROJECT_FIELDS } from "./project";

export const DOCUMENT_DATA_FIELDS = gql`
  fragment DocumentDataFields on Document {
    id
    title
    versionNo
    dateCreatedUtc
    parentId
    type {
      id
      name
      key
    }
    fileUrl
  }
`;

export const DOCUMENT_LIST_FIELDS = gql`
  ${CORE_PROJECT_FIELDS}
  ${ORG_USER_FIELDS}
  ${DOCUMENT_DATA_FIELDS}
  fragment DocumentListFields on Document {
    ...DocumentDataFields
    project {
      ...CoreProjectFields
    }
    createdBy {
      ...OrgUserFields
    }
  }
`;

export const DOCUMENT_FIELDS = gql`
  ${CORE_PROJECT_FIELDS}
  ${ORG_USER_FIELDS}
  ${DOCUMENT_LIST_FIELDS}
  fragment DocumentFields on Document {
    ...DocumentListFields
    latest {
      ...DocumentListFields
      fileUrl
    }
  }
`;
