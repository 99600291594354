import { Checkbox, CheckboxProps, Form, Popup, Select } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { useEffect, useState } from "react";
import TimePicker from "./common/TimePicker";

export interface FormData {
  number: number;
  comments: string;
  contractor: string;
  subContractor: string;
  locationOfWork: string;
  permittedEquipment: string;
  worksDescription: string;
  maxPenetrationDepth: string;
  from?: Date;
  to?: Date;
  hoursFrom: string;
  hoursTo: string;
  noAffectedServices: boolean;
  affectedServices: string[];
  asBuiltRequired: boolean;
}

interface Props {
  affectedServicesOptions?: string[];
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  isNumberEditable: boolean;
}

const AddPermitForm = ({
  affectedServicesOptions,
  formData,
  formErrors,
  errors,
  onChange,
  isNumberEditable,
}: Props) => {
  const [affectedSrvOptions, setAffectedSrvOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (affectedServicesOptions) {
      setAffectedSrvOptions(affectedServicesOptions?.map((a) => ({ key: a, text: a, value: a })));
    }
  }, [affectedServicesOptions]);

  const handleAddAffectedSrv = (e: any, { value }: any) => {
    setAffectedSrvOptions([{ text: value, value }, ...affectedSrvOptions]);
  };

  const handleChange = (e: any, { name, value }: any) => {
    onChange({ ...formData, [name]: value });
  };

  const handleasBuiltRequiredChange = (e: any, data: CheckboxProps) => {
    onChange({ ...formData, asBuiltRequired: data.checked ?? false });
  };

  const handleNoAffectedServicesChange = (e: any, data: CheckboxProps) => {
    onChange({
      ...formData,
      noAffectedServices: data.value === "true" ? true : false,
      affectedServices: data.value === "true" ? [] : formData.affectedServices,
    });
  };

  const onFromDateChange = (e: any, data: any) => {
    onChange({
      ...formData,
      from: data.value,
      to: data.value && !formData.to ? new Date(new Date(data.value).setDate(data.value.getDate() + 6)) : formData.to,
    });
  };

  return (
    <>
      <ErrorsView errors={errors} />
      <ErrorsView errors={formErrors.boundaries ? [formErrors.boundaries] : undefined} />
      <Form>
        {isNumberEditable && (
          <Form.Group widths="2">
            <Form.Field required>
              <label>Permit no.</label>
              <Form.Input
                disabled={!isNumberEditable}
                error={formErrors.number}
                name="number"
                value={formData.number}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        )}
        <Form.Group widths="2">
          <Form.Field required>
            <label>Contractor</label>
            <Form.Input
              error={formErrors.contractor}
              name="contractor"
              value={formData.contractor}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Sub-contractor</label>
            <Form.Input
              error={formErrors.subContractor}
              name="subContractor"
              value={formData.subContractor}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field required>
            <label>Works description</label>
            <Form.Input
              error={formErrors.worksDescription}
              name="worksDescription"
              value={formData.worksDescription}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field required>
            <label>Location of work</label>
            <Form.Input
              error={formErrors.locationOfWork}
              name="locationOfWork"
              value={formData.locationOfWork}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group
          widths="2"
          style={
            formErrors.from || formErrors.to
              ? {
                  marginBottom: "50px",
                }
              : undefined
          }
        >
          <Popup
            content={formErrors.from}
            position="bottom center"
            open={!!formErrors.from}
            size="mini"
            style={{
              borderColor: "#E0B4B4",
              boxShadow: "none",
              fontWeight: "bold",
              color: "#9F3A38",
            }}
            trigger={
              <SemanticDatepicker
                required
                label="Valid From"
                value={formData.from}
                error={formErrors.from}
                locale="en-US"
                name="from"
                onChange={onFromDateChange}
                type="basic"
              />
            }
          />
          <Popup
            content={formErrors.to}
            position="bottom center"
            open={!!formErrors.to}
            size="mini"
            style={{
              borderColor: "#E0B4B4",
              boxShadow: "none",
              fontWeight: "bold",
              color: "#9F3A38",
            }}
            trigger={
              <SemanticDatepicker
                required
                label="To"
                value={formData.to}
                error={formErrors.to}
                locale="en-US"
                name="to"
                onChange={handleChange}
                type="basic"
              />
            }
          />
        </Form.Group>
        <Form.Group widths="2">
          <TimePicker
            hoursLabel="Hours from"
            minutesLabel="Minutes from"
            controlName="hoursFrom"
            error={formErrors.hoursFrom}
            timeSpan={formData.hoursFrom}
            onChange={handleChange}
          />
          <TimePicker
            hoursLabel="Hours to"
            minutesLabel="Minutes to"
            controlName="hoursTo"
            error={formErrors.hoursTo}
            timeSpan={formData.hoursTo}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field>
            <label>Permitted equipment</label>
            <Form.Input
              error={formErrors.permittedEquipment}
              name="permittedEquipment"
              value={formData.permittedEquipment}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Max penetration depth</label>
            <Form.Input
              error={formErrors.maxPenetrationDepth}
              name="maxPenetrationDepth"
              value={formData.maxPenetrationDepth}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              radio
              label="Affected services"
              name="affectedServicesRadioGroup"
              value="false"
              checked={!formData.noAffectedServices}
              onChange={handleNoAffectedServicesChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            disabled={formData.noAffectedServices}
            error={formErrors.affectedServices}
            name="affectedServices"
            control={Select}
            fluid
            multiple
            search
            selection
            allowAdditions
            value={formData.affectedServices}
            options={affectedSrvOptions}
            onAddItem={handleAddAffectedSrv}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              radio
              label="I can confirm there are no affected services"
              name="affectedServicesRadioGroup"
              value="true"
              checked={formData.noAffectedServices}
              onChange={handleNoAffectedServicesChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Checkbox
              label="As-Built Required"
              error={formErrors.asBuiltRequired}
              name="asBuiltRequired"
              checked={formData.asBuiltRequired}
              onChange={handleasBuiltRequiredChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            rows={2}
            label="Comments"
            error={formErrors.comments}
            name="comments"
            value={formData.comments}
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default AddPermitForm;
