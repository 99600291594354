import {
  GET_ORG_ROLES_BY_ORGANIZATION_ID,
  useLazyGetOrgRolesByOrganizationId,
} from "../operations/queries/getOrgRolesByOrganizationId";
import { useLazyGetApplicationPermissions } from "../operations/queries/getApplicationPermissions";
import OrgRolesList from "../components/OrgRolesList";
import { useAddOrgRole } from "../operations/mutations/addOrgRole";
import { useUpdateOrgRole } from "../operations/mutations/updateOrgRole";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect } from "react";
import { PermitRoleSettingsInput } from "../models/PermitRoleSettingsInput";

const OrgRolesListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();

  const {
    getOrgRolesByOrganizationId: getOrgRoles,
    data: orgRoles,
    status: getOrgRolesStatus,
  } = useLazyGetOrgRolesByOrganizationId();
  const { getApplicationPermissions, data: applicationPermissions } = useLazyGetApplicationPermissions();
  const { mutate: addOrgRoleMutate, status: addOrgRoleStatus } = useAddOrgRole();
  const { mutate: updateOrgRoleMutate, status: updateOrgRoleStatus } = useUpdateOrgRole();

  useEffect(() => {
    if (currentOrganization) {
      getOrgRoles({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getOrgRoles]);

  useEffect(() => {
    getApplicationPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOrgRole = (
    displayName: string,
    permissionNames: string[],
    permitRoleSettingsInput: PermitRoleSettingsInput
  ) => {
    if (currentOrganization) {
      addOrgRoleMutate({
        refetchQueries: [
          { query: GET_ORG_ROLES_BY_ORGANIZATION_ID, variables: { organizationId: currentOrganization.id } },
        ],
        variables: {
          displayName,
          organizationId: currentOrganization.id,
          permissionNames,
          permitRoleSettingsInput,
        },
      });
    }
  };

  const handleEditOrgRole = (
    roleId: string,
    displayName: string,
    permissionNames: string[],
    permitRoleSettingsInput: PermitRoleSettingsInput
  ) => {
    if (currentOrganization) {
      updateOrgRoleMutate({
        variables: {
          organizationId: currentOrganization.id,
          roleId,
          displayName,
          permissionNames,
          permitRoleSettingsInput,
        },
      });
    }
  };

  return (
    <OrgRolesList
      isOrgRolesLoading={getOrgRolesStatus.loading}
      orgRoles={orgRoles && orgRoles.orgRolesByOrganizationId}
      applicationPermissions={applicationPermissions && applicationPermissions.applicationPermissions}
      onAddOrgRole={handleAddOrgRole}
      addOrgRoleStatus={addOrgRoleStatus}
      onEditOrgRole={handleEditOrgRole}
      editOrgRoleStatus={updateOrgRoleStatus}
    ></OrgRolesList>
  );
};

export default OrgRolesListContainer;
