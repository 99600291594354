import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID } from "../queries/getDocumentTypesByOrganizationId";
import { GetDocumentTypesByOrganizationId } from "../queries/__generated__/GetDocumentTypesByOrganizationId";
import { useResettableMutation } from "../useResettableMutation";
import { UpdateDocumentType, UpdateDocumentTypeVariables } from "./__generated__/UpdateDocumentType";

const UPDATE_DOCUMENT_TYPE = gql`
  mutation UpdateDocumentType(
    $organizationId: String!
    $id: String!
    $name: String
    $declaration: String!
    $isMandatory: Boolean!
  ) {
    updateDocumentType(
      input: {
        organizationId: $organizationId
        id: $id
        name: $name
        declaration: $declaration
        isMandatory: $isMandatory
      }
    ) {
      documentType {
        id
        name
        key
        organization {
          id
        }
        declaration
        isMandatory
      }
    }
  }
`;

export function useUpdateDocumentType() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateDocumentType,
    UpdateDocumentTypeVariables
  >(UPDATE_DOCUMENT_TYPE, {
    update(cache, { data }) {
      const updatedDocumentTypeFromResponse = data?.updateDocumentType?.documentType;
      if (updatedDocumentTypeFromResponse) {
        const existingDocumentTypes = cache.readQuery<GetDocumentTypesByOrganizationId>({
          query: GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID,
          variables: {
            organizationId: updatedDocumentTypeFromResponse.organization.id,
          },
        });

        if (existingDocumentTypes && updatedDocumentTypeFromResponse) {
          cache.writeQuery({
            query: GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID,
            variables: {
              organizationId: updatedDocumentTypeFromResponse.organization.id,
            },
            data: {
              documentTypesByOrganizationId: existingDocumentTypes.documentTypesByOrganizationId.map((t) =>
                t.id === updatedDocumentTypeFromResponse.id
                  ? {
                      ...t,
                      name: updatedDocumentTypeFromResponse.name,
                    }
                  : t
              ),
            },
          });
        }
      }
    },
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
