import { Item } from "semantic-ui-react";

interface Props {
  header: string;
  value?: string | null;
}

const ViewItem = ({ header, value }: Props) => {
  return (
    <Item.Group>
      <Item>
        <Item.Content>
          <Item.Meta>{header}</Item.Meta>
          <Item.Description>{value ? value : ""}</Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default ViewItem;
