import { ActionItem } from "./GridActionButton";
import { DataGrid } from "./common/DataGrid";
import { Column } from "react-table";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { ReferenceProcedure_ListItem } from "../models/ReferenceProcedure_ListItem";

interface Props {
  referenceProcedures?: ReferenceProcedure_ListItem[] | null;
  selectedReferenceProcedure?: ReferenceProcedure_ListItem;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onReferenceProcedureClicked: (referenceProcedureId: string) => void;
  onDeleteReferenceProcedure: (referenceProcedureId: string) => void;
}

const ReferenceProceduresGrid = ({
  referenceProcedures,
  selectedReferenceProcedure,
  pageCount,
  onPageInfoChange,
  onDeleteReferenceProcedure,
  onReferenceProcedureClicked,
}: Props) => {
  const actionItems = [
    {
      key: "delete",
      icon: "delete",
      content: "Delete",
      callback: (id: string) => onDeleteReferenceProcedure(id),
    },
  ] as ActionItem[];

  const columns: Column<ReferenceProcedure_ListItem>[] = [
    {
      Header: "Title",
      id: "Title",
      accessor: (ref) => ref.title,
    },
    {
      Header: "Project",
      id: "Project",
      accessor: (ref) => ref.project?.name,
    },
    {
      Header: "Date",
      id: "Date",
      accessor: (ref) => ref.dateCreatedUtc,
      Cell: ({ value }: any) => (
        <Popup
          trigger={<span>{moment(value).local().fromNow()}</span>}
          content={moment.utc(value).local().format("llll")}
        ></Popup>
      ),
    },
  ];

  const handleReferenceProcedureClicked = (ref: ReferenceProcedure_ListItem, i: number) => {
    onReferenceProcedureClicked(ref.id);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        actionItems={actionItems}
        actionButtonColumn={columns[0]}
        data={referenceProcedures ? referenceProcedures : []}
        selectedRow={selectedReferenceProcedure}
        onRowClick={handleReferenceProcedureClicked}
        onPageInfoChange={onPageInfoChange}
        loading={false}
        pageCount={pageCount}
      />
    </>
  );
};

export default ReferenceProceduresGrid;
