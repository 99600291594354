import LayoutWithSidebar from "../components/LayoutWithSidebar";
import AdminSettingsSideMenu from "../components/AdminSettingsSideMenu";
import MainHeaderContainer from "../containers/MainHeaderContainer";

const AdminSettingsPage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <AdminSettingsSideMenu></AdminSettingsSideMenu>;
  };

  const contentPanelContent = () => {
    return <></>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default AdminSettingsPage;
