import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { getErrors } from "../../utils/apolloError";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { RejectPermitToDig, RejectPermitToDigVariables } from "./__generated__/RejectPermitToDig";
import { PERMIT_TO_DIG_ALL_FIELDS } from "../fragments/permitToDig";

const REJECT_PERMIT = gql`
  ${PERMIT_TO_DIG_ALL_FIELDS}
  mutation RejectPermitToDig($id: String!, $comments: String) {
    rejectPermitToDig(input: { id: $id, comments: $comments }) {
      permitToDig {
        ...PermitToDigAllFields
      }
    }
  }
`;

export function useRejectPermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    RejectPermitToDig,
    RejectPermitToDigVariables
  >(REJECT_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
