import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { AdminUser } from "../models/AdminUser";
import { FormError } from "../models/FormError";
import EditAdminUserForm, { FormData } from "./EditAdminUserForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: string, firstName: string, lastName: string, email: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  adminUser?: AdminUser;
}

const EditAdminUserModal = ({ open, onClose, onSubmit, submitInProgress, submitErrors, adminUser }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData({ ...adminUser } as FormData);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, adminUser]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    const emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!formData.email) {
      formError.email = "Please enter email address";
    } else if (!emailPattern.test(formData.email)) {
      formError.email = "Email address format is not correct";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { id, firstName, lastName, email } = formData;
      onSubmit(id, firstName, lastName, email);
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Admin user</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <EditAdminUserForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
          ></EditAdminUserForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditAdminUserModal;
