import {
  GET_PROJECTS_BY_ORGANIZATION_ID,
  useLazyGetProjectsByOrganizationId,
} from "../operations/queries/getProjectsByOrganizationId";
import ProjectsList from "../components/ProjectsList";
import { useAddProject } from "../operations/mutations/addProject";
import { useUpdateProject } from "../operations/mutations/updateProject";
import { useDeleteProject } from "../operations/mutations/deleteProject";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useCallback, useEffect, useState } from "react";
import { EmergencyContact } from "../models/EmergencyContact";
import { EsriIntegrationTypes } from "../models/EsriIntegrationType";
import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";

const ProjectsListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 0, pageIndex: 0 });
  const [searchString, setSearchString] = useState("");

  const currentListVariables = {
    organizationId: currentOrganization?.id || "0",
    skip: paginationInfo.pageIndex * paginationInfo.pageSize,
    take: paginationInfo.pageSize,
    searchString,
  };

  const {
    getProjectsByOrganizationId: getProjects,
    data: projects,
    status: getProjectsStatus,
  } = useLazyGetProjectsByOrganizationId();
  const { mutate: addProjectMutate, status: addProjectStatus } = useAddProject();
  const { mutate: updateProjectMutate, status: updateProjectStatus } = useUpdateProject();
  const { mutate: deleteProjectMutate, status: deleteProjectStatus } = useDeleteProject();

  const handlePageInfoChange = useCallback((pageIndex: number, pageSize: number) => {
    if (pageSize !== paginationInfo.pageSize || pageIndex !== paginationInfo.pageIndex) {
      setPaginationInfo({ pageSize, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchProjects = (searchString: string) => {
    if (searchString !== undefined) setSearchString(searchString);
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  };

  useEffect(() => {
    if (currentOrganization && paginationInfo.pageSize > 0) {
      getProjects({
        variables: currentListVariables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.pageSize, paginationInfo.pageIndex, currentOrganization, searchString]);

  const handleAddProject = (
    name: string,
    number: string,
    location: string,
    boundaries: GeoJSONPolygonType,
    emergencyContact: EmergencyContact
  ) => {
    if (currentOrganization) {
      addProjectMutate({
        refetchQueries: [
          {
            query: GET_PROJECTS_BY_ORGANIZATION_ID,
            variables: currentListVariables,
          },
        ],
        variables: {
          name,
          number,
          location,
          boundaries,
          emergencyContact,
          organizationId: currentOrganization.id,
        },
      });
    }
  };

  const handleEditProject = (
    id: string,
    name: string,
    number: string,
    location: string,
    boundaries: GeoJSONPolygonType,
    emergencyContact: EmergencyContact
  ) => {
    if (currentOrganization) {
      updateProjectMutate({
        variables: {
          projectId: id,
          name,
          number,
          location,
          boundaries,
          emergencyContact,
        },
      });
    }
  };

  const handleDeleteProject = (id: string) => {
    if (currentOrganization) {
      deleteProjectMutate({
        refetchQueries: [
          {
            query: GET_PROJECTS_BY_ORGANIZATION_ID,
            variables: currentListVariables,
          },
        ],
        variables: {
          projectId: id,
        },
      });
    }
  };

  return (
    <ProjectsList
      showProjectBoundariesOnMap={
        currentOrganization?.esriConfig?.integrationTypeId != null &&
        currentOrganization.esriConfig.integrationTypeId !== EsriIntegrationTypes.NONE.id
      }
      isProjectsLoading={getProjectsStatus.loading}
      projects={projects && projects.projectsByOrganizationId?.items}
      pageCount={projects && Math.ceil((projects.projectsByOrganizationId?.totalCount ?? 0) / paginationInfo.pageSize)}
      onPageInfoChange={handlePageInfoChange}
      onSearchProjects={handleSearchProjects}
      onAddProject={handleAddProject}
      addProjectStatus={addProjectStatus}
      onEditProject={handleEditProject}
      editProjectStatus={updateProjectStatus}
      onDeleteProject={handleDeleteProject}
      deleteProjectStatus={deleteProjectStatus}
    ></ProjectsList>
  );
};

export default ProjectsListContainer;
