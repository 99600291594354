import { StateKey } from "./globalTypes";

export interface IStateKeyList {
  [key: string]: { key: StateKey; name: string };
}

export const StateKeyList: IStateKeyList = {
  REQUESTED: { key: StateKey.REQUESTED, name: "Requested" },
  REJECTED: { key: StateKey.REJECTED, name: "Rejected" },
  IN_PROGRESS: { key: StateKey.IN_PROGRESS, name: "In Progress" },
  READY_TO_ISSUE: { key: StateKey.READY_TO_ISSUE, name: "Ready To Issue" },
  ISSUED: { key: StateKey.ISSUED, name: "Issued" },
  EXPIRED: { key: StateKey.EXPIRED, name: "Expired" },
  COMPLETED: { key: StateKey.COMPLETED, name: "Completed" },
  CANCELLED: { key: StateKey.CANCELLED, name: "Cancelled" },
};
