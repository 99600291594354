import { isArray, isBoolean, isString, maxBy, remove } from "lodash";
import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { Button, Checkbox, CheckboxProps, Form, Modal, TextAreaProps } from "semantic-ui-react";
import "./SetConfigurationValueModal.css";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: object) => void;
  submitInProgress: boolean;
  title?: string;
  initialValue?: object | null;
  label?: string;
}

const SetConfigurationValueModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  title,
  initialValue,
  label,
}: Props) => {
  const [value, setValue] = useState<object | null>(null);
  const [valueItems, setValueItems] = useState<{ id: number; text: string }[]>([]);

  useEffect(() => {
    if (initialValue !== null && initialValue !== undefined) {
      setValue(initialValue);
      if (isArray(initialValue)) {
        setValueItems(
          initialValue.map((v, i) => {
            return { text: v, id: i };
          })
        );
      }
    }
  }, [initialValue]);

  const handleTextChange = (e: any, data: TextAreaProps) => {
    setValue(new String(data.value));
  };

  const handleArrayItemChange = (id: number, text: string) => {
    setValueItems([...valueItems.map((i) => (i.id === id ? { id, text } : i))]);
  };

  const handleRemoveItem = (id: number) => {
    setValueItems([...remove(valueItems, (i) => i.id !== id)]);
  };

  const handleAddItem = () => {
    var newItem = { id: (maxBy(valueItems, (i) => i.id)?.id ?? 0) + 1, text: "" };
    setValueItems([...valueItems, newItem]);
  };

  const handleSubmit = () => {
    if (isArray(value)) {
      onSubmit(valueItems.map((i) => i.text));
      return;
    }
    onSubmit(value ?? new Boolean(false));
    setValue(null);
  };

  const handleClose = () => {
    setValue(null);
    onClose();
  };

  return (
    <Modal open={open} size="large">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            {isBoolean(value) && (
              <Form.Group>
                <label>{label}</label>
                <Form.Field>
                  <Checkbox
                    radio
                    checked={value === true}
                    label="Yes"
                    name="value"
                    onChange={() => {
                      setValue(true as any);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    checked={value === false}
                    label="No"
                    onChange={() => {
                      setValue(false as any);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            )}
            {isString(value) && (
              <Form.Group widths="equal">
                <Form.TextArea label="Value" name="value" value={value} onChange={handleTextChange} />
              </Form.Group>
            )}
            {isArray(value) && (
              <>
                <ReactSortable list={valueItems} setList={setValueItems} handle=".handle">
                  {valueItems.map((item) => (
                    <Form.Input
                      key={item.id}
                      name="value"
                      value={item.text}
                      onChange={(e, p) => handleArrayItemChange(item.id, p.value)}
                      action={
                        <>
                          <Button className="handle" icon="arrows alternate vertical"></Button>
                          <Button icon="cancel" onClick={(e) => handleRemoveItem(item.id)}></Button>
                        </>
                      }
                    />
                  ))}
                </ReactSortable>
                <Button onClick={handleAddItem}>Add</Button>
              </>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SetConfigurationValueModal;
