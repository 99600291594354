import { gql, useLazyQuery } from "@apollo/client";
import { getErrors } from "../../utils";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { GetPermitsCountByStates, GetPermitsCountByStatesVariables } from "./__generated__/GetPermitsCountByStates";

export const GET_PERMITS_COUNT_BY_STATES = gql`
  query GetPermitsCountByStates($projectId: String!) {
    permitsCountByStates(projectId: $projectId) {
      state {
        name
        key
      }
      count
    }
  }
`;

export function useGetPermitsCountByStates() {
  const [getPermitsCountByStates, { loading, error, data }] = useLazyQuery<
    GetPermitsCountByStates,
    GetPermitsCountByStatesVariables
  >(GET_PERMITS_COUNT_BY_STATES, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return {
    getPermitsCountByStates,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
