import { useEffect } from "react";
import {
  Permit,
  triggerApproveOrRejectPermitted,
  triggerCancelPermitted,
  triggerCompletePermitted,
  triggerExtendPermitted,
  triggerRequestPermitted,
  triggerSavePermitted,
  triggerSubmitPermitted,
  triggerUndoCancellationPermitted,
} from "../models/Permit";
import PermitView from "../components/PermitView";
import { useRejectPermit } from "../operations/mutations/rejectPermit";
import { useApprovePermit } from "../operations/mutations/approvePermit";
import { useCancelPermit } from "../operations/mutations/cancelPermit";
import { useUndoCancelledPermit } from "../operations/mutations/undoCancelledPermit";
import { useExtendPermit } from "../operations/mutations/extendPermit";
import { useCompletePermit } from "../operations/mutations/completePermit";
import { useProjectPolicies } from "../hooks/useProjectPolicies";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useLazyGetPermitToDig } from "../operations/queries/getPermitToDig";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { StateKeyList } from "../models/StateKey";
import { useHistory } from "react-router";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";

interface Props {
  showPermitBoundariesOnMap?: boolean;
  permitId?: string | null;
  onClose: () => void;
  onPermitRejected?: (permit: Permit) => void;
  onPermitApproved?: (permit: Permit) => void;
  onPermitCancelled?: (permit: Permit) => void;
  onPermitUndoCancellation?: (permit: Permit) => void;
  onPermitExtended?: (permit: Permit) => void;
  onPermitCompleted?: (permit: Permit) => void;
}

const PermitViewContainer = ({
  showPermitBoundariesOnMap,
  permitId,
  onClose,
  onPermitApproved,
  onPermitCompleted,
  onPermitExtended,
  onPermitRejected,
  onPermitCancelled,
  onPermitUndoCancellation,
}: Props) => {
  const history = useHistory();
  const { currentOrganization, organizationName } = useCurrentOrganization();
  const { projectName } = useCurrentProject();
  const currentUser = useCurrentUser();
  const {
    authorizedToAddPermit,
    authorizedToRequestPermit,
    authorizedToApprovePermit,
    authorizedToExtendPermit,
    authorizedToCompletePermit,
    authorizedToCancelOwnPermit,
    authorizedToCancelPermit,
    authorizedToUndoCancellationOwnPermit,
    authorizedToUndoCancellationPermit,
  } = useProjectPolicies();

  const { mutate: rejectPermitMutate, status: rejectPermitStatus } = useRejectPermit();
  const { mutate: approvePermitMutate, status: approvePermitStatus } = useApprovePermit();
  const { mutate: cancelPermitMutate, status: cancelPermitStatus } = useCancelPermit();
  const { mutate: undoCancelledPermitMutate, status: undoCancelledPermitStatus } = useUndoCancelledPermit();
  const { mutate: extendPermitMutate, status: extendPermitStatus } = useExtendPermit();
  const { mutate: completePermitMutate, status: completePermitStatus } = useCompletePermit();
  const { getPermitToDig: getPermit, data: permit, refetch: refetchPermitToDig } = useLazyGetPermitToDig();

  useEffect(() => {
    if (permitId) {
      getPermit({ variables: { id: permitId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitId]);

  const handleRejectPermit = (comments: string) => {
    if (permit) {
      rejectPermitMutate({
        variables: {
          id: permit.id,
          comments,
        },
      });
    }
  };

  const handleApprovePermit = () => {
    if (permit) {
      approvePermitMutate({
        variables: {
          id: permit.id,
        },
      });
    }
  };

  const handleCancelPermit = () => {
    if (permit) {
      cancelPermitMutate({
        variables: {
          id: permit.id,
        },
      });
    }
  };

  const handleUndoCancelledPermit = () => {
    if (permit) {
      undoCancelledPermitMutate({
        variables: {
          id: permit.id,
        },
      });
    }
  };

  const handleExtendPermit = (to: Date, comments: string) => {
    if (permit) {
      extendPermitMutate({
        variables: {
          id: permit.id,
          to,
          comments,
        },
      });
    }
  };

  const handleCompletePermit = () => {
    if (permit) {
      completePermitMutate({
        variables: {
          id: permit.id,
        },
      });
    }
  };

  const cancelButtonVisibility = (): boolean => {
    if (!permit) {
      return false;
    }

    if (!triggerCancelPermitted(permit)) {
      return false;
    }

    if (permit.state.key === StateKeyList.IN_PROGRESS.key && authorizedToCancelPermit) {
      return true;
    }

    if (
      permit.state.key === StateKeyList.REJECTED.key &&
      authorizedToCancelOwnPermit &&
      permit.createdBy.user.id === currentUser.user?.id
    ) {
      return true;
    }

    return false;
  };

  const undoCancellationButtonVisibility = (): boolean => {
    if (!permit) {
      return false;
    }

    if (!triggerUndoCancellationPermitted(permit)) {
      return false;
    }

    if (
      authorizedToUndoCancellationPermit ||
      (authorizedToUndoCancellationOwnPermit && permit.createdBy.user.id === currentUser.user?.id)
    ) {
      return true;
    }

    return false;
  };

  const editButtonVisibility = (): boolean => {
    if (!permit) {
      return false;
    }

    // Edit Rejected permits by permit creator (requester)
    if (triggerRequestPermitted(permit) && permit.createdBy.user.id === currentUser.user?.id) {
      return true;
    }

    // Edit InProgress permits by permit manager (some who can submit permits)
    if (authorizedToAddPermit && (triggerSavePermitted(permit) || triggerSubmitPermitted(permit))) {
      return true;
    }

    return false;
  };

  const handleAsBuiltAdded = () => {
    refetchPermitToDig && refetchPermitToDig();
  };

  const handleEditPermit = () => {
    if (organizationName && projectName && permit) {
      if (authorizedToAddPermit) {
        history.push(`/${organizationName}/projects/${projectName}/permits/add-permit/${permit.id}`);
      } else if (authorizedToRequestPermit) {
        history.push(`/${organizationName}/projects/${projectName}/permits/request-permit/${permit.id}`);
      }
    }
  };

  return (
    <PermitView
      showPermitBoundariesOnMap={showPermitBoundariesOnMap}
      approveAndRejectButtonsVisible={authorizedToApprovePermit && permit && triggerApproveOrRejectPermitted(permit)}
      cancelButtonVisible={cancelButtonVisibility()}
      undoCancellationButtonVisible={undoCancellationButtonVisibility()}
      extendButtonVisible={authorizedToExtendPermit && permit && triggerExtendPermitted(permit)}
      completeButtonVisible={authorizedToCompletePermit && permit && triggerCompletePermitted(permit)}
      editButtonVisible={editButtonVisibility()}
      permit={permit}
      organization={currentOrganization}
      onClose={onClose}
      onEditPermit={handleEditPermit}
      onRejectPermit={handleRejectPermit}
      rejectPermitStatus={rejectPermitStatus}
      onApprovePermit={handleApprovePermit}
      approvePermitStatus={approvePermitStatus}
      onCancelPermit={handleCancelPermit}
      cancelPermitStatus={cancelPermitStatus}
      onUndoCancelledPermit={handleUndoCancelledPermit}
      undoCancelledPermitStatus={undoCancelledPermitStatus}
      onExtendPermit={handleExtendPermit}
      extendPermitStatus={extendPermitStatus}
      onCompletePermit={handleCompletePermit}
      completePermitStatus={completePermitStatus}
      onPermitRejected={onPermitRejected}
      onPermitApproved={onPermitApproved}
      onPermitCancelled={onPermitCancelled}
      onPermitUndoCancellation={onPermitUndoCancellation}
      onPermitExtended={onPermitExtended}
      onPermitCompleted={onPermitCompleted}
      onAsBuiltAdded={handleAsBuiltAdded}
    ></PermitView>
  );
};

export default PermitViewContainer;
