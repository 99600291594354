import LayoutWithSidebar from "../components/LayoutWithSidebar";
import MainSideMenu from "../components/MainSideMenu";
import OrgUsersListContainer from "../containers/OrgUsersListContainer";
import MainHeaderContainer from "../containers/MainHeaderContainer";

const OrgUserPage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <MainSideMenu></MainSideMenu>;
  };

  const contentPanelContent = () => {
    return <OrgUsersListContainer></OrgUsersListContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default OrgUserPage;
