import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { UpdateOrgRole, UpdateOrgRoleVariables } from "./__generated__/UpdateOrgRole";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";

const UPDATE_ORG_ROLE = gql`
  ${ORG_ROLE_FIELDS}
  mutation UpdateOrgRole(
    $organizationId: String!
    $roleId: String!
    $displayName: String
    $permissionNames: [String!]
    $permitRoleSettingsInput: PermitRoleSettingsInput
  ) {
    updateOrgRole(
      input: {
        organizationId: $organizationId
        roleId: $roleId
        displayName: $displayName
        permissionNames: $permissionNames
        permitRoleSettingsInput: $permitRoleSettingsInput
      }
    ) {
      orgRole {
        ...OrgRoleFields
      }
    }
  }
`;

export function useUpdateOrgRole() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateOrgRole,
    UpdateOrgRoleVariables
  >(UPDATE_ORG_ROLE, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
