import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import { Organization } from "../models/Organization";
import { trancateTime } from "../utils";
import AdminAddLicenceForm, { FormData } from "./AdminAddLicenceForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (startDate: Date, endDate: Date, numberOfUsers: number, comment: string, organizationId: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  organizations?: Organization[] | null;
}

const AdminAddLicenceModal = ({ open, onClose, onSubmit, submitInProgress, submitErrors, organizations }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    setFormData({} as FormData);
    setFormErrors({} as FormError);
    setHasSubmitted(false);
  }, [open]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.numberOfUsers) {
      formError.numberOfUsers = "Please enter number of users";
    }

    if (!formData.organizationId) {
      formError.organizationId = "Please select organization";
    }

    if (!formData.startDate) {
      formError.startDate = "Please enter start date";
    }

    if (!formData.endDate) {
      formError.endDate = "Please enter end date";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { startDate, endDate, numberOfUsers, comment, organizationId } = formData;
      onSubmit(trancateTime(startDate!), trancateTime(endDate!), parseInt(numberOfUsers), comment, organizationId);
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Licence</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AdminAddLicenceForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
            organizations={organizations}
          ></AdminAddLicenceForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AdminAddLicenceModal;
