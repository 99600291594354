import { useCurrentOrganization } from "../../hooks/useCurrentOrganization";
import Map from "./Map";

interface Props {
  longitude: number;
  latitude: number;
}

const LocationViewer = ({ longitude, latitude }: Props) => {
  const { currentOrganization } = useCurrentOrganization();

  const point = {
    type: "point",
    latitude,
    longitude,
  } as __esri.Point;

  return (
    <Map
      webMapItemId={currentOrganization?.esriConfig?.webMapItemId}
      center={point}
      items={[{ geometry: point }]}
    ></Map>
  );
};

export default LocationViewer;
