import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { DeleteOrganization, DeleteOrganizationVariables } from "./__generated__/DeleteOrganization";

const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: String!) {
    deleteOrganization(input: { id: $id }) {
      id
    }
  }
`;

export function useDeleteOrganization() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteOrganization,
    DeleteOrganizationVariables
  >(DELETE_ORGANIZATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
