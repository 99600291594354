import { gql } from "@apollo/client";

export const CORE_LICENCE_FIELDS = gql`
  fragment CoreLicenceFields on Licence {
    id
    startDate
    endDate
    numberOfUsers
    comment
    isArchived
    status
    isActive
    organization {
      id
      name
    }
  }
`;
