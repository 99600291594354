import { useEffect } from "react";
import ContactSupportModal from "../components/ContactSupportModal";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useSendEnquiryToSupport } from "../operations/mutations/sendEnquiryToSupport";
import { toastError, toastSuccess } from "../utils";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ContactSupportModalContainer = ({ open, onClose }: Props) => {
  const { currentOrganization } = useCurrentOrganization();
  const { mutate: sendEnquiryToSupportMutate, status: sendEnquiryToSupportStatus } = useSendEnquiryToSupport();

  const handleSend = (text: string) => {
    if (currentOrganization) {
      sendEnquiryToSupportMutate({ variables: { organizationId: currentOrganization?.id, text } });
    }
  };

  useEffect(() => {
    if (sendEnquiryToSupportStatus.successful) {
      toastSuccess("Enquiry was sent successfully!");
      handleClose();
    } else if (sendEnquiryToSupportStatus.errors) {
      toastError("Unable to send the enquiry, please try again later.");
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEnquiryToSupportStatus]);

  const handleClose = () => {
    sendEnquiryToSupportStatus.reset();
    onClose();
  };

  return (
    <ContactSupportModal
      open={open}
      onClose={handleClose}
      onSend={handleSend}
      sending={sendEnquiryToSupportStatus.loading}
    />
  );
};

export default ContactSupportModalContainer;
