// General Policies
const ADMIN_SYSTEM = { key: "ADMIN_SYSTEM", name: "AdminSystem" };

// Organization Policies
const MANAGE_ORG_USERS = { key: "MANAGE_ORG_USERS", name: "ManagerOrgUsers" };
const MANAGE_PROJECTS = { key: "MANAGE_PROJECTS", name: "ManageProjects" };
const MANAGE_DOCUMENT_TYPES = { key: "MANAGE_DOCUMENT_TYPES", name: "ManageDocumentTypes" };
const MANAGE_GANG_MEMBERS = { key: "MANAGE_GANG_MEMBERS", name: "ManageGangMembers" };

// Project Policies
const ADD_DOCUMENT = { key: "ADD_DOCUMENT", name: "AddDocument" };
const ADD_NEW_DOCUMENT_VERSION = { key: "ADD_NEW_DOCUMENT_VERSION", name: "AddNewDocumentVersion" };
const DELETE_DOCUMENT = { key: "DELETE_DOCUMENT", name: "DeleteDocument" };
const ADD_PERMIT = { key: "ADD_PERMIT", name: "AddPermit" };
const REQUEST_PERMIT = { key: "REQUEST_PERMIT", name: "RequestPermit" };
const VIEW_ALL_PERMITS = { key: "VIEW_ALL_PERMITS", name: "ViewAllPermits" };
const APPROVE_PERMIT_REQUEST = { key: "APPROVE_PERMIT_REQUEST", name: "ApprovePermitRequest" };
const EXTEND_PERMIT = { key: "EXTEND_PERMIT", name: "ExtendPermit" };
const COMPLETE_PERMIT = { key: "COMPLETE_PERMIT", name: "CompletePermit" };
const CANCEL_OWN_PERMIT = { key: "CANCEL_OWN_PERMIT", name: "CancelOwnPermit" };
const CANCEL_PERMIT = { key: "CANCEL_PERMIT", name: "CancelPermit" };
const UNDO_CANCELLATION_OWN_PERMIT = { key: "UNDO_CANCELLATION_OWN_PERMIT", name: "UndoCancellationOwnPermit" };
const UNDO_CANCELLATION_PERMIT = { key: "UNDO_CANCELLATION_PERMIT", name: "UndoCancellationPermit" };

export {
  ADMIN_SYSTEM,
  MANAGE_DOCUMENT_TYPES,
  MANAGE_GANG_MEMBERS,
  MANAGE_ORG_USERS,
  MANAGE_PROJECTS,
  ADD_DOCUMENT,
  ADD_NEW_DOCUMENT_VERSION,
  DELETE_DOCUMENT,
  ADD_PERMIT,
  REQUEST_PERMIT,
  VIEW_ALL_PERMITS,
  APPROVE_PERMIT_REQUEST,
  EXTEND_PERMIT,
  COMPLETE_PERMIT,
  CANCEL_OWN_PERMIT,
  CANCEL_PERMIT,
  UNDO_CANCELLATION_OWN_PERMIT,
  UNDO_CANCELLATION_PERMIT,
};
