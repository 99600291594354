import { GET_LICENCES, useLazyGetLicences } from "../operations/queries/getLicences";
import AdminLicencesList from "../components/AdminLicencesList";
import { useAddLicence } from "../operations/mutations/addLicence";
import { useCallback, useEffect, useState } from "react";
import { useArchiveLicence } from "../operations/mutations/archiveLicence";
import { useGetOrganizations } from "../operations/queries/getOrganizations";

const AdminLicencesListContainer = () => {
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 0, pageIndex: 0 });
  const [searchString, setSearchString] = useState("");
  const [orgFilter, setOrgFilter] = useState<string>();
  const [isArchivedFilter, setIsArchivedFilter] = useState<boolean | null>(false);

  const currentListVariables = {
    skip: paginationInfo.pageIndex * paginationInfo.pageSize,
    take: paginationInfo.pageSize,
    orgId: orgFilter,
    isArchived: isArchivedFilter,
    searchString,
  };

  const { data: organizations } = useGetOrganizations();
  const { getLicences, data: licences, status: getLicencesStatus } = useLazyGetLicences();
  const { mutate: addLicenceMutate, status: addLicenceStatus } = useAddLicence();
  const { mutate: archiveLicenceMutate, status: archiveLicenceStatus } = useArchiveLicence();

  const handlePageInfoChange = useCallback((pageIndex: number, pageSize: number) => {
    if (pageSize !== paginationInfo.pageSize || pageIndex !== paginationInfo.pageIndex) {
      setPaginationInfo({ pageSize, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchLicences = (searchString: string) => {
    if (searchString !== undefined) setSearchString(searchString);
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  };

  const handleOrgFilter = (orgId: string) => {
    if (orgId !== undefined) setOrgFilter(orgId);
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  };

  const handleIsArchivedFilter = (isArchived: boolean | null) => {
    setIsArchivedFilter(isArchived);
  };

  useEffect(() => {
    if (paginationInfo.pageSize > 0) {
      getLicences({
        variables: currentListVariables,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.pageSize, paginationInfo.pageIndex, orgFilter, isArchivedFilter, searchString]);

  const handleAddLicence = (
    startDate: Date,
    endDate: Date,
    numberOfUsers: number,
    comment: string,
    organizationId: string
  ) => {
    addLicenceMutate({
      refetchQueries: [
        {
          query: GET_LICENCES,
          variables: currentListVariables,
        },
      ],
      variables: {
        startDate,
        endDate,
        numberOfUsers,
        comment,
        organizationId,
      },
    });
  };

  const handleToggleArchivedStatusLicence = (id: string) => {
    archiveLicenceMutate({
      variables: {
        id,
      },
    });
  };

  return (
    <AdminLicencesList
      isLicencesLoading={getLicencesStatus.loading}
      licences={licences && licences.licences?.items}
      organizations={organizations && organizations.organizations}
      pageCount={licences && Math.ceil((licences.licences?.totalCount ?? 0) / paginationInfo.pageSize)}
      onPageInfoChange={handlePageInfoChange}
      onSearchLicences={handleSearchLicences}
      onOrgFilter={handleOrgFilter}
      onAddLicence={handleAddLicence}
      addLicenceStatus={addLicenceStatus}
      onArchiveLicence={handleToggleArchivedStatusLicence}
      archiveLicenceStatus={archiveLicenceStatus}
      isArchivedFilterValue={isArchivedFilter}
      onIsArchivedFilter={handleIsArchivedFilter}
    ></AdminLicencesList>
  );
};

export default AdminLicencesListContainer;
