/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum GeoJSONGeometryType {
  GeometryCollection = "GeometryCollection",
  LineString = "LineString",
  MultiLineString = "MultiLineString",
  MultiPoint = "MultiPoint",
  MultiPolygon = "MultiPolygon",
  Point = "Point",
  Polygon = "Polygon",
}

export enum PermitToDigSignatureType {
  DOCUMENT = "DOCUMENT",
  GANG_MEMBER = "GANG_MEMBER",
  PERMIT_AUTHORIZATION = "PERMIT_AUTHORIZATION",
  SUBMISSION = "SUBMISSION",
}

export enum PermitToDigTrigger {
  APPROVE_REQUEST = "APPROVE_REQUEST",
  CANCEL = "CANCEL",
  COMPLETE = "COMPLETE",
  CREATE = "CREATE",
  EXPIRE = "EXPIRE",
  EXTEND = "EXTEND",
  ISSUE = "ISSUE",
  REJECT_REQUEST = "REJECT_REQUEST",
  REPORT_PROBLEM = "REPORT_PROBLEM",
  REQUEST = "REQUEST",
  SAVE = "SAVE",
  SUBMIT = "SUBMIT",
  UNDO_CANCELLATION = "UNDO_CANCELLATION",
  WORK_COMPLETED = "WORK_COMPLETED",
}

export enum StateKey {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  IN_PROGRESS = "IN_PROGRESS",
  ISSUED = "ISSUED",
  READY_TO_ISSUE = "READY_TO_ISSUE",
  REJECTED = "REJECTED",
  REQUESTED = "REQUESTED",
}

export interface BuriedServicesInductionInput {
  date?: any | null;
  number?: string | null;
  expiresOn?: any | null;
}

export interface CalibrationCertificateInput {
  catSerialNumber?: string | null;
  catCalibrationDate?: any | null;
  gennySerialNumber?: string | null;
  gennyCalibrationDate?: any | null;
}

export interface CompanyInductionInput {
  date?: any | null;
  number?: string | null;
}

export interface EmergencyContactInput {
  name?: string | null;
  phoneNumber?: string | null;
}

export interface EsriConfigInput {
  webMapItemId?: string | null;
  integrationTypeId?: string | null;
}

export interface FileMetaDataInput {
  id?: string | null;
  fileName?: string | null;
  contentType?: string | null;
}

export interface GeoJSONPolygonInput {
  type?: GeoJSONGeometryType | null;
  coordinates?: ((any | null)[] | null)[] | null;
  crs?: number | null;
}

export interface OrgUserFileInput {
  title: string;
  expiryDate?: any | null;
  fileMetaData?: FileMetaDataInput | null;
}

export interface PermitAssignmentInput {
  orgRoleId?: string | null;
  orgUserId?: string | null;
}

export interface PermitDataInput {
  comments?: string | null;
  contractor?: string | null;
  subContractor?: string | null;
  locationOfWork?: string | null;
  permittedEquipment?: string | null;
  worksDescription?: string | null;
  maxPenetrationDepth?: string | null;
  from: any;
  to: any;
  hoursFrom: any;
  hoursTo: any;
  noAffectedServices: boolean;
  affectedServices?: (string | null)[] | null;
}

export interface PermitRoleSettingsInput {
  isPermitAssignee: boolean;
  mandatoryOnSubmission: boolean;
  shouldAuthorizePermit: boolean;
  authorizationDeclaration?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
