import { useEffect, useState } from "react";
import { Segment, Menu, Header, Message } from "semantic-ui-react";
import OrgUsersGrid from "./OrgUsersGrid";
import { OrgUser } from "../models/OrgUser";
import AddOrgUserModal from "./AddOrgUserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import { toastSuccess, toastError } from "./../utils";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { User } from "../models/User";
import { CalibrationCertificate } from "../models/CalibrationCertificate";
import { Licence } from "../models/Licence";
import { OrgUserFileInput } from "../models/globalTypes";
import EditOrgUserModalContainer from "../containers/EditOrgUserModalContainer";

interface Props {
  activeLicence?: Licence | null;
  isOrgUsersLoading: boolean;
  orgUsers?: OrgUser[];
  onAddOrgUser: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    orgUserFiles: OrgUserFileInput[]
  ) => void;
  addUserStatus: AsyncActionStatus;
  onAddOrgUserWithExistingUser: (
    id: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    orgUserFiles: OrgUserFileInput[]
  ) => void;
  addUserWithExistingUserStatus: AsyncActionStatus;
  onEditOrgUser: (
    id: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    newOrgUserFiles: OrgUserFileInput[],
    deletedOrgUserFileIds: string[]
  ) => void;
  editUserStatus: AsyncActionStatus;
  onDeleteOrgUser: (id: string) => void;
  deleteUserStatus: AsyncActionStatus;
  onResetPassword: (id: string, newPassword: string) => void;
  resetPasswordStatus: AsyncActionStatus;
  onSearchUser: (email: string) => void;
  onSearchUserReset: () => void;
  user?: User;
  onToggleArchivedStatusOrgUser: (orgUserId: string) => void;
  toggleArchivedStatusOrgUserStatus: AsyncActionStatus;
}

const OrgUsersList = ({
  activeLicence,
  isOrgUsersLoading,
  orgUsers,
  onAddOrgUser,
  addUserStatus,
  onAddOrgUserWithExistingUser,
  addUserWithExistingUserStatus,
  onEditOrgUser,
  editUserStatus,
  onDeleteOrgUser,
  deleteUserStatus,
  onResetPassword,
  resetPasswordStatus,
  onSearchUser,
  onSearchUserReset,
  user,
  onToggleArchivedStatusOrgUser,
  toggleArchivedStatusOrgUserStatus,
}: Props) => {
  const [openAddOrgUserModal, setOpenAddOrgUserModal] = useState(false);
  const [openEditOrgUserModal, setOpenEditOrgUserModal] = useState(false);
  const [openResetPasswordOrgUserModal, setOpenResetPasswordOrgUserModal] = useState(false);
  const [selectedOrgUserToEdit, setSelectedOrgUserToEdit] = useState<OrgUser | undefined>(undefined);

  const handleEditOrgUser = (orgUserId: string) => {
    const user = orgUsers?.find((u) => u.id === orgUserId);
    if (user) {
      setSelectedOrgUserToEdit({ ...user } as OrgUser);
      setOpenEditOrgUserModal(true);
    }
  };

  const handleResetPasswordOrgUser = (orgUserId: string) => {
    const user = orgUsers?.find((u) => u.id === orgUserId);
    if (user) {
      setSelectedOrgUserToEdit({ ...user } as OrgUser);
      setOpenResetPasswordOrgUserModal(true);
    }
  };

  const handleDeleteOrgUser = (orgUserId: string) => {
    const user = orgUsers?.find((u) => u.id === orgUserId);
    onDeleteOrgUser(user?.userId!);
  };

  useEffect(() => {
    if (deleteUserStatus.successful) {
      toastSuccess("User deleted successfully!");
      handleModalsClose();
    } else if (deleteUserStatus.errors && deleteUserStatus.errors.length > 0) {
      toastError(deleteUserStatus.errors[0]);
      handleModalsClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserStatus]);

  const handleModalsClose = () => {
    setOpenAddOrgUserModal(false);
    setOpenEditOrgUserModal(false);
    setOpenResetPasswordOrgUserModal(false);
    setSelectedOrgUserToEdit(undefined);

    addUserStatus.reset();
    addUserWithExistingUserStatus.reset();
    editUserStatus.reset();
    deleteUserStatus.reset();
    resetPasswordStatus.reset();
    onSearchUserReset();
    toggleArchivedStatusOrgUserStatus.reset();
  };

  const handleAddOrgUserModalSubmit = (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    orgUserFiles: OrgUserFileInput[]
  ) => {
    if (user) {
      onAddOrgUserWithExistingUser(user.id, isAdmin, phoneNumber, calibrationCertificate, orgUserFiles);
    } else {
      onAddOrgUser(firstName, lastName, email, password, isAdmin, phoneNumber, calibrationCertificate, orgUserFiles);
    }
  };

  useEffect(() => {
    if (addUserStatus.successful) {
      toastSuccess("User added successfully!");
      handleModalsClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserStatus.successful]);

  useEffect(() => {
    if (addUserWithExistingUserStatus.successful) {
      toastSuccess("User added successfully!");
      handleModalsClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserWithExistingUserStatus.successful]);

  const handleEditOrgUserModalSubmit = (
    userId: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    newOrgUserFiles: OrgUserFileInput[],
    deletedOrgUserFileIds: string[]
  ) => {
    onEditOrgUser(
      userId,
      firstName,
      lastName,
      isAdmin,
      phoneNumber,
      calibrationCertificate,
      newOrgUserFiles,
      deletedOrgUserFileIds
    );
  };

  useEffect(() => {
    if (editUserStatus.successful) {
      toastSuccess("User updated successfully!");
      setOpenEditOrgUserModal(false);
      setSelectedOrgUserToEdit(undefined);
    }
  }, [editUserStatus.successful]);

  const handleResetPasswordOrgUserModalSubmit = (newPassword: string) => {
    if (selectedOrgUserToEdit) {
      onResetPassword(selectedOrgUserToEdit.userId, newPassword);
    }
  };

  useEffect(() => {
    if (resetPasswordStatus.successful) {
      toastSuccess("Password reset successfully!");
      setOpenResetPasswordOrgUserModal(false);
      setSelectedOrgUserToEdit(undefined);
    }
  }, [resetPasswordStatus.successful]);

  useEffect(() => {
    if (toggleArchivedStatusOrgUserStatus.successful) {
      toastSuccess("User archive status changed successfully!");
      handleModalsClose();
    } else if (toggleArchivedStatusOrgUserStatus.errors) {
      toastError(toggleArchivedStatusOrgUserStatus.errors.toString());
      handleModalsClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleArchivedStatusOrgUserStatus]);

  return (
    <>
      <Segment basic>
        <Header as="h2">User</Header>
      </Segment>

      <Menu pointing secondary color="green">
        <Menu.Item icon="plus" name="New User" onClick={(e) => setOpenAddOrgUserModal(true)} />
      </Menu>

      <Segment basic loading={isOrgUsersLoading}>
        <Message success={activeLicence?.status === "Active"} negative={activeLicence?.status !== "Active"}>
          {activeLicence && (
            <p>
              Current licence: {activeLicence.numberOfUsers} users, Status: {activeLicence.status}
            </p>
          )}
          {!activeLicence && <p>You don't have any active licence</p>}
        </Message>
        <OrgUsersGrid
          orgUsers={orgUsers}
          onEditOrgUser={handleEditOrgUser}
          onDeleteOrgUser={handleDeleteOrgUser}
          onResetPasswordOrgUser={handleResetPasswordOrgUser}
          onToggleArchivedStatusOrgUser={onToggleArchivedStatusOrgUser}
        />
      </Segment>

      <AddOrgUserModal
        open={openAddOrgUserModal}
        onClose={handleModalsClose}
        onSubmit={handleAddOrgUserModalSubmit}
        submitInProgress={addUserStatus.loading || addUserWithExistingUserStatus.loading}
        submitErrors={addUserStatus.errors ? addUserStatus.errors : addUserWithExistingUserStatus.errors}
        onEmailEntered={onSearchUser}
        user={user}
      ></AddOrgUserModal>

      <EditOrgUserModalContainer
        open={openEditOrgUserModal}
        onClose={handleModalsClose}
        onSubmit={handleEditOrgUserModalSubmit}
        submitInProgress={editUserStatus.loading}
        orgUser={selectedOrgUserToEdit}
        submitErrors={editUserStatus.errors}
      ></EditOrgUserModalContainer>

      <ResetPasswordModal
        open={openResetPasswordOrgUserModal}
        onClose={handleModalsClose}
        onSubmit={handleResetPasswordOrgUserModalSubmit}
        submitInProgress={resetPasswordStatus.loading}
        submitErrors={resetPasswordStatus.errors}
      ></ResetPasswordModal>
    </>
  );
};

export default OrgUsersList;
