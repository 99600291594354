import { Segment, Menu, Header, Grid, Input, Dropdown, InputOnChangeData, Loader } from "semantic-ui-react";
import DocumentsGrid from "./DocumentsGrid";
import { Document_ListItem } from "../models/Document_ListItem";
import { AsyncActionStatus, AsyncQueryStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastSuccess } from "../utils";
import AddDocumentModalContainer from "../containers/AddDocumentModalContainer";
import AddNewVersionToDocumentModalContainer from "../containers/AddNewVersionToDocumentModalContainer";
import DocumentViewContainer from "../containers/DocumentViewContainer";
import ViewDocumentModal from "./ViewDocumentModal";
import { DocumentType } from "../models/DocumentType";
import { Document_Data } from "../models/Document_Data";

interface Props {
  newDocumentButtonVisible?: boolean;
  addNewVersionButtonVisible?: boolean;
  deleteDocumentButtonVisible?: boolean;
  documents?: Document_ListItem[] | null;
  pageCount?: number;
  documentTypes?: DocumentType[];
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchDocuments: (serachStrign: string) => void;
  onTypeFilter: (typeId: string) => void;
  getDocumentsStatus: AsyncQueryStatus;
  onDeleteDocument: (id: string) => void;
  deleteDocumentStatus: AsyncActionStatus;
  onDocumentAdded?: (doc: Document_Data) => void;
}

const DocumentsList = ({
  newDocumentButtonVisible,
  addNewVersionButtonVisible,
  deleteDocumentButtonVisible,
  documents,
  pageCount,
  documentTypes,
  onPageInfoChange,
  onSearchDocuments,
  onTypeFilter,
  getDocumentsStatus,
  onDeleteDocument,
  deleteDocumentStatus,
  onDocumentAdded,
}: Props) => {
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);
  const [openAddNewVersionToDocumentModal, setOpenAddNewVersionToDocumentModal] = useState(false);
  const [openViewDocumentSection, setOpenViewDocumentSection] = useState(false);
  const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);
  const [selectedDocumentToView, setSelectedDocumentToView] = useState<Document_ListItem | undefined>(undefined);
  const [selectedDocumentToAddNewVersion, setSelectedDocumentToAddNewVersion] = useState<Document_ListItem | undefined>(
    undefined
  );

  const handleAddNewVersionToDocument = (documentId: string) => {
    const document = documents?.find((o) => o.id === documentId);
    if (document) {
      setSelectedDocumentToAddNewVersion({ ...document } as Document_ListItem);
      setOpenAddNewVersionToDocumentModal(true);
    }
  };

  const handleDocViewChangeVersion = (doc: Document_ListItem) => {
    setSelectedDocumentToView(doc);
  };

  const handleDeleteDocument = (documentId: string) => {
    onDeleteDocument(documentId);
  };

  useEffect(() => {
    if (deleteDocumentStatus.successful) {
      toastSuccess("Document deleted successfully!");
    }
  }, [deleteDocumentStatus.successful]);

  const handleModalClose = () => {
    setOpenAddDocumentModal(false);
    setOpenAddNewVersionToDocumentModal(false);
    setOpenViewDocumentModal(false);

    setSelectedDocumentToAddNewVersion(undefined);

    deleteDocumentStatus.reset();
  };

  const handleDocumentClicked = (documentId: string) => {
    setOpenViewDocumentSection(true);
    setSelectedDocumentToView(documents?.find((d) => d.id === documentId));
  };

  const handleViewDocumentSectionClose = () => {
    setSelectedDocumentToView(undefined);
    setOpenViewDocumentSection(false);
  };

  const handleViewDocumentPopup = () => {
    setOpenViewDocumentModal(true);
  };

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchDocuments(value);
  };

  const handleTypeFilterChange = (e: any, { name, value }: any) => {
    onTypeFilter(value === "" ? null : value);
  };

  const handleDocumentAdded = (doc: Document_Data) => {
    handleModalClose();
    onDocumentAdded && onDocumentAdded(doc);
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">
          Documents Library
          <Loader inline active={getDocumentsStatus.loading} size="mini" />
        </Header>
      </Segment>

      <Menu pointing secondary color="green">
        {newDocumentButtonVisible && (
          <Menu.Item icon="plus" name="New document" onClick={(e) => setOpenAddDocumentModal(true)} />
        )}

        <Menu.Menu position="right">
          {/* <Menu.Item>
            <Dropdown placeholder="Dates" basic></Dropdown>
          </Menu.Item> */}
          <Menu.Item>
            <Dropdown
              placeholder="Type"
              basic
              clearable
              onChange={handleTypeFilterChange}
              options={documentTypes?.map((t) => ({ key: t.id, text: t.name, value: t.id }))}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment basic>
        <Grid columns={openViewDocumentSection ? "2" : "1"}>
          <Grid.Row>
            <Grid.Column width={openViewDocumentSection ? "10" : "16"}>
              <DocumentsGrid
                addNewVersionButtonVisible={addNewVersionButtonVisible}
                deleteDocumentButtonVisible={deleteDocumentButtonVisible}
                documents={documents}
                selectedDocument={selectedDocumentToView}
                pageCount={pageCount}
                onPageInfoChange={onPageInfoChange}
                onAddNewVersionToDocument={handleAddNewVersionToDocument}
                onDeleteDocument={handleDeleteDocument}
                onDocumentClicked={handleDocumentClicked}
              />
            </Grid.Column>
            {!openViewDocumentModal && openViewDocumentSection && (
              <Grid.Column width="6">
                <DocumentViewContainer
                  onPopup={handleViewDocumentPopup}
                  onClose={handleViewDocumentSectionClose}
                  document={selectedDocumentToView}
                  onChangeVersion={handleDocViewChangeVersion}
                ></DocumentViewContainer>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>

      <ViewDocumentModal
        open={openViewDocumentModal}
        onClose={handleModalClose}
        document={selectedDocumentToView}
        onChangeVersion={handleDocViewChangeVersion}
      ></ViewDocumentModal>

      <AddDocumentModalContainer
        open={openAddDocumentModal}
        onDocumentAdded={handleDocumentAdded}
        onClose={() => {
          handleModalClose();
        }}
      ></AddDocumentModalContainer>

      <AddNewVersionToDocumentModalContainer
        document={selectedDocumentToAddNewVersion}
        open={openAddNewVersionToDocumentModal}
        onDocumentAdded={handleDocumentAdded}
        onClose={() => {
          handleModalClose();
        }}
      ></AddNewVersionToDocumentModalContainer>
    </>
  );
};

export default DocumentsList;
