import LayoutWithSidebar from "../components/LayoutWithSidebar";
import ProjectSideMenu from "../components/ProjectSideMenu";
import MainHeaderContainer from "../containers/MainHeaderContainer";
import RequestPermitContainer from "../containers/RequestPermitContainer";
import { Grid, GridColumn } from "semantic-ui-react";

const RequestPermitPage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <GridColumn only="mobile">
            <ProjectSideMenu iconOnly={true}></ProjectSideMenu>
          </GridColumn>
          <GridColumn only="tablet computer">
            <ProjectSideMenu iconOnly={false}></ProjectSideMenu>
          </GridColumn>
        </Grid.Row>
      </Grid>
    );
  };

  const contentPanelContent = () => {
    return <RequestPermitContainer></RequestPermitContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default RequestPermitPage;
