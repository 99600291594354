import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import {
  ToggleArchivedStatusOrgUser,
  ToggleArchivedStatusOrgUserVariables,
} from "./__generated__/ToggleArchivedStatusOrgUser";

const TOGGLE_ARCHIVED_STATUS_ORG_USER = gql`
  ${ORG_USER_FIELDS}
  mutation ToggleArchivedStatusOrgUser($id: String!, $organizationId: String!) {
    toggleArchivedStatusOrgUser(input: { id: $id, organizationId: $organizationId }) {
      orgUser {
        ...OrgUserFields
      }
    }
  }
`;

export function useToggleArchivedStatusOrgUser() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ToggleArchivedStatusOrgUser,
    ToggleArchivedStatusOrgUserVariables
  >(TOGGLE_ARCHIVED_STATUS_ORG_USER, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
