import { gql } from "@apollo/client";
import { CORE_PROJECT_FIELDS } from "./project";

export const CORE_REFERENCE_PROCEDURE_FIELDS = gql`
  ${CORE_PROJECT_FIELDS}
  fragment CoreReferenceProcedureFields on ReferenceProcedure {
    id
    title
    dateCreatedUtc
    order
    fileUrl
    project {
      ...CoreProjectFields
    }
  }
`;
