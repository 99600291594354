import { toast } from "react-semantic-toasts";

const toastSuccess = (msg: string, title: string | undefined = "Success") => {
  toast({ type: "success", title: title, description: msg, time: 5000 });
};

const toastError = (msg: string, title: string | undefined = "Error") => {
  toast({ type: "error", title: title, description: msg, time: 5000 });
};

export { toastSuccess, toastError };
