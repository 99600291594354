import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { getErrors } from "../../utils/apolloError";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { ExtendPermitToDig, ExtendPermitToDigVariables } from "./__generated__/ExtendPermitToDig";
import { PERMIT_TO_DIG_ALL_FIELDS } from "../fragments/permitToDig";

const EXTEND_PERMIT = gql`
  ${PERMIT_TO_DIG_ALL_FIELDS}
  mutation ExtendPermitToDig($id: String!, $to: DateTime!, $comments: String) {
    extendPermitToDig(input: { id: $id, to: $to, comments: $comments }) {
      permitToDig {
        ...PermitToDigAllFields
      }
    }
  }
`;

export function useExtendPermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ExtendPermitToDig,
    ExtendPermitToDigVariables
  >(EXTEND_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
