import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { GetLicences, GetLicencesVariables } from "./__generated__/GetLicences";
import { CORE_LICENCE_FIELDS } from "../fragments/Licence";

export const GET_LICENCES = gql`
  ${CORE_LICENCE_FIELDS}
  query GetLicences($searchString: String, $skip: Int, $take: Int, $orgId: String, $isArchived: Boolean) {
    licences(searchString: $searchString, skip: $skip, take: $take, orgId: $orgId, isArchived: $isArchived) {
      totalCount
      items {
        ...CoreLicenceFields
      }
    }
  }
`;

export function useLazyGetLicences() {
  const [getLicences, { loading, error, data }] = useLazyQuery<GetLicences, GetLicencesVariables>(GET_LICENCES, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getLicences,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
