import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_PROJECT_FIELDS } from "../fragments/project";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";
import { DOCUMENT_LIST_FIELDS } from "../fragments/Document";
import {
  GetAllVersionsOfDocumentByParentId,
  GetAllVersionsOfDocumentByParentIdVariables,
} from "./__generated__/GetAllVersionsOfDocumentByParentId";

export const GET_All_VERSIONS_OF_DOCUMENT_BY_PARENT_ID = gql`
  ${CORE_PROJECT_FIELDS}
  ${ORG_USER_FIELDS}
  ${DOCUMENT_LIST_FIELDS}
  query GetAllVersionsOfDocumentByParentId($parentId: String!) {
    allVersionsOfDocumentByParentId(parentId: $parentId) {
      ...DocumentListFields
    }
  }
`;

export function useLazyGetAllVersionsOfDocumentByParentId() {
  const [getAllVersionsOfDocumentByParentId, { loading, error, data, fetchMore, refetch }] = useLazyQuery<
    GetAllVersionsOfDocumentByParentId,
    GetAllVersionsOfDocumentByParentIdVariables
  >(GET_All_VERSIONS_OF_DOCUMENT_BY_PARENT_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getAllVersionsOfDocumentByParentId,
    data,
    fetchMore,
    refetch,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
