import GaugeChart from "react-gauge-chart";
import { Header, Item, Segment } from "semantic-ui-react";

interface Props {
  approved: number;
  rejected: number;
}

const PermitRequestApprovalStats = ({ approved, rejected }: Props) => {
  const header = "Permit Requests Quality";
  const description = "Rejected requests vs Approved requests";

  const approvedPercentage = approved / (approved + rejected);
  const rejectedPercentage = rejected / (approved + rejected);

  const percent = 0.5 + (approvedPercentage - rejectedPercentage) / 2;

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center">{header}</Header>
      <GaugeChart
        id="inprogress-duration-gauge-chart"
        nrOfLevels={100}
        arcsLength={[0.5, 0.5]}
        colors={["#F5CD19", "#5BE12C"]}
        percent={percent}
        arcPadding={0.02}
        textColor="#555"
        fontSize="16"
        formatTextValue={(val) =>
          `${(rejectedPercentage * 100).toFixed(0)}% vs ${(approvedPercentage * 100).toFixed(0)}%`
        }
      />
      <Item>
        <Item.Content>
          <Item.Extra>{description}</Item.Extra>
        </Item.Content>
      </Item>
    </Segment>
  );
};

export default PermitRequestApprovalStats;
