import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_PROJECT_FIELDS } from "../fragments/project";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";
import { DOCUMENT_LIST_FIELDS } from "../fragments/Document";
import {
  GetLatestVersionOfDocumentsByProjectId,
  GetLatestVersionOfDocumentsByProjectIdVariables,
} from "./__generated__/GetLatestVersionOfDocumentsByProjectId";

export const GET_LATEST_VERSION_OF_DOCUMENTS_BY_PROJECT_ID = gql`
  ${CORE_PROJECT_FIELDS}
  ${ORG_USER_FIELDS}
  ${DOCUMENT_LIST_FIELDS}
  query GetLatestVersionOfDocumentsByProjectId(
    $projectId: String!
    $searchString: String
    $skip: Int
    $take: Int
    $typeId: String
  ) {
    latestVersionOfDocumentsByProjectId(
      projectId: $projectId
      searchString: $searchString
      skip: $skip
      take: $take
      typeId: $typeId
    ) {
      totalCount
      items {
        ...DocumentListFields
      }
    }
  }
`;

export function useLazyGetLatestVersionOfDocumentsByProjectId() {
  const [getLatestVersionOfDocumentsByProjectId, { loading, error, data, fetchMore, refetch }] = useLazyQuery<
    GetLatestVersionOfDocumentsByProjectId,
    GetLatestVersionOfDocumentsByProjectIdVariables
  >(GET_LATEST_VERSION_OF_DOCUMENTS_BY_PROJECT_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getLatestVersionOfDocumentsByProjectId,
    data,
    fetchMore,
    refetch,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
