import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { EsriIntegrationTypes } from "../models/EsriIntegrationType";
import { FormError } from "../models/FormError";
import { Organization } from "../models/Organization";
import AdminEditEsriConfigForm, { FormData } from "./AdminEditEsriConfigForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (integrationTypeId: string, webMapItemId: string, id: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  organization?: Organization;
}

const AdminEditEsriConfigModal = ({ open, onClose, onSubmit, submitInProgress, submitErrors, organization }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData({
        id: organization?.id,
        integrationTypeId: organization?.esriConfig?.integrationTypeId,
        webMapItemId: organization?.esriConfig?.webMapItemId,
      } as FormData);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, organization]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.integrationTypeId) {
      formError.integrationTypeId = "Please select integration type";
    }

    if (!formData.webMapItemId && formData.integrationTypeId !== EsriIntegrationTypes.NONE.id) {
      formError.webMapItemId = "Please enter organization's webmap portal item ID";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { id, integrationTypeId, webMapItemId } = formData;

      if (id) {
        onSubmit(integrationTypeId, webMapItemId, id);
      }
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Organization</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AdminEditEsriConfigForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
          ></AdminEditEsriConfigForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AdminEditEsriConfigModal;
