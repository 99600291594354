import moment from "moment";
import { Icon, Table } from "semantic-ui-react";
import { Organization } from "../models/Organization";
import GridActionButton from "./GridActionButton";

interface Props {
  onEditOrganization: (orgId: string) => void;
  onDeleteOrganization: (orgId: string) => void;
  organizations?: Organization[];
  onEsriConfigClicked: (orgId: string) => void;
}

const AdminOrganizationsGrid = ({
  onEditOrganization,
  onDeleteOrganization,
  organizations,
  onEsriConfigClicked,
}: Props) => {
  const renderOrganizationTableRow = () => {
    if (organizations) {
      return organizations.map((org) => (
        <Table.Row key={org.id}>
          <Table.Cell>
            {org.name}
            <GridActionButton
              rowId={org.id}
              items={[
                { key: "edit", icon: "edit", content: "Edit", callback: onEditOrganization },
                {
                  key: "delete",
                  icon: "delete",
                  content: "Delete",
                  callback: onDeleteOrganization,
                },
              ]}
            />
          </Table.Cell>
          <Table.Cell>
            {org.activeLicence &&
              `${moment(org.activeLicence.startDate).format("L")} - 
                ${moment(org.activeLicence.endDate).format("L")}, ${org.activeLicence.numberOfUsers} users`}
          </Table.Cell>
          <Table.Cell>
            <Icon
              style={{ cursor: "pointer" }}
              name="setting"
              title="Settings"
              onClick={() => {
                onEsriConfigClicked(org.id);
              }}
            ></Icon>
          </Table.Cell>
        </Table.Row>
      ));
    }
  };

  return (
    <>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Licence</Table.HeaderCell>
            <Table.HeaderCell>Esri Settings</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderOrganizationTableRow()}</Table.Body>

        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default AdminOrganizationsGrid;
