import { gql, useQuery } from "@apollo/client";
import { GetGeneralPolicies } from "./__generated__/GetGeneralPolicies";
import { getErrors } from "../../utils";

export const GET_GENERAL_POLICIES = gql`
  query GetGeneralPolicies {
    generalPolicies
  }
`;

export function useGetGeneralPolicies() {
  const { loading, error, data } = useQuery<GetGeneralPolicies>(GET_GENERAL_POLICIES, {
    errorPolicy: "all",
  });

  return {
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    },
  };
}
