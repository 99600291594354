import { useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import { Image, SemanticFLOATS, SemanticSIZES } from "semantic-ui-react";

interface Props {
  src: string;
  size?: SemanticSIZES;
  floated?: SemanticFLOATS;
  rounded?: boolean;
  onClick?: () => void;
  centered?: boolean;
  style?: any;
}

function ServerImage({ src, size, floated, rounded, onClick, centered, style }: Props) {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (src && token) {
      fetch(src, {
        method: "GET",
        headers: { authorization: token ? `Bearer ${token}` : "" },
      })
        .then((response) => response.blob())
        .then((imageBlob) => {
          // Then create a local URL for that image and print it
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setImage(imageObjectURL);
        });
    }
  }, [src, token]);

  useEffect(() => {
    async function getToken() {
      const val = await authService.getAccessToken();

      if (val) {
        setToken(val);
      }
    }

    getToken();
  }, []);

  return (
    <Image
      src={image}
      size={size}
      floated={floated}
      rounded={rounded}
      onClick={onClick}
      centered={centered}
      style={style}
    ></Image>
  );
}

export default ServerImage;
