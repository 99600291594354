import { useGetGeneralPolicies } from "../operations/queries/getGeneralPolicies";
import { ADMIN_SYSTEM } from "../models/Policies";

export function useGeneralPolicies() {
  const { data: generalPoliciesData } = useGetGeneralPolicies();

  const authorize = (policy: string): boolean => {
    return generalPoliciesData?.generalPolicies ? generalPoliciesData.generalPolicies.includes(policy) : false;
  };

  const authorizedSystemAdmin = authorize(ADMIN_SYSTEM.name);

  return {
    generalPolicies: generalPoliciesData?.generalPolicies || [],
    authorize,
    authorizedSystemAdmin,
  };
}
