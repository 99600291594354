import { gql } from "@apollo/client";
import { PERMISSION_FIELDS } from "./Permission";

export const ORG_ROLE_FIELDS = gql`
  ${PERMISSION_FIELDS}
  fragment OrgRoleFields on OrgRole {
    id
    roleId
    displayName
    organization {
      id
    }
    role {
      permissions {
        ...PermissionFields
      }
    }
    permitRoleSettings {
      isPermitAssignee
      mandatoryOnSubmission
      shouldAuthorizePermit
      authorizationDeclaration
    }
  }
`;
