import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { CORE_GANG_MEMBER_FIELDS } from "../fragments/GangMember";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import {
  ToggleArchivedStatusGangMember,
  ToggleArchivedStatusGangMemberVariables,
} from "./__generated__/ToggleArchivedStatusGangMember";

const TOGGLE_ARCHIVED_STATUS_GANG_MEMBER = gql`
  ${CORE_GANG_MEMBER_FIELDS}
  mutation ToggleArchivedStatusGangMember($id: String!) {
    toggleArchivedStatusGangMember(input: { id: $id }) {
      gangMember {
        ...CoreGangMemberFields
      }
    }
  }
`;

export function useToggleArchivedStatusGangMember() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ToggleArchivedStatusGangMember,
    ToggleArchivedStatusGangMemberVariables
  >(TOGGLE_ARCHIVED_STATUS_GANG_MEMBER, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
