import { Table } from "semantic-ui-react";
import { AdminUser } from "../models/AdminUser";
import GridActionButton from "./GridActionButton";

interface Props {
  adminUsers?: AdminUser[];
  onEditAdminUser: (adminUserId: string) => void;
  onDeleteAdminUser: (adminUserId: string) => void;
  onResetPasswordAdminUser: (adminUserId: string) => void;
}

const AdminUsersGrid = ({ onEditAdminUser, onDeleteAdminUser, onResetPasswordAdminUser, adminUsers }: Props) => {
  const renderAdminUserTableRow = () => {
    if (adminUsers) {
      return adminUsers.map((adminUser) => (
        <Table.Row key={adminUser.id}>
          <Table.Cell>{adminUser.firstName}</Table.Cell>
          <Table.Cell>{adminUser.lastName}</Table.Cell>
          <Table.Cell>
            {adminUser.email}
            <GridActionButton
              rowId={adminUser.id}
              items={[
                { key: "edit", icon: "edit", content: "Edit", callback: onEditAdminUser },
                {
                  key: "resetPassword",
                  icon: "exchange",
                  content: "Reset Password",
                  callback: () => onResetPasswordAdminUser(adminUser.id),
                },
                // Commented out as it can cause issues if the super admin is also an organization user
                // { key: "delete", icon: "delete", content: "Delete", callback: onDeleteAdminUser },
              ]}
            />
          </Table.Cell>
        </Table.Row>
      ));
    }
  };

  return (
    <>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Email Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderAdminUserTableRow()}</Table.Body>

        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default AdminUsersGrid;
