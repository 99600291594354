import { gql, useSubscription } from "@apollo/client";
import { getErrors } from "../../utils/apolloError";
import {
  onProjectPermitToDigDetailsChanged,
  onProjectPermitToDigDetailsChangedVariables,
} from "./__generated__/onProjectPermitToDigDetailsChanged";
import { PERMIT_TO_DIG_ALL_FIELDS } from "../fragments/permitToDig";

const ON_PROJECT_PERMIT_TO_DIG_DETAILS_CHANGED = gql`
  ${PERMIT_TO_DIG_ALL_FIELDS}
  subscription onProjectPermitToDigDetailsChanged($projectId: String) {
    projectPermitToDigDetailsChanged(projectId: $projectId) {
      permitToDig {
        ...PermitToDigAllFields
      }
    }
  }
`;

export const useOnProjectPermitToDigDetailsChanged = (projectId: string | undefined) => {
  const { loading, data, error } = useSubscription<
    onProjectPermitToDigDetailsChanged,
    onProjectPermitToDigDetailsChangedVariables
  >(ON_PROJECT_PERMIT_TO_DIG_DETAILS_CHANGED, {
    variables: { projectId: projectId },
  });

  return {
    loading,
    data,
    errors: getErrors(error),
  };
};
