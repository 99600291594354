import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { UpdatePermitToDig, UpdatePermitToDigVariables } from "./__generated__/UpdatePermitToDig";

const UPDATE_PERMIT = gql`
  mutation UpdatePermitToDig(
    $id: String!
    $permitDataInput: PermitDataInput
    $documentIds: [String!]
    $asBuiltRequired: Boolean!
    $boundaries: GeoJSONPolygonInput
    $permitAssignmentInputs: [PermitAssignmentInput!]
  ) {
    updatePermitToDig(
      input: {
        id: $id
        permitDataInput: $permitDataInput
        documentIds: $documentIds
        asBuiltRequired: $asBuiltRequired
        boundaries: $boundaries
        permitAssignmentInputs: $permitAssignmentInputs
      }
    ) {
      permitToDig {
        id
      }
    }
  }
`;

export function useUpdatePermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdatePermitToDig,
    UpdatePermitToDigVariables
  >(UPDATE_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
