import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AdminSettingsPage from "./pages/AdminSettingsPage";
import AdminOrganizationPage from "./pages/AdminOrganizationPage";
import AdminLicencePage from "./pages/AdminLicencePage";
import AdminUserPage from "./pages/AdminUserPage";
import ProjectPage from "./pages/ProjectPage";
import ReferenceProcedurePage from "./pages/ReferenceProcedurePage";
import OrgRolePage from "./pages/OrgRolePage";
import DocumentTypePage from "./pages/DocumentTypePage";
import ConfigurationPage from "./pages/ConfigurationPage";
import OrgUserPage from "./pages/OrgUserPage";
import ProjectDashboardPage from "./pages/ProjectDashboardPage";
import DocumentPage from "./pages/DocumentPage";
import PermitPage from "./pages/PermitPage";
import AddPermitPage from "./pages/AddPermitPage";
import RequestPermitPage from "./pages/RequestPermitPage";
import GangMemberPage from "./pages/GangMemberPage";
import { SemanticToastContainer } from "react-semantic-toasts";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { Worker } from "@react-pdf-viewer/core";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import "semantic-ui-less/semantic.less";
import { AppContextProvider } from "./contextProviders/AppContextProvider";

function App() {
  const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || "/";

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
        <BrowserRouter basename={baseUrl}>
          <AppContextProvider>
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <Switch>
              <AuthorizeRoute path="/" component={HomePage} exact />
              <AuthorizeRoute path="/settings" component={AdminSettingsPage} exact />
              <AuthorizeRoute path="/settings/organizations" component={AdminOrganizationPage} exact />
              <AuthorizeRoute path="/settings/licences" component={AdminLicencePage} exact />
              <AuthorizeRoute path="/settings/admin-users" component={AdminUserPage} exact />
              <AuthorizeRoute path="/:orgName/home" component={HomePage} exact />
              <AuthorizeRoute path="/:orgName/dashboard" component={HomePage} exact />
              <AuthorizeRoute path="/:orgName/gang-members" component={GangMemberPage} exact />
              <AuthorizeRoute path="/:orgName/reference-procedures" component={ReferenceProcedurePage} exact />
              <AuthorizeRoute path="/:orgName/projects" component={ProjectPage} exact />
              <AuthorizeRoute path="/:orgName/settings/roles" component={OrgRolePage} exact />
              <AuthorizeRoute path="/:orgName/settings/document-types" component={DocumentTypePage} exact />
              <AuthorizeRoute path="/:orgName/settings/configurations" component={ConfigurationPage} exact />
              <AuthorizeRoute path="/:orgName/users" component={OrgUserPage} exact />
              <AuthorizeRoute path="/:orgName/projects/:projectName/dashboard" component={ProjectDashboardPage} exact />
              <AuthorizeRoute
                path="/:orgName/projects/:projectName/permits/add-permit/:permitId"
                component={AddPermitPage}
                exact
              />
              <AuthorizeRoute
                path="/:orgName/projects/:projectName/permits/add-permit"
                component={AddPermitPage}
                exact
              />
              <AuthorizeRoute
                path="/:orgName/projects/:projectName/permits/request-permit/:permitId"
                component={RequestPermitPage}
                exact
              />
              <AuthorizeRoute
                path="/:orgName/projects/:projectName/permits/request-permit"
                component={RequestPermitPage}
                exact
              />
              <AuthorizeRoute path="/:orgName/projects/:projectName/permits" component={PermitPage} exact />
              <AuthorizeRoute path="/:orgName/projects/:projectName/settings" component={ProjectDashboardPage} exact />
              <AuthorizeRoute path="/:orgName/projects/:projectName/documents" component={DocumentPage} exact />
              <AuthorizeRoute path="/:orgName/projects/:projectName" component={ProjectDashboardPage} exact />
              <AuthorizeRoute path="/:orgName" component={HomePage} exact />
            </Switch>
          </AppContextProvider>
        </BrowserRouter>
      </Worker>
      <SemanticToastContainer position="top-center" animation="slide up" />
    </>
  );
}

export default App;
