import LayoutWithSidebar from "../components/LayoutWithSidebar";
import MainHeaderContainer from "../containers/MainHeaderContainer";
import MainSideMenu from "../components/MainSideMenu";
import MyProjectsListContainer from "../containers/MyProjectsListContainer";

const HomePage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <MainSideMenu></MainSideMenu>;
  };

  const contentPanelContent = () => {
    return <MyProjectsListContainer></MyProjectsListContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default HomePage;
