import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ResetPasswordForm, { FormData } from "./ResetPasswordForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (newPassword: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
}

const ResetPasswordModal = ({ open, onClose, onSubmit, submitInProgress, submitErrors }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    setFormData({} as FormData);
    setFormErrors({} as FormError);
    setHasSubmitted(false);
  }, [open]);

  const validateFormData = (formData: FormData): FormError => {
    const formError: FormError = {};

    if (!formData.newPassword) {
      formError.newPassword = "Please enter new password";
    }

    if (!formData.repeatNewPassword) {
      formError.repeatNewPassword = "Please enter new password again";
    } else if (formData.newPassword !== formData.repeatNewPassword) {
      formError.repeatNewPassword = "Passwords don't match";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      onSubmit(formData.newPassword);
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Reset password</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ResetPasswordForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
          ></ResetPasswordForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ResetPasswordModal;
