import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";
import {
  GetOrgUsersByOrganizationId,
  GetOrgUsersByOrganizationIdVariables,
} from "./__generated__/GetOrgUsersByOrganizationId";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";

export const GET_ORG_USERS_BY_ORGANIZATION_ID = gql`
  ${ORG_USER_FIELDS}
  query GetOrgUsersByOrganizationId($organizationId: String!) {
    orgUsersByOrganizationId(organizationId: $organizationId) {
      ...OrgUserFields
    }
  }
`;

export function useLazyGetOrgUsersByOrganizationId() {
  const [getOrgUsersByOrganizationId, { loading, error, data }] = useLazyQuery<
    GetOrgUsersByOrganizationId,
    GetOrgUsersByOrganizationIdVariables
  >(GET_ORG_USERS_BY_ORGANIZATION_ID, {
    errorPolicy: "all",
  });

  return {
    getOrgUsersByOrganizationId,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
