import { Table } from "semantic-ui-react";
import { DocumentType } from "../models/DocumentType";
import GridActionButton from "./GridActionButton";

interface Props {
  onEditDocumentType: (documentTypeId: string) => void;
  onDeleteDocumentType: (documentTypeId: string) => void;
  onToggleArchivedStatusDocumentType: (documentTypeId: string) => void;
  documentTypes?: DocumentType[];
}

const DocumentTypesGrid = ({
  onEditDocumentType,
  onDeleteDocumentType,
  onToggleArchivedStatusDocumentType,
  documentTypes,
}: Props) => {
  const renderDocumentTypeTableRow = () => {
    if (documentTypes) {
      return documentTypes.map((documentType) => (
        <Table.Row key={documentType.id}>
          <Table.Cell>
            {documentType.name}
            <GridActionButton
              rowId={documentType.id}
              items={[
                { key: "edit", icon: "edit", content: "Edit", callback: onEditDocumentType },
                { key: "delete", icon: "delete", content: "Delete", callback: onDeleteDocumentType },
                {
                  key: documentType.isArchived ? "Activate" : "Archive",
                  icon: documentType.isArchived ? "undo" : "archive",
                  content: documentType.isArchived ? "Activate" : "Archive",
                  callback: onToggleArchivedStatusDocumentType,
                },
              ]}
            />
          </Table.Cell>
          <Table.Cell>{documentType.isArchived ? "Archived" : "Active"}</Table.Cell>
        </Table.Row>
      ));
    }
  };

  return (
    <>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderDocumentTypeTableRow()}</Table.Body>

        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default DocumentTypesGrid;
