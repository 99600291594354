import { Segment, Menu, Grid, Dropdown } from "semantic-ui-react";
import DocumentTypesGrid from "./DocumentTypesGrid";
import { DocumentType } from "../models/DocumentType";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../utils";
import EditDocumentTypeModal from "./EditDocumentTypeModal";

interface Props {
  isDocumentTypesLoading: boolean;
  documentTypes?: DocumentType[];
  onAddDocumentType: (name: string, declaration: string, isMandatory: boolean) => void;
  addDocumentTypeStatus: AsyncActionStatus;
  onEditDocumentType: (id: string, name: string, declaration: string, isMandatory: boolean) => void;
  editDocumentTypeStatus: AsyncActionStatus;
  onDeleteDocumentType: (documentTypeId: string) => void;
  deleteDocumentTypeStatus: AsyncActionStatus;
  onToggleArchivedStatusDocumentType: (documentTypeId: string) => void;
  toggleArchivedStatusDocumentTypeStatus: AsyncActionStatus;
  isArchivedFilterValue?: boolean | null;
  onIsArchivedFilterValueChange: (isArchived: boolean) => void;
}

const DocumentTypesList = ({
  isDocumentTypesLoading,
  documentTypes,
  onAddDocumentType,
  addDocumentTypeStatus,
  onEditDocumentType,
  editDocumentTypeStatus,
  onDeleteDocumentType,
  deleteDocumentTypeStatus,
  onToggleArchivedStatusDocumentType,
  toggleArchivedStatusDocumentTypeStatus,
  isArchivedFilterValue,
  onIsArchivedFilterValueChange,
}: Props) => {
  const [openEditDocumentTypeModal, setOpenEditDocumentTypeModal] = useState(false);
  const [selectedDocumentTypeToEdit, setSelectedDocumentTypeToEdit] = useState<DocumentType | undefined>(undefined);

  const handleEditDocumentType = (documentTypeId: string) => {
    const documentType = documentTypes?.find((o) => o.id === documentTypeId);
    if (documentType) {
      setSelectedDocumentTypeToEdit({ ...documentType } as DocumentType);
      setOpenEditDocumentTypeModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenEditDocumentTypeModal(false);
    setSelectedDocumentTypeToEdit(undefined);

    addDocumentTypeStatus.reset();
    editDocumentTypeStatus.reset();
    deleteDocumentTypeStatus.reset();
    toggleArchivedStatusDocumentTypeStatus.reset();
  };

  const handleEditDocumentTypeModalSubmit = (name: string, declaration: string, isMandatory: boolean, id?: string) => {
    if (id) {
      onEditDocumentType(id, name, declaration, isMandatory);
    } else {
      onAddDocumentType(name, declaration, isMandatory);
    }
  };

  useEffect(() => {
    if (addDocumentTypeStatus.successful) {
      toastSuccess("New document type added successfully!");
      setOpenEditDocumentTypeModal(false);
      setSelectedDocumentTypeToEdit(undefined);
    }
  }, [addDocumentTypeStatus.successful]);

  useEffect(() => {
    if (editDocumentTypeStatus.successful) {
      toastSuccess("Document type updated successfully!");
      setOpenEditDocumentTypeModal(false);
      setSelectedDocumentTypeToEdit(undefined);
    }
  }, [editDocumentTypeStatus.successful]);

  useEffect(() => {
    if (deleteDocumentTypeStatus.successful) {
      toastSuccess("Document type deleted successfully!");
      handleModalClose();
    } else if (deleteDocumentTypeStatus.errors && deleteDocumentTypeStatus.errors.length > 0) {
      toastError(deleteDocumentTypeStatus.errors.toString());
      handleModalClose();
    }
  }, [deleteDocumentTypeStatus]);

  useEffect(() => {
    if (toggleArchivedStatusDocumentTypeStatus.successful) {
      toastSuccess("Documnet type archive status changed successfully!");
      handleModalClose();
    } else if (toggleArchivedStatusDocumentTypeStatus.errors) {
      toastError(toggleArchivedStatusDocumentTypeStatus.errors.toString());
      handleModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleArchivedStatusDocumentTypeStatus]);

  const handleIsArchivedFilterValueChange = (e: any, { name, value }: any) => {
    onIsArchivedFilterValueChange(value === "" ? null : value);
  };

  const isSystemDocumentType = (docType: string) => {
    return docType === "Drawing" || docType === "RAMS" || docType === "ArcGisDrawing";
  };

  return (
    <>
      <Menu secondary>
        <Menu.Item icon="plus" name="New type" onClick={(e) => setOpenEditDocumentTypeModal(true)} />
        <Menu.Menu position="right">
          <Menu.Item>
            <Dropdown
              placeholder="View"
              basic
              clearable
              onChange={handleIsArchivedFilterValueChange}
              value={isArchivedFilterValue === null ? undefined : isArchivedFilterValue}
              options={[
                { value: false, key: "not archived", text: "active" },
                { value: true, key: "archived", text: "archived" },
              ]}
            ></Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment basic loading={isDocumentTypesLoading}>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column width="16">
              <DocumentTypesGrid
                documentTypes={documentTypes}
                onEditDocumentType={handleEditDocumentType}
                onDeleteDocumentType={onDeleteDocumentType}
                onToggleArchivedStatusDocumentType={onToggleArchivedStatusDocumentType}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <EditDocumentTypeModal
        open={openEditDocumentTypeModal}
        onClose={handleModalClose}
        onSubmit={handleEditDocumentTypeModalSubmit}
        submitInProgress={addDocumentTypeStatus.loading || editDocumentTypeStatus.loading}
        documentType={selectedDocumentTypeToEdit}
        showDocumentTypeKey={isSystemDocumentType(selectedDocumentTypeToEdit?.key ?? "")}
        submitErrors={
          addDocumentTypeStatus.errors
            ? addDocumentTypeStatus.errors
            : editDocumentTypeStatus.errors
            ? editDocumentTypeStatus.errors
            : undefined
        }
      ></EditDocumentTypeModal>
    </>
  );
};

export default DocumentTypesList;
