import LayoutWithSidebar from "../components/LayoutWithSidebar";
import AdminSettingsSideMenu from "../components/AdminSettingsSideMenu";
import AdminLicencesListContainer from "../containers/AdminLicencesListContainer";
import MainHeaderContainer from "../containers/MainHeaderContainer";

const AdminLicencePage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <AdminSettingsSideMenu></AdminSettingsSideMenu>;
  };

  const contentPanelContent = () => {
    return <AdminLicencesListContainer></AdminLicencesListContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default AdminLicencePage;
