import { useCallback } from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import { useDropzone, FileRejection } from "react-dropzone";
import { UserFile } from "./../../models/UserFile";
import { v4 as uuid } from "uuid";

interface Props {
  multiple?: boolean;
  maxSize?: number;
  accept?: string[];
  onFilesSelected: (userFiles: UserFile[]) => void;
}

const FileDropZone = ({ onFilesSelected, multiple, maxSize, accept }: Props) => {
  const handleFileDrop = (acceptedfiles: File[], fileRejections: FileRejection[]) => {
    const userFiles: UserFile[] = [];

    acceptedfiles.forEach((file) => {
      userFiles.push({ id: uuid(), file } as UserFile);
    });

    fileRejections.forEach((rejection) => {
      userFiles.push({
        id: uuid(),
        file: rejection.file,
        uploadStatus: { fileErrors: rejection.errors.map((err) => err.message) },
      } as UserFile);
    });

    if (userFiles.length) {
      onFilesSelected(userFiles);
    }
  };

  const onDrop = useCallback(handleFileDrop, [onFilesSelected]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    maxSize,
    accept: accept?.join(","),
  });

  return (
    <div {...getRootProps()}>
      <Segment placeholder textAlign="center">
        <Header as="h4" icon>
          <Icon name="cloud upload" />
        </Header>
        <Segment.Inline>
          <input {...getInputProps()} />
          <i>Drag 'n' drop {multiple ? "some files" : "a file"} here, or click to browse files</i>
          {maxSize && (
            <Header.Subheader>
              <i>Maximum file size allowed: {maxSize / 1024} KB</i>
            </Header.Subheader>
          )}
          {accept && (
            <Header.Subheader>
              <i>Only {accept.join(", ")} file will be accepted</i>
            </Header.Subheader>
          )}
        </Segment.Inline>
      </Segment>
    </div>
  );
};

export default FileDropZone;
