import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { DocumentType } from "../models/DocumentType";
import { FormError } from "../models/FormError";
import { UserFile } from "../models/UserFile";
import { FileMetaData } from "../models/FileMetaData";
import AddDocumentForm, { FormData } from "./AddDocumentForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (title: string, typeId: string, versionNo: number, fileMetaData: FileMetaData) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  documentTypes?: DocumentType[];
  docType?: DocumentType;
  userFile?: UserFile;
  onUploadFile: (userFile: UserFile) => void;
  uploading: boolean;
}

const AddDocumentModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  submitErrors,
  documentTypes,
  docType,
  userFile,
  onUploadFile,
  uploading,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    setFormData({ ...{}, versionNo: "1.0", typeId: docType ? docType.id : formData.typeId } as FormData);
    setFormErrors({} as FormError);
    setHasSubmitted(false);
  }, [open]);

  useEffect(() => {
    setFormData({ ...formData, userFile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFile]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.title) {
      formError.title = "Please enter title";
    }

    if (!formData.typeId) {
      formError.typeId = "Please select type";
    }

    if (!formData.versionNo) {
      formError.versionNo = "Please enter version";
    }

    if (!formData.userFile || !formData.userFile.uploadStatus.uploaded || formData.userFile.uploadStatus.uploadError) {
      formError.userFile = "Please select a file";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { title, typeId, versionNo, userFile } = formData;
      if (userFile) {
        const fileMetaData = {
          id: userFile.id,
          fileName: userFile.file.name,
          contentType: userFile.file.type,
        } as FileMetaData;
        onSubmit(title, typeId, parseFloat(versionNo), fileMetaData);
      }
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Add {docType ? docType.name : "new"} document</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AddDocumentForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
            documentTypes={documentTypes}
            docType={docType}
            onUploadFile={onUploadFile}
          ></AddDocumentForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          positive
          loading={submitInProgress || uploading}
          disabled={submitInProgress || uploading}
        >
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddDocumentModal;
