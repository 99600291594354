import MyProjectsList from "../components/MyProjectsList";
import { useLazyGetMyProjectsByOrganizationId } from "../operations/queries/getMyProjectsByOrganizationId";
import { useEffect } from "react";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";

const MyProjectsListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();

  const {
    getMyProjectsByOrganizationId: getMyProjects,
    data: myProjects,
    status: getMyProjectsStatus,
  } = useLazyGetMyProjectsByOrganizationId();

  useEffect(() => {
    if (currentOrganization) {
      getMyProjects({ variables: { organizationId: currentOrganization.id } });
    }
  }, [getMyProjects, currentOrganization]);

  return (
    <MyProjectsList
      isMyProjectsLoading={getMyProjectsStatus.loading}
      myProjects={myProjects && myProjects.myProjectsByOrganizationId}
    ></MyProjectsList>
  );
};

export default MyProjectsListContainer;
