import LayoutWithSidebar from "../components/LayoutWithSidebar";
import ProjectSideMenu from "../components/ProjectSideMenu";
import MainHeaderContainer from "../containers/MainHeaderContainer";
import ProjectPermitsCountsContainer from "../containers/ProjectPermitsCountsContainer";
import ProjectPermitIssueTrendContainer from "../containers/ProjectPermitIssueTrendContainer";
import PermitInProgressAvgDurationContainer from "../containers/PermitInProgressAvgDurationContainer";
import PermitExpiredAvgDurationContainer from "../containers/PermitExpiredAvgDurationContainer";
import PermitRequestApprovalStatsContainer from "../containers/PermitRequestApprovalStatsContainer";
import { Card, Grid, GridColumn, Segment } from "semantic-ui-react";

const ProjectDashboardPage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <GridColumn only="mobile">
            <ProjectSideMenu iconOnly={true}></ProjectSideMenu>
          </GridColumn>
          <GridColumn only="tablet computer">
            <ProjectSideMenu iconOnly={false}></ProjectSideMenu>
          </GridColumn>
        </Grid.Row>
      </Grid>
    );
  };

  const contentPanelContent = () => {
    return (
      <>
        <ProjectPermitsCountsContainer />
        <ProjectPermitIssueTrendContainer />
        <Segment basic>
          <Card.Group stackable doubling itemsPerRow={3}>
            <Card>
              <PermitRequestApprovalStatsContainer />
            </Card>
            <Card>
              <PermitInProgressAvgDurationContainer />
            </Card>
            <Card>
              <PermitExpiredAvgDurationContainer />
            </Card>
          </Card.Group>
        </Segment>
      </>
    );
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default ProjectDashboardPage;
