import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import {
  SetOrganizationConfiguration,
  SetOrganizationConfigurationVariables,
} from "./__generated__/SetOrganizationConfiguration";

const SET_ORGANIZATION_CONFIGURATION = gql`
  mutation SetOrganizationConfiguration(
    $configKey: String!
    $configValue: String!
    $contentType: String!
    $organizationId: String!
  ) {
    setOrganizationConfiguration(
      input: {
        configKey: $configKey
        configValue: $configValue
        contentType: $contentType
        organizationId: $organizationId
      }
    ) {
      succeeded
    }
  }
`;

export function useSetOrganizationConfiguration() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    SetOrganizationConfiguration,
    SetOrganizationConfigurationVariables
  >(SET_ORGANIZATION_CONFIGURATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
