import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import {
  UpdateOrganizationEsriConfig,
  UpdateOrganizationEsriConfigVariables,
} from "./__generated__/UpdateOrganizationEsriConfig";

const UPDATE_ORGANIZATION_ESRI_CONFIG = gql`
  mutation UpdateOrganizationEsriConfig($id: String!, $esriConfig: EsriConfigInput) {
    updateOrganizationEsriConfig(input: { id: $id, esriConfig: $esriConfig }) {
      organization {
        id
        name
      }
    }
  }
`;

export function useUpdateOrganizationEsriConfig() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateOrganizationEsriConfig,
    UpdateOrganizationEsriConfigVariables
  >(UPDATE_ORGANIZATION_ESRI_CONFIG, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
