import { useUploadFile } from "../operations/mutations/uploadFile";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect, useState } from "react";
import { UserFile } from "../models/UserFile";
import { FileMetaData } from "../models/FileMetaData";
import AddOrgUserFileModal from "../components/AddOrgUserFileModal";
import { OrgUserFileInput } from "../models/globalTypes";

interface Props {
  open: boolean;
  onOrgUserFileAdded: (orgUserFileInput: OrgUserFileInput) => void;
  onClose: () => void;
}

const AddOrgUserFileModalContainer = ({ open, onOrgUserFileAdded, onClose }: Props) => {
  const { currentOrganization } = useCurrentOrganization();
  const [userFile, setUserFile] = useState<UserFile>();

  const {
    mutate: uploadFileMutate,
    data: uploadFile,
    error: uploadFileError,
    loading: uploadFileLoading,
  } = useUploadFile();

  const handleUploadFile = (userFile: UserFile) => {
    setUserFile(userFile);
    uploadFileMutate({
      variables: {
        file: userFile.file,
        fileId: userFile.id,
      },
    });
  };

  useEffect(() => {
    if (userFile) {
      if (uploadFile?.uploadFile?.fileId) {
        setUserFile({ ...userFile, uploadStatus: { ...userFile.uploadStatus, uploaded: true, uploading: false } });
      } else if (uploadFileError && uploadFileError.graphQLErrors) {
        setUserFile({
          ...userFile,
          uploadStatus: { ...userFile.uploadStatus, uploading: false, uploadError: uploadFileError.graphQLErrors[0] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile, uploadFileError]);

  const handleAddOrgUserFile = (title: string, expiryDate: Date, fileMetaData: FileMetaData) => {
    if (currentOrganization) {
      onOrgUserFileAdded({ title, expiryDate, fileMetaData } as OrgUserFileInput);
    }
  };

  return (
    <AddOrgUserFileModal
      open={open}
      onClose={onClose}
      onSubmit={handleAddOrgUserFile}
      userFile={userFile}
      onUploadFile={handleUploadFile}
      uploading={uploadFileLoading}
    ></AddOrgUserFileModal>
  );
};

export default AddOrgUserFileModalContainer;
