import { useLazyGetLatestVersionOfDocumentsByProjectId } from "../operations/queries/getLatestVersionOfDocumentsByProjectId";
import { useLazyGetDocumentTypesByOrganizationId } from "../operations/queries/getDocumentTypesByOrganizationId";
import DocumentsList from "../components/DocumentsList";
import { useDeleteDocument } from "../operations/mutations/deleteDocument";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { useProjectPolicies } from "../hooks/useProjectPolicies";

const DocumentsListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const { currentProject } = useCurrentProject();
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 0, pageIndex: 0 });
  const [searchString, setSearchString] = useState("");
  const [typeFilter, setTypeFilter] = useState<string>();
  const { authorizedToAddDocument, authorizedToAddNewDocumentVersion, authorizedToDeleteDocument } =
    useProjectPolicies();

  const {
    getLatestVersionOfDocumentsByProjectId: getDocuments,
    data: documents,
    status: getDocumentsStatus,
    refetch: refetchDocuments,
  } = useLazyGetLatestVersionOfDocumentsByProjectId();
  const { getDocumentTypesByOrganizationId: getDocumentTypes, data: documentTypes } =
    useLazyGetDocumentTypesByOrganizationId();
  const { mutate: deleteDocumentMutate, status: deleteDocumentStatus } = useDeleteDocument();

  useEffect(() => {
    if (currentOrganization) {
      getDocumentTypes({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getDocumentTypes]);

  const handlePageInfoChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      if (pageSize !== paginationInfo.pageSize || pageIndex !== paginationInfo.pageIndex) {
        setPaginationInfo({ pageSize, pageIndex });
      }
    },
    [paginationInfo.pageIndex, paginationInfo.pageSize]
  );

  const handleSearchDocuments = debounce((searchString: string) => {
    if (searchString !== undefined) {
      setSearchString(searchString);
    }
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  }, 500);

  const handleTypeFilter = (typeId: string) => {
    if (typeId !== undefined) setTypeFilter(typeId);
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  };

  useEffect(() => {
    if (currentProject && paginationInfo.pageSize > 0)
      getDocuments({
        variables: {
          projectId: currentProject.id,
          skip: paginationInfo.pageIndex * paginationInfo.pageSize,
          take: paginationInfo.pageSize,
          searchString,
          typeId: typeFilter,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.pageSize, paginationInfo.pageIndex, currentProject, searchString, typeFilter]);

  const handleDeleteDocument = (id: string) => {
    if (currentProject) {
      deleteDocumentMutate({
        variables: {
          id,
        },
      }).then(() => {
        refetchDocuments && refetchDocuments();
      });
    }
  };

  const handleDocumentAdded = () => {
    refetchDocuments && refetchDocuments();
  };

  return (
    <DocumentsList
      newDocumentButtonVisible={authorizedToAddDocument}
      addNewVersionButtonVisible={authorizedToAddNewDocumentVersion}
      deleteDocumentButtonVisible={authorizedToDeleteDocument}
      documents={documents && documents.latestVersionOfDocumentsByProjectId?.items}
      pageCount={
        documents &&
        Math.ceil((documents?.latestVersionOfDocumentsByProjectId?.totalCount ?? 0) / paginationInfo.pageSize)
      }
      documentTypes={documentTypes && documentTypes.documentTypesByOrganizationId.filter((t) => !t.isArchived)}
      onPageInfoChange={handlePageInfoChange}
      onSearchDocuments={handleSearchDocuments}
      onTypeFilter={handleTypeFilter}
      getDocumentsStatus={getDocumentsStatus}
      onDeleteDocument={handleDeleteDocument}
      deleteDocumentStatus={deleteDocumentStatus}
      onDocumentAdded={handleDocumentAdded}
    ></DocumentsList>
  );
};

export default DocumentsListContainer;
