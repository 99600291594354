import { useLazyGetLatestVersionOfDocumentsByProjectId } from "../operations/queries/getLatestVersionOfDocumentsByProjectId";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import SelectDocumentModal from "../components/SelectDocumentModal";
import { Document_ListItem } from "../models/Document_ListItem";
import { DocumentType } from "../models/DocumentType";

interface Props {
  open: boolean;
  onSelectDocument: (doc: Document_ListItem) => void;
  docType?: DocumentType;
  onClose: () => void;
}

const SelectDocumentModalContainer = ({ open, onSelectDocument, docType, onClose }: Props) => {
  const { currentProject } = useCurrentProject();
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 0, pageIndex: 0 });
  const [searchString, setSearchString] = useState("");

  const {
    getLatestVersionOfDocumentsByProjectId: getDocuments,
    data: documents,
    status: getDocumentsStatus,
  } = useLazyGetLatestVersionOfDocumentsByProjectId();

  const handleSelectDocument = (doc: Document_ListItem) => {
    onSelectDocument(doc);
  };

  const handlePageInfoChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      if (pageSize !== paginationInfo.pageSize || pageIndex !== paginationInfo.pageIndex) {
        setPaginationInfo({ pageSize, pageIndex });
      }
    },
    [paginationInfo.pageIndex, paginationInfo.pageSize]
  );

  const handleSearchDocuments = debounce((searchString: string) => {
    if (searchString !== undefined) {
      setSearchString(searchString);
    }
    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });
  }, 500);

  useEffect(() => {
    if (currentProject && paginationInfo.pageSize > 0)
      getDocuments({
        variables: {
          projectId: currentProject.id,
          skip: paginationInfo.pageIndex * paginationInfo.pageSize,
          take: paginationInfo.pageSize,
          searchString,
        },
      });
  }, [paginationInfo.pageSize, paginationInfo.pageIndex, currentProject, searchString, getDocuments]);

  return (
    <SelectDocumentModal
      open={open}
      documents={
        documents &&
        (docType
          ? documents.latestVersionOfDocumentsByProjectId?.items?.filter((d) => d.type.key === docType.key)
          : documents.latestVersionOfDocumentsByProjectId?.items)
      }
      pageCount={
        documents &&
        Math.ceil((documents.latestVersionOfDocumentsByProjectId?.totalCount ?? 0) / paginationInfo.pageSize)
      }
      docType={docType}
      onPageInfoChange={handlePageInfoChange}
      onSearchDocuments={handleSearchDocuments}
      getDocumentsStatus={getDocumentsStatus}
      onSelectDocument={handleSelectDocument}
      onClose={onClose}
    ></SelectDocumentModal>
  );
};

export default SelectDocumentModalContainer;
