import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_PROJECT_FIELDS } from "../fragments/project";
import {
  GetMyProjectsByOrganizationId,
  GetMyProjectsByOrganizationIdVariables,
} from "./__generated__/GetMyProjectsByOrganizationId";

export const GET_MY_PROJECTS_BY_ORGANIZATION_ID = gql`
  ${CORE_PROJECT_FIELDS}
  query GetMyProjectsByOrganizationId($organizationId: String!) {
    myProjectsByOrganizationId(organizationId: $organizationId) {
      ...CoreProjectFields
    }
  }
`;

export function useLazyGetMyProjectsByOrganizationId() {
  const [getMyProjectsByOrganizationId, { loading, error, data }] = useLazyQuery<
    GetMyProjectsByOrganizationId,
    GetMyProjectsByOrganizationIdVariables
  >(GET_MY_PROJECTS_BY_ORGANIZATION_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getMyProjectsByOrganizationId,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
