import AddReferenceProcedureModal from "../components/AddReferenceProcedureModal";
import { useAddReferenceProcedure } from "../operations/mutations/addReferenceProcedure";
import { useUploadFile } from "../operations/mutations/uploadFile";
import { toastSuccess } from "../utils";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect, useState } from "react";
import { UserFile } from "../models/UserFile";
import { FileMetaData } from "../models/FileMetaData";
import { ReferenceProcedure_Data } from "../models/ReferenceProcedure_Data";
import { Project } from "../models/Project";

interface Props {
  projects?: Project[] | null;
  open: boolean;
  onReferenceProcedureAdded?: (Ref: ReferenceProcedure_Data) => void;
  onClose: () => void;
}

const AddReferenceProcedureModalContainer = ({ projects, open, onReferenceProcedureAdded, onClose }: Props) => {
  const { currentOrganization } = useCurrentOrganization();
  const [userFile, setUserFile] = useState<UserFile>();

  const {
    mutate: addReferenceProcedureMutate,
    data: addReferenceProcedure,
    status: addReferenceProcedureStatus,
  } = useAddReferenceProcedure();
  const {
    mutate: uploadFileMutate,
    data: uploadFile,
    error: uploadFileError,
    loading: uploadFileLoading,
  } = useUploadFile();

  const handleModalClose = () => {
    addReferenceProcedureStatus.reset();
    onClose();
  };

  const handleUploadFile = (userFile: UserFile) => {
    setUserFile(userFile);
    uploadFileMutate({
      variables: {
        file: userFile.file,
        fileId: userFile.id,
      },
    });
  };

  useEffect(() => {
    if (userFile) {
      if (uploadFile?.uploadFile?.fileId) {
        setUserFile({ ...userFile, uploadStatus: { ...userFile.uploadStatus, uploaded: true, uploading: false } });
      } else if (uploadFileError && uploadFileError.graphQLErrors) {
        setUserFile({
          ...userFile,
          uploadStatus: { ...userFile.uploadStatus, uploading: false, uploadError: uploadFileError.graphQLErrors[0] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile, uploadFileError]);

  const handleAddReferenceProcedure = (title: string, projectId: string, fileMetaData: FileMetaData) => {
    if (currentOrganization) {
      addReferenceProcedureMutate({
        variables: {
          organizationId: currentOrganization.id,
          title,
          pId: projectId,
          fileMetaData: fileMetaData,
        },
      });
    }
  };

  useEffect(() => {
    if (addReferenceProcedureStatus.successful) {
      toastSuccess("New reference procedure added successfully!");
      if (onReferenceProcedureAdded && addReferenceProcedure) {
        onReferenceProcedureAdded(addReferenceProcedure.addReferenceProcedure!.referenceProcedure!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReferenceProcedureStatus.successful]);

  return (
    <AddReferenceProcedureModal
      projects={projects}
      open={open}
      onClose={handleModalClose}
      onSubmit={handleAddReferenceProcedure}
      submitInProgress={addReferenceProcedureStatus.loading}
      submitErrors={addReferenceProcedureStatus.errors}
      userFile={userFile}
      onUploadFile={handleUploadFile}
      uploading={uploadFileLoading}
    ></AddReferenceProcedureModal>
  );
};

export default AddReferenceProcedureModalContainer;
