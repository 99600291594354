export type MapItemPopup = {
  id: string;
  name: string;
  title: string;
  content: string;
  hasAction: boolean;
};

export type MapItem = {
  geometry: __esri.Geometry;
  popup?: MapItemPopup;
  color?: __esri.Color;
};

const createMapItem = (
  type: "polygon" | "polyline",
  rings: number[][][],
  popupId: string,
  popupName: string,
  popupTitle: string,
  popupContent: string,
  popupHasAction: boolean,
  color?: __esri.Color
) => {
  return {
    geometry:
      type === "polygon"
        ? ({
            type,
            rings: rings,
            spatialReference: { wkid: 4326 },
          } as __esri.Polygon)
        : ({
            type,
            paths: rings,
            spatialReference: { wkid: 4326 },
          } as __esri.Polyline),
    popup: {
      id: popupId,
      name: popupName,
      title: popupTitle,
      content: popupContent,
      hasAction: popupHasAction,
    },
    color,
  } as MapItem;
};

const configAuth = (
  OAuthInfo: __esri.OAuthInfoConstructor,
  esriId: __esri.IdentityManager,
  callbackUrl: string,
  token?: string
): Promise<void> => {
  const info = new OAuthInfo({
    appId: "CAEWVgggcFTkiJHz",
    flowType: "auto",
    popup: true,
    portalUrl: "https://vislock-ltd.maps.arcgis.com",
    popupCallbackUrl: callbackUrl,
  });

  esriId.registerOAuthInfos([info]);

  if (token) {
    esriId.registerToken({
      token: token,
      server: info.portalUrl,
    });
  }
  return new Promise<void>((resolve, reject) => {
    esriId
      .checkSignInStatus(info.portalUrl + "/sharing")
      .then((x) => {
        console.log("user is  signed in!", x);
        resolve();
      })
      .catch((err) => {
        esriId.getCredential(info.portalUrl + "/sharing");
        reject(err);
      });
  });
};

const createWebMap = (
  WebMap: __esri.WebMapConstructor,
  GraphicsLayer: __esri.GraphicsLayerConstructor,
  portalItemId: string
) => {
  const webmap = new WebMap({
    portalItem: {
      id: portalItemId,
    },
  });

  const graphicsLayer = new GraphicsLayer({ title: "Permit Boundary" });

  webmap.when(() => {
    webmap.layers.add(graphicsLayer);
  });

  return { webmap, graphicsLayer };
};

const createMapView = (
  MapView: __esri.MapViewConstructor,
  ScaleBar: __esri.ScaleBarConstructor,
  Legend: __esri.LegendConstructor,
  container: any,
  webmap: __esri.WebMap,
  showLegend: boolean,
  targets?: () => __esri.Collection<__esri.Graphic>,
  center?: __esri.Point,
  onPopupClick?: (id: string) => void
) => {
  const view = new MapView({
    map: webmap,
    center,
    zoom: 9,
    container: container,
    constraints: {
      snapToZoom: false,
    },
    spatialReference: { wkid: 4326 },
  });

  view.when(() => {
    if (targets) {
      view.goTo(targets());
    }

    if (onPopupClick) {
      view.popup.on("trigger-action", (event: any) => {
        onPopupClick(event.action.id);
      });
    }

    const scalebar = new ScaleBar({
      view: view,
    });
    view.ui.add(scalebar, "bottom-left");

    if (showLegend) {
      const legend = new Legend({
        view: view,
      });
      view.ui.add(legend, "top-right");
    }
  });

  return view;
};

const addSimpleMarker = (
  SimpleMarkerSymbol: __esri.SimpleMarkerSymbolConstructor,
  Graphic: __esri.GraphicConstructor,
  mapview: __esri.MapView,
  point: __esri.Point,
  color?: __esri.Color
) => {
  const simpleMarkerSymbol = new SimpleMarkerSymbol({
    color: color ? color : ({ r: 226, g: 119, b: 40 } as __esri.Color), // Orange
    outline: {
      color: { r: 255, g: 255, b: 255 } as __esri.Color, // White
      width: 1,
    },
  });

  // Add the geometry and symbol to a new graphic
  const graphic = new Graphic({
    geometry: point,
    symbol: simpleMarkerSymbol,
  });

  mapview.graphics.add(graphic);
};

const addPolyline = (
  SimpleLineSymbol: __esri.SimpleLineSymbolConstructor,
  Graphic: __esri.GraphicConstructor,
  PopupTemplate: __esri.PopupTemplateConstructor,
  graphicsLayer: __esri.GraphicsLayer,
  polyline: __esri.Polyline,
  color?: __esri.Color,
  popup?: MapItemPopup
) => {
  const lineSymbol = new SimpleLineSymbol({
    color: color ? ({ ...color } as __esri.Color) : ({ r: 226, g: 119, b: 40 } as __esri.Color),
    width: "4px",
    style: "short-dash",
  });

  return addGraphic(lineSymbol, Graphic, PopupTemplate, graphicsLayer, polyline, popup);
};

const addPolygon = (
  SimpleFillSymbol: __esri.SimpleFillSymbolConstructor,
  Graphic: __esri.GraphicConstructor,
  PopupTemplate: __esri.PopupTemplateConstructor,
  graphicsLayer: __esri.GraphicsLayer,
  polygon: __esri.Polygon,
  color?: __esri.Color,
  popup?: MapItemPopup
) => {
  const fillSymbol = new SimpleFillSymbol({
    color: color ? ({ ...color, a: 0.15 } as __esri.Color) : ({ r: 226, g: 119, b: 40, a: 0.15 } as __esri.Color), // { r: 0, g: 0, b: 0, a: 0.15 } as __esri.Color,
    outline: {
      color: color ? ({ ...color, a: 40 } as __esri.Color) : ({ r: 226, g: 119, b: 40 } as __esri.Color), //{ r: 0, g: 0, b: 0 } as __esri.Color,
      width: 1,
    },
  });

  return addGraphic(fillSymbol, Graphic, PopupTemplate, graphicsLayer, polygon, popup);
};

const addGraphic = (
  symbol: __esri.SimpleLineSymbol | __esri.SimpleFillSymbol,
  Graphic: __esri.GraphicConstructor,
  PopupTemplate: __esri.PopupTemplateConstructor,
  graphicsLayer: __esri.GraphicsLayer,
  geometry: __esri.Geometry,
  popup?: MapItemPopup
) => {
  const graphic = new Graphic({
    geometry: geometry,
    symbol: symbol,
  });

  if (popup) {
    const popupActions: any = popup.hasAction
      ? [
          {
            className: "esri-icon-zoom-in-magnifying-glass",
            title: popup.name,
            id: popup.id,
          },
        ]
      : [];

    const popupTemplate = new PopupTemplate({
      title: popup.title,
      content: popup.content,
      actions: popupActions,
      overwriteActions: true,
    });

    graphic.popupTemplate = popupTemplate;
  }

  graphicsLayer.add(graphic);

  return graphic;
};

const createSketchView = (
  SketchViewModel: __esri.SketchViewModelConstructor,
  mapView: __esri.MapView,
  graphicsLayer: __esri.GraphicsLayer,
  SimpleFillSymbol: __esri.SimpleFillSymbolConstructor,
  onDrawingCompleted: (graphic: __esri.Graphic) => void,
  onDrawinCancelled: () => void,
  onEditCompleted: (graphic: __esri.Graphic | null) => void
) => {
  mapView.ui.add("mainWidget", "top-right");

  const fillSymbol = new SimpleFillSymbol({
    color: { r: 226, g: 119, b: 40, a: 0.15 } as __esri.Color, // { r: 0, g: 0, b: 0, a: 0.15 } as __esri.Color,
    outline: {
      color: { r: 226, g: 119, b: 40 } as __esri.Color, //{ r: 0, g: 0, b: 0 } as __esri.Color,
      width: 1,
    },
  });

  const sketchVM = new SketchViewModel({
    view: mapView,
    layer: graphicsLayer,
    updateOnGraphicClick: false,
    polygonSymbol: fillSymbol,

    defaultUpdateOptions: {
      toggleToolOnClick: false, // only reshape operation will be enabled
    },
  });

  sketchVM.on("create", (event) => {
    if (event.state === "complete") {
      onDrawingCompleted(event.graphic);
    } else if (event.state === "cancel") {
      onDrawinCancelled();
    }
  });

  sketchVM.on("update", (event) => {
    //if (event.state === "complete") {
    // This event is called when either the "transform" or "reshape" is cancelled/finished
    onEditCompleted(event.graphics.length > 0 ? event.graphics[0] : null);
    // }
  });

  return sketchVM;
};

export {
  createMapItem,
  configAuth,
  createMapView,
  createWebMap,
  createSketchView,
  addPolygon,
  addPolyline,
  addSimpleMarker,
};
