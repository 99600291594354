import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { SendEnquiryToSupport, SendEnquiryToSupportVariables } from "./__generated__/SendEnquiryToSupport";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";

const SEND_ENQUIRY_TO_SUPPORT = gql`
  mutation SendEnquiryToSupport($organizationId: String!, $text: String!) {
    sendEnquiryToSupport(input: { organizationId: $organizationId, text: $text }) {
      succeeded
    }
  }
`;

export function useSendEnquiryToSupport() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    SendEnquiryToSupport,
    SendEnquiryToSupportVariables
  >(SEND_ENQUIRY_TO_SUPPORT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
