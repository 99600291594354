import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";
import { GetAffectedServicesOptions } from "./__generated__/GetAffectedServicesOptions";

export const GET_AFFECTED_SERVICES = gql`
  query GetAffectedServicesOptions {
    affectedServicesOptions {
      id
      name
      order
    }
  }
`;

export function useLazyGetAffectedServicesOptions() {
  const [getAffectedServicesOptions, { loading, error, data }] = useLazyQuery<GetAffectedServicesOptions>(
    GET_AFFECTED_SERVICES,
    {
      errorPolicy: "all",
    }
  );

  return {
    getAffectedServicesOptions,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
