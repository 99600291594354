import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { RequestPermitToDig, RequestPermitToDigVariables } from "./__generated__/RequestPermitToDig";

const REQUEST_PERMIT = gql`
  mutation RequestPermitToDig(
    $permitDataInput: PermitDataInput
    $number: Int!
    $projectId: String!
    $documentIds: [String!]
    $boundaries: GeoJSONPolygonInput
  ) {
    requestPermitToDig(
      input: {
        permitDataInput: $permitDataInput
        number: $number
        projectId: $projectId
        documentIds: $documentIds
        boundaries: $boundaries
      }
    ) {
      permitToDig {
        id
      }
    }
  }
`;

export function useRequestPermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    RequestPermitToDig,
    RequestPermitToDigVariables
  >(REQUEST_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
