import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";
import { GeoJSONGeometryType } from "../models/globalTypes";
import Map from "./common/Map";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { MapItem } from "../utils/arcgisUtils";

interface Props {
  showLegend: boolean;
  showLayersList: boolean;
  boundaries?: GeoJSONPolygonType | null;
  saveBoundaries?: (bouandaries: GeoJSONPolygonType | null) => void;
  height?: string;
  items: MapItem[];
}

const MapWithBoundaries = ({ showLegend, showLayersList, boundaries, saveBoundaries, height, items }: Props) => {
  let currentPolygon = undefined;

  const { currentOrganization } = useCurrentOrganization();

  if (boundaries) {
    currentPolygon = {
      type: "polygon",
      rings: boundaries.coordinates as number[][][],
      spatialReference: { wkid: 4326 },
    } as __esri.Polygon;
  }

  const handlePolygonEdit = (polygon: __esri.Polygon) => {
    if (!saveBoundaries) return;

    var geoJsonPolygon = {
      type: GeoJSONGeometryType.Polygon,
      coordinates: polygon.rings,
      crs: polygon.spatialReference.wkid,
    } as GeoJSONPolygonType;

    saveBoundaries(geoJsonPolygon);
  };

  return (
    <Map
      webMapItemId={currentOrganization?.esriConfig?.webMapItemId}
      items={items}
      onPolygonEdit={saveBoundaries ? handlePolygonEdit : undefined}
      polygonToEdit={currentPolygon}
      showLegend={showLegend}
      showLayersList={showLayersList}
      height={height}
    />
  );
};

export default MapWithBoundaries;
