import { Segment, Menu, Header, Grid, Input, Dropdown, InputOnChangeData } from "semantic-ui-react";
import ReferenceProceduresGrid from "./ReferenceProceduresGrid";
import { ReferenceProcedure_ListItem } from "../models/ReferenceProcedure_ListItem";
import { AsyncActionStatus, AsyncQueryStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastSuccess } from "../utils";
import AddReferenceProcedureModalContainer from "../containers/AddReferenceProcedureModalContainer";
import ReferenceProcedureView from "./ReferenceProcedureView";
import ViewReferenceProcedureModal from "./ViewReferenceProcedureModal";
import { Project } from "../models/Project";
import { ReferenceProcedure_Data } from "../models/ReferenceProcedure_Data";

interface Props {
  projects?: Project[] | null;
  referenceProcedures?: ReferenceProcedure_ListItem[] | null;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchReferenceProcedures: (serachStrign: string) => void;
  onProjectFilter: (projectId: string) => void;
  getReferenceProceduresStatus: AsyncQueryStatus;
  onDeleteReferenceProcedure: (id: string) => void;
  deleteReferenceProcedureStatus: AsyncActionStatus;
  onReferenceProcedureAdded?: (ref: ReferenceProcedure_Data) => void;
}

const ReferenceProceduresList = ({
  projects,
  referenceProcedures,
  pageCount,
  onPageInfoChange,
  onSearchReferenceProcedures,
  onProjectFilter,
  getReferenceProceduresStatus,
  onDeleteReferenceProcedure,
  deleteReferenceProcedureStatus,
  onReferenceProcedureAdded,
}: Props) => {
  const [openAddReferenceProcedureModal, setOpenAddReferenceProcedureModal] = useState(false);
  const [openViewReferenceProcedureSection, setOpenViewReferenceProcedureSection] = useState(false);
  const [openViewReferenceProcedureModal, setOpenViewReferenceProcedureModal] = useState(false);
  const [selectedReferenceProcedureToView, setSelectedReferenceProcedureToView] = useState<
    ReferenceProcedure_ListItem | undefined
  >(undefined);

  const handleDeleteReferenceProcedure = (referenceProcedureId: string) => {
    onDeleteReferenceProcedure(referenceProcedureId);
  };

  useEffect(() => {
    if (deleteReferenceProcedureStatus.successful) {
      toastSuccess("Reference procedure deleted successfully!");
    }
  }, [deleteReferenceProcedureStatus.successful]);

  const handleModalClose = () => {
    setOpenAddReferenceProcedureModal(false);
    setOpenViewReferenceProcedureModal(false);

    deleteReferenceProcedureStatus.reset();
  };

  const handleReferenceProcedureClicked = (referenceProcedureId: string) => {
    setOpenViewReferenceProcedureSection(true);
    setSelectedReferenceProcedureToView(referenceProcedures?.find((d) => d.id === referenceProcedureId));
  };

  const handleViewReferenceProcedureSectionClose = () => {
    setSelectedReferenceProcedureToView(undefined);
    setOpenViewReferenceProcedureSection(false);
  };

  const handleViewReferenceProcedurePopup = () => {
    setOpenViewReferenceProcedureModal(true);
  };

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchReferenceProcedures(value);
  };

  const handleProjectFilterChange = (e: any, { name, value }: any) => {
    onProjectFilter(value === "" ? null : value);
  };

  const handleReferenceProcedureAdded = (ref: ReferenceProcedure_Data) => {
    handleModalClose();
    onReferenceProcedureAdded && onReferenceProcedureAdded(ref);
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">Reference Procedures</Header>
      </Segment>

      <Menu pointing secondary color="green">
        <Menu.Item
          icon="plus"
          name="New reference procedure"
          onClick={(e) => setOpenAddReferenceProcedureModal(true)}
        />

        <Menu.Menu position="right">
          <Menu.Item>
            <Dropdown
              placeholder="Project"
              basic
              clearable
              onChange={handleProjectFilterChange}
              options={projects?.map((t) => ({ key: t.id, text: t.name, value: t.id }))}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment basic loading={getReferenceProceduresStatus.loading}>
        <Grid columns={openViewReferenceProcedureSection ? "2" : "1"}>
          <Grid.Row>
            <Grid.Column width={openViewReferenceProcedureSection ? "10" : "16"}>
              <ReferenceProceduresGrid
                referenceProcedures={referenceProcedures}
                selectedReferenceProcedure={selectedReferenceProcedureToView}
                pageCount={pageCount}
                onPageInfoChange={onPageInfoChange}
                onDeleteReferenceProcedure={handleDeleteReferenceProcedure}
                onReferenceProcedureClicked={handleReferenceProcedureClicked}
              />
            </Grid.Column>
            {openViewReferenceProcedureSection && (
              <Grid.Column width="6">
                <ReferenceProcedureView
                  containerId="RefProcView"
                  onPopup={handleViewReferenceProcedurePopup}
                  onClose={handleViewReferenceProcedureSectionClose}
                  referenceProcedure={referenceProcedures?.find((d) => d.id === selectedReferenceProcedureToView?.id)}
                ></ReferenceProcedureView>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>

      <ViewReferenceProcedureModal
        open={openViewReferenceProcedureModal}
        onClose={handleModalClose}
        referenceProcedure={referenceProcedures?.find((d) => d.id === selectedReferenceProcedureToView?.id)}
      ></ViewReferenceProcedureModal>

      <AddReferenceProcedureModalContainer
        projects={projects}
        open={openAddReferenceProcedureModal}
        onReferenceProcedureAdded={handleReferenceProcedureAdded}
        onClose={() => {
          handleModalClose();
        }}
      ></AddReferenceProcedureModalContainer>
    </>
  );
};

export default ReferenceProceduresList;
