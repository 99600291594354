import React from "react";
import { Feed, Label, Modal } from "semantic-ui-react";
import moment from "moment";
import { PermitToDigStateChangeLog } from "../models/PermitToDigStateChangeLog";
import { reverse } from "lodash";

interface Props {
  logs?: PermitToDigStateChangeLog[];
}

const StateChangeLogsView = ({ logs }: Props) => {
  const [open, setOpen] = React.useState(false);

  if (!logs || logs.length < 1) {
    return null;
  }

  let orderedLogs = [...logs];
  reverse(orderedLogs);

  var latestLog = orderedLogs[0];

  const renderStateChangeLog = (log: PermitToDigStateChangeLog, stateAsLink: boolean) => {
    return (
      <Feed.Event>
        <Feed.Content>
          <Feed.Summary>
            {stateAsLink ? (
              <Label
                size="large"
                basic
                color="blue"
                as="a"
                onClick={() => setOpen(true)}
                style={{ border: "0px", padding: "0px" }}
              >
                {log.destination}
              </Label>
            ) : (
              <>{log.destination}</>
            )}
            <Feed.Date>
              {moment(log.dateTimeUtc).local().fromNow()}{" "}
              {log.orgUser && <span>by {log.orgUser.user.displayName}</span>}
            </Feed.Date>
          </Feed.Summary>
          {log.comment && (
            <Feed.Meta>
              <p>{log.comment}</p>
            </Feed.Meta>
          )}
        </Feed.Content>
      </Feed.Event>
    );
  };

  return (
    <>
      <Feed>{renderStateChangeLog(latestLog, true)}</Feed>

      <Modal closeIcon size="tiny" onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>State change logs</Modal.Header>
        <Modal.Content>
          <Feed>{orderedLogs.map((log) => renderStateChangeLog(log, false))}</Feed>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default StateChangeLogsView;
