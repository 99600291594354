import { useHistory, useParams } from "react-router-dom";
import { Card, Header, Menu, Segment } from "semantic-ui-react";
import { Project } from "../models/Project";

interface Props {
  isMyProjectsLoading: boolean;
  myProjects?: Project[];
}

const MyProjectsList = ({ isMyProjectsLoading, myProjects }: Props) => {
  const { orgName } = useParams<{ orgName: string }>();

  let history = useHistory();

  const projectCard = (project: Project) => {
    return (
      <Card
        key={project.id}
        onClick={() => {
          history.push(`/${orgName}/projects/${project.name}/dashboard`);
        }}
      >
        <Card.Content>
          <Card.Header>{project.name}</Card.Header>
          <Card.Meta>{project.number}</Card.Meta>
          <Card.Description>{project.location}</Card.Description>
        </Card.Content>
      </Card>
    );
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">{orgName}</Header>
      </Segment>

      <Menu pointing secondary primary color="green">
        <Menu.Item name="Projects" active={true} />
      </Menu>

      <Segment basic loading={isMyProjectsLoading}>
        <Card.Group>{myProjects?.map(projectCard)}</Card.Group>
      </Segment>
    </>
  );
};

export default MyProjectsList;
