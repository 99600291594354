import moment from "moment";
import { Column } from "react-table";
import { Icon, Popup } from "semantic-ui-react";
import { Licence } from "../models/Licence";
import { DataGrid } from "./common/DataGrid";

interface Props {
  licences?: Licence[] | null;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onArchiveLicence: (id: string) => void;
  archiveLicenceInProgress: boolean;
}

const AdminLicencesGrid = ({
  licences,
  pageCount,
  onPageInfoChange,
  onArchiveLicence,
  archiveLicenceInProgress,
}: Props) => {
  const columns: Column<Licence>[] = [
    {
      Header: "Organization",
      id: "organization",
      accessor: (l) => l.organization.name,
    },
    {
      Header: "Start date",
      id: "startDate",
      accessor: (l) => moment(l.startDate).format("L"),
    },
    {
      Header: "End date",
      id: "endDate",
      accessor: (l) => moment(l.endDate).format("L"),
    },
    {
      Header: "Number of users",
      id: "numberOfUsers",
      accessor: (l) => l.numberOfUsers,
    },
    {
      Header: "Status",
      id: "status",
      accessor: (l) => l.status,
    },
    {
      Header: "Archive",
      id: "archive",
      accessor: (l) => l,
      Cell: ({ value }: any) => (
        <>
          <Icon
            style={{ cursor: value.isArchived ? "not-allowed" : "pointer" }}
            name="archive"
            disabled={value.isArchived || archiveLicenceInProgress}
            title="Archive"
            onClick={() => {
              onArchiveLicence(value.id);
            }}
          ></Icon>
        </>
      ),
    },
    {
      Header: "Comments",
      id: "comment",
      accessor: (l) => l.comment,
      Cell: ({ value }: any) => (
        <Popup
          trigger={<span>{value && value.length > 50 ? value.substring(0, 50) + "..." : value}</span>}
          content={value}
        ></Popup>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        columns={columns}
        data={licences ? licences : []}
        onPageInfoChange={onPageInfoChange}
        loading={false}
        pageCount={pageCount}
      />
    </>
  );
};

export default AdminLicencesGrid;
