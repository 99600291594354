import { Icon, Label, Progress, Table } from "semantic-ui-react";
import { UserFile } from "../../models/UserFile";

interface Props {
  userFilesMap: Map<string, UserFile>;
}

const UserFiles = ({ userFilesMap }: Props) => {
  return (
    <Table basic="very">
      <Table.Body>
        {Array.from(userFilesMap, ([key, value]) => (
          <Table.Row key={key}>
            <Table.Cell>
              <Icon name="file" /> {value.file.name}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {value.uploadStatus && value.uploadStatus.uploading && (
                <Progress size="tiny" percent={100} indicating active>
                  uploading
                </Progress>
              )}
              {value.uploadStatus && value.uploadStatus.uploaded && <Label color={"green"}>Ready</Label>}
              {value.uploadStatus && value.uploadStatus.uploadError && (
                <Label color={"red"}>Upload Failed - {value.uploadStatus.uploadError.message}</Label>
              )}
              {value.uploadStatus && value.uploadStatus.fileErrors?.length > 0 && (
                <Label color={"red"}>
                  {value.uploadStatus.fileErrors.map((err) => (
                    <span> {err} </span>
                  ))}
                </Label>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default UserFiles;
