import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { UpdateOrgUser, UpdateOrgUserVariables } from "./__generated__/UpdateOrgUser";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";

const UPDATE_ORG_USER = gql`
  ${ORG_USER_FIELDS}
  mutation UpdateOrgUser(
    $organizationId: String!
    $userId: String!
    $firstName: String
    $lastName: String
    $isAdmin: Boolean!
    $phoneNumber: String
    $calibrationCertificate: CalibrationCertificateInput
    $newOrgUserFiles: [OrgUserFileInput]
    $deletedOrgUserFileIds: [String]
  ) {
    updateOrgUser(
      input: {
        organizationId: $organizationId
        userId: $userId
        firstName: $firstName
        lastName: $lastName
        isAdmin: $isAdmin
        phoneNumber: $phoneNumber
        calibrationCertificate: $calibrationCertificate
        newOrgUserFiles: $newOrgUserFiles
        deletedOrgUserFileIds: $deletedOrgUserFileIds
      }
    ) {
      orgUser {
        ...OrgUserFields
        orgUserFiles {
          id
          title
          expiryDate
          fileUrl
        }
      }
    }
  }
`;

export function useUpdateOrgUser() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateOrgUser,
    UpdateOrgUserVariables
  >(UPDATE_ORG_USER, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
