import moment from "moment";
import { useEffect, useState } from "react";
import { Header, Segment, Menu, Dropdown, Input, Card, Button, Grid, InputOnChangeData } from "semantic-ui-react";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { BuriedServicesInduction } from "../models/BuriedServicesInduction";
import { CompanyInduction } from "../models/CompanyInduction";
import { GangMember } from "../models/GangMember";
import { toastSuccess } from "../utils";
import EditGangMemberModal from "./EditGangMemberModal";
import PhoneBookFilter from "./common/PhoneBookFilter";
import { sortBy } from "lodash";

interface Props {
  isGangMembersLoading: boolean;
  gangMembers?: GangMember[] | null;
  onSearchGangMembers: (searchStrign: string) => void;
  onAddGangMember: (
    firstName: string,
    lastName: string,
    jobTitle: string,
    employerName: string,
    companyInduction: CompanyInduction,
    buriedServicesInduction: BuriedServicesInduction
  ) => void;
  addGangMemberStatus: AsyncActionStatus;
  onEditGangMember: (
    id: string,
    firstName: string,
    lastName: string,
    jobTitle: string,
    employerName: string,
    companyInduction: CompanyInduction,
    buriedServicesInduction: BuriedServicesInduction
  ) => void;
  editGangMemberStatus: AsyncActionStatus;
  onToggleArchivedStatusGangMember: (id: string) => void;
  toggleArchivedStatusGangMemberStatus: AsyncActionStatus;
  isArchivedFilterValue: boolean | null;
  onIsArchivedFilter: (isArchived: boolean) => void;
}

const GangMembersList = ({
  isGangMembersLoading,
  gangMembers,
  onSearchGangMembers,
  onAddGangMember,
  addGangMemberStatus,
  onEditGangMember,
  editGangMemberStatus,
  onToggleArchivedStatusGangMember,
  toggleArchivedStatusGangMemberStatus,
  isArchivedFilterValue,
  onIsArchivedFilter,
}: Props) => {
  const [openEditGangMemberModal, setOpenEditGangMemberModal] = useState(false);
  const [selectedGangMemberToEdit, setSelectedGangMemberToEdit] = useState<GangMember | undefined>(undefined);
  const [selectedChar, setSelectedChar] = useState("");
  const [filteredGangMembers, setFilteredGangMembers] = useState<GangMember[]>([]);

  const handleEditGangMember = (gangMemberId: string) => {
    const gangMember = gangMembers?.find((o) => o.id === gangMemberId);
    if (gangMember) {
      setSelectedGangMemberToEdit({ ...gangMember } as GangMember);
      setOpenEditGangMemberModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenEditGangMemberModal(false);
    setSelectedGangMemberToEdit(undefined);

    addGangMemberStatus.reset();
    editGangMemberStatus.reset();
    toggleArchivedStatusGangMemberStatus.reset();
  };

  const handleEditGangMemberModalSubmit = (
    firstName: string,
    lastName: string,
    jobTitle: string,
    employerName: string,
    companyInduction: CompanyInduction,
    buriedServicesInduction: BuriedServicesInduction,
    id?: string
  ) => {
    if (id) {
      onEditGangMember(id, firstName, lastName, jobTitle, employerName, companyInduction, buriedServicesInduction);
    } else {
      onAddGangMember(firstName, lastName, jobTitle, employerName, companyInduction, buriedServicesInduction);
    }
  };

  useEffect(() => {
    if (selectedChar) {
      const filteredList = gangMembers?.filter((g) => g.lastName.startsWith(selectedChar)) || ([] as GangMember[]);
      sortBy(filteredList, ["lastName"]);
      setFilteredGangMembers(filteredList);
    } else {
      setFilteredGangMembers(gangMembers || ([] as GangMember[]));
    }
  }, [gangMembers, selectedChar]);

  useEffect(() => {
    if (addGangMemberStatus.successful) {
      toastSuccess("Gang member added successfully!");
      setOpenEditGangMemberModal(false);
    }
  }, [addGangMemberStatus.successful]);

  useEffect(() => {
    if (editGangMemberStatus.successful) {
      toastSuccess("GangMember edited successfully!");
      setOpenEditGangMemberModal(false);
      setSelectedGangMemberToEdit(undefined);
    }
  }, [editGangMemberStatus.successful]);

  useEffect(() => {
    if (toggleArchivedStatusGangMemberStatus.successful) {
      toastSuccess("Gang member archive status changed successfully!");
    }
  }, [toggleArchivedStatusGangMemberStatus.successful]);

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchGangMembers(value);
  };

  const handleIsArchivedFilterChange = (e: any, { name, value }: any) => {
    onIsArchivedFilter(value === "" ? null : value);
  };

  const getColor = (gangMember: GangMember) => {
    if (
      gangMember.buriedServicesInduction?.expiresOn &&
      moment(gangMember.buriedServicesInduction.expiresOn) > moment()
    ) {
      return "green";
    } else if (gangMember.buriedServicesInduction?.date) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const getInductionInfo = (gangMember: GangMember) => {
    if (gangMember.buriedServicesInduction?.date || gangMember.buriedServicesInduction?.expiresOn) {
      const { date, expiresOn } = gangMember.buriedServicesInduction;

      return `${date ? moment(date).format("L") : ""} ${expiresOn ? "to " + moment(expiresOn).format("L") : ""}`;
    }

    return "No buried services induction";
  };

  const GangMemberCard = (gangMember: GangMember) => {
    return (
      <Card
        key={gangMember.id}
        onClick={() => {
          !toggleArchivedStatusGangMemberStatus.loading && handleEditGangMember(gangMember.id);
        }}
        color={getColor(gangMember)}
      >
        <Card.Content>
          <Button
            style={{ boxShadow: "0", marginTop: "-2px", border: "0" }}
            floated="right"
            icon={gangMember.isArchived ? "undo" : "archive"}
            basic
            circular
            title={gangMember.isArchived ? "Activate" : "Archive"}
            disabled={toggleArchivedStatusGangMemberStatus.loading}
            onClick={(e) => {
              e.stopPropagation();
              onToggleArchivedStatusGangMember(gangMember.id);
            }}
          ></Button>
          <Card.Header>{gangMember.displayName}</Card.Header>
          <Card.Meta>{gangMember.jobTitle}</Card.Meta>
          <Card.Description>
            <p>Induction: {getInductionInfo(gangMember)}</p>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">Gang members</Header>
      </Segment>
      <Menu pointing secondary color="green" borderless>
        <Menu.Item
          icon="plus"
          name="New"
          onClick={() => {
            setOpenEditGangMemberModal(true);
          }}
        />

        <Menu.Menu position="right">
          <Menu.Item>
            <Dropdown
              placeholder="View"
              basic
              clearable
              onChange={handleIsArchivedFilterChange}
              value={isArchivedFilterValue === null ? undefined : isArchivedFilterValue}
              options={[
                { value: false, key: "active", text: "active" },
                { value: true, key: "archived", text: "archived" },
              ]}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Grid>
        <Grid.Row only="large screen">
          <Grid.Column>
            <PhoneBookFilter
              items={gangMembers || []}
              charSelector={(g: GangMember) => g.lastName[0]}
              onSelect={(char: string) => setSelectedChar(char)}
              selectedChar={selectedChar}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Segment basic loading={isGangMembersLoading}>
        <Card.Group>{filteredGangMembers?.map(GangMemberCard)}</Card.Group>
      </Segment>

      <EditGangMemberModal
        open={openEditGangMemberModal}
        onClose={handleModalClose}
        onSubmit={handleEditGangMemberModalSubmit}
        submitInProgress={addGangMemberStatus.loading || editGangMemberStatus.loading}
        gangMember={selectedGangMemberToEdit}
        submitErrors={
          addGangMemberStatus.errors
            ? addGangMemberStatus.errors
            : editGangMemberStatus.errors
            ? editGangMemberStatus.errors
            : undefined
        }
      ></EditGangMemberModal>
    </>
  );
};

export default GangMembersList;
