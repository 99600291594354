import { Confirm } from "semantic-ui-react";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
}

const ConfirmModal = ({ open, onClose, onSubmit, title }: Props) => {
  return <Confirm open={open} header={title} onCancel={onClose} onConfirm={onSubmit} size="mini" />;
};

export default ConfirmModal;
