import { Text, View } from "@react-pdf/renderer";
import styles from "./components/Styles";
import { Permit } from "../../models/Permit";
import Row from "./components/Row";
import Item from "./components/Item";
import Break from "./components/Break";
import HeaderText from "./components/HeaderText";

interface Props {
  permit: Permit;
  orgLogoUrl: string | null;
  token: string;
}

const DocumentsPage = ({ permit, orgLogoUrl, token }: Props) => {
  if (!permit) return null;

  return (
    <>
      <HeaderText title="DOCUMENTS" />
      {permit.documents.map((doc) => (
        <View wrap={false}>
          <Row border>
            <Item text={`${doc.type.name}: ${doc.title} (v${doc.versionNo})`} width="100%"></Item>
          </Row>
        </View>
      ))}
      <Break height={5} />

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </>
  );
};

export default DocumentsPage;
