import React from "react";
import { Button, Menu, MenuItemProps, Popup } from "semantic-ui-react";

export interface ActionItem {
  key: string;
  content: string;
  icon: string;
  callback: (id: any) => void;
  disabled?: boolean;
}

interface Props {
  items: ActionItem[];
  rowId: any;
}

const GridActionButton = ({ items, rowId }: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleItemClicked = (events: any, data: MenuItemProps) => {
    events.preventDefault();
    events.stopPropagation();
    if (data.index !== undefined) {
      items[data.index].callback(rowId);
      setOpen(false);
    }
  };

  return (
    <Popup
      position="left center"
      on="click"
      onClose={(e) => {
        setOpen(false);
        e.preventDefault();
        e.stopPropagation();
      }}
      onOpen={(e) => {
        setOpen(true);
        e.preventDefault();
        e.stopPropagation();
      }}
      open={open}
      basic
      size="tiny"
      trigger={
        <Button
          style={{ float: "right", padding: "0.40em" }}
          basic
          compact
          size="mini"
          icon="ellipsis horizontal"
        ></Button>
      }
    >
      <Menu
        borderless
        vertical
        secondary
        items={items.map(({ key, content, icon, disabled }) => ({
          key,
          content,
          icon,
          disabled: disabled || false,
        }))}
        onItemClick={handleItemClicked}
      />
    </Popup>
  );
};

export default GridActionButton;
