import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_PROJECT_FIELDS } from "../fragments/project";
import {
  GetProjectsByOrganizationId,
  GetProjectsByOrganizationIdVariables,
} from "./__generated__/GetProjectsByOrganizationId";

export const GET_PROJECTS_BY_ORGANIZATION_ID = gql`
  ${CORE_PROJECT_FIELDS}
  query GetProjectsByOrganizationId($organizationId: String!, $searchString: String, $skip: Int, $take: Int) {
    projectsByOrganizationId(organizationId: $organizationId, searchString: $searchString, skip: $skip, take: $take) {
      totalCount
      items {
        ...CoreProjectFields
      }
    }
  }
`;

export function useLazyGetProjectsByOrganizationId() {
  const [getProjectsByOrganizationId, { loading, error, data }] = useLazyQuery<
    GetProjectsByOrganizationId,
    GetProjectsByOrganizationIdVariables
  >(GET_PROJECTS_BY_ORGANIZATION_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getProjectsByOrganizationId,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
