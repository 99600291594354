import { Segment, Menu, Header, Grid, InputOnChangeData, Input } from "semantic-ui-react";
import ProjectsGrid from "./ProjectsGrid";
import { Project } from "../models/Project";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastSuccess } from "../utils";
import EditProjectModal from "./EditProjectModal";
import ProjectViewContainer from "../containers/ProjectViewContainer";
import { EmergencyContact } from "../models/EmergencyContact";
import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";

interface Props {
  showProjectBoundariesOnMap?: boolean;
  isProjectsLoading: boolean;
  projects?: Project[] | null;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchProjects: (searchStrign: string) => void;
  onAddProject: (
    name: string,
    number: string,
    location: string,
    boundaries: GeoJSONPolygonType,
    emergencyContact: EmergencyContact
  ) => void;
  addProjectStatus: AsyncActionStatus;
  onEditProject: (
    id: string,
    name: string,
    number: string,
    location: string,
    boundaries: GeoJSONPolygonType,
    emergencyContact: EmergencyContact
  ) => void;
  editProjectStatus: AsyncActionStatus;
  onDeleteProject: (id: string) => void;
  deleteProjectStatus: AsyncActionStatus;
}

const ProjectsList = ({
  showProjectBoundariesOnMap,
  isProjectsLoading,
  projects,
  pageCount,
  onPageInfoChange,
  onSearchProjects,
  onAddProject,
  addProjectStatus,
  onEditProject,
  editProjectStatus,
  onDeleteProject,
  deleteProjectStatus,
}: Props) => {
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
  const [openViewProjectSection, setOpenViewProjectSection] = useState(false);
  const [selectedProjectToView, setSelectedProjectToView] = useState<Project | undefined>(undefined);
  const [selectedProjectToEdit, setSelectedProjectToEdit] = useState<Project | undefined>(undefined);

  const handleEditProject = (projectId: string) => {
    const project = projects?.find((o) => o.id === projectId);
    if (project) {
      setSelectedProjectToEdit({ ...project } as Project);
      setOpenEditProjectModal(true);
    }
  };

  const handleDeleteProject = (projectId: string) => {
    onDeleteProject(projectId);
  };

  useEffect(() => {
    if (deleteProjectStatus.successful) {
      toastSuccess("Project deleted successfully!");
    }
  }, [deleteProjectStatus.successful]);

  const handleModalClose = () => {
    setOpenEditProjectModal(false);
    setSelectedProjectToEdit(undefined);

    addProjectStatus.reset();
    editProjectStatus.reset();
    deleteProjectStatus.reset();
  };

  const handleEditProjectModalSubmit = (
    name: string,
    number: string,
    location: string,
    boundaries: GeoJSONPolygonType,
    emergencyContact: EmergencyContact,
    id?: string
  ) => {
    if (id) {
      onEditProject(id, name, number, location, boundaries, emergencyContact);
    } else {
      onAddProject(name, number, location, boundaries, emergencyContact);
    }
  };

  useEffect(() => {
    if (addProjectStatus.successful) {
      toastSuccess("Project added successfully!");
      setOpenEditProjectModal(false);
      setSelectedProjectToEdit(undefined);
    }
  }, [addProjectStatus.successful]);

  useEffect(() => {
    if (editProjectStatus.successful) {
      toastSuccess("Project updated successfully!");
      setOpenEditProjectModal(false);
      setSelectedProjectToEdit(undefined);
    }
  }, [editProjectStatus.successful]);

  const handleProjectClicked = (project: Project) => {
    setOpenViewProjectSection(true);
    setSelectedProjectToView(project);
  };

  const handleViewProjectSectionClose = () => {
    setSelectedProjectToView(undefined);
    setOpenViewProjectSection(false);
  };

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchProjects(value);
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">Projects</Header>
      </Segment>

      <Menu pointing secondary color="green">
        <Menu.Item icon="plus" name="New project" onClick={(e) => setOpenEditProjectModal(true)} />

        <Menu.Menu position="right">
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment basic loading={isProjectsLoading}>
        <Grid columns={openViewProjectSection ? "2" : "1"}>
          <Grid.Row>
            <Grid.Column width={openViewProjectSection ? "10" : "16"}>
              <ProjectsGrid
                projects={projects}
                selectedProject={selectedProjectToView}
                pageCount={pageCount}
                onPageInfoChange={onPageInfoChange}
                onEditProject={handleEditProject}
                onDeleteProject={handleDeleteProject}
                onProjectClicked={handleProjectClicked}
              />
            </Grid.Column>
            {openViewProjectSection && (
              <Grid.Column width="6">
                <ProjectViewContainer
                  showProjectBoundariesOnMap={showProjectBoundariesOnMap}
                  onClose={handleViewProjectSectionClose}
                  project={projects?.find((p) => p.id === selectedProjectToView?.id)}
                ></ProjectViewContainer>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>

      <EditProjectModal
        showProjectBoundariesOnMap={showProjectBoundariesOnMap}
        open={openEditProjectModal}
        onClose={handleModalClose}
        onSubmit={handleEditProjectModalSubmit}
        submitInProgress={addProjectStatus.loading || editProjectStatus.loading}
        project={selectedProjectToEdit}
        submitErrors={
          addProjectStatus.errors
            ? addProjectStatus.errors
            : editProjectStatus.errors
            ? editProjectStatus.errors
            : undefined
        }
      ></EditProjectModal>
    </>
  );
};

export default ProjectsList;
