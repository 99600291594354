import { Form, InputOnChangeData } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";

export interface FormData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
}

const AdminUserEditForm = ({ formData, onChange, formErrors, errors }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  return (
    <>
      <Form>
        <Form.Group widths="2">
          <Form.Field required>
            <label>Email</label>
            <Form.Input error={formErrors.email} name="email" value={formData.email} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>First Name</label>
            <Form.Input name="firstName" value={formData.firstName} onChange={handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Last Name</label>
            <Form.Input name="lastName" value={formData.lastName} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default AdminUserEditForm;
