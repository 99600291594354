import AdminUserList from "../components/AdminUsersList";
import { useGetAdminUsers } from "../operations/queries/getAdminUsers";
import { useAddAdminUser } from "../operations/mutations/addAdminUser";
import { useUpdateAdminUser } from "../operations/mutations/updateAdminUser";
import { useDeleteAdminUser } from "../operations/mutations/deleteAdminUser";
import { useResetAdminUserPassword } from "../operations/mutations/resetAdminUserPassword";

const AdminUsersListContainer = () => {
  const { data: adminUsers, status: getAdminUsersStatus } = useGetAdminUsers();
  const { mutate: addUserMutate, status: addUserStatus } = useAddAdminUser();
  const { mutate: updateUserMutate, status: updateUserStatus } = useUpdateAdminUser();
  const { mutate: deleteUserMutate, status: deleteUserStatus } = useDeleteAdminUser();
  const { mutate: resetPasswordMutate, status: resetPasswordStatus } = useResetAdminUserPassword();

  const handleAddAdminUser = (firstName: string, lastName: string, email: string, password: string) => {
    addUserMutate({
      variables: {
        firstName,
        lastName,
        email,
        password,
      },
    });
  };

  const handleEditAdminUser = (id: string, firstName: string, lastName: string, email: string) => {
    updateUserMutate({
      variables: {
        id,
        firstName,
        lastName,
        email,
      },
    });
  };

  const handleDeleteAdminUser = (id: string) => {
    deleteUserMutate({
      variables: {
        id,
      },
    });
  };

  const handleResetPassword = (id: string, newPassword: string) => {
    resetPasswordMutate({
      variables: {
        id,
        newPassword,
      },
    });
  };

  return (
    <AdminUserList
      isAdminUsersLoading={getAdminUsersStatus.loading}
      adminUsers={adminUsers && adminUsers.adminUsers}
      onAddAdminUser={handleAddAdminUser}
      addUserStatus={addUserStatus}
      onEditAdminUser={handleEditAdminUser}
      editUserStatus={updateUserStatus}
      onDeleteAdminUser={handleDeleteAdminUser}
      deleteUserStatus={deleteUserStatus}
      onResetPassword={handleResetPassword}
      resetPasswordStatus={resetPasswordStatus}
    />
  );
};

export default AdminUsersListContainer;
