import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddLicence, AddLicenceVariables } from "./__generated__/AddLicence";
import { CORE_LICENCE_FIELDS } from "../fragments/Licence";

const ADD_PROJECT = gql`
  ${CORE_LICENCE_FIELDS}
  mutation AddLicence(
    $startDate: DateTime!
    $endDate: DateTime!
    $numberOfUsers: Int!
    $comment: String
    $organizationId: String!
  ) {
    addLicence(
      input: {
        startDate: $startDate
        endDate: $endDate
        numberOfUsers: $numberOfUsers
        comment: $comment
        organizationId: $organizationId
      }
    ) {
      licence {
        ...CoreLicenceFields
      }
    }
  }
`;

export function useAddLicence() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<AddLicence, AddLicenceVariables>(
    ADD_PROJECT,
    {
      errorPolicy: "all",
    }
  );
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
