import React from "react";
import { Button, Menu, Table } from "semantic-ui-react";
import moment from "moment";
import ServerFileDownloadButton from "./common/ServerFileDownloadButton";
import { OrgUserFile_AndInput } from "../models/OrgUserFile_AndInput";

type Props = {
  orgUserFiles?: OrgUserFile_AndInput[];
  onAddOrgUserFile: () => void;
  onDeleteOrgUserFile: (orgUserFile: OrgUserFile_AndInput) => void;
};

const OrgUserFilesList = ({ orgUserFiles, onAddOrgUserFile, onDeleteOrgUserFile }: Props) => {
  return (
    <>
      <Menu borderless compact secondary color="green">
        <Menu.Item icon="plus" name="New File" onClick={onAddOrgUserFile} style={{ marginLeft: 0 }} />
      </Menu>

      <Table basic="very" compact style={{ marginTop: 0 }}>
        <Table.Body>
          {orgUserFiles?.map((orgUserFile) => (
            <Table.Row key="2">
              <Table.Cell>{orgUserFile.title}</Table.Cell>
              <Table.Cell>
                {orgUserFile.expiryDate ? "Expires on: " + moment(orgUserFile.expiryDate).format("L") : ""}
              </Table.Cell>
              <Table.Cell textAlign="right" style={{ padding: 0 }}>
                {orgUserFile.fileUrl && (
                  <ServerFileDownloadButton fileUrl={orgUserFile.fileUrl}></ServerFileDownloadButton>
                )}
                <Button
                  circular
                  compact
                  icon="delete"
                  size="mini"
                  onClick={() => onDeleteOrgUserFile(orgUserFile)}
                ></Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default OrgUserFilesList;
