import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { AddUserToOrganization, AddUserToOrganizationVariables } from "./__generated__/AddUserToOrganization";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";

const ADD_USER_TO_ORGANIZATION = gql`
  ${ORG_USER_FIELDS}
  mutation AddUserToOrganization(
    $userId: String!
    $organizationId: String!
    $isAdmin: Boolean!
    $phoneNumber: String
    $calibrationCertificate: CalibrationCertificateInput
    $orgUserFiles: [OrgUserFileInput]
  ) {
    addUserToOrganization(
      input: {
        userId: $userId
        organizationId: $organizationId
        isAdmin: $isAdmin
        phoneNumber: $phoneNumber
        calibrationCertificate: $calibrationCertificate
        orgUserFiles: $orgUserFiles
      }
    ) {
      orgUser {
        ...OrgUserFields
        orgUserFiles {
          id
          title
          expiryDate
          fileUrl
        }
      }
    }
  }
`;

export function useAddUserToOrganization() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddUserToOrganization,
    AddUserToOrganizationVariables
  >(ADD_USER_TO_ORGANIZATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
