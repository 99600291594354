import { GetPermitToDig_permitToDig } from "../operations/queries/__generated__/GetPermitToDig";
import { PermitToDigTrigger } from "./globalTypes";

export interface Permit extends GetPermitToDig_permitToDig {}

export const triggerSavePermitted = (permit: Permit) => permit.permittedTriggers.includes(PermitToDigTrigger.SAVE);

export const triggerSubmitPermitted = (permit: Permit) => permit.permittedTriggers.includes(PermitToDigTrigger.SUBMIT);

export const triggerApproveOrRejectPermitted = (permit: Permit) =>
  permit.permittedTriggers.includes(PermitToDigTrigger.APPROVE_REQUEST) ||
  permit.permittedTriggers.includes(PermitToDigTrigger.REJECT_REQUEST);

export const triggerRequestPermitted = (permit: Permit) =>
  permit.permittedTriggers.includes(PermitToDigTrigger.REQUEST);

export const triggerExtendPermitted = (permit: Permit) => permit.permittedTriggers.includes(PermitToDigTrigger.EXTEND);

export const triggerCompletePermitted = (permit: Permit) =>
  permit.permittedTriggers.includes(PermitToDigTrigger.COMPLETE);

export const triggerCancelPermitted = (permit: Permit) => permit.permittedTriggers.includes(PermitToDigTrigger.CANCEL);

export const triggerUndoCancellationPermitted = (permit: Permit) =>
  permit.permittedTriggers.includes(PermitToDigTrigger.UNDO_CANCELLATION);
