import { useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import { Button } from "semantic-ui-react";

interface Props {
  fileUrl: string;
}

function ServerFileDownloadButton({ fileUrl }: Props) {
  const [token, setToken] = useState<string | undefined>(undefined);

  const handleDownloadClicked = () => {
    if (fileUrl && token) {
      var filename = "";
      const fileUrlForDownload = `${fileUrl}&download=true`;
      fetch(fileUrlForDownload, {
        method: "GET",
        headers: { authorization: token ? `Bearer ${token}` : "" },
      })
        .then((response) => {
          // extracting filename from content-diposition
          var disposition = response.headers.get("Content-Disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }

          return response.blob();
        })
        .then((fileBlob) => {
          const url = window.URL.createObjectURL(new Blob([fileBlob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode?.removeChild(link);
        });
    }
  };

  useEffect(() => {
    async function getToken() {
      const val = await authService.getAccessToken();

      if (val) {
        setToken(val);
      }
    }

    getToken();
  }, []);

  return <Button circular compact icon="download" size="mini" onClick={handleDownloadClicked}></Button>;
}

export default ServerFileDownloadButton;
