import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { DeleteProject, DeleteProjectVariables } from "./__generated__/DeleteProject";

const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: String!) {
    deleteProject(input: { projectId: $projectId }) {
      project {
        id
        name
        number
        location
        organization {
          id
        }
      }
    }
  }
`;

export function useDeleteProject() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteProject,
    DeleteProjectVariables
  >(DELETE_PROJECT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
