import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import {
  RemoveUserFromOrganization,
  RemoveUserFromOrganizationVariables,
} from "./__generated__/RemoveUserFromOrganization";

const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation RemoveUserFromOrganization($userId: String!, $organizationId: String!) {
    removeUserFromOrganization(input: { userId: $userId, organizationId: $organizationId }) {
      orgUserId
    }
  }
`;

export function useRemoveUserFromOrganization() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    RemoveUserFromOrganization,
    RemoveUserFromOrganizationVariables
  >(REMOVE_USER_FROM_ORGANIZATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
