import { Table } from "semantic-ui-react";
import { OrgUser } from "../models/OrgUser";
import GridActionButton from "./GridActionButton";

interface Props {
  orgUsers?: OrgUser[];
  onEditOrgUser: (orgUserId: string) => void;
  onDeleteOrgUser: (orgUserId: string) => void;
  onResetPasswordOrgUser: (orgUserId: string) => void;
  onToggleArchivedStatusOrgUser: (orgUserId: string) => void;
}

const OrgUsersGrid = ({
  onEditOrgUser,
  onDeleteOrgUser,
  onResetPasswordOrgUser,
  orgUsers,
  onToggleArchivedStatusOrgUser,
}: Props) => {
  const renderOrgUserTableRow = () => {
    if (orgUsers) {
      return orgUsers.map((orgUser) => (
        <Table.Row key={orgUser.id}>
          <Table.Cell>
            {orgUser.user.displayName}
            <GridActionButton
              rowId={orgUser.id}
              items={[
                ...[
                  { key: "edit", icon: "edit", content: "Edit", callback: onEditOrgUser },
                  {
                    key: "resetPassword",
                    icon: "exchange",
                    content: "Reset Password",
                    callback: onResetPasswordOrgUser,
                  },
                  {
                    key: "delete",
                    icon: "delete",
                    content: "Delete",
                    callback: onDeleteOrgUser,
                  },
                ],
                {
                  key: orgUser.isArchived ? "Activate" : "Archive",
                  icon: orgUser.isArchived ? "undo" : "archive",
                  content: orgUser.isArchived ? "Activate" : "Archive",
                  callback: () => onToggleArchivedStatusOrgUser(orgUser.id),
                },
              ]}
            />
          </Table.Cell>
          <Table.Cell>{orgUser.user.email}</Table.Cell>
          <Table.Cell>{orgUser.isArchived ? "Archived" : "Active"}</Table.Cell>
        </Table.Row>
      ));
    }
  };

  return (
    <>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderOrgUserTableRow()}</Table.Body>

        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default OrgUsersGrid;
