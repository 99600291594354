import { gql } from "@apollo/client";
import { CORE_ORGANIZATION_FIELDS } from "./organization";

export const CORE_PROJECT_FIELDS = gql`
  ${CORE_ORGANIZATION_FIELDS}
  fragment CoreProjectFields on Project {
    id
    name
    number
    location
    boundaries {
      coordinates
      crs
      type
    }
    emergencyContact {
      name
      phoneNumber
    }
    organization {
      ...CoreOrganizationFields
    }
  }
`;
