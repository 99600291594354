import { Button, Card, Label } from "semantic-ui-react";
import { ProjectAssignment } from "../models/ProjectAssignment";

interface Props {
  projectAssignments?: ProjectAssignment[];
  onDeleteProjectAssignment: (projectAssignmentId: string) => void;
  deleteProjectAssignmentInProgress: boolean;
}

const ProjectAssignmentsGrid = ({
  onDeleteProjectAssignment,
  deleteProjectAssignmentInProgress,
  projectAssignments,
}: Props) => {
  const renderProjectAssignmentCard = (a: ProjectAssignment) => {
    return (
      <Card fluid content style={{ "box-shadow": "0px 0px", "margin-top": "0px" }}>
        <Card.Content
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          <Button
            circular
            icon="close"
            floated="right"
            style={{ padding: "2px", "box-shadow": "0px 0px" }}
            onClick={() => {
              onDeleteProjectAssignment(a.id);
            }}
            loading={deleteProjectAssignmentInProgress}
            disabled={deleteProjectAssignmentInProgress}
          ></Button>
          {a.orgUser.user.displayName}
          <p>{a.orgUser.user.email}</p>
        </Card.Content>
      </Card>
    );
  };

  const renderProjectAssignments = (role: string) => {
    const assignments = projectAssignments?.filter((a) => a.orgRole.displayName === role);

    return (
      <>
        <Card fluid style={{ "box-shadow": "0px 0px" }}>
          <Label ribbon style={{ marginRight: "-28px" }}>
            {role}
          </Label>
        </Card>

        {assignments && assignments.map(renderProjectAssignmentCard)}
      </>
    );
  };

  const existingRole = Array.from(new Set(projectAssignments?.map((a) => a.orgRole.displayName)));

  return <Card.Group>{existingRole.map(renderProjectAssignments)}</Card.Group>;
};

export default ProjectAssignmentsGrid;
