/* 

import { makeVar } from "@apollo/client";

//implementation
export const currentProjectIdVar = makeVar(0);

//Usage
import { currentProjectIdVar } from "../../utils/cache";
currentProjectIdVar(project.id);
currentProjectIdVar(); 

*/

import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        feed: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

export { cache };
