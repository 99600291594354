import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import {
  GetPermitToDigsByProjectId,
  GetPermitToDigsByProjectIdVariables,
} from "./__generated__/GetPermitToDigsByProjectId";
import { PERMIT_TO_DIG_LIST_FIELDS } from "../fragments/permitToDig";

export const GET_PERMIT_TO_DIGS_BY_PROJECT_ID = gql`
  ${PERMIT_TO_DIG_LIST_FIELDS}
  query GetPermitToDigsByProjectId(
    $projectId: String!
    $searchString: String
    $skip: Int
    $take: Int
    $stateKey: StateKey
    $assignedToId: String
    $createdById: String
  ) {
    permitToDigsByProjectId(
      projectId: $projectId
      searchString: $searchString
      skip: $skip
      take: $take
      stateKey: $stateKey
      assignedToId: $assignedToId
      createdById: $createdById
    ) {
      totalCount
      items {
        ...PermitToDigListFields
      }
    }
  }
`;

export function useLazyGetPermitToDigsByProjectId() {
  const [getPermitToDigsByProjectId, { loading, error, data, refetch }] = useLazyQuery<
    GetPermitToDigsByProjectId,
    GetPermitToDigsByProjectIdVariables
  >(GET_PERMIT_TO_DIGS_BY_PROJECT_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getPermitToDigsByProjectId,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
    refetch,
  };
}
