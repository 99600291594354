import moment from "moment";

const trancateTime = (date: Date): Date => {
  return moment.utc(moment(date).format("YYYY-MM-DD")).toDate();
};

const isAfter = (firstDate: Date, secondDate: Date): boolean => {
  var firstOnlyDate = moment(moment(firstDate).format("LL"));
  var secondOnlyDate = moment(moment(secondDate).format("LL"));
  const result = firstOnlyDate.isAfter(secondOnlyDate, "day");
  return result;
};

const isBetween = (dateToCheck: Date, startDate: Date, endDate: Date): boolean => {
  var dateOnlyToCheck = moment(moment(dateToCheck).format("LL"));
  var startOnlyDate = moment(moment(startDate).format("LL"));
  var endOnlyDate = moment(moment(endDate).format("LL"));
  const result = dateOnlyToCheck.isBetween(startOnlyDate, endOnlyDate, "day", "[]");
  return result;
};

export { trancateTime, isAfter, isBetween };
