import { Message } from "semantic-ui-react";

interface Props {
  errors: string[] | undefined;
}

const ErrorsView = ({ errors }: Props) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return <Message error list={errors}></Message>;
};

export default ErrorsView;
