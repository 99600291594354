import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import {
  GetDocumentTypesByOrganizationId,
  GetDocumentTypesByOrganizationIdVariables,
} from "./__generated__/GetDocumentTypesByOrganizationId";

export const GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID = gql`
  query GetDocumentTypesByOrganizationId($organizationId: String!) {
    documentTypesByOrganizationId(organizationId: $organizationId) {
      id
      name
      key
      declaration
      isMandatory
      isArchived
    }
  }
`;

export function useLazyGetDocumentTypesByOrganizationId() {
  const [getDocumentTypesByOrganizationId, { loading, error, data }] = useLazyQuery<
    GetDocumentTypesByOrganizationId,
    GetDocumentTypesByOrganizationIdVariables
  >(GET_DOCUMENT_TYPES_BY_ORGANIZATION_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getDocumentTypesByOrganizationId,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
