import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddPermitToDig, AddPermitToDigVariables } from "./__generated__/AddPermitToDig";

const ADD_PERMIT = gql`
  mutation AddPermitToDig(
    $permitDataInput: PermitDataInput
    $number: Int!
    $projectId: String!
    $documentIds: [String!]
    $asBuiltRequired: Boolean!
    $boundaries: GeoJSONPolygonInput
    $permitAssignmentInputs: [PermitAssignmentInput!]
  ) {
    addPermitToDig(
      input: {
        permitDataInput: $permitDataInput
        number: $number
        projectId: $projectId
        documentIds: $documentIds
        asBuiltRequired: $asBuiltRequired
        boundaries: $boundaries
        permitAssignmentInputs: $permitAssignmentInputs
      }
    ) {
      permitToDig {
        id
      }
    }
  }
`;

export function useAddPermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddPermitToDig,
    AddPermitToDigVariables
  >(ADD_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
