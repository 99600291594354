import { uniq } from "lodash";
import { Menu } from "semantic-ui-react";

interface Props {
  items: any[];
  charSelector: (item: any) => string;
  onSelect: (char: string) => void;
  selectedChar: string;
}

const PhoneBookFilter = ({ items, charSelector, onSelect, selectedChar }: Props) => {
  const availableChars = uniq(items.map((item) => charSelector(item)));

  const allChars = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const handleClickChar = (e: any, val: any) => {
    onSelect(val.name);
  };

  const handleClickAll = (e: any, val: any) => {
    onSelect("");
  };

  return (
    <Menu pointing secondary color="green" size="tiny" style={{ justifyContent: "center" }}>
      <Menu.Item name="All" active={!selectedChar} onClick={handleClickAll} />
      {allChars.map((char) => (
        <Menu.Item
          name={char}
          key={char}
          active={char === selectedChar}
          disabled={availableChars.indexOf(char) < 0}
          onClick={handleClickChar}
        />
      ))}
    </Menu>
  );
};

export default PhoneBookFilter;
