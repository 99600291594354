import { useEffect, useState } from "react";
import { useLazyGetProjectPolicies } from "../operations/queries/getProjectPolicies";
import { useCurrentProject } from "../hooks/useCurrentProject";
import {
  ADD_PERMIT,
  APPROVE_PERMIT_REQUEST,
  EXTEND_PERMIT,
  REQUEST_PERMIT,
  COMPLETE_PERMIT,
  CANCEL_PERMIT,
  CANCEL_OWN_PERMIT,
  UNDO_CANCELLATION_PERMIT,
  UNDO_CANCELLATION_OWN_PERMIT,
  ADD_DOCUMENT,
  ADD_NEW_DOCUMENT_VERSION,
  DELETE_DOCUMENT,
} from "../models/Policies";

export function useProjectPolicies() {
  const [projectPolicies, setProjectPolicies] = useState<string[]>();
  const { currentProject } = useCurrentProject();
  const { getProjectPolicies, data } = useLazyGetProjectPolicies();

  useEffect(() => {
    if (currentProject) {
      getProjectPolicies({ variables: { projectId: currentProject.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (data) {
      setProjectPolicies(data.projectPolicies);
    }
  }, [data]);

  const authorize = (policy: string): boolean => {
    return projectPolicies ? projectPolicies.includes(policy) : false;
  };

  const authorizedToApprovePermit = authorize(APPROVE_PERMIT_REQUEST.name);
  const authorizedToAddPermit = authorize(ADD_PERMIT.name);
  const authorizedToRequestPermit = authorize(REQUEST_PERMIT.name);
  const authorizedToExtendPermit = authorize(EXTEND_PERMIT.name);
  const authorizedToCompletePermit = authorize(COMPLETE_PERMIT.name);
  const authorizedToCancelOwnPermit = authorize(CANCEL_OWN_PERMIT.name);
  const authorizedToCancelPermit = authorize(CANCEL_PERMIT.name);
  const authorizedToUndoCancellationOwnPermit = authorize(UNDO_CANCELLATION_OWN_PERMIT.name);
  const authorizedToUndoCancellationPermit = authorize(UNDO_CANCELLATION_PERMIT.name);
  const authorizedToAddDocument = authorize(ADD_DOCUMENT.name);
  const authorizedToAddNewDocumentVersion = authorize(ADD_NEW_DOCUMENT_VERSION.name);
  const authorizedToDeleteDocument = authorize(DELETE_DOCUMENT.name);

  return {
    projectPolicies,
    authorize,
    authorizedToApprovePermit,
    authorizedToAddPermit,
    authorizedToRequestPermit,
    authorizedToExtendPermit,
    authorizedToCompletePermit,
    authorizedToCancelOwnPermit,
    authorizedToCancelPermit,
    authorizedToUndoCancellationOwnPermit,
    authorizedToUndoCancellationPermit,
    authorizedToAddDocument,
    authorizedToAddNewDocumentVersion,
    authorizedToDeleteDocument,
  };
}
