import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import AddOrgUserForm, { FormData } from "./AddOrgUserForm";
import { FormError } from "../models/FormError";
import { User } from "../models/User";
import { CalibrationCertificate } from "../models/CalibrationCertificate";
import { trancateTime } from "../utils";
import { OrgUserFileInput } from "../models/globalTypes";
import AddOrgUserFileModalContainer from "../containers/AddOrgUserFileModalContainer";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    orgUserFiles: OrgUserFileInput[]
  ) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  onEmailEntered: (email: string) => void;
  user?: User;
}

const AddOrgUserModal = ({ user, open, onClose, onSubmit, submitInProgress, submitErrors, onEmailEntered }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [orgUserFiles, setOrgUserFiles] = useState<OrgUserFileInput[]>([]);
  const [openAddOrgUserFileModal, setOpenAddOrgUserFileModal] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({ email: user.email, firstName: "", lastName: "", isAdmin: false } as FormData);
    } else {
      setFormData({} as FormData);
    }
    setFormErrors({} as FormError);
    setHasSubmitted(false);
  }, [open, user]);

  const validateFormData = (formData: FormData): FormError => {
    const formError: FormError = {};

    const emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (user) {
      return formError;
    }

    if (!formData.password) {
      formError.password = "Please enter password";
    }

    if (!formData.repeatPassword) {
      formError.repeatPassword = "Please enter password again";
    } else if (formData.password !== formData.repeatPassword) {
      formError.repeatPassword = "Passwords don't match";
    }

    if (!formData.email) {
      formError.email = "Please enter email address";
    } else if (!emailPattern.test(formData.email)) {
      formError.email = "Email address format is not correct";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const {
        password,
        firstName,
        lastName,
        email,
        isAdmin,
        phoneNumber,
        CatSerialNumber,
        CatCalibrationDate,
        GennySerialNumber,
        GennyCalibrationDate,
      } = formData;
      onSubmit(
        firstName,
        lastName,
        email,
        password,
        isAdmin || false,
        phoneNumber,
        {
          catSerialNumber: CatSerialNumber,
          catCalibrationDate: CatCalibrationDate ? trancateTime(CatCalibrationDate) : null,
          gennySerialNumber: GennySerialNumber,
          gennyCalibrationDate: GennyCalibrationDate ? trancateTime(GennyCalibrationDate) : null,
        } as CalibrationCertificate,
        orgUserFiles
      );
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handleOrgUserFileAdded = (orgUserFile: OrgUserFileInput) => {
    setOrgUserFiles([...orgUserFiles, orgUserFile]);
    setOpenAddOrgUserFileModal(false);
  };

  const handleDeleteOrgUserFile = (orgUserFile: OrgUserFileInput) => {
    setOrgUserFiles([...orgUserFiles.filter((f) => f !== orgUserFile)]);
  };

  const handelClose = () => {
    setOrgUserFiles([]);
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={handelClose}>
        <Modal.Content>
          <Modal.Description>
            <AddOrgUserForm
              user={user}
              formErrors={formErrors}
              errors={submitErrors}
              formData={formData}
              onChange={handleFormChange}
              onEmailEntered={onEmailEntered}
              onAddOrgUserFile={() => setOpenAddOrgUserFileModal(true)}
              onDeleteOrgUserFile={handleDeleteOrgUserFile}
              orgUserFiles={orgUserFiles}
            ></AddOrgUserForm>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handelClose}>Cancel</Button>
          <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      <AddOrgUserFileModalContainer
        open={openAddOrgUserFileModal}
        onOrgUserFileAdded={handleOrgUserFileAdded}
        onClose={() => setOpenAddOrgUserFileModal(false)}
      ></AddOrgUserFileModalContainer>
    </>
  );
};

export default AddOrgUserModal;
