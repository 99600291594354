import moment from "moment";
import { Card, Icon, Segment, Grid } from "semantic-ui-react";
import { PermitToDigSignatureType } from "../models/globalTypes";
import { Permit } from "../models/Permit";
import ViewItem from "./common/ViewItem";
import ServerImage from "../components/common/ServerImage";
import { OrgRole } from "../models/OrgRole";

interface Props {
  permit: Permit;
}

const PermitAuthorizationView = ({ permit }: Props) => {
  const getSignatureFor = (orgRole: OrgRole) =>
    permit.signatures.filter(
      (s) => s.type === PermitToDigSignatureType.PERMIT_AUTHORIZATION && s.orgRole?.id === orgRole.id
    )[0];

  return (
    <>
      <Segment basic>
        {permit.signatures
          .filter((s) => s.type === PermitToDigSignatureType.PERMIT_AUTHORIZATION)
          .map((signature) => (
            <Card fluid>
              <Card.Content>
                <Card.Header>{signature.signatory?.roleName}</Card.Header>
                <Card.Description>
                  <Icon name={signature ? "check square outline" : "square outline"} />
                  {signature.declaration}
                  <Grid columns="2">
                    <Grid.Row>
                      <Grid.Column>
                        <ViewItem header="Name" value={signature.signatory?.name} />
                      </Grid.Column>
                      <Grid.Column>
                        <ViewItem
                          header="Signature"
                          value={signature?.dateSignedUtc ? moment(signature?.dateSignedUtc)?.format("lll") : "-"}
                        />
                        {signature?.signatureUrl && <ServerImage size="small" src={signature?.signatureUrl} />}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        {permit.permitAssignments
          .filter((a) => a.orgRole.permitRoleSettings.shouldAuthorizePermit)
          .filter((a) => !permit.signatures.find((s) => s.orgRole?.id === a.orgRole.id))
          .map((a) => {
            const signature = getSignatureFor(a.orgRole);
            return (
              <Card fluid>
                <Card.Content>
                  <Card.Header>{a.orgRole.displayName}</Card.Header>
                  <Card.Description>
                    <Icon name={signature ? "check square outline" : "square outline"} />
                    {a.orgRole.permitRoleSettings.authorizationDeclaration}
                    <Grid columns="2">
                      <Grid.Row>
                        <Grid.Column>
                          <ViewItem header="Name" value={a.orgUser ? a.orgUser.user.displayName : "not assigned"} />
                        </Grid.Column>
                        <Grid.Column>
                          <ViewItem
                            header="Signature"
                            value={signature?.dateSignedUtc ? moment(signature?.dateSignedUtc)?.format("lll") : "-"}
                          />
                          {signature?.signatureUrl && <ServerImage size="small" src={signature?.signatureUrl} />}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
      </Segment>
    </>
  );
};

export default PermitAuthorizationView;
