import LayoutWithSidebar from "../components/LayoutWithSidebar";
import MainSideMenu from "../components/MainSideMenu";
import ProjectsListContainer from "../containers/ProjectsListContainer";
import MainHeaderContainer from "../containers/MainHeaderContainer";

const ProjectPage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <MainSideMenu></MainSideMenu>;
  };

  const contentPanelContent = () => {
    return <ProjectsListContainer></ProjectsListContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default ProjectPage;
