import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { DeleteReferenceProcedure, DeleteReferenceProcedureVariables } from "./__generated__/DeleteReferenceProcedure";
import { CORE_REFERENCE_PROCEDURE_FIELDS } from "../fragments/ReferenceProcedure";

const DELETE_REFERENCE_PROCEDURE = gql`
  ${CORE_REFERENCE_PROCEDURE_FIELDS}
  mutation DeleteReferenceProcedure($id: String!) {
    deleteReferenceProcedure(input: { id: $id }) {
      referenceProcedure {
        ...CoreReferenceProcedureFields
      }
    }
  }
`;

export function useDeleteReferenceProcedure() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteReferenceProcedure,
    DeleteReferenceProcedureVariables
  >(DELETE_REFERENCE_PROCEDURE, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
