import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";
import { GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID } from "../queries/getProjectAssignmentsByProjectId";
import { GetProjectAssignmentsByProjectId } from "../queries/__generated__/GetProjectAssignmentsByProjectId";
import { useResettableMutation } from "../useResettableMutation";
import { AddProjectAssignments, AddProjectAssignmentsVariables } from "./__generated__/AddProjectAssignments";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";

const ADD_PROJECT_ASSIGNMENTS = gql`
  ${ORG_ROLE_FIELDS}
  mutation AddProjectAssignments($orgUserIds: [String!], $projectId: String!, $orgRoleId: String!) {
    addProjectAssignments(input: { orgUserIds: $orgUserIds, projectId: $projectId, orgRoleId: $orgRoleId }) {
      projectAssignments {
        id
        orgRole {
          ...OrgRoleFields
        }
        orgUser {
          id
          userId
          user {
            id
            email
            firstName
            lastName
          }
          organization {
            id
            name
          }
        }
        project {
          id
          name
          number
          location
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export function useAddProjectAssignments() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddProjectAssignments,
    AddProjectAssignmentsVariables
  >(ADD_PROJECT_ASSIGNMENTS, {
    update(cache, { data }) {
      const newProjectAssignmentsFromResponse = data?.addProjectAssignments?.projectAssignments;
      if (newProjectAssignmentsFromResponse && newProjectAssignmentsFromResponse.length > 0) {
        const projectId = newProjectAssignmentsFromResponse[0]?.project.id;
        const existingProjectAssignments = cache.readQuery<GetProjectAssignmentsByProjectId>({
          query: GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID,
          variables: {
            projectId,
          },
        });

        if (existingProjectAssignments) {
          cache.writeQuery({
            query: GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID,
            variables: {
              projectId,
            },
            data: {
              projectAssignmentsByProjectId: [
                ...existingProjectAssignments.projectAssignmentsByProjectId,
                ...newProjectAssignmentsFromResponse,
              ],
            },
          });
        }
      }
    },
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
