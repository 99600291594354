import { useState, useEffect } from "react";
import { Segment, Menu, Header, Grid, Divider, Icon, Loader } from "semantic-ui-react";
import PermitAttachmentBar from "./PermitAttachmentBar";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { FormError } from "../models/FormError";
import AddPermitForm, { FormData } from "./AddPermitForm";
import { trancateTime, toastError, toastSuccess, isAfter } from "../utils";
import { useHistory, useParams } from "react-router-dom";
import { Permit } from "../models/Permit";
import { PermitDataInput } from "../models/PermitDataInput";
import DocumentView from "./DocumentView";
import { AffectedServicesOption } from "../models/AffectedServicesOption";
import { Document_Data } from "../models/Document_Data";
import PermitTeamForm, { FormData as PermitTeamFormData } from "./PermitTeamForm";
import { ProjectAssignment } from "../models/ProjectAssignment";
import moment from "moment";
import PermitMap from "./PermitMap";
import MapWithBoundaries from "./MapWithBoundaries";
import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";
import { PermitAssignmentInput } from "../models/PermitAssignmentInput";
import { OrgRole } from "../models/OrgRole";
import { PermitAssignment } from "../models/PermitAssignment";
import { Project } from "../models/Project";
import { MapItem, createMapItem } from "../utils/arcgisUtils";
import { DocumentType } from "../models/DocumentType";
import PermitSubmissionModal from "./PermitSubmissionModal";

interface Props {
  showPermitBoundariesOnMap?: boolean;
  orgRoles?: OrgRole[];
  submitButtonVisible?: boolean;
  saveButtonVisible?: boolean;
  submissionDeclaration?: string[] | null;
  permit?: Permit;
  currentProject?: Project;
  affectedServicesOptions?: AffectedServicesOption[];
  projectAssignments?: ProjectAssignment[];
  nextPermitNumber?: number;
  mandatoryDocTypes?: DocumentType[];
  onAddPermit: (
    permitDataInput: PermitDataInput,
    number: number,
    documentIds: string[],
    asBuiltRequired: boolean,
    boundaries: GeoJSONPolygonType,
    permitAssignments: PermitAssignmentInput[]
  ) => void;
  addPermitStatus: AsyncActionStatus;
  onEditPermit: (
    id: string,
    permitDataInput: PermitDataInput,
    documentIds: string[],
    asBuiltRequired: boolean,
    boundaries: GeoJSONPolygonType,
    permitAssignments: PermitAssignmentInput[]
  ) => void;
  editPermitStatus: AsyncActionStatus;
  onSubmitPermit: (
    id: string,
    permitDataInput: PermitDataInput,
    number: number,
    documentIds: string[],
    asBuiltRequired: boolean,
    boundaries: GeoJSONPolygonType,
    permitAssignments: PermitAssignmentInput[]
  ) => void;
  submitPermitStatus: AsyncActionStatus;
}

const AddPermit = ({
  showPermitBoundariesOnMap,
  orgRoles,
  saveButtonVisible,
  submitButtonVisible,
  submissionDeclaration,
  permit,
  currentProject,
  affectedServicesOptions,
  projectAssignments,
  mandatoryDocTypes,
  nextPermitNumber,
  onAddPermit,
  addPermitStatus,
  onEditPermit,
  editPermitStatus,
  onSubmitPermit,
  submitPermitStatus,
}: Props) => {
  const history = useHistory();

  const { orgName } = useParams<{ orgName: string }>();
  const { projectName } = useParams<{ projectName: string }>();
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [permitTeamFormData, setPermitTeamFormData] = useState<PermitTeamFormData>({
    permitAssignments: [],
  } as PermitTeamFormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [permitTeamFormErrors, setPermitTeamFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [previewDoc, setPreviewDoc] = useState<Document_Data | undefined>(undefined);
  const [documents, setDocuments] = useState<Document_Data[]>([]);
  const [documentErrors, setDocumentErrors] = useState<FormError>({});
  const [previewBoundaries, setPreviewBoundaries] = useState(true);
  const [projectBoundaries, setProjectBoundaries] = useState<MapItem[]>([]);
  const [boundaries, setBoundaries] = useState<GeoJSONPolygonType | null>();
  const [showMapLegendSelected, setShowMapLegendSelected] = useState(false);
  const [showMapLayersListSelected, setShowMapLayersListSelected] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [declarationInitialized, setDeclarationInitialized] = useState(false);
  const [declarations, setDeclarations] = useState<
    { index: number; text: string; value: boolean; readonly: boolean }[]
  >([]);

  useEffect(() => {
    if (declarationInitialized) {
      return;
    }

    var allDeclarations = new Array();

    if (mandatoryDocTypes && submissionDeclaration) {
      allDeclarations = mandatoryDocTypes
        .filter((t) => t.declaration)
        .map((t, i) => {
          return { index: i, text: t.declaration, value: true, readonly: true };
        });

      const declarationLength = allDeclarations.length;
      allDeclarations.push(
        ...submissionDeclaration.map((d, i) => {
          return { index: i + declarationLength, text: d, value: false, readonly: false };
        })
      );

      setDeclarationInitialized(true);
    }

    setDeclarations(allDeclarations);
  }, [submissionDeclaration, mandatoryDocTypes]);

  useEffect(() => {
    if (permit) {
      setFormData({
        id: permit.id,
        number: permit.number,
        comments: permit.comments,
        contractor: permit.contractor,
        subContractor: permit.subContractor,
        locationOfWork: permit.locationOfWork,
        permittedEquipment: permit.permittedEquipment,
        worksDescription: permit.worksDescription,
        maxPenetrationDepth: permit.maxPenetrationDepth,
        from: new Date(permit.validityDuration?.from),
        to: new Date(permit.validityDuration?.to),
        hoursFrom: permit.validityDuration?.hoursFrom,
        hoursTo: permit.validityDuration?.hoursTo,
        noAffectedServices: permit.noAffectedServices,
        affectedServices: permit.affectedServices,
        asBuiltRequired: permit.asBuiltRequired,
      } as FormData);
      setPermitTeamFormData({
        permitAssignments: permit.permitAssignments,
      } as PermitTeamFormData);
      setDocuments(permit.documents);
      setBoundaries(permit.boundaries);
    } else if (nextPermitNumber) {
      setFormData({
        number: nextPermitNumber,
        hoursFrom: moment.duration("07:00").toISOString(),
        hoursTo: moment.duration("17:00").toISOString(),
        noAffectedServices: false,
      } as FormData);
      const permitAssignments = orgRoles
        ?.filter((r) => r.permitRoleSettings.isPermitAssignee)
        .map((r) => {
          return { orgRole: r, orgUser: null } as PermitAssignment;
        });
      setPermitTeamFormData({
        permitAssignments: permitAssignments,
      } as PermitTeamFormData);
    }
    setFormErrors({} as FormError);
    setHasSubmitted(false);
    addPermitStatus.reset();
    editPermitStatus.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permit, nextPermitNumber]);

  const validatePermitTeamFormData = (formData: PermitTeamFormData) => {
    const formError: FormError = {};

    formData.permitAssignments.forEach((assignment) => {
      if (assignment.orgRole.permitRoleSettings.mandatoryOnSubmission && !assignment.orgUser) {
        formError[`permitAssignment_${assignment.orgRole.id}`] = `Please select '${assignment.orgRole.displayName}'`;
      }
    });

    return formError;
  };

  const validateDocuments = () => {
    const docError: FormError = {};

    if (!(documents.length > 0)) {
      docError.any = "Please add permit documents for submission";
    }

    mandatoryDocTypes?.forEach((docType) => {
      if (!documents.find((d) => d.type.key === docType.key)) {
        docError[docType.key] = `Please attach the required '${docType.name}' within the required documents section.`;
      }
    });

    return docError;
  };

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (formData.number === undefined) {
      formError.number = "Please enter permit number";
    }

    if (!formData.contractor) {
      formError.contractor = "Please enter contractor";
    }

    if (!formData.worksDescription) {
      formError.worksDescription = "Please enter work description";
    }

    if (!formData.locationOfWork) {
      formError.locationOfWork = "Please enter location of work";
    }

    if (!formData.from) {
      formError.from = "Please enter date";
    }

    if (!formData.to) {
      formError.to = "Please enter date";
    }

    if (formData.from && formData.to && isAfter(formData.from, formData.to)) {
      formError.to = "To date cannot be before from date";
    }

    if (formData.hoursFrom === undefined) {
      formError.hoursFrom = "Please enter hours";
    }

    if (formData.hoursTo === undefined) {
      formError.hoursTo = "Please enter hours";
    }

    if (!boundaries && showPermitBoundariesOnMap) {
      formError.boundaries = "Please draw permit boundaries on map";
    }

    if (!(formData.affectedServices && formData.affectedServices.length > 0) && !formData.noAffectedServices) {
      formError.affectedServices = "Please select affected services or confirm that there are no affected services";
    }

    return formError;
  };

  useEffect(() => {
    if (currentProject && currentProject.boundaries) {
      setProjectBoundaries([createProjectBoundaries(currentProject)]);
    }
  }, [currentProject]);

  const createProjectBoundaries = (project: Project) => {
    return createMapItem(
      "polyline",
      project.boundaries?.coordinates as number[][][],
      project.id,
      "View project",
      `Project: ${project.name} (${project.number})`,
      "Location of work: " + project.location,
      false,
      { r: 119, g: 226, b: 40 } as __esri.Color
    );
  };

  const handleSave = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    const permitTeamValidationErrors = validatePermitTeamFormData(permitTeamFormData);
    setPermitTeamFormErrors(permitTeamValidationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0 && Object.keys(permitTeamValidationErrors).length === 0) {
      const {
        number,
        comments,
        contractor,
        subContractor,
        locationOfWork,
        permittedEquipment,
        worksDescription,
        maxPenetrationDepth,
        from,
        to,
        hoursFrom,
        hoursTo,
        noAffectedServices,
        affectedServices,
        asBuiltRequired,
      } = formData;

      const { permitAssignments } = permitTeamFormData;
      const permitAssignmentInputs = permitAssignments.map((a) => {
        return { orgRoleId: a.orgRole.id, orgUserId: a.orgUser?.id } as PermitAssignmentInput;
      });

      const dFrom = trancateTime(from!);
      const dTo = trancateTime(to!);

      if (permit) {
        onEditPermit(
          permit.id,
          {
            comments,
            contractor,
            subContractor,
            locationOfWork,
            permittedEquipment,
            worksDescription,
            maxPenetrationDepth,
            from: dFrom,
            to: dTo,
            hoursFrom,
            hoursTo,
            noAffectedServices,
            affectedServices,
          },
          documents?.map((d) => d.id),
          asBuiltRequired || false,
          boundaries!,
          permitAssignmentInputs
        );
      } else {
        onAddPermit(
          {
            comments,
            contractor,
            subContractor,
            locationOfWork,
            permittedEquipment,
            worksDescription,
            maxPenetrationDepth,
            from: dFrom,
            to: dTo,
            hoursFrom,
            hoursTo,
            noAffectedServices,
            affectedServices,
          },
          parseInt(number.toString()),
          documents?.map((d) => d.id),
          asBuiltRequired || false,
          boundaries!,
          permitAssignmentInputs
        );
      }
    }
  };

  const handleSubmitClick = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    const permitTeamValidationErrors = validatePermitTeamFormData(permitTeamFormData);
    setPermitTeamFormErrors(permitTeamValidationErrors);

    const documentValidationErrors = validateDocuments();
    setDocumentErrors(documentValidationErrors);

    if (
      Object.keys(validationErrors).length === 0 &&
      Object.keys(permitTeamValidationErrors).length === 0 &&
      Object.keys(documentValidationErrors).length === 0
    ) {
      setOpenSubmitModal(true);
    }
  };

  const handleSubmit = () => {
    const allDeclarationsChecked = declarations.every((item) => item.value === true);
    if (!allDeclarationsChecked) {
      toastError("All declarations must be approved, if not the permit cannot be submitted");
      return;
    }

    // If there is no validation error
    if (allDeclarationsChecked) {
      const {
        number,
        comments,
        contractor,
        subContractor,
        locationOfWork,
        permittedEquipment,
        worksDescription,
        maxPenetrationDepth,
        from,
        to,
        hoursFrom,
        hoursTo,
        noAffectedServices,
        affectedServices,
        asBuiltRequired,
      } = formData;

      const { permitAssignments } = permitTeamFormData;
      const permitAssignmentInputs = permitAssignments.map((a) => {
        return { orgRoleId: a.orgRole.id, orgUserId: a.orgUser?.id } as PermitAssignmentInput;
      });

      const dFrom = trancateTime(from!);
      const dTo = trancateTime(to!);

      onSubmitPermit(
        permit?.id || "",
        {
          comments,
          contractor,
          subContractor,
          locationOfWork,
          permittedEquipment,
          worksDescription,
          maxPenetrationDepth,
          from: dFrom,
          to: dTo,
          hoursFrom,
          hoursTo,
          noAffectedServices,
          affectedServices,
        },
        parseInt(number.toString()),
        documents?.map((d) => d.id),
        asBuiltRequired || false,
        boundaries!,
        permitAssignmentInputs
      );
    }
  };

  const handleDocClicked = (doc: Document_Data) => {
    setPreviewDoc(doc);
    setPreviewBoundaries(false);
  };

  useEffect(() => {
    if (addPermitStatus.successful) {
      toastSuccess("Permit added successfully!");
      addPermitStatus.reset();
      history.push(`/${orgName}/projects/${projectName}/permits`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPermitStatus.successful]);

  useEffect(() => {
    if (editPermitStatus.successful) {
      toastSuccess("Permit edited successfully!");
      editPermitStatus.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPermitStatus.successful]);

  useEffect(() => {
    if (submitPermitStatus.successful) {
      toastSuccess("Permit submitted successfully!");
      submitPermitStatus.reset();
      history.push(`/${orgName}/projects/${projectName}/permits`);
    } else if (submitPermitStatus.errors && submitPermitStatus.errors.length > 0) {
      toastError(submitPermitStatus.errors[0]);
      //  handleSubmitModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitPermitStatus]);

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handlePermitTeamFormChange = (formData: PermitTeamFormData) => {
    if (hasSubmitted) {
      const validationErrors = validatePermitTeamFormData(formData);
      setPermitTeamFormErrors(validationErrors);
    }

    setPermitTeamFormData({ ...formData });
  };

  const handleAddDoc = (doc: Document_Data) => {
    if (documents.find((d) => d.id === doc.id)) {
      toastError("Selected document already exists");
      return;
    }

    setDocuments([...documents, doc]);
  };

  const handleRemoveDoc = (doc: Document_Data) => {
    setDocuments([...documents.filter((d) => d.id !== doc.id)]);
  };

  const handleBackToListClick = () => {
    history.push(`/${orgName}/projects/${projectName}/permits`);
  };

  const affectedSerOptions = () => {
    if (affectedServicesOptions) {
      if (formData.affectedServices) {
        return [...affectedServicesOptions.map((a) => a.name), ...formData.affectedServices];
      } else {
        return [...affectedServicesOptions.map((a) => a.name)];
      }
    }
  };

  const handleSubmitModalClose = () => {
    setOpenSubmitModal(false);
    setDeclarations(declarations.map((item) => ({ ...item, value: item.readonly ? item.value : false })));
  };

  return (
    <>
      <Segment basic vertical>
        <Grid columns={2} stackable>
          <Grid.Column>
            <Header
              as="h2"
              content={"Permit no. " + formData.number}
              subheader={permit ? "Update details" : "Add a new permit to dig"}
            ></Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Menu compact>
              <Menu.Item link onClick={handleBackToListClick}>
                Back to list
              </Menu.Item>
              {saveButtonVisible && (
                <Menu.Item
                  link
                  onClick={handleSave}
                  disabled={addPermitStatus.loading || editPermitStatus.loading || submitPermitStatus.loading}
                >
                  Save
                  <Loader
                    style={{ marginLeft: "5px" }}
                    inline
                    active={addPermitStatus.loading || editPermitStatus.loading}
                    size="mini"
                  />
                </Menu.Item>
              )}
              {submitButtonVisible && (
                <Menu.Item
                  link
                  onClick={handleSubmitClick}
                  disabled={addPermitStatus.loading || editPermitStatus.loading || submitPermitStatus.loading}
                >
                  <Icon name="check"></Icon> Submit
                  <Loader style={{ marginLeft: "5px" }} inline active={submitPermitStatus.loading} size="mini" />
                </Menu.Item>
              )}
            </Menu>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment attached="bottom">
        <Grid divided>
          <Grid.Column stretched mobile={5} tablet={5} computer={5} largeScreen={4} widescreen={4}>
            <Segment basic vertical>
              <Header as="h3">
                <Icon name="angle right"></Icon>
                <Header.Content>Basic details</Header.Content>
              </Header>
              <Divider></Divider>

              <AddPermitForm
                affectedServicesOptions={affectedSerOptions()}
                formErrors={formErrors}
                errors={[
                  ...(documentErrors ? Object.values(documentErrors) : []),
                  ...(addPermitStatus.errors ? addPermitStatus.errors : []),
                  ...(editPermitStatus.errors ? editPermitStatus.errors : []),
                  ...(submitPermitStatus.errors ? submitPermitStatus.errors : []),
                ]}
                formData={formData}
                onChange={handleFormChange}
                isNumberEditable={!permit && nextPermitNumber === 1}
              ></AddPermitForm>
            </Segment>
          </Grid.Column>

          <Grid.Column
            mobile={4}
            tablet={4}
            computer={3}
            largeScreen={3}
            widescreen={2}
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <PermitTeamForm
              formErrors={permitTeamFormErrors}
              errors={undefined}
              formData={permitTeamFormData}
              onChange={handlePermitTeamFormChange}
              projectAssignments={projectAssignments}
            ></PermitTeamForm>

            {showPermitBoundariesOnMap && (
              <PermitMap
                showMapLayersListSelected={showMapLayersListSelected}
                showMapLegendSelected={showMapLegendSelected}
                permitHasBoundaries={true}
                onPermitBoundariesClick={() => {
                  setPreviewBoundaries(true);
                  setPreviewDoc(undefined);
                }}
                onShowMapLayerListClick={(val) => setShowMapLayersListSelected(val ?? false)}
                onShowMapLegendClick={(val) => setShowMapLegendSelected(val ?? false)}
              />
            )}

            <PermitAttachmentBar
              documents={documents}
              selectedDocument={previewDoc}
              mandatoryDocTypes={mandatoryDocTypes}
              onDocClicked={handleDocClicked}
              onAddDoc={handleAddDoc}
              onRemoveDoc={handleRemoveDoc}
            ></PermitAttachmentBar>
          </Grid.Column>

          <Grid.Column mobile={7} tablet={7} computer={8} largeScreen={9} widescreen={10} style={{ padding: "0px" }}>
            {previewDoc && <DocumentView containerId="addPermitDocView" document={previewDoc} isPopup={false} />}

            {showPermitBoundariesOnMap && previewBoundaries && (
              <MapWithBoundaries
                items={projectBoundaries}
                showLegend={showMapLegendSelected}
                showLayersList={showMapLayersListSelected}
                saveBoundaries={(bouandaries: GeoJSONPolygonType | null) => setBoundaries(bouandaries)}
                boundaries={permit?.boundaries}
              />
            )}
          </Grid.Column>
        </Grid>
      </Segment>

      <PermitSubmissionModal
        open={openSubmitModal}
        onClose={handleSubmitModalClose}
        onConfirm={handleSubmit}
        onChange={setDeclarations}
        declarations={declarations}
      ></PermitSubmissionModal>
    </>
  );
};

export default AddPermit;
