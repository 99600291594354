import { useEffect, useState } from "react";
import { Button, Card, Divider, Grid, Header, Icon, Image, Modal, Segment } from "semantic-ui-react";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { Permit } from "../models/Permit";
import { toastSuccess } from "../utils";
import ConfirmWithCommentsModal from "./common/ConfirmWithCommentsModal";
import ConfirmModal from "./common/ConfirmModal";
import ExtendPermitModal from "./ExtendPermitModal";
import CompletePermitModalContainer from "../containers/CompletePermitModalContainer";
import PermitAttachmentBar from "./PermitAttachmentBar";
import DocumentView from "./DocumentView";
import { Document_Data } from "../models/Document_Data";
import StateChangeLogsView from "./StateChangeLogsView";
import PermitDescriptionView from "./PermitDescriptionView";
import ViewItem from "./common/ViewItem";
import PermitAuthorizationView from "./PermitAuthorizationView";
import { SitePhoto } from "../models/SitePhoto";
import PhotoViewer from "./common/PhotoViewer";
import GangMembersView from "./GangMembersView";
import ServerImage from "./common/ServerImage";
import MapWithBoundaries from "./MapWithBoundaries";
import PermitMap from "./PermitMap";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { createMapItem } from "../utils/arcgisUtils";
import PermitPdf from "./permit-pdf/PermitPdf";
import { Organization } from "../models/Organization";
import ActionButton from "./common/DetailsBoxActionButton";
import LocationLogsView from "./LocationLogsView";

interface Props {
  showPermitBoundariesOnMap?: boolean;
  approveAndRejectButtonsVisible?: boolean;
  cancelButtonVisible?: boolean;
  undoCancellationButtonVisible?: boolean;
  extendButtonVisible?: boolean;
  completeButtonVisible?: boolean;
  editButtonVisible?: boolean;
  permit?: Permit;
  organization?: Organization;
  onClose: () => void;
  onEditPermit: () => void;
  onRejectPermit: (comments: string) => void;
  rejectPermitStatus: AsyncActionStatus;
  onApprovePermit: () => void;
  approvePermitStatus: AsyncActionStatus;
  onCancelPermit: () => void;
  cancelPermitStatus: AsyncActionStatus;
  onUndoCancelledPermit: () => void;
  undoCancelledPermitStatus: AsyncActionStatus;
  onExtendPermit: (to: Date, comments: string) => void;
  extendPermitStatus: AsyncActionStatus;
  onCompletePermit: () => void;
  completePermitStatus: AsyncActionStatus;
  onPermitRejected?: (permit: Permit) => void;
  onPermitApproved?: (permit: Permit) => void;
  onPermitCancelled?: (permit: Permit) => void;
  onPermitUndoCancellation?: (permit: Permit) => void;
  onPermitExtended?: (permit: Permit) => void;
  onPermitCompleted?: (permit: Permit) => void;
  onAsBuiltAdded?: () => void;
}

const PermitView = ({
  showPermitBoundariesOnMap,
  approveAndRejectButtonsVisible,
  cancelButtonVisible,
  undoCancellationButtonVisible,
  extendButtonVisible,
  completeButtonVisible,
  editButtonVisible,
  permit,
  organization,
  onClose,
  onEditPermit,
  onRejectPermit,
  rejectPermitStatus,
  onApprovePermit,
  approvePermitStatus,
  onCancelPermit,
  cancelPermitStatus,
  onUndoCancelledPermit,
  undoCancelledPermitStatus,
  onExtendPermit,
  extendPermitStatus,
  onCompletePermit,
  completePermitStatus,
  onPermitApproved,
  onPermitCompleted,
  onPermitExtended,
  onPermitRejected,
  onPermitCancelled,
  onPermitUndoCancellation,
  onAsBuiltAdded,
}: Props) => {
  const [showPdf, setShowPdf] = useState(false);
  const [openViewPermitModal, setOpenViewPermitModal] = useState(false);
  const [openConfirmRejectModal, setOpenConfirmRejectModal] = useState(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [openConfirmUndoCancellationModal, setOpenConfirmUndoCancellationModal] = useState(false);
  const [openExtendModal, setOpenExtendModal] = useState(false);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [previewDoc, setPreviewDoc] = useState<Document_Data | undefined>(undefined);
  const [previewSitePhoto, setPreviewSitePhoto] = useState<SitePhoto | undefined>(undefined);
  const [previewBoundaries, setPreviewBoundaries] = useState(true);
  const [showAuthorization, setShowAuthorization] = useState(false);
  const [showGangMembers, setShowGangMembers] = useState(false);
  const [showMapLegendSelected, setShowMapLegendSelected] = useState(false);
  const [showMapLayersListSelected, setShowMapLayersListSelected] = useState(false);

  // Display the Esri map or first SMD drawing by default
  useEffect(() => {
    if (showPermitBoundariesOnMap) {
      setPreviewBoundaries(true);
    } else {
      setPreviewBoundaries(false);

      const drawings = permit?.documents?.filter((d) => d.type.key.toLowerCase() === "drawing");
      if (drawings && drawings.length > 0) {
        setPreviewDoc(drawings[0]);
      } else {
        setPreviewDoc(undefined);
      }
    }

    setPreviewSitePhoto(undefined);
    setShowAuthorization(false);
    setShowGangMembers(false);
  }, [permit]);

  const handleViewPermitPopup = () => {
    setOpenViewPermitModal(true);
  };

  const handleModalClose = () => {
    setOpenViewPermitModal(false);
    setOpenConfirmRejectModal(false);
    setOpenConfirmCancelModal(false);
    setOpenConfirmUndoCancellationModal(false);
    setOpenExtendModal(false);
    setOpenCompleteModal(false);
    setPreviewDoc(undefined);
    setPreviewSitePhoto(undefined);

    rejectPermitStatus.reset();
    approvePermitStatus.reset();
    extendPermitStatus.reset();
    completePermitStatus.reset();
    cancelPermitStatus.reset();
    undoCancelledPermitStatus.reset();
  };

  useEffect(() => {
    if (rejectPermitStatus.successful) {
      toastSuccess("Permit rejected successfully!");
      setOpenConfirmRejectModal(false);
      if (onPermitRejected && permit) {
        onPermitRejected(permit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectPermitStatus.successful]);

  useEffect(() => {
    if (approvePermitStatus.successful) {
      toastSuccess("Permit approved successfully!");
      if (onPermitApproved && permit) {
        onPermitApproved(permit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvePermitStatus.successful]);

  useEffect(() => {
    if (cancelPermitStatus.successful) {
      toastSuccess("Permit cancelled successfully!");
      setOpenConfirmCancelModal(false);
      if (onPermitCancelled && permit) {
        onPermitCancelled(permit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelPermitStatus.successful]);

  useEffect(() => {
    if (undoCancelledPermitStatus.successful) {
      toastSuccess("Permit cancellation successfully revoked!");
      setOpenConfirmUndoCancellationModal(false);
      if (onPermitUndoCancellation && permit) {
        onPermitUndoCancellation(permit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoCancelledPermitStatus.successful]);

  useEffect(() => {
    if (extendPermitStatus.successful) {
      toastSuccess("Permit extended successfully!");
      setOpenExtendModal(false);
      if (onPermitExtended && permit) {
        onPermitExtended(permit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendPermitStatus.successful]);

  useEffect(() => {
    if (completePermitStatus.successful) {
      toastSuccess("Permit completed successfully!");
      setOpenCompleteModal(false);
      if (onPermitCompleted && permit) {
        onPermitCompleted(permit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completePermitStatus.successful]);

  const handleConfirmRejectModalSubmit = (comments: string) => {
    onRejectPermit(comments);
  };

  const handleConfirmCancelModalSubmit = () => {
    onCancelPermit();
  };

  const handleConfirmUndoCancellationModalSubmit = () => {
    onUndoCancelledPermit();
  };

  const handleExtendModalSubmit = (to: Date, comments: string) => {
    onExtendPermit(to, comments);
  };

  const handleCompleteModalSubmit = () => {
    onCompletePermit();
  };

  const projectBoundaries = () => {
    return permit && permit.project.boundaries
      ? [
          createMapItem(
            "polyline",
            permit.project.boundaries.coordinates as number[][][],
            permit.project.id,
            "View project",
            `Project: ${permit.project.name} (${permit.project.number})`,
            "Location of work: " + permit.project.location,
            false,
            { r: 119, g: 226, b: 40 } as __esri.Color
          ),
        ]
      : [];
  };

  const PermitViewModal = (permit: Permit) => {
    return (
      <Modal open={openViewPermitModal} onClose={handleModalClose} size="fullscreen">
        <Modal.Content>
          <Modal.Description>{permitViewPanel(permit, true)}</Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  const permitViewPanel = (permit: Permit, isPopup: boolean) => {
    return (
      <>
        {permit && (
          <>
            <Segment attached="top" basic secondary compact>
              <ActionButton
                icon="close"
                onClick={() => {
                  isPopup ? handleModalClose() : onClose();
                }}
              />
              {!isPopup && <ActionButton icon="arrow up" onClick={handleViewPermitPopup} />}
              {organization && (
                <PDFDownloadLink
                  document={<PermitPdf permit={permit} organization={organization} />}
                  fileName={`permit-${permit?.number}.pdf`}
                >
                  {({ blob, url, loading, error }) => (
                    <Button
                      circular
                      floated="right"
                      style={{ padding: "5px 7px", marginLeft: "8px", "box-shadow": "0px 0px" }}
                      icon={loading ? "refresh" : "download"}
                    ></Button>
                  )}
                </PDFDownloadLink>
              )}
              <ActionButton
                icon={showPdf ? "list" : "file pdf"}
                onClick={() => {
                  setShowPdf(!showPdf);
                }}
              />
              {approveAndRejectButtonsVisible && (
                <>
                  <ActionButton onClick={(e) => setOpenConfirmRejectModal(true)} text="Reject" />
                  <ActionButton
                    onClick={onApprovePermit}
                    text="Approve"
                    loading={approvePermitStatus.loading}
                    disabled={approvePermitStatus.loading}
                  />
                </>
              )}
              {extendButtonVisible && <ActionButton text="Extend" onClick={(e) => setOpenExtendModal(true)} />}
              {cancelButtonVisible && (
                <ActionButton text="Cancel Permit" onClick={(e) => setOpenConfirmCancelModal(true)} />
              )}
              {undoCancellationButtonVisible && (
                <ActionButton
                  text="Undo cancellation"
                  onClick={(e) => setOpenConfirmUndoCancellationModal(true)}
                ></ActionButton>
              )}
              {completeButtonVisible && (
                <ActionButton text="Mark as Complete" onClick={(e) => setOpenCompleteModal(true)} />
              )}
              {editButtonVisible && <ActionButton text="Edit" onClick={onEditPermit} />}

              <Header as="h3" style={{ display: "inline" }}>
                No. {permit.number.toString()}
              </Header>
            </Segment>

            {organization && showPdf && (
              <PDFViewer height={"650px"} width={"100%"}>
                <PermitPdf permit={permit} organization={organization} />
              </PDFViewer>
            )}

            {!showPdf && (
              <>
                <Segment attached color="green" style={{ borderTop: "3px !important" }}>
                  <Grid>
                    <Grid.Column width={isPopup ? "6" : "16"}>
                      <PermitDescriptionView permit={permit} showHeader={isPopup} />
                    </Grid.Column>
                    {isPopup && (
                      <Grid.Column width="3" style={{ backgroundColor: "#f8f8f8" }}>
                        <Header as="h3">
                          <Icon name="users" />
                          <Header.Content> Permit team</Header.Content>
                        </Header>
                        <Divider />
                        {permit.permitAssignments.map((assignment) => (
                          <ViewItem
                            key={assignment.id}
                            header={assignment.orgRole.displayName}
                            value={assignment.orgUser?.user.displayName}
                          />
                        ))}
                        <Card
                          content
                          style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }}
                          onClick={() => {
                            setPreviewDoc(undefined);
                            setShowAuthorization(true);
                            setPreviewSitePhoto(undefined);
                            setPreviewBoundaries(false);
                            setShowGangMembers(false);
                          }}
                        >
                          <Card.Content style={{ padding: "0px", margin: "0px" }}>View authorizations</Card.Content>
                        </Card>
                        <Card
                          content
                          style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }}
                          onClick={() => {
                            setPreviewDoc(undefined);
                            setShowAuthorization(false);
                            setPreviewSitePhoto(undefined);
                            setPreviewBoundaries(false);
                            setShowGangMembers(true);
                          }}
                        >
                          <Card.Content style={{ padding: "0px", margin: "0px" }}>
                            Gang members ({permit.gangMembers.length})
                          </Card.Content>
                        </Card>

                        {showPermitBoundariesOnMap && (
                          <PermitMap
                            showMapLayersListSelected={showMapLayersListSelected}
                            showMapLegendSelected={showMapLegendSelected}
                            permitHasBoundaries={true}
                            onPermitBoundariesClick={() => {
                              setPreviewDoc(undefined);
                              setShowAuthorization(false);
                              setPreviewSitePhoto(undefined);
                              setShowGangMembers(false);
                              setPreviewBoundaries(true);
                            }}
                            onShowMapLayerListClick={(val) => setShowMapLayersListSelected(val ?? false)}
                            onShowMapLegendClick={(val) => setShowMapLegendSelected(val ?? false)}
                          />
                        )}

                        <PermitAttachmentBar
                          documents={permit.documents}
                          selectedDocument={previewDoc}
                          onDocClicked={(doc) => {
                            setPreviewDoc(doc);
                            setShowAuthorization(false);
                            setPreviewSitePhoto(undefined);
                            setPreviewBoundaries(false);
                            setShowGangMembers(false);
                          }}
                        />
                        <Segment basic vertical>
                          <Header as="h3">
                            <Icon name="photo" />
                            <Header.Content> Site photos</Header.Content>
                          </Header>
                          <Divider />
                          <Card content style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }}>
                            <Card.Content style={{ padding: "0px", margin: "0px" }}>
                              <Image.Group size="mini">
                                {permit.sitePhotos.map((photo, index) => (
                                  <ServerImage
                                    key={index}
                                    src={photo.fileUrl}
                                    rounded
                                    onClick={() => {
                                      setShowAuthorization(false);
                                      setPreviewDoc(undefined);
                                      setPreviewSitePhoto(photo);
                                      setPreviewBoundaries(false);
                                      setShowGangMembers(false);
                                    }}
                                  />
                                ))}
                              </Image.Group>
                            </Card.Content>
                          </Card>
                        </Segment>
                      </Grid.Column>
                    )}
                    {isPopup && (
                      <Grid.Column width="7" style={{ padding: "0px" }}>
                        {previewDoc && (
                          <DocumentView containerId="permitViewDocView" document={previewDoc} isPopup={false} />
                        )}
                        {permit && showAuthorization && <PermitAuthorizationView permit={permit} />}
                        {permit && previewSitePhoto && <PhotoViewer url={previewSitePhoto.fileUrl} />}
                        {permit && showGangMembers && <GangMembersView permit={permit} />}
                        {showPermitBoundariesOnMap && permit && previewBoundaries && (
                          <MapWithBoundaries
                            items={projectBoundaries()}
                            showLayersList={showMapLayersListSelected}
                            showLegend={showMapLegendSelected}
                            boundaries={permit.boundaries}
                          />
                        )}
                      </Grid.Column>
                    )}
                  </Grid>
                </Segment>
                <div>
                  <Segment floated="left" style={{ margin: "0px" }} basic>
                    <StateChangeLogsView logs={permit.stateChangeLogs} />
                  </Segment>
                  <Segment floated="right" style={{ margin: "0px" }} basic>
                    <LocationLogsView logs={permit.locationLogs} />
                  </Segment>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {permit && permitViewPanel(permit, false)}
      {permit && openViewPermitModal && PermitViewModal(permit)}
      <ConfirmWithCommentsModal
        title="Reject permit"
        open={openConfirmRejectModal}
        onClose={handleModalClose}
        onSubmit={handleConfirmRejectModalSubmit}
        submitInProgress={rejectPermitStatus.loading}
      ></ConfirmWithCommentsModal>

      <ConfirmModal
        title="Cancel permit"
        open={openConfirmCancelModal}
        onClose={handleModalClose}
        onSubmit={handleConfirmCancelModalSubmit}
      ></ConfirmModal>

      <ConfirmModal
        title="Undo Cancellation"
        open={openConfirmUndoCancellationModal}
        onClose={handleModalClose}
        onSubmit={handleConfirmUndoCancellationModalSubmit}
      ></ConfirmModal>

      <ExtendPermitModal
        title="Extend permit"
        open={openExtendModal}
        onClose={handleModalClose}
        onSubmit={handleExtendModalSubmit}
        submitInProgress={extendPermitStatus.loading}
      ></ExtendPermitModal>

      <CompletePermitModalContainer
        showAsBuilt={permit?.asBuiltRequired}
        documents={permit?.asBuiltRequired ? permit?.documents.filter((d) => d.type.name === "SMD") : undefined}
        open={openCompleteModal}
        onClose={handleModalClose}
        onSubmit={handleCompleteModalSubmit}
        submitInProgress={completePermitStatus.loading}
        onAsBuiltAdded={onAsBuiltAdded}
      ></CompletePermitModalContainer>
    </>
  );
};

export default PermitView;
