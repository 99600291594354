import { Text, View } from "@react-pdf/renderer";
import styles from "./components/Styles";
import { Permit } from "../../models/Permit";
import Row from "./components/Row";
import Item from "./components/Item";
import moment from "moment";
import { PermitToDigSignatureType } from "../../models/globalTypes";
import Break from "./components/Break";
import HeaderText from "./components/HeaderText";

interface Props {
  permit: Permit;
  orgLogoUrl: string | null;
  token: string;
}

const SubmissionPage = ({ permit, orgLogoUrl, token }: Props) => {
  if (!permit) return null;

  const submissionSignature = permit.signatures.find((s) => s.type === PermitToDigSignatureType.SUBMISSION);
  return (
    <>
      <View wrap={false}>
        <HeaderText title="PRE-SUBMISSION DECLARATION" />
        <View style={{ backgroundColor: "whitesmoke", border: "1px solid #666", borderTop: 0 }}>
          {submissionSignature?.declaration.split(/\r?\n/).map((d) => (
            <Text style={{ ...styles.text, margin: "3px 5px", color: "#444", fontSize: 10 }}>{d}</Text>
          ))}
        </View>
        <Row border>
          <Item
            width="33%"
            title="Submitted by"
            text={`${submissionSignature?.orgUser?.user.firstName || ""} ${
              submissionSignature?.orgUser?.user.lastName || ""
            }`}
          ></Item>
          <Item
            width="33%"
            title="Date"
            text={submissionSignature?.dateSignedUtc ? moment(submissionSignature.dateSignedUtc)?.format("lll") : "-"}
          ></Item>
        </Row>
        <Break height={5} />
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </>
  );
};

export default SubmissionPage;
