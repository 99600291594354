import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { DeleteDocumentType, DeleteDocumentTypeVariables } from "./__generated__/DeleteDocumentType";

const DELETE_DOCUMENT_TYPE = gql`
  mutation DeleteDocumentType($organizationId: String!, $id: String!) {
    deleteDocumentType(input: { organizationId: $organizationId, id: $id }) {
      documentType {
        id
        name
        key
        declaration
        isMandatory
        isArchived
      }
    }
  }
`;

export function useDeleteDocumentType() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteDocumentType,
    DeleteDocumentTypeVariables
  >(DELETE_DOCUMENT_TYPE, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
