import { ActionItem } from "./GridActionButton";
import { DataGrid } from "./common/DataGrid";
import { Column } from "react-table";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { Document_ListItem } from "../models/Document_ListItem";

interface Props {
  addNewVersionButtonVisible?: boolean;
  deleteDocumentButtonVisible?: boolean;
  documents?: Document_ListItem[] | null;
  selectedDocument?: Document_ListItem;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onDocumentClicked: (documentId: string) => void;
  onAddNewVersionToDocument: (documentId: string) => void;
  onDeleteDocument: (documentId: string) => void;
}

const DocumentsGrid = ({
  addNewVersionButtonVisible,
  deleteDocumentButtonVisible,
  documents,
  selectedDocument,
  pageCount,
  onPageInfoChange,
  onAddNewVersionToDocument,
  onDeleteDocument,
  onDocumentClicked,
}: Props) => {
  const actionItems = [] as ActionItem[];
  addNewVersionButtonVisible &&
    actionItems.push({
      key: "newVersion",
      icon: "edit",
      content: "Add new version",
      callback: (id: string) => onAddNewVersionToDocument(id),
    });
  deleteDocumentButtonVisible &&
    actionItems.push({
      key: "delete",
      icon: "delete",
      content: "Delete",
      callback: (id: string) => onDeleteDocument(id),
    });

  const columns: Column<Document_ListItem>[] = [
    {
      Header: "Title",
      id: "Title",
      accessor: (doc) => doc.title,
    },
    {
      Header: "Type",
      id: "Type",
      accessor: (doc) => doc.type.name,
    },
    {
      Header: "Version",
      id: "Version",
      accessor: (doc) => doc.versionNo,
    },
    {
      Header: "Date",
      id: "Date",
      accessor: (doc) => doc.dateCreatedUtc,
      Cell: ({ value }: any) => (
        <Popup
          trigger={<span>{moment(value).local().fromNow()}</span>}
          content={moment.utc(value).local().format("llll")}
        ></Popup>
      ),
    },
    {
      Header: "Created by",
      id: "Created by",
      accessor: (doc) => doc.createdBy.user.displayName,
    },
  ];

  const handleDocumentClicked = (doc: Document_ListItem, i: number) => {
    onDocumentClicked(doc.id);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        actionItems={actionItems}
        actionButtonColumn={columns[0]}
        data={documents ? documents : []}
        selectedRow={selectedDocument}
        onRowClick={handleDocumentClicked}
        onPageInfoChange={onPageInfoChange}
        loading={false}
        pageCount={pageCount}
      />
    </>
  );
};

export default DocumentsGrid;
