import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { ApplicationPermission } from "../models/ApplicationPermission";
import { FormError } from "../models/FormError";
import { OrgRole } from "../models/OrgRole";
import { PermitRoleSettingsInput } from "../models/PermitRoleSettingsInput";
import EditOrgRoleForm, { FormData } from "./EditOrgRoleForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    displayName: string,
    permissionNames: string[],
    permitRoleSettingsInput: PermitRoleSettingsInput,
    roleId?: string
  ) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  orgRole?: OrgRole;
  applicationPermissions?: ApplicationPermission[];
}

const EditOrgRoleModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  submitErrors,
  orgRole,
  applicationPermissions,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData({
        ...orgRole,
        permissionNames: orgRole?.role?.permissions?.map((p) => p?.name),
        isPermitAssignee: orgRole?.permitRoleSettings.isPermitAssignee ?? false,
        mandatoryOnSubmission: orgRole?.permitRoleSettings.mandatoryOnSubmission ?? false,
        shouldAuthorizePermit: orgRole?.permitRoleSettings.shouldAuthorizePermit ?? false,
        authorizationDeclaration: orgRole?.permitRoleSettings.authorizationDeclaration ?? "",
      } as FormData);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, orgRole]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.displayName) {
      formError.displayName = "Please enter role name";
    }

    if (formData.shouldAuthorizePermit && !formData.authorizationDeclaration) {
      formError.authorizationDeclaration = "Please enter authorization declaration";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const {
        displayName,
        permissionNames,
        isPermitAssignee,
        mandatoryOnSubmission,
        shouldAuthorizePermit,
        authorizationDeclaration,
      } = formData;
      onSubmit(
        displayName,
        permissionNames,
        {
          isPermitAssignee: isPermitAssignee,
          mandatoryOnSubmission: mandatoryOnSubmission,
          shouldAuthorizePermit: shouldAuthorizePermit,
          authorizationDeclaration: authorizationDeclaration,
        } as PermitRoleSettingsInput,
        orgRole?.roleId
      );
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Role details</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <EditOrgRoleForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
            applicationPermissions={applicationPermissions}
          ></EditOrgRoleForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditOrgRoleModal;
