import { Card, Checkbox, Divider, Grid, GridColumn, Header, Icon, Segment } from "semantic-ui-react";

interface Props {
  showMapLegendSelected: boolean;
  showMapLayersListSelected: boolean;
  permitHasBoundaries: boolean;
  onPermitBoundariesClick?: () => void;
  onShowMapLegendClick: (selected?: boolean) => void;
  onShowMapLayerListClick: (selected?: boolean) => void;
}

const PermitMap = ({
  showMapLegendSelected,
  showMapLayersListSelected,
  permitHasBoundaries,
  onPermitBoundariesClick,
  onShowMapLegendClick,
  onShowMapLayerListClick,
}: Props) => {
  return (
    <Segment basic vertical>
      <Header as="h3">
        <Icon name="map" />
        <Header.Content>Map</Header.Content>
      </Header>
      <Divider />
      {permitHasBoundaries && (
        <>
          <Card
            content
            style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }}
            onClick={onPermitBoundariesClick}
          >
            <Card.Content style={{ padding: "0px", margin: "0px" }}>Permit boundaries</Card.Content>
          </Card>
          <Card style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }}>
            <Card.Content style={{ padding: "0px", margin: "0px" }}>
              <Grid>
                <GridColumn>
                  <Checkbox
                    slider
                    label="Layers list"
                    checked={showMapLayersListSelected}
                    onChange={(e, data) => {
                      onShowMapLayerListClick(data.checked);
                      if (data.checked) {
                        onShowMapLegendClick(!data.checked);
                      }
                    }}
                  />
                </GridColumn>
              </Grid>
              <Grid>
                <GridColumn>
                  <Checkbox
                    slider
                    label="Map legend"
                    checked={showMapLegendSelected}
                    onChange={(e, data) => {
                      onShowMapLegendClick(data.checked);
                      if (data.checked) {
                        onShowMapLayerListClick(!data.checked);
                      }
                    }}
                  />
                </GridColumn>
              </Grid>
            </Card.Content>
          </Card>
        </>
      )}
    </Segment>
  );
};

export default PermitMap;
