import { useEffect, useState } from "react";
import authService from "../components/api-authorization/AuthorizeService";
import { User } from "../models/User";

export function useCurrentUser() {
  const [authenticationState, setauthenticationState] = useState<{
    isAuthenticated: boolean;
    user?: User;
  }>({ isAuthenticated: false, user: undefined });

  const populateState = async () => {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    setauthenticationState({
      isAuthenticated,
      user: getUser(user),
    });
  };

  const getUser = (authUser: any) => {
    return {
      id: authUser.sub,
      email: authUser.email,
      firstName: authUser.given_name,
      lastName: authUser.family_name,
    } as User;
  };

  useEffect(() => {
    populateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user: authenticationState.user, isAuthenticated: authenticationState.isAuthenticated };
}
