import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { CORE_GANG_MEMBER_FIELDS } from "../fragments/GangMember";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { AddGangMember, AddGangMemberVariables } from "./__generated__/AddGangMember";

const ADD_GANG_MEMBER = gql`
  ${CORE_GANG_MEMBER_FIELDS}
  mutation AddGangMember(
    $firstName: String!
    $lastName: String!
    $jobTitle: String
    $employerName: String
    $organizationId: String!
    $companyInduction: CompanyInductionInput
    $buriedServicesInduction: BuriedServicesInductionInput
  ) {
    addGangMember(
      input: {
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        employerName: $employerName
        organizationId: $organizationId
        companyInduction: $companyInduction
        buriedServicesInduction: $buriedServicesInduction
      }
    ) {
      gangMember {
        ...CoreGangMemberFields
      }
    }
  }
`;

export function useAddGangMember() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddGangMember,
    AddGangMemberVariables
  >(ADD_GANG_MEMBER, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
