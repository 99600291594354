import moment from "moment";
import { Divider, Grid, Header, Icon } from "semantic-ui-react";
import { Permit } from "../models/Permit";
import ViewItem from "./common/ViewItem";
import ServerImage from "../components/common/ServerImage";

interface Props {
  showHeader: boolean;
  permit: Permit;
}

const PermitDescriptionView = ({ permit, showHeader }: Props) => {
  return (
    <>
      {showHeader && (
        <>
          <Header as="h3">
            {permit.project.organization.logoUrl && (
              <ServerImage floated="right" src={permit.project.organization.logoUrl} size="large" />
            )}
            <Icon name="angle right" />
            <Header.Content> Permit description</Header.Content>
          </Header>
          <Divider />
        </>
      )}
      <Grid columns="2">
        <Grid.Row>
          <Grid.Column>
            <ViewItem header="Contractor" value={permit.contractor} />
          </Grid.Column>
          <Grid.Column>
            <ViewItem header="Sub-contractor" value={permit.subContractor} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ViewItem header="Works description" value={permit.worksDescription} />
          </Grid.Column>
          <Grid.Column>
            <ViewItem header="Location of work" value={permit.locationOfWork} />
          </Grid.Column>
        </Grid.Row>
        <Divider horizontal content="Project" />
        <Grid.Row>
          <Grid.Column>
            <ViewItem header="Project name" value={permit.project.name} />
          </Grid.Column>
          <Grid.Column>
            <ViewItem header="Project no." value={permit.project.number} />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <ViewItem
              header="Duration"
              value={`${moment.utc(permit.validityDuration.from).local().format("L")} to ${moment
                .utc(permit.validityDuration.to)
                .local()
                .format("L")}`}
            />
          </Grid.Column>
          <Grid.Column>
            <ViewItem
              header="Hours"
              value={`${String(moment.duration(permit.validityDuration?.hoursFrom).hours()).padStart(2, "0")}:${String(
                moment.duration(permit.validityDuration?.hoursFrom).minutes()
              ).padStart(2, "0")} to 
                 ${String(moment.duration(permit.validityDuration?.hoursTo).hours()).padStart(2, "0")}:${String(
                moment.duration(permit.validityDuration?.hoursTo).minutes()
              ).padStart(2, "0")}`}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ViewItem header="Permitted equipment" value={permit.permittedEquipment} />
          </Grid.Column>
          <Grid.Column>
            <ViewItem header="Max penetration depth" value={permit.maxPenetrationDepth} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column>
            <ViewItem
              header="Affected services"
              value={permit.noAffectedServices ? "No affected services" : permit.affectedServices.join(", ")}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column>
            <ViewItem header="Comments" value={permit.comments} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PermitDescriptionView;
