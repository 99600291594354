import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { UpdateProject, UpdateProjectVariables } from "./__generated__/UpdateProject";
import { CORE_PROJECT_FIELDS } from "../fragments/project";

const UPDATE_PROJECT = gql`
  ${CORE_PROJECT_FIELDS}
  mutation UpdateProject(
    $projectId: String!
    $name: String!
    $number: String
    $location: String!
    $boundaries: GeoJSONPolygonInput
    $emergencyContact: EmergencyContactInput
  ) {
    updateProject(
      input: {
        projectId: $projectId
        name: $name
        number: $number
        location: $location
        boundaries: $boundaries
        emergencyContact: $emergencyContact
      }
    ) {
      project {
        ...CoreProjectFields
      }
    }
  }
`;

export function useUpdateProject() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateProject,
    UpdateProjectVariables
  >(UPDATE_PROJECT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
