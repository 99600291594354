import OrgUsersList from "../components/OrgUsersList";
import {
  GET_ORG_USERS_BY_ORGANIZATION_ID,
  useLazyGetOrgUsersByOrganizationId,
} from "../operations/queries/getOrgUsersByOrganizationId";
import { useAddOrgUser } from "../operations/mutations/addOrgUser";
import { useAddUserToOrganization } from "../operations/mutations/addUserToOrganization";
import { useUpdateOrgUser } from "../operations/mutations/updateOrgUser";
import { useRemoveUserFromOrganization } from "../operations/mutations/removeUserFromOrganization";
import { useResetOrgUserPassword } from "../operations/mutations/resetOrgUserPassword";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect, useState } from "react";
import { useLazyGetUserByEmail } from "../operations/queries/getUserByEmail";
import { User } from "../models/User";
import { CalibrationCertificate } from "../models/CalibrationCertificate";
import { useToggleArchivedStatusOrgUser } from "../operations/mutations/toggleArchivedStatusOrgUser";
import { OrgUserFileInput } from "../models/globalTypes";

const OrgUsersListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const [user, setUser] = useState<User | undefined>();

  const {
    getOrgUsersByOrganizationId: getOrgUsers,
    data: orgUsers,
    status: getOrgUsersStatus,
  } = useLazyGetOrgUsersByOrganizationId();
  const { mutate: addOrgUserMutate, status: addOrgUserStatus } = useAddOrgUser();
  const { mutate: updateOrgUserMutate, status: updateOrgUserStatus } = useUpdateOrgUser();
  const { mutate: removeUserFromOrganizationMutate, status: deleteOrgUserStatus } = useRemoveUserFromOrganization();
  const { mutate: resetPasswordMutate, status: resetPasswordStatus } = useResetOrgUserPassword();
  const { mutate: addOrgUserWithExistingUserMutate, status: addOrgUserWithExistingUserStatus } =
    useAddUserToOrganization();
  const { mutate: toggleArchivedStatusOrgUserMutate, status: toggleArchivedStatusOrgUserStatus } =
    useToggleArchivedStatusOrgUser();
  const { getUserByEmail: getUser, data: userConnection } = useLazyGetUserByEmail("network-only");

  useEffect(() => {
    if (currentOrganization) {
      getOrgUsers({ variables: { organizationId: currentOrganization.id } });
    }
  }, [getOrgUsers, currentOrganization]);

  const handleAddOrgUser = (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    orgUserFiles: OrgUserFileInput[]
  ) => {
    if (currentOrganization) {
      addOrgUserMutate({
        refetchQueries: [
          { query: GET_ORG_USERS_BY_ORGANIZATION_ID, variables: { organizationId: currentOrganization.id } },
        ],
        variables: {
          firstName,
          lastName,
          email,
          password,
          organizationId: currentOrganization.id,
          isAdmin,
          phoneNumber,
          calibrationCertificate,
          orgUserFiles,
        },
      });
    }
  };

  const handleAddOrgUserWithExistingUser = (
    userId: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    orgUserFiles: OrgUserFileInput[]
  ) => {
    if (currentOrganization) {
      addOrgUserWithExistingUserMutate({
        refetchQueries: [
          { query: GET_ORG_USERS_BY_ORGANIZATION_ID, variables: { organizationId: currentOrganization.id } },
        ],
        variables: {
          userId,
          organizationId: currentOrganization.id,
          isAdmin,
          phoneNumber,
          calibrationCertificate,
          orgUserFiles,
        },
      });
    }
  };

  const handleEditOrgUser = (
    userId: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    newOrgUserFiles: OrgUserFileInput[],
    deletedOrgUserFileIds: string[]
  ) => {
    if (currentOrganization) {
      updateOrgUserMutate({
        refetchQueries: [
          { query: GET_ORG_USERS_BY_ORGANIZATION_ID, variables: { organizationId: currentOrganization.id } },
        ],
        variables: {
          organizationId: currentOrganization.id,
          userId,
          firstName,
          lastName,
          isAdmin,
          phoneNumber,
          calibrationCertificate,
          newOrgUserFiles,
          deletedOrgUserFileIds,
        },
      });
    }
  };

  const handleDeleteOrgUser = (userId: string) => {
    if (currentOrganization) {
      removeUserFromOrganizationMutate({
        refetchQueries: [
          { query: GET_ORG_USERS_BY_ORGANIZATION_ID, variables: { organizationId: currentOrganization.id } },
        ],
        variables: {
          userId,
          organizationId: currentOrganization.id,
        },
      });
    }
  };

  const handleResetPassword = (userId: string, newPassword: string) => {
    if (currentOrganization) {
      resetPasswordMutate({
        variables: {
          organizationId: currentOrganization.id,
          userId,
          newPassword,
        },
      });
    }
  };

  const handleSearchUser = (email: string) => {
    if (email) {
      getUser({ variables: { email } });
    }
  };

  useEffect(() => {
    setUser(userConnection?.userByEmail || undefined);
  }, [userConnection]);

  const handleSearchUserReset = () => {
    setUser(undefined);
  };

  const handleToggleArchivedStatusOrgUser = (id: string) => {
    if (currentOrganization) {
      toggleArchivedStatusOrgUserMutate({
        variables: {
          id,
          organizationId: currentOrganization.id,
        },
      });
    }
  };

  return (
    <OrgUsersList
      activeLicence={currentOrganization?.activeLicence}
      isOrgUsersLoading={getOrgUsersStatus.loading}
      orgUsers={orgUsers && orgUsers.orgUsersByOrganizationId}
      onAddOrgUser={handleAddOrgUser}
      addUserStatus={addOrgUserStatus}
      onAddOrgUserWithExistingUser={handleAddOrgUserWithExistingUser}
      addUserWithExistingUserStatus={addOrgUserWithExistingUserStatus}
      onEditOrgUser={handleEditOrgUser}
      editUserStatus={updateOrgUserStatus}
      onDeleteOrgUser={handleDeleteOrgUser}
      deleteUserStatus={deleteOrgUserStatus}
      onResetPassword={handleResetPassword}
      resetPasswordStatus={resetPasswordStatus}
      onSearchUser={handleSearchUser}
      onSearchUserReset={handleSearchUserReset}
      user={user}
      onToggleArchivedStatusOrgUser={handleToggleArchivedStatusOrgUser}
      toggleArchivedStatusOrgUserStatus={toggleArchivedStatusOrgUserStatus}
    ></OrgUsersList>
  );
};

export default OrgUsersListContainer;
