import AddNewVersionToDocumentModal from "../components/AddNewVersionToDocumentModal";
import { useAddNewVersionToDocument } from "../operations/mutations/addNewVersionToDocument";
import { useUploadFile } from "../operations/mutations/uploadFile";
import { toastSuccess } from "../utils";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useEffect, useState } from "react";
import { UserFile } from "../models/UserFile";
import { FileMetaData } from "../models/FileMetaData";
import { Document_Data } from "../models/Document_Data";

interface Props {
  document?: Document_Data;
  open: boolean;
  onDocumentAdded?: (doc: Document_Data) => void;
  onClose: () => void;
}

const AddNewVersionToDocumentModalContainer = ({ document, open, onDocumentAdded, onClose }: Props) => {
  const { currentProject } = useCurrentProject();
  const [userFile, setUserFile] = useState<UserFile>();

  const {
    mutate: addNewVersionToDocumentMutate,
    data: addNewVersionToDocument,
    status: addNewVersionToDocumentStatus,
  } = useAddNewVersionToDocument();
  const {
    mutate: uploadFileMutate,
    data: uploadFile,
    error: uploadFileError,
    loading: uploadFileLoading,
  } = useUploadFile();

  const handleModalClose = () => {
    addNewVersionToDocumentStatus.reset();
    onClose();
  };

  const handleUploadFile = (userFile: UserFile) => {
    setUserFile(userFile);
    uploadFileMutate({
      variables: {
        file: userFile.file,
        fileId: userFile.id,
      },
    });
  };

  useEffect(() => {
    if (userFile) {
      if (uploadFile?.uploadFile?.fileId) {
        setUserFile({ ...userFile, uploadStatus: { ...userFile.uploadStatus, uploaded: true, uploading: false } });
      } else if (uploadFileError && uploadFileError.graphQLErrors) {
        setUserFile({
          ...userFile,
          uploadStatus: { ...userFile.uploadStatus, uploading: false, uploadError: uploadFileError.graphQLErrors[0] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile, uploadFileError]);

  const handleAddNewVersionToDocument = (id: string, title: string, versionNo: number, fileMetaData: FileMetaData) => {
    if (currentProject) {
      addNewVersionToDocumentMutate({
        variables: {
          documentId: id,
          title,
          versionNo,
          fileMetaData: fileMetaData,
        },
      });
    }
  };

  useEffect(() => {
    if (addNewVersionToDocumentStatus.successful) {
      toastSuccess("New version added successfully!");
      if (onDocumentAdded && addNewVersionToDocument) {
        onDocumentAdded(addNewVersionToDocument.addNewVersionToDocument!.document!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewVersionToDocumentStatus.successful]);

  return (
    <AddNewVersionToDocumentModal
      open={open}
      document={document}
      onClose={handleModalClose}
      onSubmit={handleAddNewVersionToDocument}
      submitInProgress={addNewVersionToDocumentStatus.loading}
      submitErrors={addNewVersionToDocumentStatus.errors}
      userFile={userFile}
      onUploadFile={handleUploadFile}
      uploading={uploadFileLoading}
    ></AddNewVersionToDocumentModal>
  );
};

export default AddNewVersionToDocumentModalContainer;
