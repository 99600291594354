import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { GetOrganizationPolicies, GetOrganizationPoliciesVariables } from "./__generated__/GetOrganizationPolicies";

export const GET_PROJECT_POLICIES = gql`
  query GetOrganizationPolicies($organizationId: String!) {
    organizationPolicies(organizationId: $organizationId)
  }
`;

export function useLazyGetOrganizationPolicies() {
  const [getOrganizationPolicies, { loading, error, data }] = useLazyQuery<
    GetOrganizationPolicies,
    GetOrganizationPoliciesVariables
  >(GET_PROJECT_POLICIES, {
    errorPolicy: "all",
  });

  return {
    getOrganizationPolicies,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
