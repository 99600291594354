import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import {
  ResetOrganizationConfiguration,
  ResetOrganizationConfigurationVariables,
} from "./__generated__/ResetOrganizationConfiguration";

const RESET_ORGANIZATION_CONFIGURATION = gql`
  mutation ResetOrganizationConfiguration($configKey: String!, $organizationId: String!) {
    resetOrganizationConfiguration(input: { configKey: $configKey, organizationId: $organizationId }) {
      succeeded
    }
  }
`;

export function useResetOrganizationConfiguration() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ResetOrganizationConfiguration,
    ResetOrganizationConfigurationVariables
  >(RESET_ORGANIZATION_CONFIGURATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
