import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Header, Segment } from "semantic-ui-react";
import PdfViewer from "./common/PdfViewer";
import useWindowDimensions from "../hooks/useWindowDimensions";
import debounce from "lodash/debounce";
import { ReferenceProcedure_Data } from "../models/ReferenceProcedure_Data";

interface Props {
  containerId: string;
  onClose: () => void;
  onPopup?: () => void;
  isPopup?: boolean;
  referenceProcedure?: ReferenceProcedure_Data;
}

const ReferenceProcedureView = ({ containerId, onClose, onPopup, isPopup, referenceProcedure }: Props) => {
  var pdfViewerSegmentRef = useRef(null);
  const [pdfViewerWidth, setPdfViewerWidth] = useState(400);

  const { width } = useWindowDimensions();

  const setWidth = useCallback(
    debounce((w: number) => {
      setPdfViewerWidth(w);
    }, 300),
    []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (pdfViewerSegmentRef.current) {
      var segment = pdfViewerSegmentRef.current as any;
      setWidth(segment.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceProcedure, pdfViewerSegmentRef.current, width]);

  return (
    <>
      {referenceProcedure && (
        <>
          <Segment basic style={{ margin: "0px", paddingBottom: "0px" }}>
            <Button
              circular
              icon="close"
              floated="right"
              style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
              onClick={onClose}
            ></Button>
            {!isPopup && (
              <Button
                circular
                icon="arrow up"
                floated="right"
                style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
                onClick={onPopup}
              ></Button>
            )}
            <Header as="h3" style={{ display: "inline" }}>
              {referenceProcedure.title}
            </Header>
          </Segment>
          <Segment basic style={{ margin: "0px", padding: "1px" }}></Segment>
          <Segment basic style={{ margin: "0px", paddingTop: "0px" }}>
            <div ref={pdfViewerSegmentRef}>
              <PdfViewer
                containerId={containerId}
                url={referenceProcedure.fileUrl}
                width={pdfViewerWidth}
                height={window.innerHeight - (isPopup ? 170 : 350)}
              />
            </div>
          </Segment>
        </>
      )}
    </>
  );
};

export default ReferenceProcedureView;
