import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { CORE_LICENCE_FIELDS } from "../fragments/Licence";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { ArchiveLicence, ArchiveLicenceVariables } from "./__generated__/ArchiveLicence";

const ARCHIVE_LICENCE = gql`
  ${CORE_LICENCE_FIELDS}
  mutation ArchiveLicence($id: String!) {
    archiveLicence(input: { id: $id }) {
      licence {
        ...CoreLicenceFields
      }
    }
  }
`;

export function useArchiveLicence() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ArchiveLicence,
    ArchiveLicenceVariables
  >(ARCHIVE_LICENCE, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
