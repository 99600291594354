import LayoutWithSidebar from "../components/LayoutWithSidebar";
import MainSideMenu from "../components/MainSideMenu";
import ReferenceProceduresListContainer from "../containers/ReferenceProceduresListContainer";
import MainHeaderContainer from "../containers/MainHeaderContainer";

const ReferenceProcedurePage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <MainSideMenu></MainSideMenu>;
  };

  const contentPanelContent = () => {
    return <ReferenceProceduresListContainer></ReferenceProceduresListContainer>;
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default ReferenceProcedurePage;
