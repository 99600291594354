import { Text, View } from "@react-pdf/renderer";

type Props = {
  title?: string;
  backgroundColor?: string;
  textColor?: string;
};

const HeaderText = ({ title, backgroundColor, textColor }: Props) => {
  return (
    <>
      {title ? (
        <View
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            gap: 20,
            backgroundColor: backgroundColor ?? "#666",
            color: textColor ?? "white",
            padding: 7,
            fontSize: 12,
            borderBottom: "2px solid #9aca3c",
          }}
        >
          <View>
            <Text>{title}</Text>
          </View>
        </View>
      ) : (
        <View
          style={{
            marginVertical: 10,
            borderBottomColor: "black",
            borderBottomWidth: "0.1mm",
          }}
        />
      )}
    </>
  );
};

export default HeaderText;
