import { Table } from "semantic-ui-react";
import { OrgRole } from "../models/OrgRole";
import GridActionButton from "./GridActionButton";

interface Props {
  onEditOrgRole: (orgRoleId: string) => void;
  orgRoles?: OrgRole[];
}

const OrgRolesGrid = ({ onEditOrgRole, orgRoles }: Props) => {
  const renderOrgRoleTableRow = () => {
    if (orgRoles) {
      return orgRoles.map((orgRole) => (
        <Table.Row key={orgRole.id}>
          <Table.Cell>
            {orgRole.displayName}
            <GridActionButton
              rowId={orgRole.id}
              items={[{ key: "edit", icon: "edit", content: "Edit", callback: onEditOrgRole }]}
            />
          </Table.Cell>
        </Table.Row>
      ));
    }
  };

  return (
    <>
      <Table basic="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderOrgRoleTableRow()}</Table.Body>

        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan="4"></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default OrgRolesGrid;
