import { Segment, Menu, Header, Grid, Input, Dropdown, InputOnChangeData, Loader, Icon } from "semantic-ui-react";
import PermitsGrid from "./PermitsGrid";
import { AsyncQueryStatus } from "../models/AsyncActionStatus";
import { useState } from "react";
import PermitViewContainer from "../containers/PermitViewContainer";
import { useHistory, useParams } from "react-router-dom";
import { Permit_ListItem } from "../models/Permit_ListItem";
import { StateKeyList } from "../models/StateKey";
import { StateKey } from "../models/globalTypes";
import { Permit } from "../models/Permit";
import { uniqBy } from "lodash";
import PermitsBoundariesOnMap from "./PermitsBoundariesOnMap";
import { Project } from "../models/Project";

interface Props {
  showPermitBoundariesOnMap?: boolean;
  newPermitButtonVisible?: boolean;
  requestPermitButtonVisible?: boolean;
  permits?: Permit_ListItem[] | null;
  currentProject?: Project;
  pageCount?: number;
  stateFilter: StateKey | null;
  assignedToFilter: string;
  createdByFilter: string;
  searchString: string;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchPermits: (searchString: string) => void;
  onStateFilter: (stateKey: StateKey) => void;
  onAssignedToFilter: (assignedToId: string) => void;
  onCreatedByFilter: (createdById: string) => void;
  getPermitsStatus: AsyncQueryStatus;
  onPermitRejected?: (permit: Permit) => void;
  onPermitApproved?: (permit: Permit) => void;
  onPermitCancelled?: (permit: Permit) => void;
  onPermitUndoCancellation?: (permit: Permit) => void;
  onPermitExtended?: (permit: Permit) => void;
  onPermitCompleted?: (permit: Permit) => void;
}

const PermitsList = ({
  showPermitBoundariesOnMap,
  newPermitButtonVisible,
  requestPermitButtonVisible,
  permits,
  currentProject,
  pageCount,
  stateFilter,
  assignedToFilter,
  createdByFilter,
  searchString,
  onPageInfoChange,
  onSearchPermits,
  onStateFilter,
  onAssignedToFilter,
  onCreatedByFilter,
  getPermitsStatus,
  onPermitApproved,
  onPermitCompleted,
  onPermitExtended,
  onPermitRejected,
  onPermitCancelled,
  onPermitUndoCancellation,
}: Props) => {
  const { orgName } = useParams<{ orgName: string }>();
  const { projectName } = useParams<{ projectName: string }>();

  const [openViewPermitSection, setOpenViewPermitSection] = useState(false);
  const [selectedPermitToView, setSelectedPermitToView] = useState<Permit_ListItem | undefined>(undefined);
  const [listMode, setListMode] = useState("table");

  const handlePermitClicked = (permitId: string) => {
    setOpenViewPermitSection(true);
    setSelectedPermitToView(permits?.find((d) => d.id === permitId));
  };

  const handleViewPermitSectionClose = () => {
    setSelectedPermitToView(undefined);
    setOpenViewPermitSection(false);
  };

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchPermits(value);
  };

  const handleStateFilterChange = (e: any, { name, value }: any) => {
    onStateFilter(value || null);
  };

  const handleAssignedToFilterChange = (e: any, { name, value }: any) => {
    onAssignedToFilter(value || null);
  };

  const handleCreatedByFilterChange = (e: any, { name, value }: any) => {
    onCreatedByFilter(value || null);
  };

  let history = useHistory();
  const handleNewPermitClick = () => {
    history.push(`/${orgName}/projects/${projectName}/permits/add-permit`);
  };

  const handleRequestPermitClick = () => {
    history.push(`/${orgName}/projects/${projectName}/permits/request-permit`);
  };

  const stateItems = Object.values(StateKeyList).map((item) => {
    return {
      key: item.key,
      text: item.name,
      value: item.key,
    };
  });

  const assignedToItems = () => {
    const permitAssignees = permits
      ?.flatMap((p) => p.permitAssignments)
      .map((a) => a.orgUser)
      .filter((u) => !!u);

    const uniqueAssignees = uniqBy(permitAssignees, "id");

    return uniqueAssignees?.map((r) => ({
      key: r?.id,
      text: r?.user.displayName,
      value: r?.id,
    }));
  };

  const createdByItems = () => {
    const permitCreators = permits?.map((p) => p.createdBy);
    const uniqueCreators = uniqBy(permitCreators, "id");

    return uniqueCreators?.map((r) => ({
      key: r.id,
      text: r.user.displayName,
      value: r.id,
    }));
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">
          Permit register <Loader inline active={getPermitsStatus.loading} size="mini" />
        </Header>
      </Segment>

      <Menu tabular>
        <Menu.Item active={listMode === "table"} onClick={() => setListMode("table")}>
          <Icon name="table" />
        </Menu.Item>
        {showPermitBoundariesOnMap && (
          <Menu.Item active={listMode === "map"} onClick={() => setListMode("map")}>
            <Icon name="map" />
          </Menu.Item>
        )}

        <Menu.Menu secondary color="green" position="left">
          {newPermitButtonVisible && <Menu.Item icon="plus" name="New permit" onClick={handleNewPermitClick} />}
          {requestPermitButtonVisible && (
            <Menu.Item icon="plus" name="Request permit" onClick={handleRequestPermitClick} />
          )}
        </Menu.Menu>
        <Menu.Menu position="right">
          {/* <Menu.Item>
            <Dropdown placeholder="Dates" basic></Dropdown>
          </Menu.Item> */}
          <Menu.Item>
            <Dropdown
              placeholder="Status"
              basic
              clearable
              options={stateItems}
              onChange={handleStateFilterChange}
              value={stateFilter ? stateFilter.toString() : ""}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              placeholder="Assigned to"
              basic
              clearable
              options={assignedToItems()}
              onChange={handleAssignedToFilterChange}
              value={assignedToFilter}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              placeholder="Created by"
              basic
              clearable
              options={createdByItems()}
              onChange={handleCreatedByFilterChange}
              value={createdByFilter}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} value={searchString} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment basic style={listMode === "map" ? { paddingTop: "0" } : {}}>
        <Grid columns={openViewPermitSection ? "2" : "1"}>
          <Grid.Row>
            <Grid.Column width={openViewPermitSection ? "10" : "16"} style={listMode === "map" ? { padding: "0" } : {}}>
              {listMode === "table" && (
                <PermitsGrid
                  permits={permits}
                  selectedPermit={selectedPermitToView}
                  pageCount={pageCount}
                  onPageInfoChange={onPageInfoChange}
                  onPermitClicked={handlePermitClicked}
                />
              )}
              {listMode === "map" && (
                <PermitsBoundariesOnMap
                  currentProject={currentProject}
                  onPermitViewClicked={handlePermitClicked}
                  permits={permits}
                />
              )}
            </Grid.Column>
            {openViewPermitSection && (
              <Grid.Column width="6">
                <PermitViewContainer
                  showPermitBoundariesOnMap={showPermitBoundariesOnMap}
                  onClose={handleViewPermitSectionClose}
                  permitId={selectedPermitToView?.id}
                  onPermitRejected={onPermitRejected}
                  onPermitApproved={onPermitApproved}
                  onPermitCancelled={onPermitCancelled}
                  onPermitUndoCancellation={onPermitUndoCancellation}
                  onPermitExtended={onPermitExtended}
                  onPermitCompleted={onPermitCompleted}
                ></PermitViewContainer>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default PermitsList;
