import { useState } from "react";
import { Button, Form, Modal, TextArea } from "semantic-ui-react";

interface Props {
  open: boolean;
  onClose: () => void;
  onSend: (text: string) => void;
  sending: boolean;
}

const ContactSupportModal = ({ open, onClose, onSend, sending }: Props) => {
  const [text, setText] = useState("");

  const handleChange = (e: any, { name, value }: any) => {
    setText(value);
  };

  return (
    <Modal open={open} size="tiny" onClose={onClose}>
      <Modal.Header>Contact support</Modal.Header>
      <Modal.Content>
        <Form>
          <TextArea rows={7} placeholder="How can we help you?" onChange={handleChange} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onSend(text);
          }}
          loading={sending}
          disabled={sending}
          positive
        >
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContactSupportModal;
