import { Column } from "react-table";
import { Project } from "../models/Project";
import { DataGrid } from "./common/DataGrid";
import { ActionItem } from "./GridActionButton";

interface Props {
  projects?: Project[] | null;
  selectedProject?: Project;
  pageCount?: number;
  onEditProject: (projectId: string) => void;
  onDeleteProject: (projectId: string) => void;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onProjectClicked: (project: Project) => void;
}

const ProjectsGrid = ({
  projects,
  selectedProject,
  pageCount,
  onPageInfoChange,
  onEditProject,
  onDeleteProject,
  onProjectClicked,
}: Props) => {
  const actionItems = [
    {
      key: "edit",
      icon: "edit",
      content: "Edit",
      callback: (id: string) => onEditProject(id),
    },
    {
      key: "delete",
      icon: "delete",
      content: "Delete",
      callback: (id: string) => onDeleteProject(id),
    },
  ] as ActionItem[];

  const columns: Column<Project>[] = [
    {
      Header: "Name",
      id: "Name",
      accessor: (p) => p.name,
    },
    {
      Header: "Number",
      id: "Number",
      accessor: (p) => p.number,
    },
  ];

  const handleProjectClicked = (proj: Project, i: number) => {
    onProjectClicked(proj);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        actionItems={actionItems}
        actionButtonColumn={columns[0]}
        data={projects ? projects : []}
        selectedRow={selectedProject}
        onRowClick={handleProjectClicked}
        onPageInfoChange={onPageInfoChange}
        loading={false}
        pageCount={pageCount}
      />
    </>
  );
};

export default ProjectsGrid;
