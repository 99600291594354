import React, { ReactElement } from "react";
import { Divider, Dropdown, Form, Header, Icon, Segment } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import { PermitAssignment } from "../models/PermitAssignment";
import { ProjectAssignment } from "../models/ProjectAssignment";
import ErrorsView from "./ErrorsView";

export interface FormData {
  permitAssignments: PermitAssignment[];
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  projectAssignments?: ProjectAssignment[];
  onChange: (formData: FormData) => void;
}

function PermitTeamForm({ formData, formErrors, errors, projectAssignments, onChange }: Props): ReactElement {
  const handleChange = (e: any, { name, value }: any) => {
    const newValues = [...formData.permitAssignments];
    const assignmentIndex = formData.permitAssignments.findIndex((a) => a.orgRole.id === name);
    const selectedUser = projectAssignments?.find((a) => a.orgUser.id === value)?.orgUser;

    newValues[assignmentIndex] = { ...newValues[assignmentIndex], orgUser: selectedUser ?? null };

    onChange({ ...formData, permitAssignments: newValues });
  };

  return (
    <>
      <Segment basic vertical>
        <Header as="h3">
          <Icon name="users" />
          <Header.Content> Permit team</Header.Content>
        </Header>
        <Divider />
        <Form>
          {formData.permitAssignments.map((assignment) => (
            <Form.Field
              required={assignment.orgRole.permitRoleSettings.mandatoryOnSubmission}
              error={formErrors[`permitAssignment_${assignment.orgRole.id}`]}
              control={Dropdown}
              clearable
              name={assignment.orgRole.id}
              fluid
              label={assignment.orgRole.displayName}
              value={assignment.orgUser?.id}
              options={projectAssignments
                ?.filter((a) => a.orgRole.id === assignment.orgRole.id)
                .map((a) => ({
                  key: a.orgUser.id,
                  text: a.orgUser.user.displayName,
                  value: a.orgUser.id,
                }))}
              onChange={handleChange}
              selection
              search
            />
          ))}
        </Form>
        <ErrorsView errors={errors} />
      </Segment>
    </>
  );
}

export default PermitTeamForm;
