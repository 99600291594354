import { CheckboxProps, Form, Grid, InputOnChangeData, Item, Tab } from "semantic-ui-react";
import ErrorsView from "./ErrorsView";
import { FormError } from "../models/FormError";
import { User } from "../models/User";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { OrgUserFileInput } from "../models/globalTypes";
import OrgUserFilesList from "./OrgUserFilesList";

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatPassword: string;
  isAdmin: boolean;
  phoneNumber: string;
  CatSerialNumber: string;
  CatCalibrationDate: Date;
  GennySerialNumber: string;
  GennyCalibrationDate: Date;
}

interface Props {
  user?: User;
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  onEmailEntered: (email: string) => void;
  onAddOrgUserFile: () => void;
  onDeleteOrgUserFile: (orgUserFile: OrgUserFileInput) => void;
  orgUserFiles: OrgUserFileInput[];
}

const OrgUserAddForm = ({
  user,
  formData,
  onChange,
  formErrors,
  errors,
  onEmailEntered,
  onAddOrgUserFile,
  onDeleteOrgUserFile,
  orgUserFiles,
}: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  const handleIsAdminChange = (e: any, data: CheckboxProps) => {
    onChange({ ...formData, isAdmin: data.checked ?? false });
  };

  const handleEmailBlur = (e: any) => {
    onEmailEntered(formData.email);
  };

  const onCatCalibrationDateChange = (e: any, data: any) => {
    formData.CatCalibrationDate = data.value;
  };

  const onGennyCalibrationDateChange = (e: any, data: any) => {
    formData.GennyCalibrationDate = data.value;
  };

  const userInfoItem = (header: string, value?: string | null) => {
    return (
      <Item.Group>
        <Item>
          <Item.Content>
            <Item.Meta>{header}</Item.Meta>
            <Item.Description>{value ? value : ""}</Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    );
  };

  const panes = [
    {
      menuItem: "Calibration certificate",
      render: () => (
        <Tab.Pane>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                label="CAT serial number"
                name="CatSerialNumber"
                value={formData.CatSerialNumber}
                onChange={handleChange}
              />

              <SemanticDatepicker
                label="CAT calibration date"
                value={formData.CatCalibrationDate}
                locale="en-US"
                onChange={onCatCalibrationDateChange}
                type="basic"
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                label="Genny serial number"
                name="GennySerialNumber"
                value={formData.GennySerialNumber}
                onChange={handleChange}
              />
              <SemanticDatepicker
                label="Genny calibration date"
                value={formData.GennyCalibrationDate}
                locale="en-US"
                onChange={onGennyCalibrationDateChange}
                type="basic"
              />
            </Form.Group>
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Files",
      render: () => (
        <Tab.Pane>
          <div style={{ minHeight: "163px" }}>
            <OrgUserFilesList
              orgUserFiles={orgUserFiles}
              onAddOrgUserFile={onAddOrgUserFile}
              onDeleteOrgUserFile={onDeleteOrgUserFile}
            />
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Form>
        <Form.Group widths="2">
          <Form.Input
            required
            label="Email"
            error={formErrors.email}
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleEmailBlur}
          />
        </Form.Group>
        {user ? (
          <>
            <Grid columns="2">
              <Grid.Row>
                <Grid.Column>{userInfoItem("First Name", user.firstName)}</Grid.Column>
                <Grid.Column>{userInfoItem("Last Name", user.lastName)}</Grid.Column>
              </Grid.Row>
            </Grid>
            <br></br>
          </>
        ) : (
          <>
            <Form.Group widths="equal">
              <Form.Input label="First Name" name="firstName" value={formData.firstName} onChange={handleChange} />
              <Form.Input label="Last name" name="lastName" value={formData.lastName} onChange={handleChange} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Password"
                error={formErrors.password}
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <Form.Input
                required
                label="Repeat Password"
                error={formErrors.repeatPassword}
                type="password"
                name="repeatPassword"
                value={formData.repeatPassword}
                onChange={handleChange}
              />
            </Form.Group>
          </>
        )}

        <Form.Group widths="equal">
          <Form.Input label="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          <Form.Field>
            <label>&nbsp;</label>
            <Form.Checkbox
              label="Is Admin?"
              error={formErrors.isAdmin}
              name="isAdmin"
              checked={formData.isAdmin}
              onChange={handleIsAdminChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Tab panes={panes} />
      <ErrorsView errors={errors} />
    </>
  );
};

export default OrgUserAddForm;
