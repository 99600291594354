import { gql } from "@apollo/client";

export const PERMISSION_FIELDS = gql`
  fragment PermissionFields on ApplicationPermission {
    name
    displayName
    description
    category
    categoryId
  }
`;
