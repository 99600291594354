import { useState } from "react";
import { Button, Card, Confirm, Divider, Dropdown, Header, Icon, Label, Segment } from "semantic-ui-react";
import SelectDocumentModalContainer from "../containers/SelectDocumentModalContainer";
import AddDocumentModalContainer from "../containers/AddDocumentModalContainer";
import { Document_Data } from "../models/Document_Data";
import { DocumentType } from "../models/DocumentType";
import styles from "./PermitAttachmentBar.module.css";

interface Props {
  onDocClicked: (doc: Document_Data) => void;
  documents?: Document_Data[];
  selectedDocument?: Document_Data;
  mandatoryDocTypes?: DocumentType[];
  onAddDoc?: (doc: Document_Data) => void;
  onRemoveDoc?: (doc: Document_Data) => void;
}

const PermitAttachmentBar = ({
  onDocClicked,
  documents,
  selectedDocument,
  mandatoryDocTypes,
  onAddDoc,
  onRemoveDoc,
}: Props) => {
  const [openSelectDocumentModal, setOpenSelectDocumentModal] = useState(false);
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);
  const [openAddDocumentConfirm, setOpenAddDocumentConfirm] = useState(false);
  const [docTypeFilter, setDocTypeFilter] = useState<DocumentType | undefined>(undefined);
  const [addedDocToConfirm, setAddedDocToConfirm] = useState<Document_Data | undefined>(undefined);

  const handleAddDoc = (doc: Document_Data) => {
    if (mandatoryDocTypes?.find((t) => t.key === doc.type.key && t.declaration)) {
      setAddedDocToConfirm(doc);
      setOpenAddDocumentConfirm(true);
    } else {
      addDoc(doc);
    }
  };

  const handleConfirm = () => {
    setOpenAddDocumentConfirm(false);
    addedDocToConfirm && addDoc(addedDocToConfirm);
  };

  const addDoc = (doc: Document_Data) => {
    setOpenSelectDocumentModal(false);
    setOpenAddDocumentModal(false);
    onAddDoc && onAddDoc(doc);
    setAddedDocToConfirm(undefined);
    setDocTypeFilter(undefined);
  };

  const renderDocCard = (doc: Document_Data | null, docType?: DocumentType) => {
    return (
      <Card
        content
        style={{ "box-shadow": "0px 0px", backgroundColor: "transparent" }}
        onClick={(e) => doc && onDocClicked(doc)}
        key={doc ? doc.id : docType?.id}
      >
        <Card.Content
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          {doc && onRemoveDoc && (
            <Button
              circular
              icon="close"
              size="mini"
              floated="right"
              style={{ padding: "2px", "box-shadow": "0px 0px" }}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveDoc(doc);
              }}
            ></Button>
          )}
          {selectedDocument && doc && doc.id === selectedDocument.id && <Icon name="chevron right" />}
          {doc ? (
            doc.type.name + ": " + doc.title
          ) : (
            <Segment basic style={{ padding: "0px", margin: "0px" }}>
              <Label as="a" size="large" style={{ padding: "5px" }}>
                <Icon size="small" name="plus" />
                <Dropdown text={"Add " + docType?.name}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        setOpenAddDocumentModal(true);
                        setDocTypeFilter(docType);
                      }}
                    >
                      Add New
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setOpenSelectDocumentModal(true);
                        setDocTypeFilter(docType);
                      }}
                    >
                      Add Existing
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Label>
            </Segment>
          )}
        </Card.Content>
      </Card>
    );
  };

  const renderMandatoryDocType = (docType: DocumentType) => {
    const docs = documents?.filter((d) => d.type.key === docType.key);
    return docs && docs.length > 0 ? docs.map((d) => renderDocCard(d)) : renderDocCard(null, docType);
  };

  return (
    <>
      <Segment basic vertical>
        <Header as="h3">
          <Icon name="file pdf" />
          <Header.Content>Documents</Header.Content>
        </Header>
        <Divider />

        {mandatoryDocTypes && mandatoryDocTypes.length > 0 ? (
          <>
            <Header as="h5">
              <Header.Content className={styles.requiredTitle}>Required documents</Header.Content>
            </Header>
            {mandatoryDocTypes && mandatoryDocTypes.map((t) => renderMandatoryDocType(t))}
            <Divider />
            <Header as="h5">
              <Header.Content>Other documents</Header.Content>
            </Header>
            {documents &&
              documents.map((d) => !mandatoryDocTypes.find((t) => t.key === d.type.key) && renderDocCard(d))}
          </>
        ) : (
          documents && documents.map((d) => renderDocCard(d))
        )}

        {onAddDoc && (
          <Segment basic style={{ padding: "0px", margin: "0px" }}>
            <Label as="a" size="large" style={{ padding: "5px" }}>
              <Icon size="small" name="plus" />
              <Dropdown text="Add Document">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => setOpenAddDocumentModal(true)}>Add New</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => setOpenSelectDocumentModal(true)}>Add Existing</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Label>
          </Segment>
        )}
      </Segment>

      <SelectDocumentModalContainer
        open={openSelectDocumentModal}
        onSelectDocument={handleAddDoc}
        docType={docTypeFilter}
        onClose={() => {
          setOpenSelectDocumentModal(false);
          setDocTypeFilter(undefined);
        }}
      ></SelectDocumentModalContainer>

      <AddDocumentModalContainer
        open={openAddDocumentModal}
        onDocumentAdded={handleAddDoc}
        docType={docTypeFilter}
        onClose={() => {
          setOpenAddDocumentModal(false);
          setDocTypeFilter(undefined);
        }}
      ></AddDocumentModalContainer>

      <Confirm
        open={openAddDocumentConfirm}
        header="Declaration"
        confirmButton="Confirm"
        content={mandatoryDocTypes?.find((t) => t.key === addedDocToConfirm?.type.key)?.declaration}
        onConfirm={handleConfirm}
        onCancel={() => setOpenAddDocumentConfirm(false)}
      />
    </>
  );
};

export default PermitAttachmentBar;
