import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import {
  ToggleArchivedStatusDocumentType,
  ToggleArchivedStatusDocumentTypeVariables,
} from "./__generated__/ToggleArchivedStatusDocumentType";

const TOGGLE_ARCHIVED_STATUS_DOCUMENT_TYPE = gql`
  mutation ToggleArchivedStatusDocumentType($organizationId: String!, $id: String!) {
    toggleArchivedStatusDocumentType(input: { organizationId: $organizationId, id: $id }) {
      documentType {
        id
        name
        key
        declaration
        isMandatory
        isArchived
      }
    }
  }
`;

export function useToggleArchivedStatusDocumentType() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ToggleArchivedStatusDocumentType,
    ToggleArchivedStatusDocumentTypeVariables
  >(TOGGLE_ARCHIVED_STATUS_DOCUMENT_TYPE, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
