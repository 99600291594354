import { Dropdown, Form, InputOnChangeData, Select } from "semantic-ui-react";
import ErrorsView from "./ErrorsView";
import { FormError } from "../models/FormError";
import { OrgUser } from "../models/OrgUser";
import { OrgRole } from "../models/OrgRole";

export interface FormData {
  id: string;
  orgUserIds: string[];
  orgRoleId: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  users?: OrgUser[];
  roles?: OrgRole[];
}

const EditProjectAssignmentForm = ({ formData, onChange, formErrors, errors, users, roles }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            required
            error={formErrors.orgUserIds}
            control={Dropdown}
            name="orgUserIds"
            fluid
            label="User"
            options={users?.map((u) => ({ key: u.id, text: u.user.displayName, value: u.id }))}
            onChange={handleChange}
            multiple
            selection
          />
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field
            required
            error={formErrors.orgRoleId}
            control={Select}
            name="orgRoleId"
            value={formData.orgRoleId}
            fluid
            label="Role"
            options={roles?.map((r) => ({ key: r.id, text: r.displayName, value: r.id }))}
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default EditProjectAssignmentForm;
