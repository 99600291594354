import SemanticDatepicker from "react-semantic-ui-datepickers";
import { CheckboxProps, Form, InputOnChangeData, Tab } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";
import OrgUserFilesList from "./OrgUserFilesList";
import { OrgUserFile_AndInput } from "../models/OrgUserFile_AndInput";
import { OrgUserFileInput } from "../models/globalTypes";

export interface FormData {
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  phoneNumber: string;
  CatSerialNumber: string;
  CatCalibrationDate: Date;
  GennySerialNumber: string;
  GennyCalibrationDate: Date;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  onAddOrgUserFile: () => void;
  onDeleteOrgUserFile: (orgUserFile: OrgUserFile_AndInput) => void;
  orgUserFiles?: OrgUserFile_AndInput[];
}

const OrgUserEditForm = ({
  formData,
  onChange,
  formErrors,
  errors,
  orgUserFiles,
  onAddOrgUserFile,
  onDeleteOrgUserFile,
}: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  const handleIsAdminChange = (e: any, data: CheckboxProps) => {
    onChange({ ...formData, isAdmin: data.checked ?? false });
  };

  const onCatCalibrationDateChange = (e: any, data: any) => {
    formData.CatCalibrationDate = data.value;
  };

  const onGennyCalibrationDateChange = (e: any, data: any) => {
    formData.GennyCalibrationDate = data.value;
  };

  const panes = [
    {
      menuItem: "Calibration certificate",
      render: () => (
        <Tab.Pane>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>CAT serial number</label>
                <Form.Input name="CatSerialNumber" value={formData.CatSerialNumber} onChange={handleChange} />
              </Form.Field>
              <SemanticDatepicker
                label="CAT calibration date"
                value={formData.CatCalibrationDate}
                locale="en-US"
                onChange={onCatCalibrationDateChange}
                type="basic"
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>Genny serial number</label>
                <Form.Input name="GennySerialNumber" value={formData.GennySerialNumber} onChange={handleChange} />
              </Form.Field>
              <SemanticDatepicker
                label="Genny calibration date"
                value={formData.GennyCalibrationDate}
                locale="en-US"
                onChange={onGennyCalibrationDateChange}
                type="basic"
              />
            </Form.Group>
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: `Files (${orgUserFiles?.length ?? 0})`,
      render: () => (
        <Tab.Pane>
          <div style={{ height: "163px" }}>
            <OrgUserFilesList
              orgUserFiles={orgUserFiles}
              onAddOrgUserFile={onAddOrgUserFile}
              onDeleteOrgUserFile={onDeleteOrgUserFile}
            />
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Input label="First Name" name="firstName" value={formData.firstName} onChange={handleChange} />
          <Form.Input label="Last name" name="lastName" value={formData.lastName} onChange={handleChange} />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input label="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          <Form.Field>
            <label>&nbsp;</label>
            <Form.Checkbox
              label="Is Admin?"
              error={formErrors.isAdmin}
              name="isAdmin"
              checked={formData.isAdmin}
              onChange={handleIsAdminChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Tab panes={panes} />
      <ErrorsView errors={errors} />
    </>
  );
};

export default OrgUserEditForm;
