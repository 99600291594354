import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";

const AdminSettingsSideMenu = () => {
  return (
    <Menu borderless secondary vertical color="grey" style={{ marginRight: "1px" }} size="large">
      <Menu.Item as={NavLink} to="/settings/organizations" content="Organizations" />
      <Menu.Item as={NavLink} to="/settings/licences" content="Licences" />
      <Menu.Item as={NavLink} to="/settings/admin-users" content="Admin users" />
      <Menu.Item
        as={NavLink}
        content="Back"
        to="/"
        isActive={() => {
          return false;
        }}
      />
    </Menu>
  );
};

export default AdminSettingsSideMenu;
