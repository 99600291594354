import { Dropdown, Form, InputOnChangeData } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";
import { EsriIntegrationTypes } from "../models/EsriIntegrationType";

export interface FormData {
  id: string;
  integrationTypeId: string;
  webMapItemId: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
}

const AdminEditEsriConfigForm = ({ formData, formErrors, errors, onChange }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            required
            error={formErrors.integrationTypeId}
            control={Dropdown}
            selection
            search
            name="integrationTypeId"
            value={formData.integrationTypeId}
            fluid
            label="Integration type"
            options={Object.values(EsriIntegrationTypes)?.map((t) => ({ key: t.id, text: t.name, value: t.id }))}
            onChange={handleChange}
          />
        </Form.Group>
        {formData.integrationTypeId && formData.integrationTypeId !== EsriIntegrationTypes.NONE.id && (
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Web map item ID </label>
              <Form.Input
                error={formErrors.webMapItemId}
                name="webMapItemId"
                value={formData.webMapItemId}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default AdminEditEsriConfigForm;
