import LayoutWithSidebar from "../components/LayoutWithSidebar";
import MainSideMenu from "../components/MainSideMenu";
import OrgRolesListContainer from "../containers/OrgRolesListContainer";
import MainHeaderContainer from "../containers/MainHeaderContainer";
import OrganizationSettingsMenu from "../components/OrganizationSettingsMenu";
import { Header, Segment } from "semantic-ui-react";

const OrgRolePage = () => {
  const headerContent = () => {
    return <MainHeaderContainer></MainHeaderContainer>;
  };

  const sidebarContent = () => {
    return <MainSideMenu></MainSideMenu>;
  };

  const contentPanelContent = () => {
    return (
      <>
        <Segment basic>
          <Header as="h2">Organization's Settings</Header>
        </Segment>
        <OrganizationSettingsMenu></OrganizationSettingsMenu>
        <OrgRolesListContainer></OrgRolesListContainer>
      </>
    );
  };

  return (
    <LayoutWithSidebar
      headerContent={headerContent()}
      sidebarContent={sidebarContent()}
      mainPanelContent={contentPanelContent()}
    ></LayoutWithSidebar>
  );
};

export default OrgRolePage;
