import { gql } from "@apollo/client";

export const CORE_ORGANIZATION_FIELDS = gql`
  fragment CoreOrganizationFields on Organization {
    id
    name
    logoUrl
    esriConfig {
      integrationTypeId
      webMapItemId
    }
  }
`;
