import { useEffect, useState } from "react";
import { Page, Document } from "@react-pdf/renderer";
import { Permit } from "../../models/Permit";
import { Organization } from "../../models/Organization";
import authService from "../api-authorization/AuthorizeService";
import PermitDescriptionPage from "./PermitDescriptionPage";
import EightPointChecklistPage from "./EightPointChecklistPage";
import AuthorizationsPage from "./AuthorizationsPage";
import PhotosPage from "./PhotosPage";
import DocumentsPage from "./DocumentsPage";
import styles from "./components/Styles";
import SubmissionPage from "./SubmissionPage";

type Props = {
  permit: Permit;
  organization: Organization;
};

const PermitPdf = ({ permit, organization }: Props) => {
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getToken() {
      const val = await authService.getAccessToken();

      if (val) {
        setToken(val);
      }
    }

    getToken();
  }, []);

  if (!token) return null;

  return (
    <Document>
      <Page style={styles.body}>
        <PermitDescriptionPage permit={permit} orgLogoUrl={organization.logoUrl} token={token} />
        <DocumentsPage permit={permit} orgLogoUrl={organization.logoUrl} token={token} />
        <SubmissionPage permit={permit} orgLogoUrl={organization.logoUrl} token={token} />
        <AuthorizationsPage permit={permit} orgLogoUrl={organization.logoUrl} token={token} />
      </Page>
      {permit?.eightPointCheckList && (
        <Page style={styles.body} wrap>
          <EightPointChecklistPage permit={permit} orgLogoUrl={organization.logoUrl} token={token} />
        </Page>
      )}
      {permit?.sitePhotos && permit.sitePhotos.length > 0 && (
        <Page style={styles.body} wrap>
          <PhotosPage permit={permit} orgLogoUrl={organization.logoUrl} token={token} />
        </Page>
      )}
    </Document>
  );
};

export default PermitPdf;
