import React, { useEffect } from "react";
import { useGetPermitIssueTrend } from "../operations/queries/getPermitIssueTrend";
import { useCurrentProject } from "../hooks/useCurrentProject";
import ProjectPermitIssueTrend from "../components/ProjectPermitIssueTrend";

interface Props {}

const ProjectPermitIssueTrendContainer = (props: Props) => {
  const { currentProject } = useCurrentProject();

  const { getPermitIssueTrend, data: permitIssueTrend } = useGetPermitIssueTrend();

  useEffect(() => {
    if (currentProject) {
      getPermitIssueTrend({
        variables: {
          projectId: currentProject.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  if (!permitIssueTrend) return null;

  return (
    <ProjectPermitIssueTrend
      data={permitIssueTrend.permitIssueTrend.map(function (item) {
        return { year: item.year, month: item.month, count: item.count };
      })}
    />
  );
};

export default ProjectPermitIssueTrendContainer;
