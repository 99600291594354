import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Dropdown, Form, InputOnChangeData } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import { Organization } from "../models/Organization";
import ErrorsView from "./ErrorsView";

export interface FormData {
  startDate: Date;
  endDate: Date;
  numberOfUsers: string;
  comment: string;
  organizationId: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  organizations?: Organization[] | null;
}

const AdminAddLicenceForm = ({ formData, formErrors, errors, onChange, organizations }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  const onStartDateChange = (e: any, data: any) => {
    formData.startDate = data.value;
  };

  const onEndDateChange = (e: any, data: any) => {
    formData.endDate = data.value;
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            required
            error={formErrors.organizationId}
            control={Dropdown}
            selection
            search
            name="organizationId"
            value={formData.organizationId}
            fluid
            label="Organization"
            options={organizations?.map((o) => ({ key: o.id, text: o.name, value: o.id }))}
            onChange={handleChange}
          />
          <Form.Field required>
            <label>Number of users</label>
            <Form.Input
              error={formErrors.numberOfUsers}
              name="numberOfUsers"
              value={formData.numberOfUsers}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <SemanticDatepicker
            required
            label="Start date"
            value={formData.startDate}
            error={formErrors.startDate}
            locale="en-US"
            onChange={onStartDateChange}
            type="basic"
          />
          <SemanticDatepicker
            required
            label="End date"
            value={formData.endDate}
            error={formErrors.endDate}
            locale="en-US"
            onChange={onEndDateChange}
            type="basic"
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>Comments</label>
            <Form.Input error={formErrors.comment} name="comment" value={formData.comment} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default AdminAddLicenceForm;
