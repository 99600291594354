import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_PROJECT_FIELDS } from "../fragments/project";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";
import {
  GetProjectAssignmentsByProjectId,
  GetProjectAssignmentsByProjectIdVariables,
} from "./__generated__/GetProjectAssignmentsByProjectId";

export const GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID = gql`
  ${CORE_PROJECT_FIELDS}
  ${ORG_USER_FIELDS}
  ${ORG_ROLE_FIELDS}
  query GetProjectAssignmentsByProjectId($projectId: String!) {
    projectAssignmentsByProjectId(projectId: $projectId) {
      id
      orgRole {
        ...OrgRoleFields
      }
      orgUser {
        ...OrgUserFields
      }
      project {
        ...CoreProjectFields
      }
    }
  }
`;

export function useGetProjectAssignmentsByProjectId() {
  const [getProjectAssignmentsByProjectId, { loading, error, data }] = useLazyQuery<
    GetProjectAssignmentsByProjectId,
    GetProjectAssignmentsByProjectIdVariables
  >(GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID, {
    errorPolicy: "all",
  });

  return {
    getProjectAssignmentsByProjectId,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
