import { Dropdown, Form, InputOnChangeData } from "semantic-ui-react";
import { DocumentType } from "../models/DocumentType";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";
import FileDropZone from "./common/FileDropZone";
import UserFiles from "./common/UserFiles";
import { UserFile } from "../models/UserFile";

export interface FormData {
  title: string;
  typeId: string;
  userFile?: UserFile;
  versionNo: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
  documentTypes?: DocumentType[];
  docType?: DocumentType;
  onUploadFile: (userFile: UserFile) => void;
}

const AddDocumentForm = ({ formData, formErrors, errors, onChange, documentTypes, docType, onUploadFile }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };
  const handleFileSelected = (userFiles: UserFile[]) => {
    onUploadFile(userFiles[0]);
  };
  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            required
            disabled={!!docType}
            error={formErrors.typeId}
            control={Dropdown}
            selection
            search
            name="typeId"
            value={formData.typeId}
            fluid
            label="Type"
            options={documentTypes?.map((t) => ({ key: t.id, text: t.name, value: t.id }))}
            onChange={handleChange}
          />
          <Form.Field required>
            <label>Title</label>
            <Form.Input error={formErrors.title} name="title" value={formData.title} onChange={handleChange} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="2">
          <Form.Field required>
            <label>Version</label>
            <Form.Input
              error={formErrors.versionNo}
              name="versionNo"
              value={formData.versionNo}
              onChange={handleChange}
              type="number"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>File</label>
            {formErrors.userFile && (
              <span className="field error">
                <label>{formErrors.userFile}</label>
              </span>
            )}
            <FileDropZone multiple={false} accept={[".pdf"]} onFilesSelected={handleFileSelected} />
          </Form.Field>
        </Form.Group>

        {formData.userFile && (
          <Form.Group widths="equal">
            <Form.Field>
              <UserFiles userFilesMap={new Map([[formData.userFile.id, formData.userFile]])} />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default AddDocumentForm;
