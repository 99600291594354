import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";

interface Props {}

const OrganizationSettingsMenu = (props: Props) => {
  const { orgName } = useParams<{ orgName: string }>();

  return (
    <Menu pointing secondary color="green">
      <Menu.Item as={NavLink} content="Permit Document Types" to={"/" + orgName + "/settings/document-types"} />
      <Menu.Item as={NavLink} content="Roles" to={"/" + orgName + "/settings/roles"} />
      <Menu.Item as={NavLink} content="Configurations" to={"/" + orgName + "/settings/configurations"} />
    </Menu>
  );
};

export default OrganizationSettingsMenu;
