import { useState } from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import AddNewVersionToDocumentModalContainer from "../containers/AddNewVersionToDocumentModalContainer";
import { Document } from "../models/Document";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitInProgress: boolean;
  title?: string;
  showAsBuilt?: boolean;
  documents?: Document[];
  onAsBuiltAdded?: () => void;
}

const CompletePermitModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  title,
  showAsBuilt,
  documents,
  onAsBuiltAdded,
}: Props) => {
  const [selectedDocumentToUploadAsBuilt, setSelectedDocumentToUploadAsBuilt] = useState<Document | null>(null);

  const handleSubmit = () => {
    onSubmit();
  };

  const renderDocTableRow = () => {
    if (documents) {
      return documents.map((doc) => (
        <Table.Row key={doc.id}>
          <Table.Cell>
            {doc.title}, version {doc.versionNo}
          </Table.Cell>
          <Table.Cell>
            {doc.versionNo === doc.latest.versionNo ? (
              <span>
                "Not uploaded"{" "}
                <Button
                  size="mini"
                  onClick={() => {
                    setSelectedDocumentToUploadAsBuilt(doc);
                  }}
                >
                  Upload now
                </Button>
              </span>
            ) : (
              <span>version {doc.latest.versionNo}</span>
            )}
          </Table.Cell>
        </Table.Row>
      ));
    }
  };

  return (
    <>
      <Modal open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {showAsBuilt ? (
              <>
                <p>
                  This permit requires as-built for drawings. Please review and confirm that updated version of permit
                  drawings have been uploaded.
                </p>
                <Table basic="very" compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Drawing</Table.HeaderCell>
                      <Table.HeaderCell>As-Built version</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>{renderDocTableRow()}</Table.Body>
                </Table>
              </>
            ) : (
              <>Are you sure?</>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
            Mark as complete
          </Button>
        </Modal.Actions>
      </Modal>

      <AddNewVersionToDocumentModalContainer
        open={selectedDocumentToUploadAsBuilt !== null}
        document={selectedDocumentToUploadAsBuilt!}
        onClose={() => {
          setSelectedDocumentToUploadAsBuilt(null);
        }}
        onDocumentAdded={(doc) => {
          setSelectedDocumentToUploadAsBuilt(null);
          onAsBuiltAdded && onAsBuiltAdded();
        }}
      />
    </>
  );
};

export default CompletePermitModal;
