import { View } from "@react-pdf/renderer";
import React from "react";

type Props = {
  bgColor?: string;
  border?: boolean;
  last?: boolean;
  first?: boolean;
  children: React.ReactNode;
};

const Row = ({ bgColor, border, first, last, children }: Props) => {
  return (
    <View
      style={{
        borderLeft: border ? "1px solid #666" : "none",
        borderRight: border ? "1px solid #666" : "none",
        borderBottom: border ? "1px solid #666" : "none",
        borderTop: border && first ? "1px solid #666" : "none",
        backgroundColor: bgColor ? bgColor : "",
        display: "flex",
        flexDirection: "row",
        margin: 0,
      }}
    >
      {children}
    </View>
  );
};

export default Row;
