import { text } from "body-parser";
import React from "react";
import { Button, ButtonProps } from "semantic-ui-react";

type Props = {
  icon?: string;
  text?: string;
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => void) | undefined;
  loading?: boolean;
  disabled?: boolean;
};

const DetailsBoxActionButton = ({ icon, text, onClick, loading, disabled }: Props) => {
  return (
    <Button
      circular
      floated="right"
      style={{ padding: "5px 7px", marginLeft: "8px", "box-shadow": "0px 0px" }}
      icon={icon}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      {text && <>{text}</>}
    </Button>
  );
};

export default DetailsBoxActionButton;
