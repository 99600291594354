import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import EditOrgUserForm, { FormData } from "./EditOrgUserForm";
import { CalibrationCertificate } from "../models/CalibrationCertificate";
import { trancateTime } from "../utils";
import { OrgUser_WithFiles } from "../models/OrgUser_WithFiles";
import { OrgUserFileInput } from "../models/globalTypes";
import AddOrgUserFileModalContainer from "../containers/AddOrgUserFileModalContainer";
import { OrgUserFile_AndInput } from "../models/OrgUserFile_AndInput";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    userId: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    newOrgUserFiles: OrgUserFileInput[],
    deletedOrgUserFileIds: string[]
  ) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  orgUser?: OrgUser_WithFiles;
}

const EditOrgUserModal = ({ open, onClose, onSubmit, submitInProgress, submitErrors, orgUser }: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [orgUserFiles, setOrgUserFiles] = useState<OrgUserFile_AndInput[]>([]);
  const [newOrgUserFiles, setNewOrgUserFiles] = useState<OrgUserFileInput[]>([]);
  const [openAddOrgUserFileModal, setOpenAddOrgUserFileModal] = useState(false);
  const [deletedOrgUserFileIds, setDeletedOrgUserFileIds] = useState<string[]>([]);

  useEffect(() => {
    if (open && orgUser) {
      setFormData({
        firstName: orgUser.user.firstName,
        lastName: orgUser.user.lastName,
        isAdmin: orgUser.isAdmin,
        phoneNumber: orgUser.user.phoneNumber,
        CatSerialNumber: orgUser.calibrationCertificate?.catSerialNumber,
        CatCalibrationDate: orgUser.calibrationCertificate?.catCalibrationDate
          ? new Date(orgUser.calibrationCertificate.catCalibrationDate)
          : undefined,
        GennySerialNumber: orgUser.calibrationCertificate?.gennySerialNumber,
        GennyCalibrationDate: orgUser.calibrationCertificate?.gennyCalibrationDate
          ? new Date(orgUser.calibrationCertificate.gennyCalibrationDate)
          : undefined,
      } as FormData);
      setOrgUserFiles(orgUser.orgUserFiles);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
      setNewOrgUserFiles([]);
      setDeletedOrgUserFileIds([]);
    }
  }, [open, orgUser]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const {
        firstName,
        lastName,
        isAdmin,
        phoneNumber,
        CatSerialNumber,
        CatCalibrationDate,
        GennySerialNumber,
        GennyCalibrationDate,
      } = formData;
      onSubmit(
        orgUser?.userId!,
        firstName,
        lastName,
        isAdmin || false,
        phoneNumber,
        {
          catSerialNumber: CatSerialNumber,
          catCalibrationDate: CatCalibrationDate ? trancateTime(CatCalibrationDate) : null,
          gennySerialNumber: GennySerialNumber,
          gennyCalibrationDate: GennyCalibrationDate ? trancateTime(GennyCalibrationDate) : null,
        } as CalibrationCertificate,
        newOrgUserFiles,
        deletedOrgUserFileIds
      );
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handleOrgUserFileAdded = (orgUserFile: OrgUserFileInput) => {
    setNewOrgUserFiles([...newOrgUserFiles, orgUserFile]);
    setOrgUserFiles([...orgUserFiles, orgUserFile]);
    setOpenAddOrgUserFileModal(false);
  };

  const handleDeleteOrgUserFile = (orgUserFile: OrgUserFile_AndInput) => {
    if (orgUserFile.id) {
      setDeletedOrgUserFileIds([...deletedOrgUserFileIds, orgUserFile.id]);
    } else {
      setNewOrgUserFiles([...newOrgUserFiles.filter((f) => f !== orgUserFile)]);
    }
    setOrgUserFiles([...orgUserFiles.filter((f) => f !== orgUserFile)]);
  };

  const handelClose = () => {
    setOrgUserFiles([]);
    setNewOrgUserFiles([]);
    setDeletedOrgUserFileIds([]);
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={handelClose}>
        <Modal.Content>
          <Modal.Description>
            <EditOrgUserForm
              formErrors={formErrors}
              errors={submitErrors}
              formData={formData}
              onChange={handleFormChange}
              orgUserFiles={orgUserFiles}
              onAddOrgUserFile={() => setOpenAddOrgUserFileModal(true)}
              onDeleteOrgUserFile={handleDeleteOrgUserFile}
            ></EditOrgUserForm>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handelClose}>Cancel</Button>
          <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      <AddOrgUserFileModalContainer
        open={openAddOrgUserFileModal}
        onOrgUserFileAdded={handleOrgUserFileAdded}
        onClose={() => setOpenAddOrgUserFileModal(false)}
      ></AddOrgUserFileModalContainer>
    </>
  );
};

export default EditOrgUserModal;
