import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { CORE_GANG_MEMBER_FIELDS } from "../fragments/GangMember";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { UpdateGangMember, UpdateGangMemberVariables } from "./__generated__/UpdateGangMember";

const UPDATE_GANG_MEMBER = gql`
  ${CORE_GANG_MEMBER_FIELDS}
  mutation UpdateGangMember(
    $id: String!
    $firstName: String!
    $lastName: String!
    $jobTitle: String
    $employerName: String
    $companyInduction: CompanyInductionInput
    $buriedServicesInduction: BuriedServicesInductionInput
  ) {
    updateGangMember(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        employerName: $employerName
        companyInduction: $companyInduction
        buriedServicesInduction: $buriedServicesInduction
      }
    ) {
      gangMember {
        ...CoreGangMemberFields
      }
    }
  }
`;

export function useUpdateGangMember() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateGangMember,
    UpdateGangMemberVariables
  >(UPDATE_GANG_MEMBER, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
