import { Checkbox, Form } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";

export interface FormData {
  id: string;
  name: string;
  key: string;
  declaration: string;
  isMandatory: boolean;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  showDocumentTypeKey: boolean;
  onChange: (formData: FormData) => void;
}

const EditDocumentTypeForm = ({ formData, formErrors, errors, showDocumentTypeKey, onChange }: Props) => {
  const handleChange = (e: any, { name, value }: any) => {
    onChange({ ...formData, [name]: value });
  };

  const hanldeSettingsChange = (e: any, { name, checked }: any) => {
    onChange({ ...formData, [name]: checked });
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Name</label>
            <Form.Input error={formErrors.name} name="name" value={formData.name} onChange={handleChange} />
          </Form.Field>
          {showDocumentTypeKey && formData.key && (
            <Form.Field disabled>
              <label>System name</label>
              <Form.Input name="systemName" value={formData.key} />
            </Form.Field>
          )}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              label="Declaration text for when a document of this type is added to a permit (optional)"
              name="declaration"
              value={formData.declaration}
              placeholder="Declaration"
              onChange={handleChange}
              error={formErrors.declaration}
            ></Form.Input>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              label="By ticking this box you make this document type mandatory, so at least one document of this type is required for submitting a permit"
              name="isMandatory"
              checked={formData.isMandatory}
              onChange={hanldeSettingsChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default EditDocumentTypeForm;
