import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddReferenceProcedure, AddReferenceProcedureVariables } from "./__generated__/AddReferenceProcedure";
import { CORE_REFERENCE_PROCEDURE_FIELDS } from "../fragments/ReferenceProcedure";

const ADD_REFERENCE_PROCEDURE = gql`
  ${CORE_REFERENCE_PROCEDURE_FIELDS}
  mutation AddReferenceProcedure(
    $title: String!
    $organizationId: String!
    $pId: String
    $fileMetaData: FileMetaDataInput!
  ) {
    addReferenceProcedure(
      input: { title: $title, organizationId: $organizationId, pId: $pId, fileMetaData: $fileMetaData }
    ) {
      referenceProcedure {
        ...CoreReferenceProcedureFields
      }
    }
  }
`;

export function useAddReferenceProcedure() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddReferenceProcedure,
    AddReferenceProcedureVariables
  >(ADD_REFERENCE_PROCEDURE, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
