import AddPermit from "../components/AddPermit";
import { useAddPermit } from "../operations/mutations/addPermit";
import { useUpdatePermit } from "../operations/mutations/updatePermit";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useLazyGetPermitToDig } from "../operations/queries/getPermitToDig";
import { useSubmitPermit } from "../operations/mutations/submitPermit";
import { triggerSavePermitted, triggerSubmitPermitted } from "../models/Permit";
import { PermitDataInput } from "../models/PermitDataInput";
import { useLazyGetAffectedServicesOptions } from "../operations/queries/getAffectedServicesOptions";
import { useGetProjectAssignmentsByProjectId } from "../operations/queries/getProjectAssignmentsByProjectId";
import { useLazyGetNextPermitNumber } from "../operations/queries/getNextPermitNumber";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";
import { EsriIntegrationTypes } from "../models/EsriIntegrationType";
import { PermitAssignmentInput } from "../models/PermitAssignmentInput";
import { useLazyGetDocumentTypesByOrganizationId } from "../operations/queries/getDocumentTypesByOrganizationId";
import { useLazyGetOrganizationConfiguration } from "../operations/queries/getOrganizationConfiguration";

const AddPermitContainer = () => {
  const { currentOrganization } = useCurrentOrganization();
  const { currentProject } = useCurrentProject();
  const { permitId } = useParams<{ permitId: string }>();

  const { mutate: submitPermitMutate, status: submitPermitStatus } = useSubmitPermit();
  const { getProjectAssignmentsByProjectId: getProjectAssignments, data: projectAssignments } =
    useGetProjectAssignmentsByProjectId();
  const { getAffectedServicesOptions, data: affectedServicesOptions } = useLazyGetAffectedServicesOptions();
  const { getPermitToDig: getPermit, data: permit } = useLazyGetPermitToDig();
  const { getNextPermitNumber, data: getNextPermitNumberData } = useLazyGetNextPermitNumber();
  const { mutate: addPermitMutate, status: addPermitStatus } = useAddPermit();
  const { mutate: updatePermitMutate, status: updatePermitStatus } = useUpdatePermit();
  const {
    getDocumentTypesByOrganizationId: getDocumentTypes,
    data: documentTypes,
    status: getDocumentTypesStatus,
  } = useLazyGetDocumentTypesByOrganizationId();
  const {
    getOrganizationConfiguration,
    data: organizationConfiguration,
    status: getOrganizationConfigurationStatus,
  } = useLazyGetOrganizationConfiguration();

  useEffect(() => {
    if (currentOrganization) {
      getDocumentTypes({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getDocumentTypes]);

  useEffect(() => {
    if (currentOrganization) {
      getOrganizationConfiguration({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getOrganizationConfiguration]);

  useEffect(() => {
    if (currentProject) {
      getProjectAssignments({ variables: { projectId: currentProject.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (currentProject && !permitId) {
      getNextPermitNumber({ variables: { projectId: currentProject.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, permitId]);

  useEffect(() => {
    getAffectedServicesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permitId) {
      getPermit({ variables: { id: permitId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitId]);

  const handleAddPermit = (
    permitDataInput: PermitDataInput,
    number: number,
    documentIds: string[],
    asBuiltRequired: boolean,
    boundaries: GeoJSONPolygonType,
    permitAssignmentInputs: PermitAssignmentInput[]
  ) => {
    if (currentProject) {
      addPermitMutate({
        variables: {
          permitDataInput,
          number,
          projectId: currentProject.id,
          documentIds,
          asBuiltRequired,
          boundaries,
          permitAssignmentInputs,
        },
      });
    }
  };

  const handleEditPermit = (
    id: string,
    permitDataInput: PermitDataInput,
    documentIds: string[],
    asBuiltRequired: boolean,
    boundaries: GeoJSONPolygonType,
    permitAssignmentInputs: PermitAssignmentInput[]
  ) => {
    if (currentProject) {
      const { __typename, ...boundariesInput } = boundaries || {};

      updatePermitMutate({
        variables: {
          id,
          permitDataInput,
          documentIds,
          asBuiltRequired,
          boundaries: Object.keys(boundariesInput).length === 0 ? null : boundariesInput,
          permitAssignmentInputs,
        },
      });
    }
  };

  const handleSubmitPermit = (
    id: string,
    permitDataInput: PermitDataInput,
    number: number,
    documentIds: string[],
    asBuiltRequired: boolean,
    boundaries: GeoJSONPolygonType,
    permitAssignmentInputs: PermitAssignmentInput[]
  ) => {
    if (currentProject) {
      const { __typename, ...boundariesInput } = boundaries || {};

      submitPermitMutate({
        variables: {
          id,
          permitDataInput,
          number,
          projectId: currentProject.id,
          documentIds,
          asBuiltRequired,
          boundaries: Object.keys(boundariesInput).length === 0 ? null : boundariesInput,
          permitAssignmentInputs,
        },
      });
    }
  };

  return (
    <AddPermit
      showPermitBoundariesOnMap={
        currentOrganization?.esriConfig?.integrationTypeId != null &&
        currentOrganization.esriConfig.integrationTypeId !== EsriIntegrationTypes.NONE.id
      }
      orgRoles={currentOrganization?.orgRoles}
      saveButtonVisible={!permit || triggerSavePermitted(permit)}
      submitButtonVisible={!permit || triggerSubmitPermitted(permit)}
      submissionDeclaration={
        organizationConfiguration &&
        organizationConfiguration.organizationConfiguration.permitSubmission?.preSubmissionDeclarations
      }
      permit={permit}
      currentProject={currentProject}
      affectedServicesOptions={affectedServicesOptions && affectedServicesOptions.affectedServicesOptions}
      projectAssignments={projectAssignments && projectAssignments.projectAssignmentsByProjectId}
      mandatoryDocTypes={
        documentTypes && documentTypes.documentTypesByOrganizationId.filter((t) => t.isMandatory && !t.isArchived)
      }
      onAddPermit={handleAddPermit}
      addPermitStatus={addPermitStatus}
      onEditPermit={handleEditPermit}
      editPermitStatus={updatePermitStatus}
      onSubmitPermit={handleSubmitPermit}
      submitPermitStatus={submitPermitStatus}
      nextPermitNumber={getNextPermitNumberData?.nextPermitNumber}
    ></AddPermit>
  );
};

export default AddPermitContainer;
