import { Component } from "react";
import { NavLink } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { Dropdown, Icon } from "semantic-ui-react";

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      name: null,
      userName: null,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    this.setState({
      isAuthenticated,
      name: `${user.given_name || ""} ${user.family_name || ""}`,
      userName: user && user.name,
    });
  }

  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  render() {
    const { isAuthenticated, userName, name } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      const displayName = this.isEmptyOrSpaces(name) ? userName : name;
      return this.authenticatedView(displayName, profilePath, logoutPath);
    }
  }

  authenticatedView(displayName, profilePath, logoutPath) {
    return (
      <Dropdown item trigger={<Icon name="user outline" />}>
        <Dropdown.Menu>
          <Dropdown.Item>{displayName}</Dropdown.Item>
          <Dropdown.Item as={NavLink} to={profilePath}>
            Profile
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={logoutPath}>
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  anonymousView(registerPath, loginPath) {
    return <div></div>;
  }
}
