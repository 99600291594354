import { useEffect } from "react";
import { OrgUser } from "../models/OrgUser";
import { CalibrationCertificate } from "../models/CalibrationCertificate";
import { useLazyGetOrgUserByOrganizationIdAndOrgUserId } from "../operations/queries/getOrgUserByOrganizationIdAndOrgUserId";
import EditOrgUserModal from "../components/EditOrgUserModal";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { OrgUserFileInput } from "../models/globalTypes";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    userId: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    phoneNumber: string,
    calibrationCertificate: CalibrationCertificate,
    newOrgUserFiles: OrgUserFileInput[],
    deletedOrgUserFileIds: string[]
  ) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  orgUser?: OrgUser;
}

const EditOrgUserModalContainer = ({ open, onClose, onSubmit, submitInProgress, submitErrors, orgUser }: Props) => {
  const { currentOrganization } = useCurrentOrganization();

  const {
    getOrgUserByOrganizationIdAndOrgUserId: getOrgUser,
    data: orgUserWithFiles,
    status: getOrgUserStatus,
  } = useLazyGetOrgUserByOrganizationIdAndOrgUserId();

  useEffect(() => {
    if (currentOrganization && orgUser) {
      getOrgUser({ variables: { organizationId: currentOrganization.id, orgUserId: orgUser.id } });
    }
  }, [getOrgUser, currentOrganization, orgUser]);

  return (
    <EditOrgUserModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      submitInProgress={submitInProgress}
      orgUser={orgUserWithFiles?.orgUserByOrganizationIdAndOrgUserId}
      submitErrors={submitErrors}
    ></EditOrgUserModal>
  );
};

export default EditOrgUserModalContainer;
