import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import {
  GetReferenceProceduresByOrganizationId,
  GetReferenceProceduresByOrganizationIdVariables,
} from "./__generated__/GetReferenceProceduresByOrganizationId";
import { CORE_REFERENCE_PROCEDURE_FIELDS } from "../fragments/ReferenceProcedure";

export const GET_REFERENCE_PROCEDURES_BY_ORGANIZATION_ID = gql`
  ${CORE_REFERENCE_PROCEDURE_FIELDS}
  query GetReferenceProceduresByOrganizationId(
    $organizationId: String!
    $searchString: String
    $skip: Int
    $take: Int
    $pId: String
  ) {
    referenceProceduresByOrganizationId(
      organizationId: $organizationId
      searchString: $searchString
      skip: $skip
      take: $take
      pId: $pId
    ) {
      totalCount
      items {
        ...CoreReferenceProcedureFields
      }
    }
  }
`;

export function useLazyGetReferenceProceduresByOrganizationId() {
  const [getReferenceProceduresByOrganizationId, { loading, error, data, refetch }] = useLazyQuery<
    GetReferenceProceduresByOrganizationId,
    GetReferenceProceduresByOrganizationIdVariables
  >(GET_REFERENCE_PROCEDURES_BY_ORGANIZATION_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getReferenceProceduresByOrganizationId,
    data,
    refetch,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
