import { Image, Text, View } from "@react-pdf/renderer";
import styles from "./Styles";

type Props = { logoUrl: string | null; token: string; title: string; permitNumber?: number };

const Header = ({ logoUrl, token, title, permitNumber }: Props) => {
  return (
    <View
      fixed
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {logoUrl && (
        <Image
          style={{ ...styles.image, maxWidth: "20mm" }}
          src={{
            uri: logoUrl,
            method: "GET",
            headers: { authorization: `Bearer ${token}` },
            body: "",
          }}
        />
      )}
      <View style={{ width: "100%", alignSelf: "center", marginLeft: logoUrl ? "0mm" : "20mm" }}>
        <Text style={{ ...styles.title, width: "100%", alignSelf: "center" }}>{title}</Text>
        {permitNumber && (
          <Text style={{ ...styles.text, width: "100%", alignSelf: "center", textAlign: "center" }}>
            number: {permitNumber}
          </Text>
        )}
      </View>
      <Image style={{ ...styles.image, maxWidth: "20mm" }} source="/logoV.jpg" />
    </View>
  );
};

export default Header;
