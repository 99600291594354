import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";
import { GetApplicationPermissions } from "./__generated__/GetApplicationPermissions";
import { PERMISSION_FIELDS } from "../fragments/Permission";

export const GET_APPLICATION_PERMISSIONS = gql`
  ${PERMISSION_FIELDS}
  query GetApplicationPermissions {
    applicationPermissions {
      ...PermissionFields
    }
  }
`;

export function useLazyGetApplicationPermissions() {
  const [getApplicationPermissions, { loading, error, data }] = useLazyQuery<GetApplicationPermissions>(
    GET_APPLICATION_PERMISSIONS,
    {
      errorPolicy: "all",
    }
  );

  return {
    getApplicationPermissions,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
