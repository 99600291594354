import { gql } from "@apollo/client";
import { GET_ADMIN_USERS } from "../queries/getAdminUsers";
import { useResettableMutation } from "../useResettableMutation";
import { GetAdminUsers } from "../queries/__generated__/GetAdminUsers";
import { UpdateAdminUser, UpdateAdminUserVariables } from "./__generated__/UpdateAdminUser";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { ADMIN_USER_FIELDS } from "../fragments/AdminUser";

const UPDATE_ADMIN_USER = gql`
  ${ADMIN_USER_FIELDS}
  mutation UpdateAdminUser($id: String, $firstName: String!, $lastName: String!, $email: String) {
    updateAdminUser(input: { id: $id, firstName: $firstName, lastName: $lastName, email: $email }) {
      adminUser {
        ...AdminUserFields
      }
    }
  }
`;

export function useUpdateAdminUser() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UpdateAdminUser,
    UpdateAdminUserVariables
  >(UPDATE_ADMIN_USER, {
    update(cache, { data }) {
      const updatedAdminUserFromResponse = data?.updateAdminUser?.adminUser;
      const existingAdminUsers = cache.readQuery<GetAdminUsers>({
        query: GET_ADMIN_USERS,
      });

      if (existingAdminUsers && updatedAdminUserFromResponse) {
        cache.writeQuery({
          query: GET_ADMIN_USERS,
          data: {
            adminUsers: existingAdminUsers.adminUsers.map((a) =>
              a.id === updatedAdminUserFromResponse.id
                ? {
                    ...a,
                    firstName: updatedAdminUserFromResponse.firstName,
                    lastName: updatedAdminUserFromResponse.lastName,
                    email: updatedAdminUserFromResponse.email,
                  }
                : a
            ),
          },
        });
      }
    },
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
