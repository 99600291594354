import { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Button, Form, Modal } from "semantic-ui-react";
import { trancateTime } from "../utils";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (to: Date, comments: string) => void;
  submitInProgress: boolean;
  title?: string;
}

const ExtendPermitModal = ({ open, onClose, onSubmit, submitInProgress, title }: Props) => {
  const [comments, setComments] = useState<string>("");
  const [to, setTo] = useState<Date | null>();
  const [toError, setToError] = useState<string>("");

  useEffect(() => {
    setComments("");
    setTo(null);
    setToError("");
  }, [open]);

  const handleChange = (e: any, { name, value }: any) => {
    setComments(value);
  };

  const onToDateChange = (e: any, data: any) => {
    setTo(data.value);
  };

  const handleSubmit = () => {
    if (to) {
      onSubmit(trancateTime(to), comments);
    } else {
      setToError("Please enter date");
    }
  };

  const handelClose = () => {
    setComments("");
    onClose();
  };

  return (
    <Modal open={open} size="mini">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Group widths="equal">
              <SemanticDatepicker
                required
                label="To"
                value={to}
                error={toError}
                locale="en-US"
                onChange={onToDateChange}
                type="basic"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea value={comments} label="Comments" name="comments" onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ExtendPermitModal;
