import { SyntheticEvent, useEffect } from "react";
import { Column, Row, usePagination, useTable } from "react-table";
import {
  Divider,
  Dropdown,
  DropdownProps,
  Grid,
  GridColumn,
  Icon,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { Entity } from "../../models/Entity";
import GridActionButton, { ActionItem } from "../GridActionButton";

type TableProps<T extends Entity> = {
  data: T[];
  selectedRow?: T;
  columns: Column<T>[];
  onPageInfoChange: (pageIndex: number, pageSize: number) => void;
  loading: boolean;
  pageCount?: number;
  onRowClick?: (object: T, rowIndex: number) => void;
  actionItems?: ActionItem[];
  actionButtonColumn?: Column<T>;
};

export function DataGrid<T extends Entity>({
  columns,
  data,
  selectedRow,
  onPageInfoChange,
  loading,
  pageCount: controlledPageCount,
  onRowClick,
  actionItems,
  actionButtonColumn,
}: TableProps<T>) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    state,
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount ?? 1,
    },
    usePagination
  );

  useEffect(() => {
    if (controlledPageCount && state.pageIndex >= controlledPageCount) gotoPage(0);
  }, [state.pageIndex, controlledPageCount, gotoPage]);

  useEffect(() => {
    onPageInfoChange(state.pageIndex, state.pageSize);
  }, [onPageInfoChange, state.pageIndex, state.pageSize]);

  const handlePageChange = (event: SyntheticEvent, data: any) => {
    gotoPage(data.activePage - 1);
  };

  const handlePageSizeChange = (event: any, data: DropdownProps) => {
    setPageSize(Number(data.value ?? 15));
  };

  const handleRowClick = (row: Row<T>, index: number) => {
    if (onRowClick) {
      onRowClick(row.original, index);
    }
  };

  // Render the UI for your table
  return (
    <Segment basic loading={loading} style={{ padding: "0px", margin: "0px" }}>
      <Table basic="very" compact {...getTableProps()} selectable={!!onRowClick}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell {...column.getHeaderProps()}>{column.render("Header")}</Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Table.Row
                {...row.getRowProps()}
                onClick={() => handleRowClick(row, i)}
                className={selectedRow?.id === row.original.id ? "selected" : ""}
                style={{
                  cursor: !onRowClick ? "auto" : "pointer",
                }}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <Table.Cell {...cell.getCellProps()}>
                      {index === 0 && selectedRow?.id === row.original.id && <Icon name="chevron right"></Icon>}
                      {cell.render("Cell")}
                      {cell.column.Header === actionButtonColumn?.Header && actionItems && actionItems.length > 0 && (
                        <GridActionButton rowId={row.original.id} items={actionItems ? actionItems : []} />
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row textAlign="left">
            <Table.Cell colSpan={columns.length}>
              <Divider />
              <Grid columns="equal">
                <Grid.Row>
                  <GridColumn only="tablet computer" textAlign="left">
                    Page size:&nbsp;&nbsp;
                    <Dropdown
                      basic
                      onChange={handlePageSizeChange}
                      value={state.pageSize}
                      options={[
                        { key: 2, value: 2, text: 2 },
                        { key: 15, value: 15, text: 15 },
                        { key: 30, value: 30, text: 30 },
                        { key: 50, value: 50, text: 50 },
                        { key: 100, value: 100, text: 100 },
                      ]}
                    ></Dropdown>
                  </GridColumn>
                  <GridColumn textAlign="right" width="12">
                    <Pagination
                      firstItem={{ content: <>⟨⟨</>, disabled: !canPreviousPage }}
                      lastItem={{ content: <>⟩⟩</>, disabled: !canNextPage }}
                      nextItem={{ content: <>⟩</>, disabled: !canNextPage }}
                      prevItem={{ content: <>⟨</>, disabled: !canPreviousPage }}
                      totalPages={controlledPageCount ?? 1}
                      activePage={state.pageIndex + 1}
                      onPageChange={handlePageChange}
                      secondary
                    />
                  </GridColumn>
                </Grid.Row>
              </Grid>
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
}
