import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { ResetPasswordOrgUser, ResetPasswordOrgUserVariables } from "./__generated__/ResetPasswordOrgUser";

const RESET_ORG_USER_PASSWORD = gql`
  mutation ResetPasswordOrgUser($organizationId: String!, $userId: String!, $newPassword: String) {
    resetPasswordOrgUser(input: { organizationId: $organizationId, userId: $userId, newPassword: $newPassword }) {
      succeeded
    }
  }
`;

export function useResetOrgUserPassword() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    ResetPasswordOrgUser,
    ResetPasswordOrgUserVariables
  >(RESET_ORG_USER_PASSWORD, {
    errorPolicy: "all",
  });

  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
