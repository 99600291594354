import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils/apolloError";
import {
  GetOrgUserByOrganizationIdAndOrgUserId,
  GetOrgUserByOrganizationIdAndOrgUserIdVariables,
} from "./__generated__/GetOrgUserByOrganizationIdAndOrgUserId";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";

export const GET_ORG_USER_BY_ORGANIZATION_ID_AND_ORG_USER_ID = gql`
  ${ORG_USER_FIELDS}
  query GetOrgUserByOrganizationIdAndOrgUserId($organizationId: String!, $orgUserId: String!) {
    orgUserByOrganizationIdAndOrgUserId(organizationId: $organizationId, orgUserId: $orgUserId) {
      ...OrgUserFields
      orgUserFiles {
        id
        title
        expiryDate
        fileUrl
      }
    }
  }
`;

export function useLazyGetOrgUserByOrganizationIdAndOrgUserId() {
  const [getOrgUserByOrganizationIdAndOrgUserId, { loading, error, data }] = useLazyQuery<
    GetOrgUserByOrganizationIdAndOrgUserId,
    GetOrgUserByOrganizationIdAndOrgUserIdVariables
  >(GET_ORG_USER_BY_ORGANIZATION_ID_AND_ORG_USER_ID, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return {
    getOrgUserByOrganizationIdAndOrgUserId,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
