import { DataGrid } from "./common/DataGrid";
import { Column } from "react-table";
import moment from "moment";
import { truncate } from "lodash";
import { Permit_ListItem } from "../models/Permit_ListItem";

interface Props {
  permits?: Permit_ListItem[] | null;
  selectedPermit?: Permit_ListItem;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onPermitClicked: (permitId: string) => void;
}

const PermitsGrid = ({ permits, selectedPermit, pageCount, onPageInfoChange, onPermitClicked }: Props) => {
  const columns: Column<Permit_ListItem>[] = [
    {
      Header: "No.",
      id: "No",
      accessor: (p) => p.number,
    },
    {
      Header: "Work Description",
      id: "WorkDescription",
      accessor: (p) => truncate(p.worksDescription!, { length: 30, separator: " " }),
    },
    {
      Header: "Location",
      id: "Location",
      accessor: (p) => p.locationOfWork,
    },
    {
      Header: "Start date",
      id: "StartDate",
      accessor: (p) => moment.utc(p.validityDuration?.from).local().format("L"),
    },
    {
      Header: "End date",
      id: "EndDate",
      accessor: (p) => moment.utc(p.validityDuration?.to).local().format("L"),
    },
    {
      Header: "State",
      id: "State",
      accessor: (p) => p.state.name,
    },
  ];

  const handlePermitClicked = (doc: Permit_ListItem, i: number) => {
    onPermitClicked(doc.id);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        data={permits ? permits : []}
        selectedRow={selectedPermit}
        onRowClick={handlePermitClicked}
        onPageInfoChange={onPageInfoChange}
        loading={false}
        pageCount={pageCount}
      />
    </>
  );
};

export default PermitsGrid;
