import React, { useEffect, useState } from "react";
import GangMembersList from "../components/GangMembersList";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import {
  GET_GANG_MEMBERS_BY_ORGANIZATION_ID,
  useLazyGetGangMembersByOrganizationId,
} from "../operations/queries/getGangMembersByOrganizationId";
import { useAddGangMember } from "../operations/mutations/addGangMember";
import { useUpdateGangMember } from "../operations/mutations/updateGangMember";
import { useToggleArchivedStatusGangMember } from "../operations/mutations/toggleArchivedStatusGangMember";
import { BuriedServicesInduction } from "../models/BuriedServicesInduction";
import { CompanyInduction } from "../models/CompanyInduction";

interface Props {}

const GangMembersListContainer = (props: Props) => {
  const { currentOrganization } = useCurrentOrganization();
  const [searchString, setSearchString] = useState("");
  const [isArchivedFilter, setIsArchivedFilter] = useState<boolean | null>(false);

  const {
    getGangMembersByOrganizationId: getGangMembers,
    data: gangMembers,
    status: getGangMembersStatus,
  } = useLazyGetGangMembersByOrganizationId();
  const { mutate: addGangMemberMutate, status: addGangMemberStatus } = useAddGangMember();
  const { mutate: updateGangMemberMutate, status: updateGangMemberStatus } = useUpdateGangMember();
  const { mutate: toggleArchivedStatusGangMemberMutate, status: toggleArchivedStatusGangMemberStatus } =
    useToggleArchivedStatusGangMember();

  const handleSearchGangMembers = (searchString: string) => {
    if (searchString !== undefined) setSearchString(searchString);
  };

  const handleIsArchivedFilter = (isArchived: boolean | null) => {
    setIsArchivedFilter(isArchived);
  };

  useEffect(() => {
    if (currentOrganization)
      getGangMembers({
        variables: {
          organizationId: currentOrganization.id,
          searchString,
          isArchived: isArchivedFilter,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, searchString, isArchivedFilter]);

  const handleAddGangMember = (
    firstName: string,
    lastName: string,
    jobTitle: string,
    employerName: string,
    companyInduction: CompanyInduction,
    buriedServicesInduction: BuriedServicesInduction
  ) => {
    if (currentOrganization) {
      addGangMemberMutate({
        refetchQueries: [
          {
            query: GET_GANG_MEMBERS_BY_ORGANIZATION_ID,
            variables: {
              organizationId: currentOrganization.id,
              searchString,
              isArchived: isArchivedFilter,
            },
          },
        ],
        variables: {
          firstName,
          lastName,
          jobTitle,
          employerName,
          organizationId: currentOrganization.id,
          companyInduction,
          buriedServicesInduction,
        },
      });
    }
  };

  const handleEditGangMember = (
    id: string,
    firstName: string,
    lastName: string,
    jobTitle: string,
    employerName: string,
    companyInduction: CompanyInduction,
    buriedServicesInduction: BuriedServicesInduction
  ) => {
    if (currentOrganization) {
      updateGangMemberMutate({
        variables: {
          id,
          firstName,
          lastName,
          jobTitle,
          employerName,
          companyInduction,
          buriedServicesInduction,
        },
      });
    }
  };

  const handleToggleArchivedStatusGangMember = (id: string) => {
    if (currentOrganization) {
      toggleArchivedStatusGangMemberMutate({
        variables: {
          id,
        },
      });
    }
  };

  return (
    <GangMembersList
      isGangMembersLoading={getGangMembersStatus.loading}
      gangMembers={gangMembers?.gangMembersByOrganizationId?.items && gangMembers.gangMembersByOrganizationId.items}
      onSearchGangMembers={handleSearchGangMembers}
      onAddGangMember={handleAddGangMember}
      addGangMemberStatus={addGangMemberStatus}
      onEditGangMember={handleEditGangMember}
      editGangMemberStatus={updateGangMemberStatus}
      onToggleArchivedStatusGangMember={handleToggleArchivedStatusGangMember}
      toggleArchivedStatusGangMemberStatus={toggleArchivedStatusGangMemberStatus}
      isArchivedFilterValue={isArchivedFilter}
      onIsArchivedFilter={handleIsArchivedFilter}
    ></GangMembersList>
  );
};

export default GangMembersListContainer;
