import { ApolloClient, ApolloLink, from, split } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import authService from "./components/api-authorization/AuthorizeService";
import { cache } from "./utils/cache";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";

type Props = {
  children: JSX.Element;
};

const ApolloConnection = ({ children }: Props) => {
  const httpLink = createUploadLink({
    uri: "/graphql",
  }) as unknown as ApolloLink; // There is a mismatch in ApolloLink types! cast to unknown as a quick fix for now

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from authService
    const token = await authService.getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

        if (extensions?.code === "AUTH_NOT_AUTHENTICATED") {
          console.log("[SIGN IN AGAIN...]");

          const state = { returnUrl: window.location.href };
          await authService.signIn(state);
        }

        // This is added for when the token is invalid and the user is still logged in! Sending the user to "/" will prevent
        // a loop if the user is really unauthorized to access a resource
        if (extensions?.code === "AUTH_NOT_AUTHORIZED") {
          const state = { returnUrl: "/" };
          await authService.signIn(state);
        }
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const { protocol, host } = window.location;
  const localUrl = `${protocol === "https:" ? "wss:" : "ws:"}//${host}`;
  const wsLink = new GraphQLWsLink(
    createClient({
      url: localUrl + "/graphql",
      lazy: true,
      connectionParams: async () => {
        const token = await authService.getAccessToken();
        return {
          AuthToken: token,
        };
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    //@ts-ignore
    link: from([errorLink, authLink, splitLink]),
    cache: cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloConnection;
