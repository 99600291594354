import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import {
  GetOrganizationConfiguration,
  GetOrganizationConfigurationVariables,
} from "./__generated__/GetOrganizationConfiguration";

export const GET_ORGANIZATION_CONFIGURATION = gql`
  query GetOrganizationConfiguration($organizationId: String!) {
    organizationConfiguration(organizationId: $organizationId) {
      permitWorkflow {
        permitMustHaveGangMembersToIssue
      }
      eightPointCheckList {
        items
        declaration
      }
      permitSubmission {
        preSubmissionDeclarations
      }
    }
  }
`;

export function useLazyGetOrganizationConfiguration() {
  const [getOrganizationConfiguration, { loading, error, data }] = useLazyQuery<
    GetOrganizationConfiguration,
    GetOrganizationConfigurationVariables
  >(GET_ORGANIZATION_CONFIGURATION, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getOrganizationConfiguration,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
