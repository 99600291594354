import { useEffect, useState } from "react";
import { Segment, Menu, Header } from "semantic-ui-react";
import AdminUsersGrid from "./AdminUsersGrid";
import { AdminUser } from "../models/AdminUser";
import AddAdminUserModal from "./AddAdminUserModal";
import EditAdminUserModal from "./EditAdminUserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import { toastSuccess } from "./../utils";
import { AsyncActionStatus } from "../models/AsyncActionStatus";

interface Props {
  isAdminUsersLoading: boolean;
  adminUsers?: AdminUser[];
  onAddAdminUser: (firstName: string, lastName: string, email: string, password: string) => void;
  addUserStatus: AsyncActionStatus;
  onEditAdminUser: (id: string, firstName: string, lastName: string, email: string) => void;
  editUserStatus: AsyncActionStatus;
  onDeleteAdminUser: (id: string) => void;
  deleteUserStatus: AsyncActionStatus;
  onResetPassword: (id: string, newPassword: string) => void;
  resetPasswordStatus: AsyncActionStatus;
}

const AdminUserList = ({
  isAdminUsersLoading,
  adminUsers,
  onAddAdminUser,
  addUserStatus,
  onEditAdminUser,
  editUserStatus,
  onDeleteAdminUser,
  deleteUserStatus,
  onResetPassword,
  resetPasswordStatus,
}: Props) => {
  const [openAddAdminUserModal, setOpenAddAdminUserModal] = useState(false);
  const [openEditAdminUserModal, setOpenEditAdminUserModal] = useState(false);
  const [openResetPasswordAdminUserModal, setOpenResetPasswordAdminUserModal] = useState(false);
  const [selectedAdminUserToEdit, setSelectedAdminUserToEdit] = useState<AdminUser | undefined>(undefined);

  const handleEditAdminUser = (adminUserId: string) => {
    const user = adminUsers?.find((u) => u.id === adminUserId);
    if (user) {
      setSelectedAdminUserToEdit({ ...user } as AdminUser);
      setOpenEditAdminUserModal(true);
    }
  };

  const handleResetPasswordAdminUser = (adminUserId: string) => {
    const user = adminUsers?.find((u) => u.id === adminUserId);
    if (user) {
      setSelectedAdminUserToEdit({ ...user } as AdminUser);
      setOpenResetPasswordAdminUserModal(true);
    }
  };

  const handleDeleteAdminUser = (adminUserId: string) => {
    onDeleteAdminUser(adminUserId);
  };

  useEffect(() => {
    if (deleteUserStatus.successful) {
      toastSuccess("Admin user deleted successfully!");
    }
  }, [deleteUserStatus.successful]);

  const handleModalsClose = () => {
    setOpenAddAdminUserModal(false);
    setOpenEditAdminUserModal(false);
    setOpenResetPasswordAdminUserModal(false);
    setSelectedAdminUserToEdit(undefined);

    addUserStatus.reset();
    editUserStatus.reset();
    deleteUserStatus.reset();
    resetPasswordStatus.reset();
  };

  const handleAddAdminUserModalSubmit = (firstName: string, lastName: string, email: string, password: string) => {
    onAddAdminUser(firstName, lastName, email, password);
  };

  useEffect(() => {
    if (addUserStatus.successful) {
      toastSuccess("Admin user added successfully!");
      setOpenAddAdminUserModal(false);
    }
  }, [addUserStatus.successful]);

  const handleEditAdminUserModalSubmit = (id: string, firstName: string, lastName: string, email: string) => {
    onEditAdminUser(id, firstName, lastName, email);
  };

  useEffect(() => {
    if (editUserStatus.successful) {
      toastSuccess("Admin user updated successfully!");
      setOpenEditAdminUserModal(false);
      setSelectedAdminUserToEdit(undefined);
    }
  }, [editUserStatus.successful]);

  const handleResetPasswordAdminUserModalSubmit = (newPassword: string) => {
    if (selectedAdminUserToEdit) {
      onResetPassword(selectedAdminUserToEdit.id, newPassword);
    }
  };

  useEffect(() => {
    if (resetPasswordStatus.successful) {
      toastSuccess("Password reset successfully!");
      setOpenResetPasswordAdminUserModal(false);
      setSelectedAdminUserToEdit(undefined);
    }
  }, [resetPasswordStatus.successful]);

  return (
    <>
      <Segment basic>
        <Header as="h2">Admin user</Header>
      </Segment>

      <Menu pointing secondary color="green">
        <Menu.Item icon="plus" name="New admin user" onClick={(e) => setOpenAddAdminUserModal(true)} />
      </Menu>

      <Segment basic loading={isAdminUsersLoading}>
        <AdminUsersGrid
          adminUsers={adminUsers}
          onEditAdminUser={handleEditAdminUser}
          onDeleteAdminUser={handleDeleteAdminUser}
          onResetPasswordAdminUser={handleResetPasswordAdminUser}
        />
      </Segment>

      <AddAdminUserModal
        open={openAddAdminUserModal}
        onClose={handleModalsClose}
        onSubmit={handleAddAdminUserModalSubmit}
        submitInProgress={addUserStatus.loading}
        submitErrors={addUserStatus.errors}
      ></AddAdminUserModal>

      <EditAdminUserModal
        open={openEditAdminUserModal}
        onClose={handleModalsClose}
        onSubmit={handleEditAdminUserModalSubmit}
        submitInProgress={editUserStatus.loading}
        adminUser={selectedAdminUserToEdit}
        submitErrors={editUserStatus.errors}
      ></EditAdminUserModal>

      <ResetPasswordModal
        open={openResetPasswordAdminUserModal}
        onClose={handleModalsClose}
        onSubmit={handleResetPasswordAdminUserModalSubmit}
        submitInProgress={resetPasswordStatus.loading}
        submitErrors={resetPasswordStatus.errors}
      ></ResetPasswordModal>
    </>
  );
};

export default AdminUserList;
