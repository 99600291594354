import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { GetProjectPolicies, GetProjectPoliciesVariables } from "./__generated__/GetProjectPolicies";

export const GET_PROJECT_POLICIES = gql`
  query GetProjectPolicies($projectId: String!) {
    projectPolicies(projectId: $projectId)
  }
`;

export function useLazyGetProjectPolicies() {
  const [getProjectPolicies, { loading, error, data }] = useLazyQuery<GetProjectPolicies, GetProjectPoliciesVariables>(
    GET_PROJECT_POLICIES,
    {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    }
  );

  return {
    getProjectPolicies,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
