import { useLazyGetDocumentTypesByOrganizationId } from "../operations/queries/getDocumentTypesByOrganizationId";
import AddDocumentModal from "../components/AddDocumentModal";
import { useAddDocument } from "../operations/mutations/addDocument";
import { useUploadFile } from "../operations/mutations/uploadFile";
import { toastSuccess } from "../utils";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect, useState } from "react";
import { UserFile } from "../models/UserFile";
import { FileMetaData } from "../models/FileMetaData";
import { Document_Data } from "../models/Document_Data";
import { DocumentType } from "../models/DocumentType";

interface Props {
  open: boolean;
  onDocumentAdded?: (doc: Document_Data) => void;
  docType?: DocumentType;
  onClose: () => void;
}

const AddDocumentModalContainer = ({ open, onDocumentAdded, docType, onClose }: Props) => {
  const { currentOrganization } = useCurrentOrganization();
  const { currentProject } = useCurrentProject();
  const [userFile, setUserFile] = useState<UserFile>();

  const { getDocumentTypesByOrganizationId: getDocumentTypes, data: documentTypes } =
    useLazyGetDocumentTypesByOrganizationId();
  const { mutate: addDocumentMutate, data: addDocument, status: addDocumentStatus } = useAddDocument();
  const {
    mutate: uploadFileMutate,
    data: uploadFile,
    error: uploadFileError,
    loading: uploadFileLoading,
  } = useUploadFile();

  const handleModalClose = () => {
    addDocumentStatus.reset();
    onClose();
  };

  useEffect(() => {
    if (currentOrganization) {
      getDocumentTypes({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getDocumentTypes]);

  const handleUploadFile = (userFile: UserFile) => {
    setUserFile(userFile);
    uploadFileMutate({
      variables: {
        file: userFile.file,
        fileId: userFile.id,
      },
    });
  };

  useEffect(() => {
    if (userFile) {
      if (uploadFile?.uploadFile?.fileId) {
        setUserFile({ ...userFile, uploadStatus: { ...userFile.uploadStatus, uploaded: true, uploading: false } });
      } else if (uploadFileError && uploadFileError.graphQLErrors) {
        setUserFile({
          ...userFile,
          uploadStatus: { ...userFile.uploadStatus, uploading: false, uploadError: uploadFileError.graphQLErrors[0] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile, uploadFileError]);

  const handleAddDocument = (title: string, typeId: string, versionNo: number, fileMetaData: FileMetaData) => {
    if (currentProject) {
      addDocumentMutate({
        variables: {
          title,
          projectId: currentProject.id,
          typeId,
          versionNo,
          fileMetaData: fileMetaData,
        },
      });
    }
  };

  useEffect(() => {
    if (addDocumentStatus.successful) {
      toastSuccess("New document added successfully!");
      if (onDocumentAdded && addDocument) {
        onDocumentAdded(addDocument.addDocument!.document!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDocumentStatus.successful]);

  return (
    <AddDocumentModal
      open={open}
      onClose={handleModalClose}
      onSubmit={handleAddDocument}
      submitInProgress={addDocumentStatus.loading}
      submitErrors={addDocumentStatus.errors}
      documentTypes={documentTypes && documentTypes.documentTypesByOrganizationId.filter((t) => !t.isArchived)}
      docType={docType}
      userFile={userFile}
      onUploadFile={handleUploadFile}
      uploading={uploadFileLoading}
    ></AddDocumentModal>
  );
};

export default AddDocumentModalContainer;
