import { gql } from "@apollo/client";
import { GET_ADMIN_USERS } from "../queries/getAdminUsers";
import { useResettableMutation } from "../useResettableMutation";
import { GetAdminUsers } from "../queries/__generated__/GetAdminUsers";
import { DeleteAdminUser, DeleteAdminUserVariables } from "./__generated__/DeleteAdminUser";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";

const DELETE_ADMIN_USER = gql`
  mutation DeleteAdminUser($id: String) {
    deleteAdminUser(input: { id: $id }) {
      id
    }
  }
`;

export function useDeleteAdminUser() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteAdminUser,
    DeleteAdminUserVariables
  >(DELETE_ADMIN_USER, {
    update(cache, { data }) {
      const deletedAdminUserIdFromResponse = data?.deleteAdminUser?.id;
      const existingAdminUsers = cache.readQuery<GetAdminUsers>({
        query: GET_ADMIN_USERS,
      });

      if (existingAdminUsers && deletedAdminUserIdFromResponse) {
        cache.writeQuery({
          query: GET_ADMIN_USERS,
          data: {
            adminUsers: [...existingAdminUsers.adminUsers.filter((u) => u.id !== deletedAdminUserIdFromResponse)],
          },
        });
      }
    },
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
