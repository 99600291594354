import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Divider, Form, InputOnChangeData } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";

export interface FormData {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  employerName: string;
  companyInduction_date: Date;
  companyInduction_number: string;
  buriedServicesInduction_date: Date;
  buriedServicesInduction_number: string;
  buriedServicesInduction_expiresOn: Date;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
}

const EditGangMemberForm = ({ formData, formErrors, errors, onChange }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  const onCompanyInductionDateChange = (e: any, data: any) => {
    formData.companyInduction_date = data.value;
  };

  const onBuriedServicesInductionDateChange = (e: any, data: any) => {
    formData.buriedServicesInduction_date = data.value;
  };

  const onBuriedServicesInductionExpiresOnChange = (e: any, data: any) => {
    formData.buriedServicesInduction_expiresOn = data.value;
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>First Name</label>
            <Form.Input
              name="firstName"
              value={formData.firstName}
              error={formErrors.firstName}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Last Name</label>
            <Form.Input name="lastName" value={formData.lastName} error={formErrors.lastName} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Job Title</label>
            <Form.Input name="jobTitle" value={formData.jobTitle} error={formErrors.jobTitle} onChange={handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Employer Name</label>
            <Form.Input
              name="employerName"
              value={formData.employerName}
              error={formErrors.employerName}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Divider horizontal section>
          Company induction
        </Divider>
        <Form.Group widths="3">
          <SemanticDatepicker
            label="Date"
            value={formData.companyInduction_date}
            error={formErrors.companyInduction_date}
            locale="en-US"
            onChange={onCompanyInductionDateChange}
            type="basic"
          />

          <Form.Field>
            <label>Number</label>
            <Form.Input
              name="companyInduction_number"
              value={formData.companyInduction_number}
              error={formErrors.companyInduction_number}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Divider horizontal section>
          Last buried services induction
        </Divider>
        <Form.Group widths="3">
          <SemanticDatepicker
            label="Date"
            value={formData.buriedServicesInduction_date}
            error={formErrors.buriedServicesInduction_date}
            locale="en-US"
            onChange={onBuriedServicesInductionDateChange}
            type="basic"
          />
          <Form.Field>
            <label>Number</label>
            <Form.Input
              name="buriedServicesInduction_number"
              value={formData.buriedServicesInduction_number}
              error={formErrors.buriedServicesInduction_number}
              onChange={handleChange}
            />
          </Form.Field>
          <SemanticDatepicker
            label="Expires on"
            value={formData.buriedServicesInduction_expiresOn}
            error={formErrors.buriedServicesInduction_expiresOn}
            locale="en-US"
            onChange={onBuriedServicesInductionExpiresOnChange}
            type="basic"
          />
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default EditGangMemberForm;
