import { gql } from "@apollo/client";
import { GET_ADMIN_USERS } from "../queries/getAdminUsers";
import { useResettableMutation } from "../useResettableMutation";
import { GetAdminUsers } from "../queries/__generated__/GetAdminUsers";
import { getErrors } from "../../utils";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { AddAdminUser, AddAdminUserVariables } from "./__generated__/AddAdminUser";
import { ADMIN_USER_FIELDS } from "../fragments/AdminUser";

const ADD_ADMIN_USER = gql`
  ${ADMIN_USER_FIELDS}
  mutation AddAdminUser($firstName: String, $lastName: String, $email: String!, $password: String!) {
    addAdminUser(input: { firstName: $firstName, lastName: $lastName, email: $email, password: $password }) {
      adminUser {
        ...AdminUserFields
      }
    }
  }
`;

export function useAddAdminUser() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<AddAdminUser, AddAdminUserVariables>(
    ADD_ADMIN_USER,
    {
      update(cache, { data }) {
        const newAdminUserFromResponse = data?.addAdminUser?.adminUser;
        const existingAdminUsers = cache.readQuery<GetAdminUsers>({
          query: GET_ADMIN_USERS,
        });

        if (existingAdminUsers && newAdminUserFromResponse) {
          cache.writeQuery({
            query: GET_ADMIN_USERS,
            data: {
              adminUsers: [...existingAdminUsers.adminUsers, newAdminUserFromResponse],
            },
          });
        }
      },
      errorPolicy: "all",
    }
  );
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
