import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useGeneralPolicies } from "../hooks/useGeneralPolicies";
import { useOrganizationPolicies } from "../hooks/useOrganizationPolicies";

const MainSideMenu = () => {
  const { currentOrganization } = useCurrentOrganization();
  const { authorizedSystemAdmin } = useGeneralPolicies();
  const {
    authorizedToManageOrgUsers,
    authorizedToManageProjects,
    authorizedToManageDocumentTypes,
    authorizedToManageGangMembers,
    organizationPolicies,
  } = useOrganizationPolicies();

  const orgName = currentOrganization?.name;

  return (
    <Menu borderless secondary vertical color="black" style={{ "margin-right": "1px" }} size="large">
      {currentOrganization && <Menu.Item icon="home" as={NavLink} content="Home" to={"/" + orgName + "/home"} />}
      {authorizedToManageProjects && (
        <Menu.Item icon="tasks" as={NavLink} content="Projects" to={"/" + orgName + "/projects"} />
      )}
      {authorizedToManageOrgUsers && (
        <Menu.Item icon="users" as={NavLink} content="Users" to={"/" + orgName + "/users"} />
      )}
      {authorizedToManageGangMembers && (
        <Menu.Item icon="id card" as={NavLink} content="Gang members" to={"/" + orgName + "/gang-members"} />
      )}
      {authorizedToManageProjects && (
        <Menu.Item
          icon="file"
          as={NavLink}
          content="Reference Procedures"
          to={"/" + orgName + "/reference-procedures"}
        />
      )}
      {authorizedToManageDocumentTypes && (
        <Menu.Item icon="settings" as={NavLink} content="Settings" to={"/" + orgName + "/settings/document-types"} />
      )}
      {authorizedSystemAdmin && <Menu.Item as={NavLink} content="Admin Settings" to="/settings/organizations" />}
    </Menu>
  );
};

export default MainSideMenu;
