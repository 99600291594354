import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { getErrors } from "../../utils/apolloError";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import {
  RequestRejectedPermitToDig,
  RequestRejectedPermitToDigVariables,
} from "./__generated__/RequestRejectedPermitToDig";

const REQUEST_REJECTED_PERMIT = gql`
  mutation RequestRejectedPermitToDig(
    $id: String!
    $permitDataInput: PermitDataInput
    $documentIds: [String!]
    $boundaries: GeoJSONPolygonInput
  ) {
    requestRejectedPermitToDig(
      input: { id: $id, permitDataInput: $permitDataInput, documentIds: $documentIds, boundaries: $boundaries }
    ) {
      permitToDig {
        id
      }
    }
  }
`;

export function useRequestRejectedPermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    RequestRejectedPermitToDig,
    RequestRejectedPermitToDigVariables
  >(REQUEST_REJECTED_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
