import moment, { Moment } from "moment";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { PermitToDigSignatureType } from "../models/globalTypes";
import { Permit } from "../models/Permit";
import ServerImage from "../components/common/ServerImage";
import { useEffect, useState } from "react";
import { isBetween } from "../utils";

interface Props {
  permit: Permit;
}

const GangMembersView = ({ permit }: Props) => {
  const [offset, setOffset] = useState(0);
  const limit = 7;

  useEffect(() => {
    if (permit) {
      if (isBetween(new Date(), permit.validityDuration.from, permit.validityDuration.to)) {
        const initialOffset =
          Math.floor(moment(new Date()).diff(moment(permit.validityDuration.from), "days") / limit) * limit;
        setOffset(initialOffset);
      }
    }
  }, [permit]);

  const permitDurationInDays =
    moment(permit.validityDuration.to)
      .startOf("day")
      .diff(moment(permit.validityDuration.from).startOf("day"), "days") + 1;

  const getSignature = (gangMemberId: string, date: Moment) => {
    return permit.signatures.filter(
      (s) =>
        s.type === PermitToDigSignatureType.GANG_MEMBER &&
        s.gangMember?.id === gangMemberId &&
        moment(s.date).isSame(date, "date")
    )[0];
  };

  const ableToClickNextWeek = [...Array(permitDurationInDays)].length > offset + limit;
  const ableToClickPreviousWeek = offset > 0;

  return (
    <Segment basic>
      <Header>
        Gang Members
        {(ableToClickNextWeek || ableToClickPreviousWeek) && (
          <Button.Group floated="right">
            <Button
              icon="chevron left"
              style={{ "background-color": "transparent" }}
              disabled={!ableToClickPreviousWeek}
              onClick={() => {
                setOffset(offset - limit);
              }}
            ></Button>
            <Button disabled style={{ "background-color": "transparent" }}>
              Week
            </Button>
            <Button
              icon="chevron right"
              style={{ "background-color": "transparent" }}
              disabled={!ableToClickNextWeek}
              onClick={() => {
                setOffset(offset + limit);
              }}
            ></Button>
          </Button.Group>
        )}
      </Header>
      <Divider />
      {permit.gangMembers.map((gangMember) => (
        <>
          <Header as="h4">{gangMember.displayName}</Header>
          <Grid columns="7">
            {[...Array(permitDurationInDays)]
              .filter((d, index) => index >= offset && index < offset + limit)
              .map((e, i) => {
                const signature = getSignature(
                  gangMember.id,
                  moment(permit.validityDuration.from).add(i + offset, "days")
                );
                return (
                  <Grid.Column key={i}>
                    {moment(permit.validityDuration.from)
                      .add(i + offset, "days")
                      .format("DD/MM")}
                    <br />
                    {signature ? moment(signature.dateSignedUtc).format("LT") : ""}
                    <br />
                    {signature ? (
                      signature.isAbsent ? (
                        "Absent"
                      ) : signature.signatureUrl ? (
                        <ServerImage size="tiny" src={signature.signatureUrl}></ServerImage>
                      ) : (
                        "-"
                      )
                    ) : (
                      "-"
                    )}
                  </Grid.Column>
                );
              })}
          </Grid>
        </>
      ))}
    </Segment>
  );
};

export default GangMembersView;
