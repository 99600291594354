import { gql } from "@apollo/client";
import { USER_FIELDS } from "./User";

export const ORG_USER_FIELDS = gql`
  ${USER_FIELDS}
  fragment OrgUserFields on OrgUser {
    id
    userId
    isAdmin
    isArchived
    user {
      ...UserFields
    }
    organization {
      id
    }
    calibrationCertificate {
      catSerialNumber
      catCalibrationDate
      gennySerialNumber
      gennyCalibrationDate
    }
  }
`;
