import AdminOrganizationsList from "../components/AdminOrganizationsList";
import { useGetOrganizations } from "../operations/queries/getOrganizations";
import { useAddOrganization } from "../operations/mutations/addOrganization";
import { useUpdateOrganization } from "../operations/mutations/updateOrganization";
import { useUpdateOrganizationEsriConfig } from "../operations/mutations/updateOrganizationEsriConfig";
import { useDeleteOrganization } from "../operations/mutations/deleteOrganization";
import { useEffect, useState } from "react";
import { UserFile } from "../models/UserFile";
import { useUploadFile } from "../operations/mutations/uploadFile";
import { FileMetaData } from "../models/FileMetaData";
import { EsriConfig } from "../models/EsriConfig";

const AdminOrganizationsListContainer = () => {
  const { data: organizations, refetch: refetchOrganizations, status: getOrganizationsStatus } = useGetOrganizations();
  const { mutate: addOrganizationMutate, status: addOrganizationStatus } = useAddOrganization();
  const { mutate: updateOrganizationMutate, status: updateOrganizationStatus } = useUpdateOrganization();
  const { mutate: updateOrganizationEsriConfigMutate, status: updateOrganizationEsriConfigStatus } =
    useUpdateOrganizationEsriConfig();
  const { mutate: deleteOrganizationMutate, status: deleteOrganizationStatus } = useDeleteOrganization();
  const {
    mutate: uploadFileMutate,
    data: uploadFile,
    error: uploadFileError,
    loading: uploadFileLoading,
  } = useUploadFile();
  const [logo, setLogo] = useState<UserFile>();

  const handleUploadLogo = (logo: UserFile) => {
    setLogo(logo);
    uploadFileMutate({
      variables: {
        file: logo.file,
        fileId: logo.id,
      },
    });
  };

  useEffect(() => {
    if (logo) {
      if (uploadFile?.uploadFile?.fileId) {
        setLogo({ ...logo, uploadStatus: { ...logo.uploadStatus, uploaded: true, uploading: false } });
      } else if (uploadFileError && uploadFileError.graphQLErrors) {
        setLogo({
          ...logo,
          uploadStatus: { ...logo.uploadStatus, uploading: false, uploadError: uploadFileError.graphQLErrors[0] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile, uploadFileError]);

  const handleAddOrganization = (name: string, addDefaultRoles: boolean, logoMetaData: FileMetaData | null) => {
    addOrganizationMutate({ variables: { name, logoMetaData, addDefaultRoles } }).then(() => {
      refetchOrganizations && refetchOrganizations();
    });
  };

  const handleEditOrganization = (id: string, name: string, logoMetaData: FileMetaData | null) => {
    updateOrganizationMutate({
      variables: {
        id,
        name,
        logoMetaData,
      },
    }).then(() => {
      refetchOrganizations && refetchOrganizations();
    });
  };

  const handleEditEsriConfig = (id: string, integrationTypeId: string, webMapItemId: string) => {
    updateOrganizationEsriConfigMutate({
      variables: {
        id,
        esriConfig: {
          integrationTypeId,
          webMapItemId: webMapItemId,
        } as EsriConfig,
      },
    }).then(() => {
      refetchOrganizations && refetchOrganizations();
    });
  };

  const handleDeleteOrganization = (id: string) => {
    deleteOrganizationMutate({
      variables: {
        id,
      },
    }).then(() => {
      refetchOrganizations && refetchOrganizations();
    });
  };

  return (
    <AdminOrganizationsList
      isOrganizationsLoading={getOrganizationsStatus.loading}
      organizations={organizations && organizations.organizations}
      onAddOrganization={handleAddOrganization}
      addOrganizationStatus={addOrganizationStatus}
      onEditOrganization={handleEditOrganization}
      editOrganizationStatus={updateOrganizationStatus}
      onEditEsriConfig={handleEditEsriConfig}
      editEsriConfigStatus={updateOrganizationEsriConfigStatus}
      onDeleteOrganization={handleDeleteOrganization}
      deleteOrganizationStatus={deleteOrganizationStatus}
      logo={logo}
      onUploadLogo={handleUploadLogo}
      uploading={uploadFileLoading}
    ></AdminOrganizationsList>
  );
};

export default AdminOrganizationsListContainer;
