import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router";
import { Organization } from "../models/Organization";
import { useGetMyOrganizations } from "../operations/queries/getMyOrganizations";
import { Project } from "../models/Project";
import { useLazyGetMyProjectsByOrganizationId } from "../operations/queries/getMyProjectsByOrganizationId";

interface Props {
  children: React.ReactNode;
}

export const AppContext = React.createContext<{
  currentOrganization?: Organization;
  organizationName: string;
  currentProject?: Project;
  projectName: string;
}>({ currentOrganization: undefined, organizationName: "", currentProject: undefined, projectName: "" });

export const AppContextProvider = ({ children }: Props) => {
  const { data: organizationList } = useGetMyOrganizations();
  const { getMyProjectsByOrganizationId: getMyProjects, data: myProjects } = useLazyGetMyProjectsByOrganizationId();

  let orgNameMatch: any = useRouteMatch("/:orgName/");
  let projNameMatch: any = useRouteMatch("/:orgName/projects/:projectName/");

  const orgName = orgNameMatch?.params.orgName;
  const projName = projNameMatch?.params.projectName;

  const [currentOrganization, setCurrentOrganization] = useState<Organization | undefined>(undefined);
  const [currentProject, setCurrentProject] = useState<Project>();

  // Set Current Organization
  useEffect(() => {
    if (organizationList && organizationList.myOrganizations && orgName) {
      var org = organizationList.myOrganizations.find((o) => o.name === orgName);
      if (org?.id !== currentOrganization?.id || org?.name !== currentOrganization?.name) {
        setCurrentOrganization(org);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList, orgName]);

  // Get My Projects
  useEffect(() => {
    if (currentOrganization) {
      getMyProjects({ variables: { organizationId: currentOrganization.id } });
    }
  }, [getMyProjects, currentOrganization]);

  // Set Current Organization
  useEffect(() => {
    if (myProjects && myProjects.myProjectsByOrganizationId && projName) {
      setCurrentProject(myProjects.myProjectsByOrganizationId.find((p) => p.name === projName));
    }
  }, [myProjects, projName]);

  return (
    <AppContext.Provider
      value={{
        currentOrganization,
        organizationName: orgName,
        currentProject,
        projectName: projName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
