import { Text, View } from "@react-pdf/renderer";
import styles from "./Styles";

type Props = { title?: string | null; text?: string | null; width: string };

const Item = ({ title, text, width }: Props) => {
  return (
    <View style={{ width: width }}>
      {title && <Text style={styles.subtitle}>{title}</Text>}
      <Text style={styles.text}>{text || ""}</Text>
    </View>
  );
};

export default Item;
