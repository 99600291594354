import { useEffect, useRef, useState } from "react";
import { Button } from "semantic-ui-react";
import {
  configAuth,
  addPolygon,
  addPolyline,
  addSimpleMarker,
  createMapView,
  createSketchView,
  createWebMap,
  MapItem,
} from "../../utils/arcgisUtils";
import { loadModules, loadCss } from "esri-loader";

type Props = {
  webMapItemId: string | null | undefined;
  center?: __esri.Point;
  items: MapItem[];
  showLegend?: boolean;
  showLayersList?: boolean;
  onPopupClick?: (id: string) => void;
  polygonToEdit?: __esri.Polygon;
  onPolygonEdit?: (polygon: __esri.Polygon) => void;
  height?: string;
};

const Map = ({
  webMapItemId,
  center,
  items,
  showLegend,
  showLayersList,
  onPopupClick,
  polygonToEdit,
  onPolygonEdit,
  height,
}: Props) => {
  const mapEl = useRef(null);

  const [sketchVm, setSketchVm] = useState<__esri.SketchViewModel | null>(null);
  const [editState, setEditState] = useState<"draw" | "reshape" | "transform" | "">("");
  const [currentGraphic, setCurrentGraphic] = useState<__esri.Graphic | null>(null);
  const [graphicsLayer, setGraphicsLayer] = useState<__esri.GraphicsLayer | null>(null);
  const [view, setView] = useState<__esri.MapView | null>(null);
  const [legend, setLegend] = useState<__esri.Legend | null>(null);
  const [layersList, setLayersList] = useState<__esri.LayerList | null>(null);
  const canEdit = !!onPolygonEdit;

  useEffect(() => {
    if (view) {
      if (showLegend) {
        loadModules(["esri/widgets/Legend"]).then(([Legend]) => {
          const legend = new Legend({
            view: view,
          });
          setLegend(legend);
          view.ui.add(legend, "top-right");
        });
      } else if (legend) {
        view.ui.remove(legend);
        setLegend(null);
      }
    }
  }, [view, showLegend]);

  useEffect(() => {
    if (view) {
      if (showLayersList) {
        loadModules(["esri/widgets/LayerList"]).then(([LayerList]) => {
          const layerList = new LayerList({
            view: view,
          });
          view.ui.add(layerList, "top-right");
          setLayersList(layerList);
        });
      } else if (layersList) {
        view.ui.remove(layersList);
        setLayersList(null);
      }
    }
  }, [view, showLayersList]);

  useEffect(() => {
    loadCss();
    loadModules([
      "esri/config",
      "esri/WebMap",
      "esri/views/MapView",
      "esri/layers/GraphicsLayer",
      "esri/Graphic",
      "esri/PopupTemplate",
      "esri/geometry/Point",
      "esri/geometry/Polygon",
      "esri/geometry/Polyline",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/SimpleLineSymbol",
      "esri/widgets/ScaleBar",
      "esri/widgets/Legend",
      "esri/widgets/Sketch/SketchViewModel",
      "esri/identity/OAuthInfo",
      "esri/identity/IdentityManager",
      "esri/widgets/BasemapToggle",
      "esri/Basemap",
    ]).then(
      ([
        config,
        WebMap,
        MapView,
        GraphicsLayer,
        Graphic,
        PopupTemplate,
        Point,
        Polygon,
        Polyline,
        SimpleMarkerSymbol,
        SimpleFillSymbol,
        SimpleLineSymbol,
        ScaleBar,
        Legend,
        SketchViewModel,
        OAuthInfo,
        esriId,
        BasemapToggle,
        Basemap,
      ]) => {
        // config.apiKey =
        //   "AAPKe7d21445ba604a5488756d8cd0d232f4OzbQd3I0wGKfs8gXxfkdow9QldSlgBNDJlyOdMSWpz5iRabyH8rT3-MOgq3t2HHD";
        configAuth(OAuthInfo, esriId, "/esri-auth-callback.html");

        if (!webMapItemId) {
          return;
        }

        const { webmap, graphicsLayer } = createWebMap(WebMap, GraphicsLayer, webMapItemId);
        setGraphicsLayer(graphicsLayer);

        // Filter to get Permit boundary for the Goto target. Project boundary is added as a Polyline.
        var gotoTargets = () => graphicsLayer.graphics.filter((g) => g.geometry.type === "polygon");

        var view = createMapView(
          MapView,
          ScaleBar,
          Legend,
          mapEl.current,
          webmap,
          showLegend ?? false,
          gotoTargets,
          center,
          onPopupClick
        );
        setView(view);

        let basemap = new Basemap({
          portalItem: {
            id: "52bdc7ab7fb044d98add148764eaa30a", // WGS84 Streets Vector webmap
          },
        });

        // 1 - Create the widget
        const toggle = new BasemapToggle({
          // 2 - Set properties
          view: view, // view that provides access to the map's 'topo-vector' basemap
          nextBasemap: basemap, // allows for toggling to the 'hybrid' basemap
        });

        // Add widget to the top right corner of the view
        view?.ui.add(toggle, "bottom-right");

        view?.when(() => {
          if (canEdit) {
            const sketchVm = createSketchView(
              SketchViewModel,
              view,
              graphicsLayer,
              SimpleFillSymbol,
              onDrawingCompleted,
              onDrawingCancelled,
              onEditCompleted
            );
            setSketchVm(sketchVm);
          }
        });

        items.forEach((item) => {
          var geometry = item.geometry;

          if (geometry.type === "point") {
            addSimpleMarker(SimpleMarkerSymbol, Graphic, view, new Point(geometry), item.color);
          } else if (geometry.type === "polygon") {
            addPolygon(
              SimpleFillSymbol,
              Graphic,
              PopupTemplate,
              graphicsLayer,
              new Polygon(geometry),
              item.color,
              item.popup
            );
          } else if (geometry.type === "polyline") {
            addPolyline(
              SimpleLineSymbol,
              Graphic,
              PopupTemplate,
              graphicsLayer,
              new Polyline(geometry),
              item.color,
              item.popup
            );
          }
        });
      }
    );

    return () => {
      // clean up the map view
      if (!!view) {
        graphicsLayer?.destroy();
        view.ui.remove("mainWidget");
        sketchVm?.destroy();
        view.destroy();
        setView(null);
      }
    };
  }, [mapEl, items]);

  useEffect(() => {
    loadModules(["esri/Graphic", "esri/PopupTemplate", "esri/symbols/SimpleFillSymbol"]).then(
      ([Graphic, PopupTemplate, SimpleFillSymbol]) => {
        if (!currentGraphic && polygonToEdit && graphicsLayer) {
          var graphic = addPolygon(SimpleFillSymbol, Graphic, PopupTemplate, graphicsLayer, polygonToEdit);
          setCurrentGraphic(graphic);
        }
      }
    );
  }, [polygonToEdit, graphicsLayer]);

  const onDrawingCompleted = (graphic: __esri.Graphic) => {
    if (onPolygonEdit) {
      onPolygonEdit(graphic.geometry as __esri.Polygon);
    }
    setCurrentGraphic(graphic);
    setEditState("");
  };

  const onDrawingCancelled = () => {
    setCurrentGraphic(null);
    setEditState("");
  };

  const onEditCompleted = (graphic: __esri.Graphic | null) => {
    if (onPolygonEdit && graphic) {
      onPolygonEdit(graphic.geometry as __esri.Polygon);
    }
    setEditState("");
  };

  const handleDrawClick = () => {
    if (!sketchVm) return;

    if (editState === "draw") {
      sketchVm.cancel();
      setEditState("");
    } else {
      if (graphicsLayer && currentGraphic) {
        graphicsLayer.remove(currentGraphic);
      }
      sketchVm.create("polygon");
      setEditState("draw");
    }
  };

  const handleReshapeClick = () => {
    if (!sketchVm) return;

    if (editState === "reshape") {
      sketchVm.cancel();
      setEditState("");
    } else {
      sketchVm.update([currentGraphic!], {
        tool: "reshape",
        toggleToolOnClick: false,
      });
      setEditState("reshape");
    }
  };

  const handleTransformClick = () => {
    if (!sketchVm) return;

    if (editState === "transform") {
      sketchVm.cancel();
      setEditState("");
    } else {
      sketchVm.update([currentGraphic!], {
        tool: "transform",
        toggleToolOnClick: false,
      });
      setEditState("transform");
    }
  };

  return (
    <>
      <div className="webmap" ref={mapEl} style={{ height: height ? height : "700px" }}>
        <div id="BasemapToggle"></div>
        {canEdit && (
          <div id="mainWidget" className="esri-widget">
            <Button.Group basic size="tiny">
              <Button id="drawButton" onClick={handleDrawClick}>
                Draw boundaries
              </Button>
              <Button id="reshapeButton" onClick={handleReshapeClick}>
                Reshape
              </Button>
              <Button id="transformButton" onClick={handleTransformClick}>
                Transform
              </Button>
            </Button.Group>
          </div>
        )}
      </div>
    </>
  );
};

export default Map;
