import React, { useEffect } from "react";
import { Feed, Grid, Header, Label, Menu, Modal } from "semantic-ui-react";
import moment from "moment";
import { reverse } from "lodash";
import { PermitLocationLog } from "../models/PermitLocationLog";
import LocationViewer from "./common/LocationViewer";

interface Props {
  logs?: PermitLocationLog[];
}

const LocationLogsView = ({ logs }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState<PermitLocationLog>();

  useEffect(() => {
    if (logs) {
      setActiveItem(logs[logs.length - 1]);
    }
  }, [logs]);

  if (!logs || logs.length < 1) {
    return null;
  }

  let orderedLogs = [...logs];
  reverse(orderedLogs);

  var latestLog = orderedLogs[0];

  const renderMenuItem = (log: PermitLocationLog) => {
    return (
      <Menu.Item
        active={activeItem?.id === log.id}
        onClick={(e) => {
          setActiveItem(log);
        }}
      >
        <Header as="h4">{log.user.user.displayName}</Header>
        {moment.utc(log.date).local().format("DD/MM/YYYY HH:mm")}
      </Menu.Item>
    );
  };

  return (
    <>
      <Feed>
        <Feed.Event>
          <Feed.Content>
            <Feed.Summary>
              <Label
                size="large"
                basic
                color="blue"
                as="a"
                onClick={() => setOpen(true)}
                style={{ border: "0px", padding: "0px" }}
              >
                Last access
              </Label>

              <Feed.Date>
                {moment.utc(latestLog.date).local().format("DD/MM/YYYY HH:mm") +
                  " - " +
                  latestLog.user.user.displayName}
              </Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>

      <Modal closeIcon onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>Location logs</Modal.Header>
        <Modal.Content style={{ padding: "0px" }}>
          <Grid style={{ margin: "0px" }}>
            <Grid.Column
              stretched
              width="4"
              style={{ padding: "0px", maxHeight: "700px", overflowY: "auto", overflowX: "hidden" }}
            >
              <Menu vertical size="large" style={{ border: "0px", "box-shadow": "none" }}>
                {orderedLogs.map((l) => renderMenuItem(l))}
              </Menu>
            </Grid.Column>

            <Grid.Column width="12" style={{ padding: "0px", height: "700px" }}>
              {activeItem && <LocationViewer longitude={activeItem.longitude} latitude={activeItem.latitude} />}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default LocationLogsView;
