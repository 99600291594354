import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddDocument, AddDocumentVariables } from "./__generated__/AddDocument";
import { DOCUMENT_FIELDS } from "../fragments/Document";

const ADD_DOCUMENT = gql`
  ${DOCUMENT_FIELDS}
  mutation AddDocument(
    $title: String!
    $projectId: String!
    $typeId: String!
    $versionNo: Decimal!
    $fileMetaData: FileMetaDataInput!
  ) {
    addDocument(
      input: {
        title: $title
        projectId: $projectId
        typeId: $typeId
        versionNo: $versionNo
        fileMetaData: $fileMetaData
      }
    ) {
      document {
        ...DocumentFields
      }
    }
  }
`;

export function useAddDocument() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<AddDocument, AddDocumentVariables>(
    ADD_DOCUMENT,
    {
      errorPolicy: "all",
    }
  );
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
