import { NavLink, useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";

interface Props {
  iconOnly: boolean;
}

const ProjectSideMenu = ({ iconOnly }: Props) => {
  const { orgName } = useParams<{ orgName: string }>();
  const { projectName } = useParams<{ projectName: string }>();

  return (
    <Menu icon={iconOnly ? true : "labeled"} color="black" borderless secondary vertical style={{ marginRight: "1px" }}>
      <Menu.Item
        icon="chart bar outline"
        as={NavLink}
        content={!iconOnly && "Dashboard"}
        to={`/${orgName}/projects/${projectName}/dashboard`}
      />
      <Menu.Item
        icon="clipboard outline"
        as={NavLink}
        content={!iconOnly && "Permits"}
        to={`/${orgName}/projects/${projectName}/permits`}
      />
      <Menu.Item
        icon="folder open outline"
        as={NavLink}
        content={!iconOnly && "Documents"}
        to={`/${orgName}/projects/${projectName}/documents`}
      />
      {/* <Menu.Item
        icon="setting"
        as={NavLink}
        content={!iconOnly && "Settings"}
        to={`/${orgName}/projects/${projectName}/settings`}
      /> */}
    </Menu>
  );
};

export default ProjectSideMenu;
