import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { DeleteDocument, DeleteDocumentVariables } from "./__generated__/DeleteDocument";

const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(input: { id: $id }) {
      document {
        project {
          id
          name
          number
          location
        }
        type {
          id
          name
          key
        }
        createdBy {
          id
          organization {
            id
            name
          }
          user {
            id
            email
            firstName
            lastName
          }
          userId
        }
        id
        title
        versionNo
        dateCreatedUtc
      }
    }
  }
`;

export function useDeleteDocument() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    DeleteDocument,
    DeleteDocumentVariables
  >(DELETE_DOCUMENT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
