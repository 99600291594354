import { useEffect } from "react";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useGetPermitRequestApprovalStats } from "../operations/queries/getPermitRequestApprovalStats";
import PermitRequestApprovalStats from "../components/PermitRequestApprovalStats";

interface Props {}

const PermitRequestApprovalStatsContainer = (props: Props) => {
  const { currentProject } = useCurrentProject();

  const { getPermitRequestApprovalStats, data } = useGetPermitRequestApprovalStats();

  useEffect(() => {
    if (currentProject) {
      getPermitRequestApprovalStats({
        variables: {
          projectId: currentProject.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  if (!data) return null;

  return (
    <PermitRequestApprovalStats
      approved={data.permitRequestApprovalStats.approved}
      rejected={data.permitRequestApprovalStats.rejected}
    />
  );
};

export default PermitRequestApprovalStatsContainer;
