import { Button, Form, FormField, List, Modal, Radio } from "semantic-ui-react";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onChange: (decorators: { index: number; text: string; value: boolean; readonly: boolean }[]) => void;
  declarations: { index: number; text: string; value: boolean; readonly: boolean }[];
}

const PermitSubmissionModal = ({ open, onClose, onConfirm, onChange, declarations }: Props) => {
  const handleChange = (value: boolean, i: number) => {
    const newValues = [...declarations];
    newValues[i].value = value ?? false;
    onChange(newValues);
  };

  const renderItem = (index: number, text: string, value: boolean, readonly: boolean) => (
    <List.Item key={index}>
      <List.Content floated="right">
        <Form>
          <Form.Group inline style={{ marginBottom: "0.3em", marginTop: "0.3em" }}>
            <Form.Field>
              <Radio
                disabled={readonly ?? false}
                label="No"
                checked={!value}
                onChange={() => handleChange(false, index)}
              ></Radio>
            </Form.Field>
            <FormField>
              <Radio readOnly={readonly} label="Yes" checked={value} onChange={() => handleChange(true, index)}></Radio>
            </FormField>
          </Form.Group>
        </Form>
      </List.Content>
      <List.Content>{text}</List.Content>
    </List.Item>
  );

  const renderDeclarations = () => (
    <div className="content">
      <h4>I can confirm:</h4>
      <List divided>{declarations.map((item) => renderItem(item.index, item.text, item.value, item.readonly))}</List>
      <h5>* Please note that all declarations must be ticked to Yes for permit to get submitted.</h5>
    </div>
  );

  const handelClose = () => {
    onClose();
  };
  return (
    <Modal open={open} onClose={handelClose}>
      <Modal.Header>Declaration</Modal.Header>
      <Modal.Content>{renderDeclarations()}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={onConfirm} positive>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PermitSubmissionModal;
