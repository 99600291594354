import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddOrgRole, AddOrgRoleVariables } from "./__generated__/AddOrgRole";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";

const ADD_ORG_ROLE = gql`
  ${ORG_ROLE_FIELDS}
  mutation AddOrgRole(
    $displayName: String!
    $organizationId: String!
    $permissionNames: [String!]
    $permitRoleSettingsInput: PermitRoleSettingsInput
  ) {
    addOrgRole(
      input: {
        displayName: $displayName
        organizationId: $organizationId
        permissionNames: $permissionNames
        permitRoleSettingsInput: $permitRoleSettingsInput
      }
    ) {
      orgRole {
        ...OrgRoleFields
      }
    }
  }
`;

export function useAddOrgRole() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<AddOrgRole, AddOrgRoleVariables>(
    ADD_ORG_ROLE,
    {
      errorPolicy: "all",
    }
  );
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
