import { useEffect, useState } from "react";
import { useLazyGetOrganizationPolicies } from "../operations/queries/getOrganizationPolicies";
import { useCurrentOrganization } from "./useCurrentOrganization";
import { MANAGE_DOCUMENT_TYPES, MANAGE_GANG_MEMBERS, MANAGE_ORG_USERS, MANAGE_PROJECTS } from "../models/Policies";

export function useOrganizationPolicies() {
  const [organizationPolicies, setOrganizationPolicies] = useState<string[]>();
  const { currentOrganization } = useCurrentOrganization();
  const { getOrganizationPolicies, data } = useLazyGetOrganizationPolicies();

  useEffect(() => {
    if (currentOrganization) {
      getOrganizationPolicies({ variables: { organizationId: currentOrganization.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  useEffect(() => {
    if (data) {
      setOrganizationPolicies(data.organizationPolicies);
    }
  }, [data]);

  const authorize = (policy: string): boolean => {
    return organizationPolicies ? organizationPolicies.includes(policy) : false;
  };

  const authorizedToManageProjects = authorize(MANAGE_PROJECTS.name);
  const authorizedToManageOrgUsers = authorize(MANAGE_ORG_USERS.name);
  const authorizedToManageGangMembers = authorize(MANAGE_GANG_MEMBERS.name);
  const authorizedToManageDocumentTypes = authorize(MANAGE_DOCUMENT_TYPES.name);

  return {
    organizationPolicies,
    authorize,
    authorizedToManageProjects,
    authorizedToManageOrgUsers,
    authorizedToManageGangMembers,
    authorizedToManageDocumentTypes,
  };
}
