import GaugeChart from "react-gauge-chart";
import { Header, Item, Segment } from "semantic-ui-react";

interface Props {
  header: string;
  description: string;
  value: number;
  maxValue: number;
}

const DashboardGauge = ({ header, description, value, maxValue }: Props) => {
  const percent = Math.min(maxValue, value) / maxValue;
  const formattedValue = value.toFixed(2);

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center">{header}</Header>
      <GaugeChart
        id="inprogress-duration-gauge-chart"
        nrOfLevels={100}
        arcsLength={[0.33, 0.33, 0.33]}
        colors={["#5BE12C", "#F5CD19", "#EA4228"]}
        percent={percent}
        arcPadding={0.02}
        fontSize="16"
        textColor="#555"
        formatTextValue={(val) => formattedValue + " h"}
      />
      <Item>
        <Item.Content>
          <Item.Extra>{description}</Item.Extra>
        </Item.Content>
      </Item>
    </Segment>
  );
};

export default DashboardGauge;
