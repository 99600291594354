import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Header, Label, Segment } from "semantic-ui-react";
import PdfViewer from "./common/PdfViewer";
import useWindowDimensions from "../hooks/useWindowDimensions";
import debounce from "lodash/debounce";
import { Document_Data } from "../models/Document_Data";

interface Props {
  containerId: string;
  onClose?: () => void;
  onPopup?: () => void;
  isPopup?: boolean;
  document?: Document_Data;
  onChangeVersion?: (id: string) => void;
  documentVersions?: { key: string; text: string; value: string }[];
}

const DocumentView = ({
  containerId,
  onClose,
  onPopup,
  isPopup,
  document,
  onChangeVersion,
  documentVersions,
}: Props) => {
  const handleDocumentChange = (e: any, { value }: any) => {
    if (onChangeVersion) {
      onChangeVersion(value);
    }
  };

  var pdfViewerSegmentRef = useRef(null);
  const [pdfViewerWidth, setPdfViewerWidth] = useState(400);
  const { width } = useWindowDimensions();

  const setWidth = useCallback(
    debounce((w: number) => {
      setPdfViewerWidth(w);
    }, 300),
    [setPdfViewerWidth]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (pdfViewerSegmentRef.current) {
      var segment = pdfViewerSegmentRef.current as any;
      setWidth(segment.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, pdfViewerSegmentRef.current, width]);

  return (
    <>
      {document && (
        <>
          <Segment basic style={{ margin: "0px", paddingBottom: "0px" }}>
            {onClose && (
              <Button
                circular
                icon="close"
                floated="right"
                style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
                onClick={onClose}
              ></Button>
            )}
            {!isPopup && onPopup && (
              <Button
                circular
                icon="arrow up"
                floated="right"
                style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px" }}
                onClick={onPopup}
              ></Button>
            )}
            {documentVersions && (
              <Label
                as="a"
                size="medium"
                circular
                style={{ padding: "5px", marginLeft: "8px", "box-shadow": "0px 0px", float: "right" }}
              >
                <Dropdown
                  text="Versions"
                  value={document.id}
                  options={documentVersions}
                  onChange={handleDocumentChange}
                ></Dropdown>
              </Label>
            )}
            <Header as="h3" style={{ display: "inline" }}>
              {document.title} v{document.versionNo}
            </Header>
          </Segment>
          <Segment basic style={{ margin: "0px", padding: "1px" }}></Segment>
          <Segment basic style={{ margin: "0px", paddingTop: "0px" }}>
            <div ref={pdfViewerSegmentRef}>
              <PdfViewer
                containerId={containerId}
                url={document.fileUrl}
                width={pdfViewerWidth}
                height={window.innerHeight - (isPopup ? 170 : 350)}
              />
            </div>
          </Segment>
        </>
      )}
    </>
  );
};

export default DocumentView;
