import { useEffect, useState } from "react";
import { Button, Checkbox, Grid, Menu, Modal } from "semantic-ui-react";
import { EmergencyContact } from "../models/EmergencyContact";
import { FormError } from "../models/FormError";
import { GeoJSONPolygonType } from "../models/GeoJSONPolygonType";
import { Project } from "../models/Project";
import EditProjectForm, { FormData } from "./EditProjectForm";
import MapWithBoundaries from "./MapWithBoundaries";
import { MapItem } from "../utils/arcgisUtils";

interface Props {
  showProjectBoundariesOnMap?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (
    name: string,
    number: string,
    location: string,
    boundaries: GeoJSONPolygonType,
    emergencyContact: EmergencyContact,
    id?: string
  ) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  project?: Project;
}

const EditProjectModal = ({
  showProjectBoundariesOnMap,
  open,
  onClose,
  onSubmit,
  submitInProgress,
  submitErrors,
  project,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [boundaries, setBoundaries] = useState<GeoJSONPolygonType | null>();
  const [showMapLegendSelected, setShowMapLegendSelected] = useState(false);
  const [showMapLayersListSelected, setShowMapLayersListSelected] = useState(false);
  const [mapItems, setMapItems] = useState<MapItem[]>([]);

  useEffect(() => {
    if (open && project) {
      setFormData({
        ...project,
        emergencyContact_name: project?.emergencyContact?.name,
        emergencyContact_phoneNumber: project?.emergencyContact?.phoneNumber,
      } as FormData);
      setBoundaries(project.boundaries);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, project]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.name) {
      formError.name = "Please enter project name";
    }

    if (!formData.location) {
      formError.location = "Please enter project location";
    }

    if (!boundaries && showProjectBoundariesOnMap) {
      formError.boundaries = "Please draw project boundaries on map";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { name, number, location, emergencyContact_name, emergencyContact_phoneNumber, id } = formData;
      onSubmit(
        name,
        number,
        location,
        boundaries!,
        { name: emergencyContact_name, phoneNumber: emergencyContact_phoneNumber } as EmergencyContact,
        id
      );
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open} size={showProjectBoundariesOnMap ? "large" : "small"}>
      <Modal.Header>Project</Modal.Header>
      <Modal.Content style={{ padding: "0px" }}>
        <Grid divided style={{ margin: "0px" }}>
          <Grid.Column stretched width={showProjectBoundariesOnMap ? "8" : "16"}>
            <EditProjectForm
              formErrors={formErrors}
              errors={submitErrors}
              formData={formData}
              onChange={handleFormChange}
            ></EditProjectForm>
          </Grid.Column>

          {showProjectBoundariesOnMap && (
            <Grid.Column width="8" style={{ padding: "0px" }}>
              <Menu borderless size="small" style={{ marginBottom: "0px", border: "0", backgroundColor: "#f1f1f1" }}>
                <Menu.Item header>Map options:</Menu.Item>
                <Menu.Item position="right">
                  <Checkbox
                    slider
                    label="Layers list"
                    checked={showMapLayersListSelected}
                    onChange={(e, data) => {
                      setShowMapLayersListSelected(data.checked ?? false);
                      if (data.checked) {
                        setShowMapLegendSelected(!data.checked);
                      }
                    }}
                  ></Checkbox>
                </Menu.Item>
                <Menu.Item>
                  <Checkbox
                    slider
                    label="Map legend"
                    checked={showMapLegendSelected}
                    onChange={(e, data) => {
                      setShowMapLegendSelected(data.checked ?? false);
                      if (data.checked) {
                        setShowMapLayersListSelected(!data.checked);
                      }
                    }}
                  ></Checkbox>
                </Menu.Item>
              </Menu>

              <MapWithBoundaries
                items={mapItems}
                showLegend={showMapLegendSelected}
                showLayersList={showMapLayersListSelected}
                saveBoundaries={(bouandaries: GeoJSONPolygonType | null) => setBoundaries(bouandaries)}
                boundaries={project?.boundaries}
                height="500px"
              />
            </Grid.Column>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditProjectModal;
