import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { FileMetaData } from "../models/FileMetaData";
import { FormError } from "../models/FormError";
import { Organization } from "../models/Organization";
import { UserFile } from "../models/UserFile";
import AdminEditOrganizationForm, { FormData } from "./AdminEditOrganizationForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, addDefaultRoles: boolean, logoMetaData: FileMetaData | null, id?: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  organization?: Organization;
  logo?: UserFile;
  onUploadLogo: (logo: UserFile) => void;
  uploading: boolean;
}

const AdminEditOrganizationModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  submitErrors,
  organization,
  logo,
  onUploadLogo,
  uploading,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData({ ...organization } as FormData);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, organization]);

  useEffect(() => {
    setFormData({ ...formData, logo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.name) {
      formError.name = "Please enter organization name";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { id, name, addDefaultRoles, logo, logoMetaData } = formData;

      let newLogoMetaData: FileMetaData | null = null;
      if (logo) {
        newLogoMetaData = {
          id: logo.id,
          fileName: logo.file.name,
          contentType: logo.file.type,
        } as FileMetaData;
      } else if (logoMetaData) {
        newLogoMetaData = {
          id: logoMetaData.id,
          fileName: logoMetaData.fileName,
          contentType: logoMetaData.contentType,
        } as FileMetaData;
      }
      if (id) {
        onSubmit(name, addDefaultRoles ?? false, newLogoMetaData, id);
      } else {
        onSubmit(name, addDefaultRoles ?? false, newLogoMetaData);
      }
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handleClose = () => {
    onClose();
  };

  const handleRemoveLogo = () => {
    setFormData({ ...formData, logoMetaData: undefined });
  };

  return (
    <Modal open={open}>
      <Modal.Header>Organization</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AdminEditOrganizationForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            showAddDefaultRoleOption={!organization}
            onChange={handleFormChange}
            onUploadLogo={onUploadLogo}
            onRemoveLogo={handleRemoveLogo}
          ></AdminEditOrganizationForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          positive
          loading={submitInProgress || uploading}
          disabled={submitInProgress || uploading}
        >
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AdminEditOrganizationModal;
