import { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (comments: string) => void;
  submitInProgress: boolean;
  title?: string;
}

const ConfirmWithCommentsModal = ({ open, onClose, onSubmit, submitInProgress, title }: Props) => {
  const [comments, setComments] = useState<string>("");

  const handleChange = (e: any, { name, value }: any) => {
    setComments(value);
  };

  const handleSubmit = () => {
    onSubmit(comments);
    setComments("");
  };

  const handelClose = () => {
    setComments("");
    onClose();
  };

  return (
    <Modal open={open} size="mini">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Group widths="equal">
              <Form.TextArea value={comments} label="Comments" name="comments" onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmWithCommentsModal;
