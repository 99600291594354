import { gql } from "@apollo/client";
import { useResettableMutation } from "../useResettableMutation";
import { getErrors } from "../../utils/apolloError";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { UndoCancelledPermitToDig, UndoCancelledPermitToDigVariables } from "./__generated__/UndoCancelledPermitToDig";
import { PERMIT_TO_DIG_ALL_FIELDS } from "../fragments/permitToDig";

const CANCEL_PERMIT = gql`
  ${PERMIT_TO_DIG_ALL_FIELDS}
  mutation UndoCancelledPermitToDig($id: String!) {
    undoCancelledPermitToDig(input: { id: $id }) {
      permitToDig {
        ...PermitToDigAllFields
      }
    }
  }
`;

export function useUndoCancelledPermit() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    UndoCancelledPermitToDig,
    UndoCancelledPermitToDigVariables
  >(CANCEL_PERMIT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
