import { Permit_ListItem } from "../models/Permit_ListItem";
import Map from "./common/Map";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { Checkbox, Menu } from "semantic-ui-react";
import { useState } from "react";
import { createMapItem, MapItem } from "../utils/arcgisUtils";
import { Project } from "../models/Project";

interface Props {
  permits?: Permit_ListItem[] | null;
  currentProject?: Project;
  onPermitViewClicked: (permitId: string) => void;
}

const PermitsBoundariesOnMap = ({ permits, currentProject, onPermitViewClicked }: Props) => {
  const { currentOrganization } = useCurrentOrganization();
  const [showMapLegendSelected, setShowMapLegendSelected] = useState(false);
  const [showMapLayersListSelected, setShowMapLayersListSelected] = useState(false);

  const createPermitMapItem = (permit: Permit_ListItem): MapItem => {
    return {
      geometry: {
        type: "polygon",
        rings: permit.boundaries?.coordinates as number[][][],
        spatialReference: { wkid: 4326 },
      } as __esri.Polygon,
      popup: {
        id: permit.id,
        name: "View permit",
        title: "Permit number: " + permit.number,
        content: "Location of work: " + permit.locationOfWork,
        hasAction: true,
      },
    };
  };

  const projectBoundaries = () => {
    return currentProject && currentProject.boundaries
      ? [
          createMapItem(
            "polyline",
            currentProject.boundaries.coordinates as number[][][],
            currentProject.id,
            "View project",
            `Project: ${currentProject.name} (${currentProject.number})`,
            "Location of work: " + currentProject.location,
            false,
            { r: 119, g: 226, b: 40 } as __esri.Color
          ),
        ]
      : [];
  };

  return (
    <>
      {permits && (
        <>
          <Menu attached="top" style={{ marginTop: "-14px", border: "0", backgroundColor: "#f1f1f1" }}>
            <Menu.Item header>Map options:</Menu.Item>
            <Menu.Item position="right">
              <Checkbox
                slider
                label="Layers list"
                checked={showMapLayersListSelected}
                onChange={(e, data) => {
                  setShowMapLayersListSelected(data.checked ?? false);
                  if (data.checked) {
                    setShowMapLegendSelected(!data.checked);
                  }
                }}
              ></Checkbox>
            </Menu.Item>
            <Menu.Item>
              <Checkbox
                slider
                label="Map legend"
                checked={showMapLegendSelected}
                onChange={(e, data) => {
                  setShowMapLegendSelected(data.checked ?? false);
                  if (data.checked) {
                    setShowMapLayersListSelected(!data.checked);
                  }
                }}
              ></Checkbox>
            </Menu.Item>
          </Menu>
          <Map
            webMapItemId={currentOrganization?.esriConfig?.webMapItemId}
            items={projectBoundaries().concat(
              permits.filter((permit) => permit.boundaries).map((permit) => createPermitMapItem(permit))
            )}
            onPopupClick={onPermitViewClicked}
            showLayersList={showMapLayersListSelected}
            showLegend={showMapLegendSelected}
          ></Map>
        </>
      )}
    </>
  );
};

export default PermitsBoundariesOnMap;
