import { gql, useQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { GetOrganizations } from "./__generated__/GetOrganizations";
import { CORE_LICENCE_FIELDS } from "../fragments/Licence";
import { CORE_ORGANIZATION_FIELDS } from "../fragments/organization";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";

export const GET_ORGANIZATIONS = gql`
  ${CORE_LICENCE_FIELDS}
  ${CORE_ORGANIZATION_FIELDS}
  ${ORG_ROLE_FIELDS}
  query GetOrganizations {
    organizations {
      ...CoreOrganizationFields
      activeLicence {
        ...CoreLicenceFields
      }
      orgRoles {
        ...OrgRoleFields
      }
    }
  }
`;

export function useGetOrganizations() {
  const { loading, error, data, refetch } = useQuery<GetOrganizations>(GET_ORGANIZATIONS, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    data,
    refetch,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
