import { Button, Modal } from "semantic-ui-react";
import SelectDocument from "./SelectDocument";
import { AsyncQueryStatus } from "../models/AsyncActionStatus";
import { Document_ListItem } from "../models/Document_ListItem";
import { DocumentType } from "../models/DocumentType";

interface Props {
  open: boolean;
  documents?: Document_ListItem[] | null;
  pageCount?: number;
  loading?: boolean;
  docType?: DocumentType;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchDocuments: (serachStrign: string) => void;
  getDocumentsStatus: AsyncQueryStatus;
  onSelectDocument: (doc: Document_ListItem) => void;
  onClose: () => void;
}

const SelectDocumentModal = ({
  open,
  documents,
  pageCount,
  loading,
  docType,
  onPageInfoChange,
  onSearchDocuments,
  getDocumentsStatus,
  onSelectDocument,
  onClose,
}: Props) => {
  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Select {docType && docType.name} document</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <SelectDocument
            documents={documents}
            pageCount={pageCount}
            loading={loading}
            onPageInfoChange={onPageInfoChange}
            onSearchDocuments={onSearchDocuments}
            onSelectDocument={onSelectDocument}
          ></SelectDocument>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SelectDocumentModal;
