import { startCase, truncate } from "lodash";
import { Header, Icon, Table } from "semantic-ui-react";
import { OrganizationConfiguration } from "../models/OrganizationConfiguration";
import GridActionButton from "./GridActionButton";

interface Props {
  onSetOrganizationConfiguration: (configKey: string, configValue: object) => void;
  onResetOrganizationConfiguration: (configKey: string) => void;
  organizationConfiguration?: OrganizationConfiguration;
}

const OrganizationConfigurationGrid = ({
  onSetOrganizationConfiguration,
  onResetOrganizationConfiguration,
  organizationConfiguration,
}: Props) => {
  const renderOrganizationConfigurationTableRow = (k: string, v: object) => {
    const va = Object.entries(v);

    if (va) {
      return va
        .filter((v) => !v[0].startsWith("__"))
        .map(([key, value]) => (
          <Table.Row key={key}>
            <Table.Cell width={"2"}>{startCase(key).toLowerCase()}</Table.Cell>
            <Table.Cell width={"3"}>
              {typeof value == "boolean" && <> {value ? "Yes" : "No"}</>}
              {typeof value !== "boolean" && truncate((value as Object)?.toString())}
              <GridActionButton
                rowId={key}
                items={[
                  {
                    key: "set",
                    icon: "edit",
                    content: "Edit",
                    callback: (e) => onSetOrganizationConfiguration(k + ":" + key, value),
                  },
                  {
                    key: "reset",
                    icon: "redo",
                    content: "Reset",
                    callback: (e) => onResetOrganizationConfiguration(k + ":" + key),
                  },
                ]}
              />
            </Table.Cell>
          </Table.Row>
        ));
    }
  };

  const renderOrganizationConfigurationCategory = () => {
    return configurationMap
      ?.filter((v) => !v[0].startsWith("__"))
      .map(([key, value]) => (
        <div key={key}>
          <Header as="h4" style={{ marginBottom: 0, marginLeft: "-0.6em", marginTop: "1em" }}>
            <Icon name="caret right" />
            {startCase(key)} Settings
          </Header>

          <Table basic="very" compact style={{ marginTop: 0, marginBottom: "2em" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{renderOrganizationConfigurationTableRow(key, value)}</Table.Body>

            <Table.Footer>
              <Table.Row textAlign="right">
                <Table.HeaderCell colSpan="2"></Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      ));
  };

  const configurationMap = organizationConfiguration ? Object.entries(organizationConfiguration) : null;

  return <>{renderOrganizationConfigurationCategory()}</>;
};

export default OrganizationConfigurationGrid;
