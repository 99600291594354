import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddNewVersionToDocument, AddNewVersionToDocumentVariables } from "./__generated__/AddNewVersionToDocument";
import { DOCUMENT_FIELDS } from "../fragments/Document";

const ADD_NEW_VERSION_TO_DOCUMENT = gql`
  ${DOCUMENT_FIELDS}
  mutation AddNewVersionToDocument(
    $documentId: String!
    $title: String!
    $versionNo: Decimal!
    $fileMetaData: FileMetaDataInput!
  ) {
    addNewVersionToDocument(
      input: { documentId: $documentId, title: $title, versionNo: $versionNo, fileMetaData: $fileMetaData }
    ) {
      document {
        ...DocumentFields
      }
    }
  }
`;

export function useAddNewVersionToDocument() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddNewVersionToDocument,
    AddNewVersionToDocumentVariables
  >(ADD_NEW_VERSION_TO_DOCUMENT, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
