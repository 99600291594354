import { useLazyGetAllVersionsOfDocumentByParentId } from "../operations/queries/getAllVersionsOfDocumentByParentId";
import DocumentView from "../components/DocumentView";
import { Document_Data } from "../models/Document_Data";
import { useEffect } from "react";
import { Document_ListItem } from "../models/Document_ListItem";

interface Props {
  onClose?: () => void;
  onPopup?: () => void;
  isPopup?: boolean;
  document?: Document_Data;
  onChangeVersion?: (doc: Document_ListItem) => void;
}

const DocumentViewContainer = ({ onClose, onPopup, isPopup, document, onChangeVersion }: Props) => {
  const { getAllVersionsOfDocumentByParentId: getDocuments, data: documents } =
    useLazyGetAllVersionsOfDocumentByParentId();

  useEffect(() => {
    if (document) {
      getDocuments({ variables: { parentId: document.parentId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const handleChangeVersion = (id: string) => {
    onChangeVersion &&
      documents &&
      onChangeVersion(documents.allVersionsOfDocumentByParentId.find((d) => d.id === id)!);
  };

  return (
    <DocumentView
      containerId="DocViewContainer"
      onPopup={onPopup}
      onClose={onClose}
      isPopup={isPopup}
      document={document}
      onChangeVersion={handleChangeVersion}
      documentVersions={
        documents && documents.allVersionsOfDocumentByParentId.length > 1
          ? documents?.allVersionsOfDocumentByParentId.map((d) => ({ key: d.id, text: d.versionNo, value: d.id }))
          : undefined
      }
    ></DocumentView>
  );
};

export default DocumentViewContainer;
