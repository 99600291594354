import { gql } from "@apollo/client";
import { ORG_USER_FIELDS } from "./OrgUser";
import { ORG_ROLE_FIELDS } from "./OrgRole";
import { CORE_PROJECT_FIELDS } from "./project";
import { DOCUMENT_FIELDS } from "./Document";
import { CORE_GANG_MEMBER_FIELDS } from "./GangMember";

export const PERMIT_TO_DIG_DATA_FIELDS = gql`
  fragment PermitToDigDataFields on PermitToDig {
    comments
    validityDuration {
      from
      to
      hoursFrom
      hoursTo
    }
    contractor
    subContractor
    locationOfWork
    permittedEquipment
    worksDescription
    maxPenetrationDepth
    noAffectedServices
    affectedServices
    asBuiltRequired
  }
`;

export const PERMIT_TO_DIG_LIST_FIELDS = gql`
  ${ORG_USER_FIELDS}
  ${ORG_ROLE_FIELDS}
  fragment PermitToDigListFields on PermitToDig {
    id
    number
    validityDuration {
      from
      to
      hoursFrom
      hoursTo
    }
    locationOfWork
    worksDescription
    createdBy {
      ...OrgUserFields
    }
    state {
      key
      name
    }
    boundaries {
      coordinates
      crs
      type
    }
    permitAssignments {
      id
      orgRole {
        ...OrgRoleFields
      }
      orgUser {
        ...OrgUserFields
      }
    }
  }
`;

export const PERMIT_TO_DIG_ASSOCIATED_ENTITIES = gql`
  ${CORE_PROJECT_FIELDS}
  ${PERMIT_TO_DIG_DATA_FIELDS}
  ${ORG_USER_FIELDS}
  ${ORG_ROLE_FIELDS}
  ${DOCUMENT_FIELDS}
  ${CORE_GANG_MEMBER_FIELDS}
  fragment PermitToDigAssociatedEntities on PermitToDig {
    signatures {
      type
      dateSignedUtc
      orgRole {
        ...OrgRoleFields
      }
      orgUser {
        ...OrgUserFields
      }
      date
      isAbsent
      gangMember {
        id
      }
      signatureUrl
      declaration
      signatory {
        name
        roleName
        phoneNumber
      }
    }
    sitePhotos {
      fileUrl
    }
    gangMembers {
      ...CoreGangMemberFields
    }
    documents {
      ...DocumentFields
    }
    locationLogs {
      id
      user {
        ...OrgUserFields
      }
      date
      latitude
      longitude
    }
    boundaries {
      coordinates
      crs
      type
    }
  }
`;

export const PERMIT_TO_DIG_ALL_FIELDS = gql`
  ${CORE_PROJECT_FIELDS}
  ${PERMIT_TO_DIG_DATA_FIELDS}
  ${ORG_USER_FIELDS}
  ${PERMIT_TO_DIG_ASSOCIATED_ENTITIES}
  fragment PermitToDigAllFields on PermitToDig {
    id
    number
    permittedTriggers
    ...PermitToDigDataFields
    ...PermitToDigAssociatedEntities
    createdBy {
      ...OrgUserFields
    }
    state {
      key
      name
    }
    project {
      ...CoreProjectFields
    }
    stateChangeLogs {
      source
      destination
      trigger
      dateTimeUtc
      orgUser {
        ...OrgUserFields
      }
      comment
    }
    permitAssignments {
      id
      orgRole {
        ...OrgRoleFields
      }
      orgUser {
        ...OrgUserFields
      }
      assignedBy {
        ...OrgUserFields
      }
      dateAssignedUtc
    }
    eightPointCheckList {
      items
      declaration
    }
  }
`;
