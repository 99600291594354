import { gql } from "@apollo/client";

export const CORE_GANG_MEMBER_FIELDS = gql`
  fragment CoreGangMemberFields on GangMember {
    id
    firstName
    lastName
    displayName
    jobTitle
    employerName
    isArchived
    companyInduction {
      date
      number
    }
    buriedServicesInduction {
      date
      number
      expiresOn
    }
  }
`;
