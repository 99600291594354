import { gql, useLazyQuery } from "@apollo/client";
import { getErrors } from "../../utils";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { GetPermitIssueTrend, GetPermitIssueTrendVariables } from "./__generated__/GetPermitIssueTrend";

export const GET_PERMIT_ISSUE_TREND = gql`
  query GetPermitIssueTrend($projectId: String!) {
    permitIssueTrend(projectId: $projectId) {
      year
      month
      count
    }
  }
`;

export function useGetPermitIssueTrend() {
  const [getPermitIssueTrend, { loading, error, data }] = useLazyQuery<
    GetPermitIssueTrend,
    GetPermitIssueTrendVariables
  >(GET_PERMIT_ISSUE_TREND, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  return {
    getPermitIssueTrend,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
