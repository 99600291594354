import { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import { DocumentType } from "../models/DocumentType";
import EditDocumentTypeForm, { FormData } from "./EditDocumentTypeForm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, declaration: string, isMandatory: boolean, id?: string) => void;
  submitInProgress: boolean;
  submitErrors?: string[];
  documentType?: DocumentType;
  showDocumentTypeKey: boolean;
}

const EditDocumentTypeModal = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  submitErrors,
  documentType,
  showDocumentTypeKey,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData({
        ...documentType,
        declaration: documentType?.declaration ?? "",
        isMandatory: documentType?.isMandatory ?? false,
      } as FormData);
    } else {
      setFormData({} as FormData);
      setFormErrors({} as FormError);
      setHasSubmitted(false);
    }
  }, [open, documentType]);

  const validateFormData = (formData: FormData) => {
    const formError: FormError = {};

    if (!formData.name) {
      formError.name = "Please enter document type name";
    }

    return formError;
  };

  const handleSubmit = () => {
    setHasSubmitted(true);

    const validationErrors = validateFormData(formData);
    setFormErrors(validationErrors);

    // If there is no validation error
    if (Object.keys(validationErrors).length === 0) {
      const { name, declaration, isMandatory, id } = formData;
      onSubmit(name, declaration, isMandatory, id);
    }
  };

  const handleFormChange = (formData: FormData) => {
    if (hasSubmitted) {
      const validationErrors = validateFormData(formData);
      setFormErrors(validationErrors);
    }

    setFormData({ ...formData });
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Modal.Header>Document Type's Details</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <EditDocumentTypeForm
            formErrors={formErrors}
            errors={submitErrors}
            formData={formData}
            onChange={handleFormChange}
            showDocumentTypeKey={showDocumentTypeKey}
          ></EditDocumentTypeForm>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handelClose}>Cancel</Button>
        <Button onClick={handleSubmit} positive loading={submitInProgress} disabled={submitInProgress}>
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditDocumentTypeModal;
