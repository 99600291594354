import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddOrganization, AddOrganizationVariables } from "./__generated__/AddOrganization";

const ADD_ORGANIZATION = gql`
  mutation AddOrganization($name: String!, $logoMetaData: FileMetaDataInput, $addDefaultRoles: Boolean!) {
    addOrganization(input: { name: $name, logoMetaData: $logoMetaData, addDefaultRoles: $addDefaultRoles }) {
      organization {
        id
        name
      }
    }
  }
`;

export function useAddOrganization() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<
    AddOrganization,
    AddOrganizationVariables
  >(ADD_ORGANIZATION, {
    errorPolicy: "all",
  });
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
