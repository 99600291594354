import CompletePermitModal from "../components/CompletePermitModal";
import { Document } from "../models/Document";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitInProgress: boolean;
  showAsBuilt?: boolean;
  documents?: Document[];
  onAsBuiltAdded?: () => void;
}

const CompletePermitModalContainer = ({
  open,
  onClose,
  onSubmit,
  submitInProgress,
  showAsBuilt,
  documents,
  onAsBuiltAdded,
}: Props) => {
  return (
    <CompletePermitModal
      title="Completion review"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      submitInProgress={submitInProgress}
      showAsBuilt={showAsBuilt}
      documents={documents}
      onAsBuiltAdded={onAsBuiltAdded}
    ></CompletePermitModal>
  );
};

export default CompletePermitModalContainer;
