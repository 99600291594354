import { Segment, Grid } from "semantic-ui-react";
import OrganizationConfigurationGrid from "./OrganizationConfigurationGrid";
import { OrganizationConfiguration } from "../models/OrganizationConfiguration";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastSuccess } from "../utils";
import ConfirmModal from "./common/ConfirmModal";
import SetConfigurationValueModal from "./SetConfigurationValueModal";
import { startCase } from "lodash";

interface Props {
  isOrganizationConfigurationLoading: boolean;
  organizationConfiguration?: OrganizationConfiguration;
  onSetOrganizationConfiguration: (configKey: string, configValue: object) => void;
  setOrganizationConfigurationStatus: AsyncActionStatus;
  onResetOrganizationConfiguration: (configKey: string) => void;
  resetOrganizationConfigurationStatus: AsyncActionStatus;
}

const ConfigurationList = ({
  isOrganizationConfigurationLoading,
  organizationConfiguration,
  onSetOrganizationConfiguration,
  setOrganizationConfigurationStatus,
  onResetOrganizationConfiguration,
  resetOrganizationConfigurationStatus,
}: Props) => {
  const [openResetOrganizationConfigurationModal, setOpenResetOrganizationConfigurationModal] = useState(false);
  const [openSetOrganizationConfigurationModal, setOpenSetOrganizationConfigurationModal] = useState(false);
  const [selectedOrganizationConfiguration, setSelectedOrganizationConfiguration] = useState<{
    configKey: string;
    configValue: object | null;
  }>();

  const handleResetOrganizationConfiguration = (configKey: string) => {
    setSelectedOrganizationConfiguration({ configKey, configValue: null });
    setOpenResetOrganizationConfigurationModal(true);
  };

  const handleSetOrganizationConfiguration = (configKey: string, configValue: object) => {
    setSelectedOrganizationConfiguration({ configKey, configValue });
    setOpenSetOrganizationConfigurationModal(true);
  };

  const handleModalClose = () => {
    setOpenResetOrganizationConfigurationModal(false);
    setOpenSetOrganizationConfigurationModal(false);
    setSelectedOrganizationConfiguration(undefined);

    setOrganizationConfigurationStatus.reset();
    resetOrganizationConfigurationStatus.reset();
  };

  const handleResetOrganizationConfigurationModalSubmit = () => {
    if (selectedOrganizationConfiguration)
      onResetOrganizationConfiguration(selectedOrganizationConfiguration.configKey);
  };

  const handleSetOrganizationConfigurationModalSubmit = (configValue: object) => {
    if (selectedOrganizationConfiguration)
      onSetOrganizationConfiguration(selectedOrganizationConfiguration.configKey, configValue);
  };

  useEffect(() => {
    if (setOrganizationConfigurationStatus.successful) {
      toastSuccess("New value has been set successfully!");
      setOpenSetOrganizationConfigurationModal(false);
      setSelectedOrganizationConfiguration(undefined);
    }
  }, [setOrganizationConfigurationStatus.successful]);

  useEffect(() => {
    if (resetOrganizationConfigurationStatus.successful) {
      toastSuccess("Configuration reset successfully!");
      setOpenResetOrganizationConfigurationModal(false);
      setSelectedOrganizationConfiguration(undefined);
    }
  }, [resetOrganizationConfigurationStatus.successful]);

  return (
    <>
      <Segment basic loading={isOrganizationConfigurationLoading}>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column width="16">
              <OrganizationConfigurationGrid
                organizationConfiguration={organizationConfiguration}
                onResetOrganizationConfiguration={handleResetOrganizationConfiguration}
                onSetOrganizationConfiguration={handleSetOrganizationConfiguration}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <ConfirmModal
        onClose={handleModalClose}
        onSubmit={handleResetOrganizationConfigurationModalSubmit}
        open={openResetOrganizationConfigurationModal}
        title="Reset configuration"
      />

      <SetConfigurationValueModal
        onClose={handleModalClose}
        onSubmit={handleSetOrganizationConfigurationModalSubmit}
        submitInProgress={setOrganizationConfigurationStatus.loading}
        open={openSetOrganizationConfigurationModal}
        title="Edit"
        initialValue={selectedOrganizationConfiguration?.configValue}
        label={startCase(selectedOrganizationConfiguration?.configKey.split(":").pop())?.toLowerCase()}
      />
    </>
  );
};

export default ConfigurationList;
