import { Text } from "@react-pdf/renderer";
import moment from "moment";
import styles from "./components/Styles";
import { Permit } from "../../models/Permit";
import Header from "./components/Header";
import Row from "./components/Row";
import Item from "./components/Item";
import HeaderText from "./components/HeaderText";

interface Props {
  permit: Permit;
  orgLogoUrl: string | null;
  token: string;
}

const PermitDescriptionPage = ({ permit, orgLogoUrl, token }: Props) => {
  return (
    <>
      <Header logoUrl={orgLogoUrl} token={token} title={"Permit to dig"} permitNumber={permit.number} />

      <HeaderText title="PROJECT" />

      <Row border>
        <Item title="Project name" text={permit.project.name} width="50%" />
        <Item title="Project no." text={permit.project.number} width="50%" />
      </Row>

      <HeaderText title="DETAILS" />
      <Row border>
        <Item title="Contractor" text={permit.contractor} width="50%" />
        <Item title="Sub-contractor" text={permit.subContractor} width="50%" />
      </Row>

      <Row border>
        <Item title="Works description" text={permit.worksDescription} width="50%" />
        <Item title="Location of work" text={permit.locationOfWork} width="50%" />
      </Row>

      <Row border>
        <Item
          title="Duration"
          text={`${moment.utc(permit.validityDuration.from).local().format("L")} to ${moment
            .utc(permit.validityDuration.to)
            .local()
            .format("L")}`}
          width="50%"
        />
        <Item
          title="Hours"
          text={`${String(moment.duration(permit.validityDuration?.hoursFrom).hours()).padStart(2, "0")}:${String(
            moment.duration(permit.validityDuration?.hoursFrom).minutes()
          ).padStart(2, "0")} to ${String(moment.duration(permit.validityDuration?.hoursTo).hours()).padStart(
            2,
            "0"
          )}:${String(moment.duration(permit.validityDuration?.hoursTo).minutes()).padStart(2, "0")}`}
          width="50%"
        />
      </Row>

      <Row border>
        <Item title="Permitted equipment" text={permit.permittedEquipment} width="50%" />
        <Item title="Max penetration depth" text={permit.maxPenetrationDepth} width="50%" />
      </Row>

      <Row border>
        <Item title="Affected services" text={permit.affectedServices.join(", ")} width="100%" />
      </Row>

      <Row border last>
        <Item title="Comments" text={permit.comments} width="100%" />
      </Row>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </>
  );
};

export default PermitDescriptionPage;
