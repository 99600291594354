import { Segment, Menu, Header, Grid, InputOnChangeData, Input, Dropdown } from "semantic-ui-react";
import AdminLicencesGrid from "./AdminLicencesGrid";
import { Licence } from "../models/Licence";
import { AsyncActionStatus } from "../models/AsyncActionStatus";
import { useEffect, useState } from "react";
import { toastSuccess } from "../utils";
import AdminAddLicenceModal from "./AdminAddLicenceModal";
import { Organization } from "../models/Organization";

interface Props {
  isLicencesLoading: boolean;
  licences?: Licence[] | null;
  organizations?: Organization[] | null;
  pageCount?: number;
  onPageInfoChange: (pageSize: number, pageIndex: number) => void;
  onSearchLicences: (searchStrign: string) => void;
  onOrgFilter: (orgId: string) => void;
  onAddLicence: (
    startDate: Date,
    endDate: Date,
    numberOfUsers: number,
    comment: string,
    organizationId: string
  ) => void;
  addLicenceStatus: AsyncActionStatus;
  onArchiveLicence: (id: string) => void;
  archiveLicenceStatus: AsyncActionStatus;
  isArchivedFilterValue?: boolean | null;
  onIsArchivedFilter: (isArchived: boolean) => void;
}

const AdminLicencesList = ({
  isLicencesLoading,
  licences,
  organizations,
  pageCount,
  onPageInfoChange,
  onSearchLicences,
  onOrgFilter,
  onAddLicence,
  addLicenceStatus,
  onArchiveLicence,
  archiveLicenceStatus,
  isArchivedFilterValue,
  onIsArchivedFilter,
}: Props) => {
  const [openAddLicenceModal, setOpenAddLicenceModal] = useState(false);

  const handleModalClose = () => {
    setOpenAddLicenceModal(false);
    addLicenceStatus.reset();
  };

  const handleAddLicenceModalSubmit = (
    startDate: Date,
    endDate: Date,
    numberOfUsers: number,
    comment: string,
    organizationId: string
  ) => {
    onAddLicence(startDate, endDate, numberOfUsers, comment, organizationId);
  };

  useEffect(() => {
    if (addLicenceStatus.successful) {
      toastSuccess("Licence added successfully!");
      setOpenAddLicenceModal(false);
    }
  }, [addLicenceStatus.successful]);

  useEffect(() => {
    if (archiveLicenceStatus.successful) {
      toastSuccess("Licence archived successfully!");
    }
  }, [archiveLicenceStatus.successful]);

  const handleSearchStringChange = (e: any, { name, value }: InputOnChangeData) => {
    onSearchLicences(value);
  };

  const handleIsArchivedFilterChange = (e: any, { name, value }: any) => {
    onIsArchivedFilter(value === "" ? null : value);
  };

  const handleOrgFilterChange = (e: any, { name, value }: any) => {
    onOrgFilter(value === "" ? null : value);
  };

  return (
    <>
      <Segment basic>
        <Header as="h2">Licences</Header>
      </Segment>

      <Menu pointing secondary color="green">
        <Menu.Item icon="plus" name="New licence" onClick={(e) => setOpenAddLicenceModal(true)} />

        <Menu.Menu position="right">
          <Menu.Item>
            <Dropdown
              placeholder="Organization"
              basic
              clearable
              onChange={handleOrgFilterChange}
              options={organizations?.map((o) => ({ key: o.id, text: o.name, value: o.id }))}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              placeholder="View"
              basic
              clearable
              onChange={handleIsArchivedFilterChange}
              value={isArchivedFilterValue === null ? undefined : isArchivedFilterValue}
              options={[
                { value: false, key: "not archived", text: "not archived" },
                { value: true, key: "archived", text: "archived" },
              ]}
            ></Dropdown>
          </Menu.Item>
          <Menu.Item>
            <Input icon="search" placeholder="Search..." onChange={handleSearchStringChange} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment basic loading={isLicencesLoading}>
        <Grid columns="1">
          <Grid.Row>
            <Grid.Column width="16">
              <AdminLicencesGrid
                licences={licences}
                pageCount={pageCount}
                onPageInfoChange={onPageInfoChange}
                onArchiveLicence={onArchiveLicence}
                archiveLicenceInProgress={archiveLicenceStatus.loading}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <AdminAddLicenceModal
        open={openAddLicenceModal}
        onClose={handleModalClose}
        onSubmit={handleAddLicenceModalSubmit}
        submitInProgress={addLicenceStatus.loading}
        submitErrors={addLicenceStatus.errors}
        organizations={organizations}
      ></AdminAddLicenceModal>
    </>
  );
};

export default AdminLicencesList;
