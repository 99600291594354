import { gql } from "@apollo/client";
import { AsyncActionStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { useResettableMutation } from "../useResettableMutation";
import { AddOrgUser, AddOrgUserVariables } from "./__generated__/AddOrgUser";
import { ORG_USER_FIELDS } from "../fragments/OrgUser";

const ADD_ORG_USER = gql`
  ${ORG_USER_FIELDS}
  mutation AddOrgUser(
    $firstName: String
    $lastName: String
    $email: String!
    $password: String!
    $organizationId: String!
    $isAdmin: Boolean!
    $phoneNumber: String
    $calibrationCertificate: CalibrationCertificateInput
    $orgUserFiles: [OrgUserFileInput]
  ) {
    addOrgUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        organizationId: $organizationId
        isAdmin: $isAdmin
        phoneNumber: $phoneNumber
        calibrationCertificate: $calibrationCertificate
        orgUserFiles: $orgUserFiles
      }
    ) {
      orgUser {
        ...OrgUserFields
        orgUserFiles {
          id
          title
          expiryDate
          fileUrl
        }
      }
    }
  }
`;

export function useAddOrgUser() {
  const [mutate, { loading, data, error, reset, called }] = useResettableMutation<AddOrgUser, AddOrgUserVariables>(
    ADD_ORG_USER,
    {
      errorPolicy: "all",
    }
  );
  return {
    mutate,
    data,
    status: {
      loading,
      reset,
      called,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncActionStatus,
  };
}
