import { gql, useLazyQuery } from "@apollo/client";
import { PERMIT_TO_DIG_ALL_FIELDS } from "../fragments/permitToDig";
import { GetPermitToDig, GetPermitToDigVariables } from "./__generated__/GetPermitToDig";
import { getErrors } from "../../utils";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";

export const GET_PERMIT_TO_DIG = gql`
  ${PERMIT_TO_DIG_ALL_FIELDS}
  query GetPermitToDig($id: String!) {
    permitToDig(id: $id) {
      ...PermitToDigAllFields
    }
  }
`;

export function useLazyGetPermitToDig() {
  const [getPermitToDig, { loading, error, data, refetch }] = useLazyQuery<GetPermitToDig, GetPermitToDigVariables>(
    GET_PERMIT_TO_DIG,
    {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    }
  );

  return {
    getPermitToDig,
    data: data?.permitToDig,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
    refetch,
  };
}
