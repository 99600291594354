import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import {
  GetOrgRolesByOrganizationId,
  GetOrgRolesByOrganizationIdVariables,
} from "./__generated__/GetOrgRolesByOrganizationId";
import { ORG_ROLE_FIELDS } from "../fragments/OrgRole";

export const GET_ORG_ROLES_BY_ORGANIZATION_ID = gql`
  ${ORG_ROLE_FIELDS}
  query GetOrgRolesByOrganizationId($organizationId: String!) {
    orgRolesByOrganizationId(organizationId: $organizationId) {
      ...OrgRoleFields
    }
  }
`;

export function useLazyGetOrgRolesByOrganizationId() {
  const [getOrgRolesByOrganizationId, { loading, error, data }] = useLazyQuery<
    GetOrgRolesByOrganizationId,
    GetOrgRolesByOrganizationIdVariables
  >(GET_ORG_ROLES_BY_ORGANIZATION_ID, {
    errorPolicy: "all",
  });

  return {
    getOrgRolesByOrganizationId,
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
