import { gql, useLazyQuery } from "@apollo/client";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";
import { getErrors } from "../../utils";
import { CORE_GANG_MEMBER_FIELDS } from "../fragments/GangMember";
import {
  GetGangMembersByOrganizationId,
  GetGangMembersByOrganizationIdVariables,
} from "./__generated__/GetGangMembersByOrganizationId";

export const GET_GANG_MEMBERS_BY_ORGANIZATION_ID = gql`
  ${CORE_GANG_MEMBER_FIELDS}
  query GetGangMembersByOrganizationId(
    $organizationId: String!
    $searchString: String
    $skip: Int
    $take: Int
    $isArchived: Boolean
  ) {
    gangMembersByOrganizationId(
      organizationId: $organizationId
      searchString: $searchString
      skip: $skip
      take: $take
      isArchived: $isArchived
    ) {
      totalCount
      items {
        ...CoreGangMemberFields
      }
    }
  }
`;

export function useLazyGetGangMembersByOrganizationId() {
  const [getGangMembersByOrganizationId, { loading, error, data }] = useLazyQuery<
    GetGangMembersByOrganizationId,
    GetGangMembersByOrganizationIdVariables
  >(GET_GANG_MEMBERS_BY_ORGANIZATION_ID, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return {
    getGangMembersByOrganizationId,
    data,
    status: { loading, errors: getErrors(error), successful: !error && data ? true : false } as AsyncQueryStatus,
  };
}
