import {
  GET_ORGANIZATION_CONFIGURATION,
  useLazyGetOrganizationConfiguration,
} from "../operations/queries/getOrganizationConfiguration";
import ConfigurationList from "../components/ConfigurationList";
import { useSetOrganizationConfiguration } from "../operations/mutations/setOrganizationConfiguration";
import { useResetOrganizationConfiguration } from "../operations/mutations/resetOrganizationConfiguration";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useEffect } from "react";
import { isArray } from "lodash";

const ConfigurationListContainer = () => {
  const { currentOrganization } = useCurrentOrganization();

  const {
    getOrganizationConfiguration,
    data: organizationConfiguration,
    status: getOrganizationConfigurationStatus,
  } = useLazyGetOrganizationConfiguration();
  const { mutate: setOrganizationConfigurationMutate, status: setOrganizationConfigurationStatus } =
    useSetOrganizationConfiguration();
  const { mutate: resetOrganizationConfigurationMutate, status: resetOrganizationConfigurationStatus } =
    useResetOrganizationConfiguration();

  useEffect(() => {
    if (currentOrganization) {
      getOrganizationConfiguration({ variables: { organizationId: currentOrganization.id } });
    }
  }, [currentOrganization, getOrganizationConfiguration]);

  const handleSetOrganizationConfiguration = (configKey: string, configValue: object) => {
    if (currentOrganization) {
      setOrganizationConfigurationMutate({
        refetchQueries: [GET_ORGANIZATION_CONFIGURATION],
        variables: {
          configKey,
          configValue: isArray(configValue) ? JSON.stringify(configValue) : configValue.toString(),
          contentType: isArray(configValue) ? "application/json" : "",
          organizationId: currentOrganization.id,
        },
      });
    }
  };

  const handleResetOrganizationConfiguration = (configKey: string) => {
    if (currentOrganization) {
      resetOrganizationConfigurationMutate({
        refetchQueries: [GET_ORGANIZATION_CONFIGURATION],
        variables: {
          organizationId: currentOrganization.id,
          configKey,
        },
      });
    }
  };

  return (
    <ConfigurationList
      isOrganizationConfigurationLoading={getOrganizationConfigurationStatus.loading}
      organizationConfiguration={organizationConfiguration && organizationConfiguration.organizationConfiguration}
      onSetOrganizationConfiguration={handleSetOrganizationConfiguration}
      setOrganizationConfigurationStatus={setOrganizationConfigurationStatus}
      onResetOrganizationConfiguration={handleResetOrganizationConfiguration}
      resetOrganizationConfigurationStatus={resetOrganizationConfigurationStatus}
    ></ConfigurationList>
  );
};

export default ConfigurationListContainer;
