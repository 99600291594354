import { useLazyGetPermitToDigsByProjectId } from "../operations/queries/getPermitToDigsByProjectId";
import PermitsList from "../components/PermitsList";
import { useCurrentProject } from "../hooks/useCurrentProject";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import { useProjectPolicies } from "../hooks/useProjectPolicies";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StateKey } from "../models/globalTypes";
import { useQueryString } from "../hooks/useQueryString";
import { StateKeyList } from "../models/StateKey";
import { EsriIntegrationTypes } from "../models/EsriIntegrationType";
import { useOnProjectPermitToDigDetailsChanged } from "../operations/subscriptions/onProjectPermitToDigDetailsChanged";

const PermitsListContainer = () => {
  const { currentOrganization, organizationName } = useCurrentOrganization();
  const { currentProject, projectName } = useCurrentProject();

  const history = useHistory();
  const queryString = useQueryString();

  const searchString = queryString.get("search") ?? "";
  const stateFilterKeyString = queryString.get("state");
  const assignedToFilter = queryString.get("assignedToId") ?? "";
  const createdByFilter = queryString.get("createdById") ?? "";
  const stateFilter: StateKey | null = stateFilterKeyString
    ? StateKeyList[stateFilterKeyString.toLocaleUpperCase()].key
    : null;

  const { authorizedToAddPermit, authorizedToRequestPermit } = useProjectPolicies();
  const [paginationInfo, setPaginationInfo] = useState({ pageSize: 0, pageIndex: 0 });

  const {
    getPermitToDigsByProjectId: getPermits,
    status: getPermitsStatus,
    data: permits,
    refetch,
  } = useLazyGetPermitToDigsByProjectId();

  const { data } = useOnProjectPermitToDigDetailsChanged(currentProject?.id);

  /* useEffect(() => {
    if (permitListChanged && refetch) {
      refetch();
    }
  }, [permitListChanged, refetch]); */

  const handlePageInfoChange = useCallback((pageIndex: number, pageSize: number) => {
    if (pageSize !== paginationInfo.pageSize || pageIndex !== paginationInfo.pageIndex) {
      setPaginationInfo({ pageSize, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchPermits = (searchString: string) => {
    if (searchString) {
      queryString.set("search", searchString);
    } else {
      queryString.delete("search");
    }

    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });

    history.push({
      pathname: `/${organizationName}/projects/${projectName}/permits`,
      search: queryString.toString(),
    });
  };

  const handleStateFilter = (stateKey: StateKey) => {
    if (stateKey) {
      queryString.set("state", stateKey);
    } else {
      queryString.delete("state");
    }

    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });

    history.push({
      pathname: `/${organizationName}/projects/${projectName}/permits`,
      search: queryString.toString(),
    });
  };

  const handleAssignedToFilter = (assignedToId: string) => {
    if (assignedToId) {
      queryString.set("assignedToId", assignedToId);
    } else {
      queryString.delete("assignedToId");
    }

    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });

    history.push({
      pathname: `/${organizationName}/projects/${projectName}/permits`,
      search: queryString.toString(),
    });
  };

  const handleCreatedByFilter = (createdById: string) => {
    if (createdById) {
      queryString.set("createdById", createdById);
    } else {
      queryString.delete("createdById");
    }

    setPaginationInfo({ ...paginationInfo, pageIndex: 0 });

    history.push({
      pathname: `/${organizationName}/projects/${projectName}/permits`,
      search: queryString.toString(),
    });
  };

  useEffect(() => {
    if (currentProject && paginationInfo.pageSize > 0)
      getPermits({
        variables: {
          projectId: currentProject.id,
          skip: paginationInfo.pageIndex * paginationInfo.pageSize,
          take: paginationInfo.pageSize,
          searchString,
          stateKey: stateFilter,
          assignedToId: assignedToFilter,
          createdById: createdByFilter,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationInfo.pageSize,
    paginationInfo.pageIndex,
    currentProject,
    searchString,
    stateFilter,
    assignedToFilter,
    createdByFilter,
  ]);

  /* const handlePermitListUpdate = () => {
    refetchPermits && refetchPermits();
  }; */

  return (
    <PermitsList
      showPermitBoundariesOnMap={
        currentOrganization?.esriConfig?.integrationTypeId != null &&
        currentOrganization.esriConfig.integrationTypeId !== EsriIntegrationTypes.NONE.id
      }
      newPermitButtonVisible={authorizedToAddPermit}
      requestPermitButtonVisible={authorizedToRequestPermit}
      permits={permits?.permitToDigsByProjectId?.items && permits.permitToDigsByProjectId.items}
      currentProject={currentProject}
      pageCount={permits && Math.ceil((permits?.permitToDigsByProjectId?.totalCount ?? 0) / paginationInfo.pageSize)}
      stateFilter={stateFilter}
      assignedToFilter={assignedToFilter}
      createdByFilter={createdByFilter}
      searchString={searchString}
      onPageInfoChange={handlePageInfoChange}
      onSearchPermits={handleSearchPermits}
      onStateFilter={handleStateFilter}
      onAssignedToFilter={handleAssignedToFilter}
      onCreatedByFilter={handleCreatedByFilter}
      getPermitsStatus={getPermitsStatus}
    ></PermitsList>
  );
};

export default PermitsListContainer;
