import {
  GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID,
  useGetProjectAssignmentsByProjectId,
} from "../operations/queries/getProjectAssignmentsByProjectId";
import { useLazyGetOrgUsersByOrganizationId } from "../operations/queries/getOrgUsersByOrganizationId";
import { useLazyGetOrgRolesByOrganizationId } from "../operations/queries/getOrgRolesByOrganizationId";
import { useAddProjectAssignments } from "../operations/mutations/addProjectAssignments";
import { useDeleteProjectAssignment } from "../operations/mutations/deleteProjectAssignment";
import { useCurrentOrganization } from "../hooks/useCurrentOrganization";
import ProjectView from "../components/ProjectView";
import { Project } from "../models/Project";
import { useEffect } from "react";

interface Props {
  showProjectBoundariesOnMap?: boolean;
  isPopup?: boolean;
  project?: Project;
  onClose: () => void;
}

const ProjectsViewContainer = ({ showProjectBoundariesOnMap, isPopup, project, onClose }: Props) => {
  const { currentOrganization } = useCurrentOrganization();

  const { getProjectAssignmentsByProjectId: getProjectAssignments, data: projectAssignments } =
    useGetProjectAssignmentsByProjectId();
  const { getOrgUsersByOrganizationId: getOrgUsers, data: orgUsers } = useLazyGetOrgUsersByOrganizationId();
  const { getOrgRolesByOrganizationId: getOrgRoles, data: orgRoles } = useLazyGetOrgRolesByOrganizationId();
  const { mutate: addProjectAssignmentsMutate, status: addProjectAssignmentsStatus } = useAddProjectAssignments();
  const { mutate: deleteProjectAssignmentMutate, status: deleteProjectAssignmentStatus } = useDeleteProjectAssignment();

  useEffect(() => {
    if (project) {
      getProjectAssignments({ variables: { projectId: project.id } });
    }
  }, [project, getProjectAssignments]);

  const handleOrgUsersRequest = () => {
    if (currentOrganization) {
      getOrgUsers({ variables: { organizationId: currentOrganization.id } });
      getOrgRoles({ variables: { organizationId: currentOrganization.id } });
    }
  };

  const handleAddProjectAssignments = (orgUserIds: string[], projectId: string, orgRoleId: string) => {
    addProjectAssignmentsMutate({
      refetchQueries: [
        {
          query: GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID,
          variables: {
            projectId: project?.id,
          },
        },
      ],
      variables: {
        orgUserIds,
        projectId,
        orgRoleId,
      },
    });
  };

  const handleDeleteProjectAssignment = (projectAssignmentId: string) => {
    deleteProjectAssignmentMutate({
      refetchQueries: [
        {
          query: GET_PROJECT_ASSIGNMENTS_BY_PROJECT_ID,
          variables: {
            projectId: project?.id,
          },
        },
      ],
      variables: {
        projectAssignmentId,
        projectId: project!.id,
      },
    });
  };

  return (
    <ProjectView
      showProjectBoundariesOnMap={showProjectBoundariesOnMap}
      isPopup={isPopup}
      project={project}
      projectAssignments={projectAssignments && projectAssignments.projectAssignmentsByProjectId}
      orgUsers={orgUsers && orgUsers.orgUsersByOrganizationId}
      orgRoles={orgRoles && orgRoles.orgRolesByOrganizationId}
      onClose={onClose}
      onOrgUsersRequest={handleOrgUsersRequest}
      onAddProjectAssignments={handleAddProjectAssignments}
      addProjectAssignmentsStatus={addProjectAssignmentsStatus}
      onDeleteProjectAssignment={handleDeleteProjectAssignment}
      deleteProjectAssignmentStatus={deleteProjectAssignmentStatus}
    ></ProjectView>
  );
};

export default ProjectsViewContainer;
