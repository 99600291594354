import { gql, useQuery } from "@apollo/client";
import { GetAdminUsers } from "./__generated__/GetAdminUsers";
import { ADMIN_USER_FIELDS } from "../fragments/AdminUser";
import { getErrors } from "../../utils";
import { AsyncQueryStatus } from "../../models/AsyncActionStatus";

export const GET_ADMIN_USERS = gql`
  ${ADMIN_USER_FIELDS}
  query GetAdminUsers {
    adminUsers {
      ...AdminUserFields
    }
  }
`;

export function useGetAdminUsers() {
  const { loading, error, data } = useQuery<GetAdminUsers>(GET_ADMIN_USERS, {
    errorPolicy: "all",
  });

  return {
    data,
    status: {
      loading,
      errors: getErrors(error),
      successful: !error && data ? true : false,
    } as AsyncQueryStatus,
  };
}
