import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 55,
    paddingHorizontal: 25,
    fontFamily: "Helvetica",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 12,
    margin: 5,
    color: "gray",
  },
  text: {
    margin: 5,
    fontSize: 12,
    textAlign: "justify",
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 5,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  row: {
    border: "1px solid #666",
    display: "flex",
    flexDirection: "row",
    margin: 0,
  },
});

export default styles;
