import { useQuery } from "@apollo/client";
import MainHeader from "../components/MainHeader";
import { GET_MY_ORGANIZATIONS } from "../operations/queries/getMyOrganizations";
import { GetMyOrganizations } from "../operations/queries/__generated__/GetMyOrganizations";

const MainHeaderContainer = () => {
  const { data } = useQuery<GetMyOrganizations>(GET_MY_ORGANIZATIONS);

  return <MainHeader organizations={data && data.myOrganizations}></MainHeader>;
};

export default MainHeaderContainer;
