import { Divider, Form, InputOnChangeData } from "semantic-ui-react";
import { FormError } from "../models/FormError";
import ErrorsView from "./ErrorsView";

export interface FormData {
  id: string;
  name: string;
  number: string;
  location: string;
  emergencyContact_name: string;
  emergencyContact_phoneNumber: string;
}

interface Props {
  formData: FormData;
  formErrors: FormError;
  errors: string[] | undefined;
  onChange: (formData: FormData) => void;
}

const EditProjectForm = ({ formData, formErrors, errors, onChange }: Props) => {
  const handleChange = (e: any, { name, value }: InputOnChangeData) => {
    onChange({ ...formData, [name]: value });
  };

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Name</label>
            <Form.Input error={formErrors.name} name="name" value={formData.name} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Number</label>
            <Form.Input error={formErrors.number} name="number" value={formData.number} onChange={handleChange} />
          </Form.Field>
          <Form.Field required>
            <label>Location</label>
            <Form.Input error={formErrors.location} name="location" value={formData.location} onChange={handleChange} />
          </Form.Field>
        </Form.Group>
        <br />
        <Divider horizontal>Emergency Contact</Divider>
        <br />
        <Form.Group widths="equal">
          <Form.Field>
            <label>Name</label>
            <Form.Input
              error={formErrors.emergencyContact_name}
              name="emergencyContact_name"
              value={formData.emergencyContact_name}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Phone Number</label>
            <Form.Input
              error={formErrors.emergencyContact_phoneNumber}
              name="emergencyContact_phoneNumber"
              value={formData.emergencyContact_phoneNumber}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <ErrorsView errors={errors} />
    </>
  );
};

export default EditProjectForm;
